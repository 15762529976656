import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

function TruncateTextWithToggle({ text, maxLength }) {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const truncatedText = text.slice(0, maxLength) + '...';
  const displayText = showMore ? text : truncatedText;
  const buttonText = showMore ? 'Show Less' : 'Show More';

  return (
    <div>
      <p className="mb-0">{displayText}</p>
      {text.length > maxLength && (
        <Button variant="link" className="m-0 p-0" onClick={toggleShowMore}>
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export default TruncateTextWithToggle;
