import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';

import { FormSelect } from 'components/elements/form-select/FormSelect';

// import media files
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import FileUploadInput from 'pages/components/FileUploadInput';
import { useFormik } from 'formik';
import {
  useGetLevel1CertificateMutation,
  useGetProfileMutation,
  useUpdateApplicationMutation,
  useUpdateAvatarMutation,
} from 'pages/redux/features/user/userApi';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import { toast } from 'react-toastify';
import FileUploadInput2 from 'pages/components/FileUploadInput2';
import { useDispatch } from 'react-redux';
import { saveUser } from 'pages/redux/features/auth/authSlice';
import { BASE_URL } from 'pages/redux/features/url';
import { CustomButton } from 'pages/components/CustomButton';
import { Document, Page } from 'react-pdf';
import { WarningModal } from 'pages/components/Modals/WarningModal';

const Profile = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: userInfo, token } = useSelector((state) => state.userAuth);
  const [updateDocs, setUpdateDocs] = useState(false);
  const [updatePhoto, setUpdatePhoto] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrorVisible, setModalErrorVisible] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [showPickDpModal, setShowPickDpModal] = useState(false);
  const handleCloseImageModal = () => setShowImageModal(false);
  const handleClosePickDpModal = () => {
    setShowPickDpModal(false);
    setRefresh(!refresh);
  };

  const [refresh, setRefresh] = useState(true);

  const [getLevel1Certificate, { isLoading: isGettingLevel1Certificate }] =
    useGetLevel1CertificateMutation();
  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();
  const [updateApplication, { isLoading: isUpdatingApplication }] =
    useUpdateApplicationMutation();
  const [updateAvatar, { isLoading: isUpdatingAvatar }] =
    useUpdateAvatarMutation();

  function extractLabel(arr, value) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value === value) {
        return arr[i].label;
      }
    }
    return ''; // or throw an error if the value is not found
  }

  const fetchProfile = async () => {
    const res1 = await getProfile();
    if (res1?.data?.success) {
      dispatch(
        saveUser({
          ...res1.data?.data?.user,
          ...res1.data?.data,
          programmes: res1.data?.data?.programmes,
          videos: res1.data?.data?.videos,
          videos_progress: res1.data?.data?.videos_progress,
          uncompleted_surveys: res1.data?.data?.uncompleted_surveys,
          application: res1.data?.data?.application,
        })
      );
    } else {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
      history.push('/');
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [refresh]);

  const formik = useFormik({
    initialValues: {
      highest_certificate: '',
      photo_id: '',
      avatar: '',
    },
    onSubmit: (values) => console.log(values),
  });

  const handleDpChange = async () => {
    const resolveValues = {
      avatar: formik.values?.avatar,
    };
    const formData = objectToFormData(resolveValues);
    logFormData(formData);
    const res = await updateAvatar(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    handleClosePickDpModal();
  };
  const handleDocUpdate = async () => {
    const resolveValues = {
      highest_certificate: formik.values?.highest_certificate,
    };
    const formData = objectToFormData(resolveValues);
    const res = await updateApplication({
      id: userInfo?.application?.id,
      formData,
    });
    logFormData(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      formik.setFieldValue('highest_certificate', '');
      setUpdateDocs(!updateDocs);
    }
    setUpdateDocs(false);
  };

  const handleDocEditEnable = () => {
    setUpdateDocs(true);
  };
  const handlePhotoUpdate = async () => {
    const resolveValues = { photo_id: formik.values?.photo_id };
    const formData = objectToFormData(resolveValues);
    const res = await updateApplication({
      id: userInfo?.application?.id,
      formData,
    });
    logFormData(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      formik.setFieldValue('photo_id', '');
      setUpdatePhoto(!updatePhoto);
    }
    setUpdatePhoto(false);
  };

  const handlePhotoEditEnable = () => {
    setUpdatePhoto(true);
  };
  const [imageSrc, setImageSrc] = useState('');
  const [isGettingCertificate, setIsGettingCertificate] = useState(false);

  const downloadImage = (dataUrl, filename) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleCertificate = async () => {
    if (
      !userInfo?.application?.photo_id ||
      !userInfo?.application?.highest_certificate
    ) {
      let errorArray = [];
      !userInfo?.application?.highest_certificate &&
        errorArray.push(
          'You need to upload your highest educational certificate'
        );
      !userInfo?.application?.photo_id &&
        errorArray.push('You need to upload your photo ID');
      setModalError(errorArray);
      setModalErrorVisible(true);
      return;
    }
    const res = await getLevel1Certificate();

    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      downloadImage(
        res?.data?.image?.encoded,
        'level_1.' + res?.data?.image?.extension
      );
      setRefresh(!refresh);
    } else {
      setModalError(
        res?.error?.data?.message || res?.data?.message || 'Failed!'
      );
      setModalErrorVisible(true);
      // toast.error(
      //   Array.isArray(res?.error?.data?.message)
      //     ? res?.error?.data?.message.join('\n')
      //     : res?.error?.data?.message || res?.data?.message || 'Failed!',
      //   {
      //     position: toast.POSITION.TOP_RIGHT,
      //   }
      // );
    }
  };

  const handleCertificate3 = async () => {
    try {
      const response = await fetch(BASE_URL + 'certificate/level1/get');
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setImageSrc(url);

      toast.success('Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    } catch (error) {
      toast.error(JSON.stringify(error) || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleCertificate1 = async () => {
    setIsGettingCertificate(true);
    fetch(BASE_URL + 'certificate/level1/get', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.blob().then((blob) => {
            const url = URL.createObjectURL(blob);
            setImageSrc(url);

            const link = document.createElement('a');
            link.href = url;
            link.download = 'kap_certificate.jpg';

            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            document.body.removeChild(link);
            setIsGettingCertificate(false);
          });
        } else {
          return response.json();
        }
      })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        setIsGettingCertificate(false);
        toast.error(error?.message || error || 'Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error('Error:', error);
      });
  };

  const handleCertificate5 = async () => {
    setIsGettingCertificate(true);
    let response;
    try {
      response = await fetch(BASE_URL + 'certificate/level1/get', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Unable to get certificate');
      }

      const blob = await response.blob();

      const url = URL.createObjectURL(blob);
      setImageSrc(url);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'kap_certificate.jpg';

      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
      setIsGettingCertificate(false);
    } catch (error) {
      setIsGettingCertificate(false);
      toast.error(error?.message || error || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  return (
    <Container>
      <Row>
        <Col className="mb-4 mt-4">
          <Card className="border-0">
            <Card.Header>
              <div className="mb-3 mb-3">
                <h3 className="mb-0">Profile Details</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="d-lg-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center mb-4 mb-lg-0">
                  {userInfo?.avatar ? (
                    <Image
                      src={userInfo?.avatar}
                      style={{ width: 200, height: 200 }}
                    />
                  ) : (
                    <Icon icon={user} size={60} className="px-3" />
                  )}
                </div>
                <div>
                  <Button
                    variant="outline-white"
                    size="sm"
                    onClick={() => setShowPickDpModal(true)}
                  >
                    Update
                  </Button>{' '}
                </div>
              </div>
              <hr className="my-5" />
              <div>
                <h4 className="mb-4">Personal Details</h4>
                {/* Form */}
                <Form>
                  <Row>
                    {/* First name */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="First Name"
                          required
                          value={userInfo?.firstname}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    {/* Last name */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Last Name"
                          required
                          value={userInfo?.lastname}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    {/* Phone */}
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Email address"
                          required
                          value={userInfo?.email}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone"
                          required
                          value={userInfo?.phone}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <p className="mb-4">
                  If you need to make changes to your profile, please send an
                  email to{' '}
                  <a className="text-primary" href="mailto:info@kap.academy">
                    info@kap.academy
                  </a>{' '}
                </p>
              </div>
              <hr className="my-5" />
              <div>
                <h4 className="mb-4">Certificates</h4>
                <CustomButton
                  size="sm"
                  style={{ minWidth: 100 }}
                  onClick={() => {
                    handleCertificate();
                  }}
                  loading={isGettingCertificate || isGettingLevel1Certificate}
                  text="Get Level 1 Certificate"
                />
              </div>
              <hr className="my-5" />
              {userInfo?.application && (
                <div>
                  <h4 className="mb-4">Editable Application Details</h4>
                  {/* Form */}
                  <Form>
                    <Row>
                      {/* First name */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Label className="d-lg-flex align-items-center justify-content-between w-100">
                          <div className=" w-100">
                            <div className="d-lg-flex align-items-center justify-content-between w-100">
                              <div className="d-flex align-items-center mb-4 mb-lg-0">
                                <p className="mb-0">Highest certificate</p>
                              </div>
                              <div>
                                {updateDocs && (
                                  <Button
                                    variant="outline-white"
                                    size="sm"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        'highest_certificate',
                                        ''
                                      );
                                      setUpdateDocs(!updateDocs);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </div>
                            </div>
                            {updateDocs && (
                              <div className="mb-0">
                                (WASCE, GCE, NECO or similar) with minimum of
                                four(4) O'Level Credits in any subjects Degree
                                Award (Bsc, HND etc)
                              </div>
                            )}
                          </div>
                        </Form.Label>

                        {updateDocs ? (
                          <FileUploadInput
                            selectedFile={formik.values?.highest_certificate}
                            setSelectedFile={(e) => {
                              formik.setFieldValue('highest_certificate', e[0]);
                            }}
                          />
                        ) : userInfo?.application?.highest_certificate ? (
                          userInfo?.application?.highest_certificate?.endsWith(
                            '.pdf'
                          ) ? (
                            <>
                              <Document
                                file={
                                  userInfo?.application?.highest_certificate
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                              <a
                                href={
                                  userInfo?.application?.highest_certificate
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Open file
                              </a>
                            </>
                          ) : (
                            // <iframe
                            //   src={`${`https://research.google.com/pubs/archive/44678.pdf`}#view=Fit`}
                            //   width="100%"
                            //   height="600px"
                            //   title="PDF Preview"
                            // />
                            <Image
                              src={userInfo?.application?.highest_certificate}
                              alt="Certificate"
                              className="w-100"
                              style={{ height: 200 }}
                              onClick={() => {
                                setSelectedImage({
                                  title: 'Highest Certificate',
                                  url: userInfo?.application
                                    ?.highest_certificate,
                                });
                                setShowImageModal(true);
                              }}
                            />
                          )
                        ) : (
                          <div style={{ height: 100 }}>
                            No Document Attached
                          </div>
                        )}
                        {formik.errors.highest_certificate && (
                          <p className="fs-8 text-danger mb-0">
                            {formik.errors.highest_certificate}
                          </p>
                        )}
                      </Col>

                      {/* Last name */}
                      <Col md={6} sm={12} className="mb-3">
                        <Form.Label className=" w-100">
                          <div className=" w-100">
                            <div className="d-lg-flex align-items-center justify-content-between w-100">
                              <div className="d-flex align-items-center mb-4 mb-lg-0">
                                <p className="mb-0">Photo ID</p>
                              </div>
                              <div>
                                {updatePhoto && (
                                  <Button
                                    variant="outline-white"
                                    size="sm"
                                    onClick={() => {
                                      formik.setFieldValue('photo_id', '');
                                      setUpdatePhoto(!updatePhoto);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </div>
                            </div>
                            {updatePhoto && (
                              <div className="mb-0">
                                (NIN, Voter's Card, Int'l Passport, Driver's
                                License, Student ID etc)
                              </div>
                            )}
                          </div>
                        </Form.Label>
                        {updatePhoto ? (
                          <FileUploadInput
                            selectedFile={formik.values?.photo_id}
                            setSelectedFile={(e) => {
                              formik.setFieldValue('photo_id', e[0]);
                            }}
                          />
                        ) : userInfo?.application?.photo_id ? (
                          userInfo?.application?.photo_id?.endsWith('.pdf') ? (
                            <>
                              <Document
                                file={userInfo?.application?.photo_id}
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                              <a
                                href={userInfo?.application?.photo_id}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Open file
                              </a>
                            </>
                          ) : (
                            // <iframe
                            //   src={`${`https://research.google.com/pubs/archive/44678.pdf`}#view=Fit`}
                            //   width="100%"
                            //   height="600px"
                            //   title="PDF Preview"
                            // />
                            <Image
                              src={userInfo?.application?.photo_id}
                              alt="Certificate"
                              className="w-100"
                              style={{ height: 200 }}
                              onClick={() => {
                                setSelectedImage({
                                  title: 'Highest Certificate',
                                  url: userInfo?.application?.photo_id,
                                });
                                setShowImageModal(true);
                              }}
                            />
                          )
                        ) : (
                          <div style={{ height: 100 }}>
                            No Document Attached
                          </div>
                        )}
                        {formik.errors.photo_id && (
                          <p className="fs-8 text-danger mb-0">
                            {formik.errors.photo_id}
                          </p>
                        )}
                      </Col>

                      <Col
                        sm={12}
                        md={12}
                        className="d-flex justify-content-between"
                      >
                        <CustomButton
                          size="sm"
                          type="button"
                          style={{ minWidth: 100 }}
                          onClick={
                            updateDocs || formik.values?.highest_certificate
                              ? handleDocUpdate
                              : handleDocEditEnable
                          }
                          loading={isUpdatingApplication}
                          disabled={
                            updateDocs && !formik.values?.highest_certificate
                          }
                          text={
                            updateDocs || formik.values?.highest_certificate
                              ? 'Update Certificate'
                              : 'Edit Certificate'
                          }
                        />

                        <CustomButton
                          size="sm"
                          type="button"
                          style={{ minWidth: 100 }}
                          onClick={
                            updatePhoto || formik.values?.photo_id
                              ? handlePhotoUpdate
                              : handlePhotoEditEnable
                          }
                          loading={isUpdatingApplication}
                          disabled={updatePhoto && !formik.values?.photo_id}
                          text={
                            updatePhoto || formik.values?.photo_id
                              ? 'Update Photo ID'
                              : 'Edit Photo ID'
                          }
                        />
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <WarningModal
        showModal={modalErrorVisible}
        setShowModal={setModalErrorVisible}
        text={modalError}
        title="Incomplete Criteria for Certificate Approval"
        size="md"
      />
      <Modal show={showImageModal} onHide={handleCloseImageModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedImage?.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Image src={selectedImage?.url} fluid />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPickDpModal} onHide={handleClosePickDpModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{'Profile Picture'}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="d-flex justify-content-center">
          <div className="justify-content-center align-items-center w-100">
            {
              <FileUploadInput
                selectedFile={formik.values?.avatar}
                setSelectedFile={(e) => {
                  formik.setFieldValue('avatar', e[0]);
                }}
              />
            }
            {formik.errors.avatar && (
              <p className="fs-8 text-danger mb-0">{formik.errors.avatar}</p>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleDpChange}>Update</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Profile;
