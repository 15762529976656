const level1Img = 'https://api.kap.academy/storage/uploads/level1Image.jpg';

const level2Img = 'https://api.kap.academy/storage/uploads/level2Image.jpg';
const extraImg3 = 'https://api.kap.academy/storage/uploads/extraImg3.jpg';
const extraImg3jpeg = 'https://api.kap.academy/storage/uploads/extraImg3.jpg';
const financegirl = 'https://api.kap.academy/storage/uploads/financegirl.jpg';
const Directing =
  'https://api.kap.academy/storage/uploads/directing1_backup.jpg';
const Directing1 =
  'https://api.kap.academy/storage/uploads/directing1_backup.jpg';
const logosblack = 'https://api.kap.academy/storage/uploads/logosblack.png';
const logosblack2 = 'https://api.kap.academy/storage/uploads/logosblack2.png';
const masterClassImg =
  'https://api.kap.academy/storage/uploads/masterClassImage.jpg';
const warningsign = 'https://api.kap.academy/storage/uploads/warning-sign.png';
const workingguys = 'https://api.kap.academy/storage/uploads/workingguys.jpg';
const programbackground =
  'https://api.kap.academy/storage/uploads/top-view-film-elements-white-background.jpg';
export {
  level1Img,
  level2Img,
  extraImg3,
  extraImg3jpeg,
  financegirl,
  Directing,
  Directing1,
  logosblack,
  logosblack2,
  masterClassImg,
  warningsign,
  workingguys,
  programbackground,
};
