// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Row,
  Col,
  Image,
  Navbar,
  Nav,
  Container,
  Form,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  Badge,
} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

// simple bar scrolling used for notification item scrolling
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// import custom components
import DotBadge from '../elements/bootstrap/DotBadge';
import DocumentMenu from './DocumentMenu';

// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';

// import data files
import NavbarDefaultRoutes from 'pages/routes/NavbarDefault';
import NotificationList from 'data/Notification';
import NavDropdownMain from 'pages/components/navbars/NavDropdownMain';
import { useDispatch } from 'react-redux';
import {
  logout,
  saveProgrammes,
  saveToUserStore,
  setCompleteApplication,
} from 'pages/redux/features/auth/authSlice';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
  useGetProgramsMutation,
  useGetStudentNotificationsMutation,
  useMarkNotificationMutation,
  useQueryStudentNotificationsQuery,
} from 'pages/redux/features/user/userApi';
import { Loader } from '../Loader';
import ProgramList from 'pages/kapAdmin/programmes/ProgramList';
import { v4 as uuid } from 'uuid';
import { sortProgramsByName } from 'pages/helper/sortProgramsByName';
import localStorage from 'redux-persist/es/storage';
import { logosblack } from 'pages/assets/images/imageUrl';
import { isSubstringInArray } from 'pages/helper/formatArray';

const NavbarDefault = ({ headerstyle, login }) => {
  const {
    user: userInfo,
    programmes,
    studentNotifications,
    unreadStudentNotifications,
  } = useSelector((state) => state.userAuth);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [refresh, setRefresh] = useState(true);
  const [unreadNotif, setunreadNotif] = useState(unreadStudentNotifications);

  const { completeApplication } = useSelector((state) => state.userAuth);

  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });
  const isLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const isMobile = useMediaQuery({
    query: '(min-width: 640px)',
  });

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [menuList, setMenuList] = useState([]);

  const handleLogout = () => {
    localStorage.removeItem('userAccountKap');
    dispatch(logout());
    history.push('/login');
  };

  const [programsDetails, setProgramsDetails] = useState(programmes);
  const [notifications, setNotifications] = useState([]);
  const [notificationError, setNotificationError] = useState(
    studentNotifications?.length == 0 && 'No Notifications yet'
  );

  const [getPrograms, { isLoading: isLoadingPrograms }] =
    useGetProgramsMutation();
  const [markNotification, { isLoading: isMarking }] =
    useMarkNotificationMutation();

  const {
    data: fetchedNotifications,
    isSuccess,
    isError,
    isLoading: isLoadingNotifications,
    isFetching: isFetchingNotifications,
    refetch,
  } = useQueryStudentNotificationsQuery(
    { take: 6, skip: 0 },
    {
      pollingInterval: 30000,
      skip: false,
    }
  );

  useEffect(() => {
    if (isSuccess && !isFetchingNotifications) {
      setNotifications(fetchedNotifications?.data);
      dispatch(
        saveToUserStore({
          value: fetchedNotifications?.unread,
          key: 'unreadStudentNotifications',
        })
      );
      setunreadNotif(fetchedNotifications?.unread);
    } else if (!fetchedNotifications?.success || isError) {
      setNotificationError(
        fetchedNotifications?.message || 'Failed to fetch notifications'
      );
    }
  }, [fetchedNotifications, isSuccess, isFetchingNotifications]);

  const fetchPrograms = async () => {
    const res = await getPrograms();
    if (res?.data?.success) {
      setProgramsDetails(res?.data?.data?.programmes);
      dispatch(saveProgrammes(res?.data?.data?.programmes));
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    dispatch(setCompleteApplication(!!userInfo?.application ? true : false));
    fetchPrograms();
  }, []);

  useEffect(() => {
    if (programsDetails?.length > 0) {
      const navDef = NavbarDefaultRoutes({
        programs: sortProgramsByName(programsDetails)?.map((x) => {
          return {
            id: uuid(),
            menuitem: x?.name,
            link: {
              pathname: '/info',
              state: x,
            },
            available: true,
            // available: !userInfo?.application
            //   ? x?.name?.toLowerCase()?.includes('master')
            //   : !!userInfo?.programmes?.find((y) => y?.name == x?.name),
            // popoverTitle: !x?.name?.toLowerCase()?.includes('master')
            //   ? 'Restricted Access'
            //   : 'Free access',
            // popoverBody: !userInfo?.application
            //   ? 'You need to complete your application'
            //   : 'You need to complete the level 1 programme',
          };
        }),
      });
      setMenuList(navDef);
    }
  }, [programsDetails]);

  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown visibility

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
    refetch();
  };
  const handleMarkAsRead = async (id) => {
    const res = await markNotification({ id });
    if (res?.data?.success) {
      refetch();
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function MarkAsRead({ removable, id }) {
    if (removable) {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Mark as read</Tooltip>}
          >
            <Link to="#" onClick={async () => await handleMarkAsRead(id)}>
              <DotBadge bg="info"></DotBadge>
            </Link>
          </OverlayTrigger>
        </Fragment>
      );
    } else {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Read</Tooltip>}
        >
          <Link to="#">
            <DotBadge bg="secondary"></DotBadge>
          </Link>
        </OverlayTrigger>
      );
    }
  }

  if (isLoadingPrograms && !programmes) {
    return <Loader />;
  }

  const QuickMenu = () => {
    return (
      <Fragment>
        {!completeApplication && isMobile && (
          <Link
            to="/complete-registration"
            className="popup-youtube btn btn-primary btn-sm fs-5 d-flex align-items-center mx-2"
          >
            Complete Application
          </Link>
        )}
        {!isSubstringInArray('notification', [location?.pathname]) ? (
          <Dropdown
            as={Nav.Item}
            show={dropdownOpen}
            onToggle={handleDropdownToggle}
            style={{ marginRight: 10 }}
          >
            <Dropdown.Toggle
              as={Nav.Link}
              bsPrefix="dt"
              className="text-dark icon-notifications me-lg-2 me-2 btn btn-light btn-icon rounded-circle  text-muted mx-3"
              id="dropdownNotification"
            >
              <i className="fa fa-envelope text-black fs-4"></i>
              {unreadNotif > 0 && ( // Check if there are unreadNotif?
                <Badge
                  bg="primary"
                  className="position-absolute top-0 start-100 translate-middle rounded-circle fs-8"
                  style={{
                    maxWidth: 24,
                    maxHeight: 24,
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  {unreadNotif > 99 ? '99+' : unreadNotif}
                </Badge>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-4 py-0"
              aria-labelledby="dropdownNotification"
              align="end"
            >
              <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-left align-items-end">
                <span className="h4 mb-0">Notifications</span>
                {/* <Link to="# " className="text-muted" onClick={refetch}>
                  <span className="align-middle">
                    <i className="fe fe-refresh-ccw me-1"></i>
                  </span>
                </Link> */}
              </div>
              <SimpleBar style={{ maxHeight: '300px' }}>
                <ListGroup variant="flush">
                  {notifications?.map(function (item, index) {
                    return (
                      <ListGroup.Item
                        className={index === 0 ? 'bg-light' : ''}
                        key={index}
                      >
                        <Row>
                          <Col>
                            <Link className="text-body" to="#">
                              <div className="d-flex">
                                <i className="fa fa-bell fs-4"></i>

                                <div className="ms-3">
                                  <h5 className="fw-bold mb-1">
                                    {item?.subject}
                                  </h5>
                                  <p className="mb-3">{item?.body}</p>
                                  <span className="fs-6 text-muted">
                                    <span className="ms-1">
                                      {item?.created_at}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </Col>
                          <Col className="col-auto text-center me-2">
                            <MarkAsRead
                              removable={!item?.read_at}
                              id={item?.id}
                            />
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </SimpleBar>
              <div className="border-top px-3 pt-3 pb-3">
                <Link
                  to="/notifications"
                  className="text-link fw-semi-bold"
                  onClick={() => setDropdownOpen(false)}
                >
                  See all Notifications
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <></>
        )}
        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle
            as={Nav.Link}
            bsPrefix="dt"
            className="text-dark icon-notifications me-lg-2 me-2 btn btn-light btn-icon rounded-circle text-muted"
            id="dropdownUser"
          >
            <div>
              {userInfo?.avatar ? (
                <Image
                  src={userInfo?.avatar}
                  style={{ width: 40, height: 40, borderRadius: 20 }}
                />
              ) : (
                <i className="fa fa-user text-black fs-4"></i>
              )}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownUser"
            align="end"
          >
            <Dropdown.Item className="mt-3">
              <div className="d-flex">
                <div className="avatar avatar-md">
                  <Icon icon={user} size={25} />
                </div>
                <div className="ms-3 lh-1">
                  <h5 className="mb-1">
                    {userInfo?.firstname + ' ' + userInfo?.lastname}
                  </h5>
                  <p className="mb-0 text-muted">{userInfo?.email}</p>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            {isSubstringInArray(
              'student',
              Array.isArray(userInfo?.roles)
                ? typeof userInfo?.roles[0] === 'string'
                  ? userInfo?.roles
                  : userInfo?.roles?.map((x) => {
                      return x?.name;
                    })
                : [userInfo?.roles]
            ) && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="2"
                  onClick={() => {
                    history.push('/profile');
                  }}
                >
                  <i className="fe fe-user me-2"></i> Profile
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Divider />
            <Dropdown.Item className="mb-3" onClick={handleLogout}>
              <i className="fe fe-power me-2"></i> Log Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className={`${login ? 'bg-white' : ''} navbar ${
          headerstyle === 'dark' ? 'navbar-dark bg-dark' : 'navbar-default'
        }`}
        style={{ padding: '0px' }}
      >
        <Container fluid className="px-0 ps-0 ">
          <Navbar.Brand
            as={Link}
            to="/"
            className="justify-content-center  bg-primary"
          >
            <Image src={logosblack} alt="" height="70px" />
          </Navbar.Brand>
          <div
            className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
              login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
            }`}
          >
            <QuickMenu />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {menuList?.length > 0 &&
                menuList?.map((item, index) => {
                  if (item.children === undefined) {
                    return (
                      <Nav.Link
                        key={index}
                        as={Link}
                        to={item.link}
                        className={`d-flex align-items-center`}
                        style={{ marginTop: isDesktop ? '-1px' : '3px' }}
                      >
                        {item.menuitem}
                      </Nav.Link>
                    );
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
              {/* <DocumentMenu /> */}
            </Nav>

            <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
              <span
                className={`${
                  login
                    ? isDesktop || isLaptop
                      ? 'd-flex'
                      : 'd-none'
                    : 'd-none'
                }`}
                style={{ marginTop: isDesktop ? '2px' : '2px' }}
              >
                <QuickMenu />
              </span>
            </Nav>
            {/* end of right side quick / shortcut menu  */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ToastContainer />
    </Fragment>
  );
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
  headerstyle: 'navbar-default',
  login: false,
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
  headerstyle: PropTypes.string,
  login: PropTypes.bool,
};

export default NavbarDefault;
