// import node module libraries
import { Link } from 'react-router-dom';
import { Clock, Video, Users } from 'react-feather';
import {
  Container,
  Image,
  Button,
  Row,
  Col,
  Card,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
  CardGroup,
} from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiEmail } from '@mdi/js';

import { Fragment, useMemo } from 'react';
import Carousel from 'react-multi-carousel';
import { formatKobo } from 'pages/helper/formatText';
import moment from 'moment';
import { isSubstringInArray } from 'pages/helper/formatArray';

const StatsSection = ({ item, bgColor, videos, mainTextColor }) => {
  const details = useMemo(() => {
    let filtered = item;
    return filtered;
  }, [item]);

  return (
    <Fragment>
      {details && (
        <div className={`py-md-6 py-6 ${bgColor || 'bg-black'}`}>
          <Container>
            <Row>
              <StatsCards details={details} videos={videos} />
            </Row>
          </Container>
        </div>
      )}
    </Fragment>
  );
};
export default StatsSection;

const StatsCards = ({ details, videos }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  return (
    <Carousel responsive={responsive} itemAriaLabel={'itemStats'}>
      <Card
        style={{
          width: 220,
          alignContent: 'center',
        }}
        className={'bg-white'}
      >
        <Card.Body className="text-center">
          <h4 className="mb-3 text-uppercase">Tuition</h4>
          <h2 className={`fw-bold ls-sm text-primary`}>
            {details?.price ? '₦' + formatKobo(details?.price) : 'FREE'}
          </h2>
        </Card.Body>
      </Card>
      {details?.courses?.length > 0 ||
      isSubstringInArray('speciali', [details?.name || '']) ? (
        <Card
          style={{
            width: 220,
            alignContent: 'center',
          }}
          className={'bg-white'}
        >
          <Card.Body className="text-center">
            <h4 className="mb-3 text-uppercase">Courses</h4>
            <h2 className={`fw-bold ls-sm text-primary`}>
              {details?.courses?.length}
            </h2>
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            width: 220,
            alignContent: 'center',
          }}
          className={'bg-white'}
        >
          <Card.Body className="text-center">
            <h4 className="mb-3 text-uppercase">Videos</h4>
            <h2 className={`fw-bold ls-sm text-primary`}>
              {videos?.masterclass?.length}
            </h2>
          </Card.Body>
        </Card>
      )}
      {details?.duration && (
        <Card
          style={{
            width: 220,
            alignContent: 'center',
          }}
          className={'bg-white'}
        >
          <Card.Body className="text-center">
            <h4 className="mb-3 text-uppercase">Duration</h4>
            <h2 className={`fw-bold ls-sm text-primary`}>
              {details?.duration}
            </h2>
          </Card.Body>
        </Card>
      )}
      {!!details?.starts ? (
        <Card
          style={{
            width: 220,
            alignContent: 'center',
          }}
          className={'bg-white'}
        >
          <Card.Body className="text-center">
            <h4 className="mb-3 text-uppercase">Starts</h4>
            <h2 className={`fw-bold ls-sm text-primary`}>
              {moment(details?.starts).format('MMM Do YYYY')}
            </h2>
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            width: 220,
            alignContent: 'center',
          }}
          className={'bg-white'}
        >
          <Card.Body className="text-center">
            <h4 className="mb-3 text-uppercase">Starts</h4>
            <h2 className={`fw-bold ls-sm text-primary`}>
              {isSubstringInArray('speciali', [details?.name || ''])
                ? 'N/A'
                : 'ONGOING'}
            </h2>
          </Card.Body>
        </Card>
      )}
    </Carousel>
  );
};
