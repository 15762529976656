// import node module libraries
import { Fragment } from 'react';
import { Menu, Search } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import { Nav, Navbar, Dropdown, Image } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { logout } from 'pages/redux/features/auth/authSlice';
import Icon from 'react-icons-kit';
import { user } from 'react-icons-kit/fa/user';
import { useSelector } from 'react-redux';
import { isSubstringInArray } from 'pages/helper/formatArray';

const HeaderDefault = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: userInfo } = useSelector((state) => state.userAuth);
  const handleLogout = () => {
    dispatch(logout());
    history.push('/login');
  };
  return (
    <Fragment>
      <Navbar expanded="lg" className="navbar-default">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Link
              id="nav-toggle"
              to="#"
              onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}
            >
              <Menu size="18px" />
            </Link>
          </div>

          <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                bsPrefix="dt"
                className="text-dark icon-notifications me-lg-2 me-2 btn btn-light btn-icon rounded-circle text-muted"
                id="dropdownUser"
              >
                <div>
                  {userInfo?.avatar ? (
                    <Image
                      src={userInfo?.avatar}
                      style={{ width: 40, height: 40, borderRadius: 20 }}
                    />
                  ) : (
                    <i className="fa fa-user text-black fs-4"></i>
                  )}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
                aria-labelledby="dropdownUser"
                align="end"
              >
                <Dropdown.Item className="mt-3">
                  <div className="d-flex">
                    <div className="avatar avatar-md">
                      <Icon icon={user} size={25} />
                    </div>
                    <div className="ms-3 lh-1">
                      <h5 className="mb-1">
                        {userInfo?.firstname + ' ' + userInfo?.lastname}
                      </h5>
                      <p className="mb-0 text-muted">{userInfo?.email}</p>
                    </div>
                  </div>
                </Dropdown.Item>
                {isSubstringInArray(
                  'student',
                  Array.isArray(userInfo?.roles)
                    ? typeof userInfo?.roles[0] === 'string'
                      ? userInfo?.roles
                      : userInfo?.roles?.map((x) => {
                          return x?.name;
                        })
                    : [userInfo?.roles]
                ) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      eventKey="2"
                      onClick={() => {
                        history.push('/profile');
                      }}
                    >
                      <i className="fe fe-user me-2"></i> Profile
                    </Dropdown.Item>
                  </>
                )}

                <Dropdown.Divider />
                <Dropdown.Item className="mb-3" onClick={handleLogout}>
                  <i className="fe fe-power me-2"></i> Log Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Navbar>
    </Fragment>
  );
};

export default HeaderDefault;
