// import node module libraries
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  ListGroup,
  Toast,
  Spinner,
} from 'react-bootstrap';
import { Trash, Edit, Inbox, MoreVertical } from 'react-feather';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import AddNewAnnouncement from './AddNewLiveClass';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useGetAnnouncementsMutation,
  useGetLiveClassesMutation,
  useGetCoursesLiteMutation,
  useGetVacanciesMutation,
} from 'pages/redux/features/user/userApi';
import { sortByProperty } from 'pages/helper/formatArray';
import { truncateText } from 'pages/helper/formatText';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { saveProgrammes } from 'pages/redux/features/auth/authSlice';
import AddNewLiveClass from './AddNewLiveClass';
import moment from 'moment';

const LiveClassList = () => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [getLiveClasses, { isLoading }] = useGetLiveClassesMutation();
  const [refresh, setRefresh] = useState(false);
  const [initial, setInitial] = useState(null);
  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const { programmes } = useSelector((state) => state.userAuth);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setType('add');
    setInitial(null);
    setShow(true);
  };

  const fetchLiveClasses = async () => {
    const res = await getLiveClasses();
    if (res?.data?.success) {
      setList(res?.data?.meetings);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchLiveClasses();
  }, [refresh]);

  const courseOptions = useMemo(() => {
    let filtered = courses || [];
    filtered = filtered?.map((x) => {
      return { label: x?.title, value: x?.id, id: x?.id };
    });
    return filtered;
  }, [courses]);
  const [getCoursesLite, { isLoading: isLoadingCoursesLite }] =
    useGetCoursesLiteMutation();
  const fetchCourses = async () => {
    const res = await getCoursesLite();
    if (res?.data?.success) {
      setCourses(res?.data?.data?.courses);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'topic',
        Header: 'Topic',
        Cell: ({ value, row }) => {
          return <h5 onClick={() => onRowClick(row.original)}>{value}</h5>;
        },
      },
      {
        accessor: 'description',
        Header: 'Description',
        Cell: ({ value }) => {
          return truncateText(value, 50);
        },
      },
      {
        accessor: 'course_id',
        Header: 'Course',
        Cell: ({ value }) => {
          return courseOptions?.find((x) => x?.id == value)?.label;
        },
      },
      {
        accessor: 'duration',
        Header: 'Duration',
      },
      {
        accessor: 'start_time',
        Header: 'Start time',
        Cell: ({ value }) => {
          return moment(value).format('hh:mm:ss DD-MM-YYYY');
        },
      },
      {
        accessor: 'start_link',
        Header: 'Start link',
        Cell: ({ value }) => {
          return (
            <a href={value} target="_blank" rel="noreferrer">
              {truncateText(value)}
            </a>
          );
        },
      },
      {
        accessor: 'join_link',
        Header: 'Join link',
        Cell: ({ value }) => {
          return (
            <a href={value} target="_blank" rel="noreferrer">
              {truncateText(value)}
            </a>
          );
        },
      },
      {
        accessor: 'meet_id',
        Header: 'Meet ID',
      },
      {
        accessor: 'password',
        Header: 'Password',
      },
      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    [courseOptions]
  );

  const data = useMemo(() => {
    let filtered = list || [];
    if (filtered.length > 0) {
      filtered = sortByProperty(filtered, 'created_at', 'desc');
      // filtered = filtered.filter((x, index) => !x?.deleted_at);
      filtered = filtered.map((x, index) => {
        return {
          ...x,
          course: x?.program,
          sn: index + 1,
        };
      });
    }

    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const getRowProps = useCallback((row) => {
    return {
      style: {
        backgroundColor: row.original.deleted_at ? '#DC00001A' : 'white',
      },
    };
  }, []);

  const onRowClick = (val) => {
    setType('edit');
    setInitial(val);
    setShow(true);
  };

  if (isLoading || isLoadingCoursesLite) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Live Classes</h1>
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                Create Live Class
              </Button>
              <AddNewLiveClass
                show={show}
                handleClose={handleClose}
                refresh={refresh}
                initialState={initial}
                type={type}
                setRefresh={setRefresh}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <div className="overflow-hidden">
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className="mb-lg-0 mb-2 px-5 py-4"
                    >
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                        placeholder="Search List"
                      />
                    </Col>
                  </Row>
                </div>
                <Table
                  {...getTableProps()}
                  className="text-nowrap striped bordered hover"
                  style={{ position: 'relative', zIndex: 1000 }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps(getRowProps(row))}
                          // onClick={() => onRowClick(row.original)}
                          className="clickable-row"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {/* Pagination @ Footer */}
                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default LiveClassList;
