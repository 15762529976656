// import node module libraries
import React, { Fragment, useEffect } from "react";

// import layouts
import NavbarDefault from "./navbars/NavbarDefault";
import Footer from "./footers/Footer";

const LayoutDefault = (props) => {
  const user = localStorage.getItem("user");
  useEffect(() => {
    document.body.style.backgroundColor = "#f5f4f8";
  });
  return (
    <Fragment>
      <NavbarDefault login user={user} />
      <div style={{ minHeight: window.innerHeight - 150 }}>{props.children}</div>
      <Footer />
    </Fragment>
  );
};

export const NoLayout = (props) => {
  return <div style={{ minHeight: window.innerHeight }}>{props.children}</div>;
};

export default LayoutDefault;
