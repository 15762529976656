// import node module libraries
import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Col,
  Row,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  CloseButton,
  Image,
  Container,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import data files
import {
  useGetPostsMutation,
  useGetStudentNotificationsMutation,
  useMarkNotificationMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { saveToUserStore } from 'pages/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Loader } from 'pages/components/Loader';
import {
  sortAndRemoveDuplicates,
  sortedDataByCreatedAt,
} from 'pages/helper/formatArray';
import { mdiForum } from '@mdi/js';
import Icon from '@mdi/react';
import { truncateText } from 'pages/helper/formatText';

const Forums = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { studentNotifications } = useSelector((state) => state.userAuth);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [posts, setPosts] = useState([]);

  const [getPosts, { isLoading }] = useGetPostsMutation();

  const fetchForums = async () => {
    const res = await getPosts();
    console.log('res', res);
    if (res?.data?.success) {
      setPosts(res?.data?.data?.posts?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchForums();
  }, [refresh]);

  const filteredPosts = useMemo(() => {
    let filtered = posts;
    filtered = filtered?.map((x, index) => {
      return {
        icon: mdiForum,
        ...x,
      };
    });
    return filtered;
  }, [posts]);

  return (
    <Container className="mt-4 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 font-weight-bold">Forum</h1>
            </div>
            <div>
              <Link
                to="#"
                className="btn btn-white"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                {isLoading ? 'Refreshing' : 'Refresh'}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          {posts?.length === 0 && isLoading ? (
            <Card
              className={`rounded-3 ${
                posts?.length === 0 ? 'bg-transparent' : 'bg-white'
              }`}
            >
              <Card.Body
                className={`rounded-3 p-0 ${
                  posts?.length === 0 ? 'bg-transparent' : 'bg-white'
                }`}
              >
                <Loader />{' '}
              </Card.Body>
            </Card>
          ) : posts?.length === 0 ? (
            <Card
              className={`rounded-3 ${
                posts?.length === 0 ? 'bg-transparent' : 'bg-white'
              }`}
            >
              <Card.Body
                className={`rounded-3 p-0 ${
                  posts?.length === 0 ? 'bg-transparent' : 'bg-white'
                }`}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: window.innerHeight / 1.3 }}
                >
                  No posts available
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Row>
              {filteredPosts.map((item, index) => {
                return (
                  <Col md={4} sm={12} key={index}>
                    <Card
                      className="mb-4"
                      onClick={() => {
                        history.push({
                          pathname: '/forum/' + item?.id,
                          state: item,
                        });
                      }}
                    >
                      <Card.Body className="p-5">
                        <div className="mb-3">
                          <Icon
                            path={item.icon}
                            size={1.5}
                            className="text-primary"
                          />
                        </div>
                        <h4 className="mb-2">{item.title}</h4>
                        <p className="mb-0">{truncateText(item.body, 150)}</p>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Forums;
