// import node module libraries
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  setAuthState,
  setCredentials,
  setRole,
} from 'pages/redux/features/auth/authSlice';
import { useSelector } from 'react-redux';
import {
  useForgotPasswordOtpMutation,
  useResetPasswordMutation,
  useResetPasswordRRMutation,
  useVerifyOtpMutation,
} from 'pages/redux/features/auth/authApi';
import { toast } from 'react-toastify';
import { CustomButton } from 'pages/components/CustomButton';
import * as Yup from 'yup';
import { logosblack } from 'pages/assets/images/imageUrl';

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
};

const INITIAL_COUNT = 60;

const ResetPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(location?.state?.detail);

  const [counter, setCounter] = useState(60);
  const [prevCounter, setPrevCounter] = useState(60);

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [initial, setIntial] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const [otpSent, setOtpSent] = useState(true);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60;

  useEffect(() => {
    setMessage('Verification code has been sent to your email address');
  }, [location]);

  const [verifyOtp, { isLoading: isVerifying }] = useVerifyOtpMutation();
  const [forgotPasswordOtp, { isLoading }] = useForgotPasswordOtpMutation();

  const handleForgotPassword = async () => {
    const res = await forgotPasswordOtp({ email: location?.state?.email });
    if (res?.data?.success) {
      handleReset();
      !otpSent && setOtpSent(true);
      toast.success('A new verification code has been sent', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    !location?.state?.type && history.push('/login');
  }, []);
  useEffect(() => {
    handleStart();
  }, []);

  const handleStart = () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
    setIntial(INITIAL_COUNT);
  };

  const handleReset = () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(initial * 2);
    setIntial(initial * 2);
  };

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  // source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const twoDigits = (num) => String(num).padStart(2, '0');

  const [resetPassword, { isLoading: isResetting }] =
    useResetPasswordMutation();
  const [resetPasswordRR, { isLoading: isResettingRR }] =
    useResetPasswordRRMutation();

  const handleResetPassword = async (values) => {
    const res = await resetPassword({
      ...values,
      otp: values?.code,
      ...location?.state,
    });
    if (res?.data?.success) {
      toast.success('Password reset successfully, Login with new password', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push('/login');
    } else {
      toast.error('Password reset failed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    // code: Yup.string()
    //   .required('Required')
    //   .min(6, 'Code must be at least 8 characters long'),
    password: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters long'),
    confirmpassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  useEffect(() => {
    !location?.state?.type && history.push('/login');
  }, []);

  const formik = useFormik({
    initialValues: {
      code: '',
      password: '',
      confirmPassword: '',
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      handleResetPassword(values);
    },
  });

  const handleOtp = async (values) => {
    const res = await verifyOtp({ otp: values?.code });
    if (res?.data?.success) {
      toast.success('Verification successful', {
        position: toast.POSITION.TOP_RIGHT,
      });
      location?.state?.type === 'forgot-password'
        ? history.push({
            pathname: '/reset-password',
            state: {
              detail: res?.data?.message,
              email: location?.state?.email,
              otp: values?.code,
              type: 'forgot-password',
            },
          })
        : history.push('/login');
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 7000,
      });
    }
  };

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={9} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Header className="mb-1">
              <Image src={logosblack} alt="" className="img-fluid w-50" />
            </Card.Header>
            <Card.Body className="p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Reset Password</h1>
                <span>
                  {message || 'Enter the code sent to your email/phone number.'}
                </span>
              </div>
              {/* Form */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/*  email */}
                    <Form.Label className="d-flex justify-content-between ">
                      Code
                      <span>
                        {otpSent &&
                          (secondsRemaining === 0 ? (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleForgotPassword();
                              }}
                              className="text-primary"
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                              }}
                            >
                              Resend
                            </button>
                          ) : (
                            <div>
                              {twoDigits(hoursToDisplay)}:
                              {twoDigits(minutesToDisplay)}:
                              {twoDigits(secondsToDisplay)}
                            </div>
                          ))}
                      </span>
                    </Form.Label>

                    <Form.Control
                      type="text"
                      id="code"
                      placeholder="Enter your code"
                      required
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      name="password"
                      placeholder="**************"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.password}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      id="confirmpassword"
                      name="confirmpassword"
                      placeholder="**************"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.confirmpassword}
                    />
                    {formik.errors.confirmpassword && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.confirmpassword}
                      </p>
                    )}
                  </Col>

                  <Col lg={12} md={12} className="mb-3 d-grid gap-2">
                    <CustomButton
                      variant="primary"
                      type="submit"
                      text="Verify"
                      loading={isLoading || isVerifying}
                      disabled={formik.isSubmitting || isLoading || isVerifying}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ResetPassword;
