// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAdmitWithScholarshipByIdMutation,
  useCreateCourseMutation,
  useGetTeachersMutation,
  useUpdateCourseMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';

const AddCourse = ({ show, handleClose, id, type, initialState }) => {
  const [instructors, setInstructors] = useState([]);

  const [createCourse, { isLoading: isCreatingCourse }] =
    useCreateCourseMutation();

  const [updateCourse, { isLoading: isUpdatingCourse }] =
    useUpdateCourseMutation();

  const [getTeachers, { isLoading: fetchingTeachers }] =
    useGetTeachersMutation();

  const fetchInstructors = async () => {
    const res = await getTeachers();
    if (res?.data?.success) {
      setInstructors(
        res?.data?.data?.map((x, index) => {
          return {
            label: x?.firstname + ' ' + x?.lastname,
            value: x?.id,
            id: x?.id,
          };
        })
      );
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchInstructors();
  }, []);

  const handleCourseCreation = async (formData) => {
    const res =
      type == 'edit'
        ? await updateCourse({ id: initialState?.id, formData })
        : await createCourse(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    description: Yup.string().required('Required').typeError('Text expected'),
    programme_id: Yup.string()
      .required('Required')
      .typeError('Program Id expected'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      description: initialState?.description || '',
      programme_id: id,
      pre_class_video: initialState?.pre_class_video || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveInstr = { ...values };
      !(values?.pre_class_video instanceof File) &&
        delete resolveInstr?.pre_class_video;
      const formData = objectToFormData(resolveInstr);
      logFormData(formData);
      handleCourseCreation(formData);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Course' : 'Add Course'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pre class video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                id="pre_class_video"
                multiple
                required
                onChange={(e) => {
                  formik.setFieldValue(
                    'pre_class_video',
                    e.currentTarget.files[0]
                  );
                }}
              />
              {formik.errors.pre_class_video && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.pre_class_video}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isCreatingCourse || isUpdatingCourse}
        >
          {isCreatingCourse || isUpdatingCourse ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCourse;
