import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../features/url';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
  }),

  endpoints: (builder) => ({
    signupUser: builder.mutation({
      query: ({ firstname, lastname, phone, email, password }) => ({
        url: 'auth/register',
        method: 'POST',
        body: { firstname, lastname, phone, email, password },
      }),
    }),
    signupSuperAdmin: builder.mutation({
      query: ({ firstname, lastname, phone, email, password }) => ({
        url: 'auth/register/superadmin',
        method: 'POST',
        body: { firstname, lastname, phone, email, password },
      }),
    }),
    createAdmin: builder.mutation({
      query: (data) => ({
        url: 'users/create-admin',
        method: 'POST',
        body: data,
      }),
    }),
    loginUser: builder.mutation({
      query: ({ email, password }) => {
        return {
          url: 'auth/signin',
          method: 'POST',
          body: {
            email,
            password,
          },
        };
      },
    }),
    loginSIS: builder.mutation({
      query: ({ id }) => {
        return {
          url: `sis/${id}`,
          method: 'GET',
        };
      },
    }),
    loginAdmin: builder.mutation({
      query: ({ email, password }) => {
        return {
          url: 'auth/signin',
          method: 'POST',
          body: {
            email,
            password,
          },
        };
      },
    }),
    facebookSignup: builder.mutation({
      query: () => {
        return {
          url: 'auth/social/facebook',
          method: 'GET',
        };
      },
    }),
    twitterSignup: builder.mutation({
      query: () => {
        return {
          url: 'auth/social/twitter',
          method: 'GET',
        };
      },
    }),
    deleteUser: builder.mutation({
      query: ({ email }) => {
        return {
          url: 'auth/delete',
          method: 'DELETE',
          body: {
            email,
          },
        };
      },
    }),
    verifyOtp: builder.mutation({
      query: ({ otp }) => ({
        url: 'auth/verify-otp',
        method: 'POST',
        body: { otp },
      }),
    }),
    forgotPasswordOtp: builder.mutation({
      query: ({ email }) => ({
        url: 'auth/forgot-password',
        method: 'POST',
        body: { email },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, confirmpassword, email, otp }) => ({
        url: 'auth/reset-password-otp',
        method: 'POST',
        body: { password, password_confirmation: confirmpassword, email, otp },
      }),
    }),
    resetPasswordRR: builder.mutation({
      query: ({ password, confirmpassword }) => ({
        url: 'auth/reset-password/free',
        method: 'POST',
        body: { password, password_confirmation: confirmpassword },
      }),
    }),
  }),
});

export const {
  useLoginUserMutation,
  useSignupUserMutation,
  useResetPasswordMutation,
  useVerifyOtpMutation,
  useForgotPasswordOtpMutation,
  useDeleteUserMutation,
  useCreateAdminMutation,
  useLoginAdminMutation,
  useFacebookSignupMutation,
  useTwitterSignupMutation,
  useSignupSuperAdminMutation,
  useLoginSISMutation,
  useResetPasswordRRMutation,
} = authApi;
