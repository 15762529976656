// import node module libraries
import { forwardRef, Fragment, useEffect, useRef, useState } from 'react';
import { Form, Button, Modal, Toast } from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useEnrollStudentForCourseMutation,
  useGetProfileMutation,
  useGetStudentProgrammesMutation,
  useMakePaymentMutation,
  useRecordPaymentMutation,
  useVerifyPaymentMutation,
  useVerifyScholarshipCodeLevel2Mutation,
  useVerifyScholarshipCodeMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { usePaystackPayment } from 'react-paystack';
import { useSelector } from 'react-redux';
import {
  saveProgrammesStarted,
  saveUser,
} from 'pages/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { CustomButton } from 'pages/components/CustomButton';
import { isSubstringInArray } from 'pages/helper/formatArray';
import { getValue } from 'pages/helper/formatObject';
import { useHistory } from 'react-router-dom';
import { Loader } from 'pages/components/Loader';

const AccessProgramme = ({
  show,
  handleClose,
  size = 'lg',
  level,
  centered = true,
  currentLevelId = 2,
  course,
  scholarshipUsed,
  amount = 10000000,
}) => {
  const history = useHistory();
  const parentOptions = [
    { value: 'scholarship', label: 'Scholarship' },
    { value: 'paystack', label: 'Without Scholarship' },
  ];
  const parentOptions1 = [{ value: 'paystack', label: 'Card/Online Payment' }];
  const { user, programmesStarted, programmes } = useSelector(
    (state) => state.userAuth
  );
  const dispatch = useDispatch();
  const [config, setConfig] = useState({
    email: user?.email,
    amount: amount,
    publicKey: 'pk_live_485cbf47022ff2d8d230ca3f79bfda3d91b6d05c',
  });
  const [triggerPaystack, setTriggerPaystack] = useState(false);
  const [makePayment, { isLoading: isMakingPayment }] =
    useMakePaymentMutation();

  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();
  const [
    verifyScholarshipCodeLevel2,
    { isLoading: isVerifyingScholarshipCodeLevel2 },
  ] = useVerifyScholarshipCodeLevel2Mutation();
  const [verifyScholarshipCode, { isLoading: isVerifyingScholarshipCode }] =
    useVerifyScholarshipCodeMutation();
  const [getStudentProgrammes, { isLoading: isGettingStudentProgrammes }] =
    useGetStudentProgrammesMutation();
  const [recordPayment, { isLoading: isRecordingPayment }] =
    useRecordPaymentMutation();
  const [enrollStudentForCourse, { isLoading: isEnrolling }] =
    useEnrollStudentForCourseMutation();

  const validationSchema = Yup.object().shape({
    type: Yup.object().required('Admission type is required'),
  });

  const formik = useFormik({
    initialValues: {
      type: '',
      scholarshipCode: '',
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      handleConfirmation(values);
    },
  });

  const fetchProfile = async () => {
    const res1 = await getProfile();
    formik.resetForm();
    if (res1?.data?.success) {
      dispatch(
        saveUser({
          ...res1.data?.data?.user,
          programmes: res1.data?.data?.programmes,
          videos: res1.data?.data?.videos,
          videos_progress: res1.data?.data?.videos_progress,
          uncompleted_surveys: res1.data?.data?.uncompleted_surveys,
          application: res1.data?.data?.application,
        })
      );
    } else {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
      history.push('/');
    }
  };

  useEffect(() => {
    show && fetchProfile();
  }, [show]);

  useEffect(() => {
    setConfig({
      email: user?.email,
      amount: amount,
      publicKey: 'pk_live_485cbf47022ff2d8d230ca3f79bfda3d91b6d05c',
    });
  }, [amount, level, currentLevelId, show]);

  const onSuccess = async (result) => {
    const res = await recordPayment({
      programme_id: currentLevelId,
      course_id: course?.id,
      reference: result?.reference,
      trans: result?.trans,
      status: result?.status,
      message: result?.message,
      transaction: result?.transaction,
      trxref: result?.trxref,
      redirecturl: result?.redirecturl,
    });

    if (res?.data?.success) {
      dispatch(saveUser({ ...user, programmes: res?.data?.data?.programmes }));
      await fetchProfile();
      let extProg = programmesStarted;
      res?.data?.data?.programmes.forEach((obj1) => {
        let objectExists = programmesStarted.some((obj2) => {
          return obj1.id == obj2.id;
        });

        if (!objectExists) {
          programmesStarted.push({ obj1, started: false });
        }
      });
      dispatch(saveProgrammesStarted(extProg));
      toast.success('Payment successful', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onClose = (error) => {
    toast.error('Payment cancelled!', {
      position: toast.POSITION.TOP_RIGHT,
    });
    // Perform any additional logic or redirect to error page
  };
  const initializePayment = usePaystackPayment(config);

  const handlePayNow = () => {
    initializePayment(
      (e) => onSuccess(e),
      (e) => onClose(e)
    );
  };

  const handleScholarship = async ({ type, scholarshipCode }) => {
    const currentLevel = programmes?.find((x) => x?.id == currentLevelId);
    const res = currentLevel?.name?.toLowerCase()?.includes('speciali')
      ? await verifyScholarshipCodeLevel2({
          code: scholarshipCode,
          course_id: course?.id,
        })
      : await verifyScholarshipCode({
          code: scholarshipCode,
        });

    if (res?.data?.success) {
      await fetchProfile();
      const res1 = await getStudentProgrammes();
      if (res1?.data?.success) {
        toast.success(res?.data?.message || 'Enrolled successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose(true);
      } else {
        toast.error(res?.data?.message || 'Refresh required', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error(res?.error?.data?.message || 'Application code is invalid!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleConfirmation = async (values) => {
    const { type, scholarshipCode } = values;
    getValue(type?.value)?.includes('scholarship')
      ? handleScholarship({
          type: getValue(type?.value),
          scholarshipCode,
        })
      : handlePayNow();
  };

  return (
    <Modal show={show} onHide={handleClose} size={size} centered={centered}>
      <Modal.Header closeButton>
        <Modal.Title>
          Access {!course ? 'Programme' : course?.title + ' Course' || 'Course'}
        </Modal.Title>
      </Modal.Header>
      {isGettingProfile ? (
        <Loader isFullScreen={false} height={200} />
      ) : (
        <Modal.Body>
          <Fragment>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>
                  {!course ? 'Admission' : 'Enrollment'} Type
                </Form.Label>
                <MultiSelectDropdown
                  options={
                    scholarshipUsed ||
                    ('verified' ==
                      user?.application?.scholarship_code_status_level_2?.toLowerCase() &&
                      isSubstringInArray('admitted', [
                        user?.application?.status_level_2,
                      ]))
                      ? parentOptions1
                      : parentOptions
                  }
                  selected={formik?.values?.type}
                  setSelected={(e) => {
                    formik.setFieldValue('type', e);
                  }}
                  placeholder={!course ? 'Admission type' : 'Enrollment type'}
                />
                {formik.errors.type && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.type}</p>
                )}
              </Form.Group>
              {isSubstringInArray(
                formik?.values?.type?.value || formik?.values?.type,
                ['scholarship']
              ) && (
                <Form.Group className="mb-3">
                  <Form.Label>Scholarship Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="scholarshipCode"
                    placeholder="Scholarship Code"
                    required
                    onChange={formik.handleChange}
                  />
                  {formik.errors.scholarshipCode && (
                    <p className="fs-8 text-danger mb-0">
                      {formik.errors.scholarshipCode}
                    </p>
                  )}
                </Form.Group>
              )}
            </Form>
          </Fragment>
        </Modal.Body>
      )}
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <CustomButton
          variant="outline-white"
          onClick={handleClose}
          disabled={isMakingPayment}
          size="md"
          style={{ minWidth: 100 }}
          text="Close"
        />
        <CustomButton
          size="md"
          style={{ minWidth: 100 }}
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isMakingPayment}
          loading={
            isMakingPayment ||
            isVerifyingScholarshipCode ||
            isVerifyingScholarshipCodeLevel2 ||
            isGettingProfile
          }
          text={
            isSubstringInArray(
              formik?.values?.type?.value || formik?.values?.type,
              ['paystack']
            )
              ? 'Pay'
              : 'Confirm'
          }
        />
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AccessProgramme;
