// ** Import from react dom
import { Route, Switch, Redirect, useHistory } from "react-router-dom";

// ** Import core SCSS styles
import "assets/scss/theme.scss";

// ** Import Layouts
import DashboardIndex from "pages/components/navbars/DashboardIndex";
import AuthLayout from "layouts/dashboard/AuthLayout";

// ** Import Dahbaord Menu Pages

import Register from "pages/auth/Register";
import ForgotPassword from "pages/auth/ForgotPassword";
import Login from "pages/auth/Login";
import ResetPassword from "pages/auth/ResetPassword";
import LayoutDefault, { NoLayout } from "pages/components/LayoutDefault";
import VerifyAccount from "pages/auth/VerifyAccount";
import { useSelector } from "react-redux";
import StudentDashboard from "pages/kapStudent/dashboard/StudentDashboard";
import JobsDashboard from "pages/kapStudent/dashboard/JobsDashboard";
import FinanceDashboard from "pages/kapStudent/dashboard/FinanceDashboard";
import ResourceDashboard from "pages/kapStudent/dashboard/ResourceDashboard";
import CompleteRegistration from "pages/kapStudent/dashboard/CompleteRegistration";
import AdminDashboard from "pages/kapAdmin/dashboard/AdminDashboard";
import ApplicationList from "pages/kapAdmin/admissions/ApplicationList";
import AdminList from "pages/kapAdmin/userManagement/AdminList";
import InstructorList from "pages/kapAdmin/userManagement/InstructorList";
import StudentList from "pages/kapAdmin/userManagement/StudentList";
import AdminDetails from "pages/kapAdmin/userManagement/AdminDetails";
import InstructorDetails from "pages/kapAdmin/userManagement/InstructorDetails";
import StudentDetails from "pages/kapAdmin/userManagement/StudentDetails";
import ApplicationDetails from "pages/kapAdmin/admissions/ApplicationDetails";
import StudentSIS from "pages/kapStudent/dashboard/StudentSIS";
import ProgramList from "pages/kapAdmin/programmes/ProgramList";
import ProgramDetails from "pages/kapAdmin/programmes/ProgramDetails";
import JobList from "pages/kapAdmin/jobs/JobList";
import JobDetails from "pages/kapAdmin/jobs/JobDetails";
import FinanceDetails from "pages/kapAdmin/finance/FinanceDetails";
import ResourceDetails from "pages/kapAdmin/resources/ResourceDetails";
import FinanceList from "pages/kapAdmin/finance/FinanceList";
import ResourceList from "pages/kapAdmin/resources/ResourceList";
import CourseList from "pages/kapAdmin/courses/CourseList";
import CourseDetails from "pages/kapAdmin/courses/CourseDetails";
import SingleProgramme from "pages/kapStudent/programmes/SingleProgramme";
import DashboardStudent from "pages/kapStudent/dashboard/Dashboard";
import Profile from "pages/kapStudent/profile/Profile";
import EditProfile from "pages/kapStudent/profile/EditProfile";
import NotFound from "pages/layouts/marketing/NotFound";
import TopicDetails from "pages/kapAdmin/topics/TopicDetails";
import TopicList from "pages/kapAdmin/topics/TopicList";
import InstructorCourseDetails from "pages/kapInstructor/courses/CourseDetails";
import InstructorCourseList from "pages/kapInstructor/courses/CourseList";
import InstructorTopicList from "pages/kapInstructor/topics/TopicList";
import InstructorTopicDetails from "pages/kapInstructor/topics/TopicDetails";
import Error404 from "components/marketing/pages/specialty/Error404";
import InstructorDashboard from "pages/kapInstructor/dashboard/InstructorDashboard";
import AssignmentDetails from "pages/kapInstructor/assignments/AssignmentDetails";
import QuizDetails from "pages/kapInstructor/quizzes/QuizDetails";
import RegistrationList from "pages/kapAdmin/admissions/RegistrationList";
import Quiz from "pages/kapStudent/programmes/Quiz";
import Assignment from "pages/kapStudent/programmes/Assignment";
import ProgrammeDetails from "pages/kapStudent/dashboard/ProgrammeDetails";
import AboutUs from "pages/kapStudent/dashboard/AboutUs";
import QuizReview from "pages/kapInstructor/quizzes/QuizReview";
import AssignmentReview from "pages/kapInstructor/assignments/AssignmentReview";
import Survey from "pages/kapStudent/surveys/Survey";
import Analytics from "pages/kapAdmin/report/Analytics";
import PayingList from "pages/kapAdmin/admissions/PayingList";
import ScholarshipList from "pages/kapAdmin/admissions/ScholarshipList";
import PendingList from "pages/kapAdmin/admissions/PendingList";
import ScholarshipList2 from "pages/kapAdmin/admissions/ScholarshipList2";
import PayingList2 from "pages/kapAdmin/admissions/PayingList2";
import PendingList2 from "pages/kapAdmin/admissions/PendingList2";
import ApplicationList2 from "pages/kapAdmin/admissions/ApplicationList2";
import Notifications from "pages/kapStudent/notifications/Notifications";
import AnnouncementList from "pages/kapAdmin/announcements/AnnouncementList";
import Resources from "pages/kapStudent/resources/Resources";
import Finance from "pages/kapStudent/resources/Finance";
import Jobs from "pages/kapStudent/resources/Jobs";
import LiveClassList from "pages/kapAdmin/liveclasses/LiveClassList";
import ForumList from "pages/kapAdmin/forums/ForumList";
import ForumDetails from "pages/kapAdmin/forums/ForumDetails";
import StudentForumDetails from "pages/kapStudent/forums/StudentForumDetails";
import Forums from "pages/kapStudent/forums/Forums";

/* IMPORTS FOR MARKETING PAGES - END */
/* --------------------------------- */

const ProtectedAppRoute = ({ component: Component, layout: Layout, type = "", checkPage, ...rest }) => {
  const { user, token, isAuthenticated, role, pageState } = useSelector((state) => state.userAuth);
  const { path } = rest;
  return token || path.includes("sis") ? (
    (!type || role === type || path.includes("sis")) &&
    ((role === "student" && !path.includes("admin") && !path.includes("instructor")) ||
      path.includes(role) ||
      !type ||
      path.includes("sis")) ? (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props}></Component>
          </Layout>
        )}
      ></Route>
    ) : role === "student" ? (
      <Redirect to="/" />
    ) : role === "instructor" ? (
      <Redirect to="/instructor" />
    ) : role === "admin" ? (
      <Redirect to="/admin" />
    ) : (
      <Redirect to="/login" />
    )
  ) : (
    <Redirect to="/login" />
  );
};
const AppRoute = ({ component: Component, layout: Layout, checkPage, ...rest }) => {
  const { pageState } = useSelector((state) => state.userAuth);
  const history = useHistory();
  return !checkPage ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      )}
    ></Route>
  ) : pageState === checkPage ? (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props}></Component>
        </Layout>
      )}
    ></Route>
  ) : (
    <Redirect to="/" />
  );
};

function AllRoutes() {
  const { user } = useSelector((state) => state.userAuth);
  return (
    <Switch>
      {/* NEW ROUTES--------------------------- */}
      <AppRoute exact path="/login" layout={AuthLayout} component={Login} />
      <AppRoute exact path="/register" type="student" layout={AuthLayout} component={Register} />
      <AppRoute exact path="/forgot-password" type="student" layout={AuthLayout} component={ForgotPassword} />
      <AppRoute exact path="/reset-password" type="student" layout={AuthLayout} component={ResetPassword} />
      <AppRoute exact checkPage="verify-account" path="/verify-account" type="student" layout={AuthLayout} component={VerifyAccount} />
      <ProtectedAppRoute exact type="student" path="/" layout={LayoutDefault} component={StudentDashboard} />
      <ProtectedAppRoute exact type="student" path="/sis/:user_id/:path" layout={LayoutDefault} component={StudentSIS} />
      <ProtectedAppRoute
        // exact
        type="student"
        path="/sis/:user_id"
        layout={LayoutDefault}
        component={StudentSIS}
      />
      <ProtectedAppRoute exact type="student" path="/jobs/all" layout={LayoutDefault} component={Jobs} />
      <ProtectedAppRoute exact type="student" path="/jobs" layout={LayoutDefault} component={JobsDashboard} />
      <ProtectedAppRoute exact type="student" path="/finance/all" layout={LayoutDefault} component={Finance} />
      <ProtectedAppRoute exact type="student" path="/finance" layout={LayoutDefault} component={FinanceDashboard} />
      <ProtectedAppRoute exact type="student" path="/resources/all" layout={LayoutDefault} component={Resources} />
      <ProtectedAppRoute exact type="student" path="/resources" layout={LayoutDefault} component={ResourceDashboard} />
      <ProtectedAppRoute exact type="student" path="/profile" layout={LayoutDefault} component={Profile} />
      <ProtectedAppRoute exact type="student" path="/edit-profile" layout={LayoutDefault} component={EditProfile} />
      <ProtectedAppRoute exact type="student" path="/programme/:id" layout={LayoutDefault} component={SingleProgramme} />
      <ProtectedAppRoute exact type="student" path="/quiz" layout={LayoutDefault} component={Quiz} />
      <ProtectedAppRoute exact type="student" path="/survey/:id/:title" layout={NoLayout} component={Survey} />
      <ProtectedAppRoute exact type="student" path="/survey" layout={LayoutDefault} component={Survey} />
      {/* <ProtectedAppRoute
        exact
        type="admin"
        path="/admin/quiz"
        layout={LayoutDefault}
        component={Quiz}
      /> */}
      <ProtectedAppRoute exact type="student" path="/assignment" layout={LayoutDefault} component={Assignment} />
      <ProtectedAppRoute exact type="student" path="/aboutus" layout={LayoutDefault} component={AboutUs} />
      <ProtectedAppRoute exact type="student" path="/forum" layout={LayoutDefault} component={Forums} />
      <ProtectedAppRoute exact type="student" path="/forum/:id" layout={LayoutDefault} component={StudentForumDetails} />
      <ProtectedAppRoute exact type="student" path="/info" layout={LayoutDefault} component={ProgrammeDetails} />
      <ProtectedAppRoute exact type="student" path="/info/:id" layout={LayoutDefault} component={ProgrammeDetails} />
      <ProtectedAppRoute exact type="student" path="/complete-registration" layout={LayoutDefault} component={CompleteRegistration} />
      <ProtectedAppRoute exact type="student" path="/notifications" layout={LayoutDefault} component={Notifications} />
      <ProtectedAppRoute exact type="admin" path="/admin" layout={DashboardIndex} component={AdminDashboard} />
      <ProtectedAppRoute type="admin" path="/admin/applications/level1" layout={DashboardIndex} component={ApplicationList} />
      <ProtectedAppRoute type="admin" path="/admin/applications/level2" layout={DashboardIndex} component={ApplicationList2} />
      <ProtectedAppRoute exact type="admin" path="/admin/pending/level1" layout={DashboardIndex} component={PendingList} />
      <ProtectedAppRoute exact type="admin" path="/admin/pending/level2" layout={DashboardIndex} component={PendingList2} />
      <ProtectedAppRoute exact type="admin" path="/admin/paying/level1" layout={DashboardIndex} component={PayingList} />
      <ProtectedAppRoute exact type="admin" path="/admin/paying/level2" layout={DashboardIndex} component={PayingList2} />
      <ProtectedAppRoute exact type="admin" path="/admin/scholarship/level1" layout={DashboardIndex} component={ScholarshipList} />
      <ProtectedAppRoute exact type="admin" path="/admin/scholarship/level2" layout={DashboardIndex} component={ScholarshipList2} />
      <ProtectedAppRoute exact type="admin" path="/admin/registrations" layout={DashboardIndex} component={RegistrationList} />
      <ProtectedAppRoute exact type="admin" path="/admin/admins" layout={DashboardIndex} component={AdminList} />
      <ProtectedAppRoute exact type="admin" path="/admin/instructors" layout={DashboardIndex} component={InstructorList} />
      <ProtectedAppRoute exact type="admin" path="/admin/students" layout={DashboardIndex} component={StudentList} />
      <ProtectedAppRoute exact type="admin" path="/admin/programmes" layout={DashboardIndex} component={ProgramList} />
      <ProtectedAppRoute exact type="admin" path="/admin/courses" layout={DashboardIndex} component={CourseList} />
      <ProtectedAppRoute exact type="admin" path="/admin/announcements" layout={DashboardIndex} component={AnnouncementList} />
      <ProtectedAppRoute exact type="admin" path="/admin/forums" layout={DashboardIndex} component={ForumList} />
      <ProtectedAppRoute exact type="admin" path="/admin/liveclass" layout={DashboardIndex} component={LiveClassList} />
      <ProtectedAppRoute exact type="admin" path="/admin/jobs" layout={DashboardIndex} component={JobList} />
      <ProtectedAppRoute exact type="admin" path="/admin/finance" layout={DashboardIndex} component={FinanceList} />
      <ProtectedAppRoute exact type="admin" path="/admin/resources" layout={DashboardIndex} component={ResourceList} />
      <ProtectedAppRoute exact type="admin" path="/admin/admin/:id" layout={DashboardIndex} component={AdminDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/instructor/:id" layout={DashboardIndex} component={InstructorDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/student/:id" layout={DashboardIndex} component={StudentDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/programme/:id" layout={DashboardIndex} component={ProgramDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/topics" layout={DashboardIndex} component={TopicList} />
      <ProtectedAppRoute exact type="admin" path="/admin/topic/:id" layout={DashboardIndex} component={TopicDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/course/:id" layout={DashboardIndex} component={CourseDetails} />
      <ProtectedAppRoute type="admin" path="/admin/application/level_2/:id" layout={DashboardIndex} component={ApplicationDetails} />
      <ProtectedAppRoute type="admin" path="/admin/application/:id" layout={DashboardIndex} component={ApplicationDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/job/:id" layout={DashboardIndex} component={JobDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/finance/:id" layout={DashboardIndex} component={FinanceDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/resource/:id" layout={DashboardIndex} component={ResourceDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/forums/:id" layout={DashboardIndex} component={ForumDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/report" layout={DashboardIndex} component={Analytics} />
      <ProtectedAppRoute exact type="instructor" path="/instructor" layout={DashboardIndex} component={InstructorDashboard} />
      <ProtectedAppRoute exact type="instructor" path="/instructor/courses" layout={DashboardIndex} component={InstructorCourseList} />
      <ProtectedAppRoute
        exact
        type="instructor"
        path="/instructor/course/:id"
        layout={DashboardIndex}
        component={InstructorCourseDetails}
      />
      <ProtectedAppRoute exact type="instructor" path="/instructor/topics" layout={DashboardIndex} component={InstructorTopicList} />
      <ProtectedAppRoute exact type="instructor" path="/instructor/topic/:id" layout={DashboardIndex} component={InstructorTopicDetails} />
      <ProtectedAppRoute exact path="/instructor/topic/assignment/:id" layout={DashboardIndex} component={AssignmentDetails} />
      <ProtectedAppRoute exact type="admin" path="/admin/topic/assignment/:id" layout={DashboardIndex} component={AssignmentDetails} />
      <ProtectedAppRoute exact path="/instructor/topic/quiz/:id" layout={DashboardIndex} component={QuizDetails} />
      <ProtectedAppRoute exact path="/instructor/quiz/review" layout={DashboardIndex} component={QuizReview} />
      <ProtectedAppRoute exact path="/instructor/assignment/review" layout={DashboardIndex} component={AssignmentReview} />
      <ProtectedAppRoute exact type="admin" path="/admin/topic/quiz/:id" layout={DashboardIndex} component={QuizDetails} />

      <ProtectedAppRoute exact type="student" path="/404-error/" layout={NotFound} component={Error404} />

      <Redirect to="/404-error/" />
      <Redirect to="/" />
    </Switch>
  );
}

export default AllRoutes;
