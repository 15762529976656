// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Nav,
  Tab,
  Card,
  Container,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import Avatar3 from 'assets/images/avatar/avatar-3.jpg';

// import data files
import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useGetProfileMutation,
  useGetProgramByIdMutation,
  useGetUserVideosMutation,
} from 'pages/redux/features/user/userApi';
import {
  saveProgrammesStarted,
  saveUser,
} from 'pages/redux/features/auth/authSlice';
import { toast } from 'react-toastify';
import { isSubstringInArray, updateObjects } from 'pages/helper/formatArray';
import { Loader } from 'pages/components/Loader';
import StatsSection from './components/StatsSection';
import ProgramInfo from './components/ProgramInfo';
import { WarningModal } from 'pages/components/Modals/WarningModal';
import AccessProgramme from './AccessProgramme';
import {
  level1Img,
  level2Img,
  masterClass,
  masterClassImg,
} from 'pages/assets/images/imageUrl';
import SurveysModal from './components/SurveysModal';
import Icon from 'react-icons-kit';
import { fileText } from 'react-icons-kit/feather';

const ProgrammeDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const location = useLocation();
  const {
    user: userInfo,
    programmesStarted,
    completeApplication,
  } = useSelector((state) => state.userAuth);
  const program = location?.state;
  const dispatch = useDispatch();
  const [programDetails, setProgramDetails] = useState({});
  const [userVideos, setUserVideos] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [show, setShow] = useState(false);
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const [surveys, setSurveys] = useState([]);

  const [warningText, setWarningText] = useState(
    'You need to get admitted to access this programme.'
  );
  const [getProgramById, { isLoading }] = useGetProgramByIdMutation();
  const [getUserVideos, { isLoading: isLoadingUserVideos }] =
    useGetUserVideosMutation();

  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();

  const fetchProgramById = async () => {
    const res = await getProgramById({ id: route_id });
    if (res?.data?.success) {
      setProgramDetails({ ...program, ...res?.data?.data?.programme });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
      history.push('/sis/redirect');
    }
  };
  const fetchProfile = async () => {
    const res1 = await getProfile();
    if (res1?.data?.success) {
      dispatch(
        saveUser({
          ...res1.data?.data?.user,
          ...res1.data?.data,
          programmes: res1.data?.data?.programmes,
          videos: res1.data?.data?.videos,
          videos_progress: res1.data?.data?.videos_progress,
          uncompleted_surveys: res1.data?.data?.uncompleted_surveys,
          application: res1.data?.data?.application,
        })
      );
    } else {
      toast.error(res1?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
    }
  };
  const fetchUserVideos = async () => {
    const res = await getUserVideos();
    if (res?.data?.success) {
      setUserVideos(res?.data?.data?.videos);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
    !program && !route_id && history.push('/');
  });

  useEffect(() => {
    console.log({
      program: isSubstringInArray('masterclass', [programDetails?.name]),
      name: programDetails?.name,
    });
    program && setProgramDetails(program);
  }, [program]);
  useEffect(() => {
    route_id && fetchProgramById();
    // fetchUserVideos();
    route_id && fetchProfile();
  }, [route_id]);

  // const fetchProgramById = async () => {
  //   const res = await getProgramById({ id: id });
  //   if (res?.data?.success) {
  //     setProgramDetails(res?.data?.data?.programme);
  //     dispatch(
  //       saveProgrammesStarted(
  //         updateObjects(
  //           programmesStarted,
  //           (obj) => obj?.id == id,
  //           'started',
  //           true
  //         )
  //       )
  //     );
  //   } else {
  //     toast.error(res?.error?.data?.message || 'Error!', {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (userInfo?.uncompleted_surveys?.length > 0) {
      const extrSurveys =
        userInfo?.application?.status?.includes('admitted') &&
        userInfo?.application?.current_level == 1
          ? userInfo?.uncompleted_surveys?.filter((x) =>
              isSubstringInArray(x?.title, ['KAP Academy Post Training Survey'])
            )
          : userInfo?.application?.current_level == 2
          ? userInfo?.uncompleted_surveys
          : [];
      setSurveys(extrSurveys);
    }
  }, [userInfo?.uncompleted_surveys]);

  if (isLoading || !programDetails || (isGettingProfile && !userInfo)) {
    return <Loader />;
  }

  return (
    <Fragment>
      <ProgramInfo
        item={{
          title: programDetails?.name,
          description: programDetails?.description,
          mainRedirect:
            userInfo?.programmes?.some((x) => x?.id == program?.id) ||
            isSubstringInArray('masterclass', [programDetails?.name]) ||
            programDetails?.price == 0
              ? 'Continue to Programme'
              : userInfo?.application &&
                ((userInfo?.application?.status?.includes('admitted') &&
                  isSubstringInArray('introduction to film making', [
                    programDetails?.name,
                  ])) ||
                  (userInfo?.application?.status_level_2?.includes(
                    'admitted'
                  ) &&
                    isSubstringInArray('speciali', [programDetails?.name])))
              ? 'Access Programme'
              : 'Access Programme',
          secondaryRedirect: !completeApplication ? 'Complete Application' : '',
          image:
            programDetails?.name?.toLowerCase().includes('introduction to') &&
            isSubstringInArray('level 1', [programDetails?.nick_name])
              ? level1Img
              : programDetails?.name?.toLowerCase().includes('speciali') &&
                isSubstringInArray('level 2', [programDetails?.nick_name])
              ? level2Img
              : masterClassImg,
          mainRedirectLink:
            userInfo?.programmes?.some((x) => x?.id == programDetails?.id) ||
            isSubstringInArray('masterclass', [programDetails?.name]) ||
            (userInfo?.application &&
              userInfo?.application?.status_level_2?.includes('admitted') &&
              isSubstringInArray('speciali', [programDetails?.name]))
              ? '/programme/' + programDetails?.id
              : null,
          mainRedirectAction:
            !userInfo?.programmes?.some((x) => x?.id == programDetails?.id) &&
            !isSubstringInArray('masterclass', [programDetails?.name]) &&
            !(
              userInfo?.application &&
              userInfo?.application?.status_level_2?.includes('admitted') &&
              isSubstringInArray('speciali', [programDetails?.name])
            )
              ? userInfo?.application &&
                userInfo?.application?.status?.includes('admitted') &&
                isSubstringInArray('introduction to film making', [
                  programDetails?.name,
                ])
                ? handleShow
                : () => setShowWarning(true)
              : null,
          secondaryRedirectLink: '/complete-registration',
          popOverOnMain: !completeApplication,
        }}
        // bgColor={'bg-primary'}
        // mainTextColor={'text-black'}
      />
      {programDetails && (
        <StatsSection
          item={programDetails}
          videos={userVideos}
          bgColor={'bg-primary'}
          mainTextColor={'text-white'}
        />
      )}
      <CoursesSection />
      <OverlayTrigger placement="top" overlay={<Tooltip>Surveys</Tooltip>}>
        <div
          className="position-fixed bottom-0 end-0 m-6"
          style={{
            zIndex: 1031,
            justifyContent: 'center',
          }}
          onClick={() => setSurveyModalShow(true)}
        >
          <div
            className="bg-primary"
            style={{
              borderRadius: '50%',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Icon
              icon={fileText}
              size={30}
              className="px-3"
              style={{ color: 'white' }}
            />
          </div>
          <h4 className="mb-0 text-primary">Surveys</h4>
        </div>
      </OverlayTrigger>
      <SurveysModal
        show={surveyModalShow}
        onHide={() => setSurveyModalShow(false)}
        data={surveys}
      />

      <WarningModal
        showModal={showWarning}
        setShowModal={setShowWarning}
        text={warningText}
      />
      <AccessProgramme
        show={show}
        handleClose={handleClose}
        type={'edit'}
        size="md"
        currentLevelId={programDetails?.id}
        amount={programDetails?.price}
        level={programDetails}
      />
    </Fragment>
  );
};
export default ProgrammeDetails;
