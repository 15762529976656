// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  ListGroup,
  Toast,
  Spinner,
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import AddTopic from './AddTopic';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetTeachersMutation,
  useGetTopicsLiteMutation,
} from 'pages/redux/features/user/userApi';
import { sortByProperty } from 'pages/helper/formatArray';

const TopicList = () => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [getTopics, { isLoading }] = useGetTopicsLiteMutation();
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);

  const [getClasses, { isLoading: fetchingClasses }] = useGetClassesMutation();

  const [getCourses, { isLoading: fetchingCourses }] =
    useGetCoursesLiteMutation();

  const fetchCourses = async () => {
    const res = await getCourses();
    if (res?.data?.success) {
      setCourses(res?.data?.data?.courses);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchClasses = async () => {
    const res = await getClasses();
    if (res?.data?.success) {
      setClasses(res?.data?.data?.classes);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchTopics = async () => {
    const res = await getTopics();
    if (res?.data?.success) {
      setList(res?.data?.data?.topics);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchTopics();
    fetchClasses();
    fetchCourses();
  }, [refresh]);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'title',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/topic/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'description',
        Header: 'Description',
        Cell: ({ value, row }) => {
          return (
            <Link to={`/admin/topic/${row?.original?.id}`}>
              <h5 className="text-primary-hover">{value.slice(0, 35)}...</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'course_id',
        Header: 'Course',
        Cell: ({ value }) => {
          return courses.find((x) => x?.id == value)?.title;
        },
      },
      {
        accessor: 'created_at',
        Header: 'START DATE',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'updated_at',
        Header: 'last updated',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      // {
      //   accessor: 'action',
      //   Header: 'ACTION',
      //   Cell: ({ value }) => {
      //     return <ActionMenu />;
      //   },
      // },
    ],
    [courses]
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  if (isLoading || fetchingClasses || fetchingCourses) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Topics</h1>
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                Create New Topic
              </Button>
              <AddTopic
                show={show}
                handleClose={handleClose}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <div className=" overflow-hidden">
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className="mb-lg-0 mb-2 px-5 py-4"
                    >
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                        placeholder="Search List"
                      />
                    </Col>
                  </Row>
                </div>

                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>

                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default TopicList;
