// import node module libraries
import { Fragment, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Modal } from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  useCreatePostMutation,
  useGetProgramsLiteMutation,
  useUpdatePostMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgrammes } from 'pages/redux/features/auth/authSlice';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { getValue } from 'pages/helper/formatObject';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import { CustomButton } from 'pages/components/CustomButton';

const AddNewForum = ({
  show,
  handleClose,
  size = 'md',
  type,
  initialState,
}) => {
  const dispatch = useDispatch();
  const { programmes } = useSelector((state) => state.userAuth);
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    body: Yup.string().required('Required'),
    program_id: Yup.mixed().required('Required'),
    // comment: Yup.string().required('Required'),
  });

  const programOptions = useMemo(() => {
    let filtered = programmes || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?.id, id: x?.id };
    });
    filtered?.push({ label: 'All', value: null, id: null });
    return filtered;
  }, [programmes]);

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',

      body: initialState?.body || '',
      attachments: null,

      program_id:
        programOptions?.find(
          (x) => x?.id == initialState?.programme_id || initialState?.program_id
        ) || '',
    },
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      if (!values?.attachments) {
        delete values.attachments;
      }
      const resolveValues = {
        ...values,
        program_id: getValue(values?.program_id) || null,
        // programme_id: getValue(values?.program_id) || null,
      };

      const formData = objectToFormData(resolveValues);
      logFormData(formData);
      handlePost(formData);
    },
  });

  const [getProgramsLite, { isLoading: isLoadingProgrammeLite }] =
    useGetProgramsLiteMutation();
  const [createPost, { isLoading }] = useCreatePostMutation();
  const [updatePost, { isLoading: isUpdating }] = useUpdatePostMutation();

  const fetchPrograms = async () => {
    const res = await getProgramsLite();
    if (res?.data?.success) {
      dispatch(saveProgrammes(res?.data?.data?.programmes));
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    show && fetchPrograms();
  }, [show]);

  const handlePost = async (values) => {
    const res =
      type == 'edit'
        ? await updatePost({ id: initialState?.id, data: values })
        : await createPost(values);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Post' : 'Create Post'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Title"
                required
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && formik.touched.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Body</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="body"
                placeholder="Body"
                required
                value={formik.values?.body}
                onChange={formik.handleChange}
              />
              {formik.errors.body && formik.touched.body && (
                <p className="fs-8 text-danger mb-0">{formik.errors.body}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Attachments</Form.Label>
              <Form.Control
                type="file"
                id="attachments"
                multiple
                required
                onChange={(e) => {
                  formik.setFieldValue('attachments', e.target.files);
                }}
              />
              {formik.errors?.attachments && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.attachments}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Programme</Form.Label>
              <MultiSelectDropdown
                options={programOptions}
                selected={formik.values?.program_id}
                setSelected={(e) => formik.setFieldValue('program_id', e)}
                placeholder={'Select programme'}
                isLoading={isLoadingProgrammeLite}
              />
              {formik.errors.program_id && formik.touched.program_id && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.program_id}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <CustomButton
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </CustomButton>
        <CustomButton
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading || isUpdating}
          loading={isLoading || isUpdating}
        >
          {type == 'edit' ? 'Edit Post' : 'Add Post'}
        </CustomButton>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewForum;
