// import node module libraries
import { useUpdateStudentMutation } from 'pages/redux/features/user/userApi';
import { useEffect } from 'react';
import { Fragment, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UpdateStudentDetails = ({ show, initial, handleClose, id }) => {
  const [selected, setSelected] = useState(null);
  const [firstname, setFirstname] = useState(initial?.firstname || '');
  const [lastname, setLastname] = useState(initial?.lastname || '');
  const [address, setAddress] = useState(initial?.address || '');
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const [updateStudent, { isLoading }] = useUpdateStudentMutation();

  const handleUpdate = async () => {
    const data = { firstname, lastname, address };
    const res = await updateStudent({ id, data });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    setFirstname(initial?.firstname);
    setLastname(initial?.lastname);
    setAddress(initial?.application?.address);
  }, [initial]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Student Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                required
                type="text"
                id="firstname"
                placeholder="firstname"
                value={firstname}
                onChange={(e) => {
                  setFirstname(e?.target?.value);
                }}
              />
              {!firstname && (
                <p className="fs-8 text-danger mb-0">{'Required'}</p>
              )}
              {firstname && firstname.length < 3 && (
                <p className="fs-8 text-danger mb-0">
                  {'First name must be longer than 2 letters'}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                required
                type="text"
                id="lastname"
                placeholder="lastname"
                value={lastname}
                onChange={(e) => {
                  setLastname(e?.target?.value);
                }}
              />
              {!lastname && (
                <p className="fs-8 text-danger mb-0">{'Required'}</p>
              )}
              {lastname && lastname.length < 3 && (
                <p className="fs-8 text-danger mb-0">
                  {'Last name must be longer than 2 letters'}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Address</Form.Label>
              <Form.Control
                required
                type="text"
                id="address"
                placeholder="address"
                value={address}
                onChange={(e) => {
                  setAddress(e?.target?.value);
                }}
              />
              {!address && (
                <p className="fs-8 text-danger mb-0">{'Required'}</p>
              )}
              {address && address.length < 15 && (
                <p className="fs-8 text-danger mb-0">
                  {'Enter a valid address'}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={handleUpdate}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateStudentDetails;
