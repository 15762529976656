// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAdmitWithScholarshipByIdMutation,
  useCreateCourseMutation,
  useGetTeachersMutation,
  useUpdateCourseMutation,
  useUpdateVideoMutation,
  useUploadVideoMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import { extractEndNumber } from 'pages/helper/formatText';

const AddVideo = ({ show, handleClose, id, type, initialState }) => {
  const [uploadVideo, { isLoading: isUploadingVideo }] =
    useUploadVideoMutation();

  const [updateVideo, { isLoading: isUpdatingVideo }] =
    useUpdateVideoMutation();

  const handleVideo = async (formData) => {
    const res =
      type == 'edit'
        ? await updateVideo({ id: initialState?.id, formData })
        : await uploadVideo(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    tags: Yup.number().required('Required').typeError('Required'),
  });


  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      tags: extractEndNumber(initialState?.tags[0]?.name) || '',
      video: initialState?.video || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveInstr = {
        ...values,
        tags: ['Masterclass #' + values?.tags],
      };

      !(values?.video instanceof File) && delete resolveInstr?.video;
      const formData = objectToFormData(resolveInstr);
      logFormData(formData);
      handleVideo(formData);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type == 'edit' ? 'Edit Video' : 'Add Video'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tag</Form.Label>
              <InputGroup>
                <InputGroup.Text>Masterclass #</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="tags"
                  required
                  value={formik.values?.tags}
                  onChange={formik.handleChange}
                />
              </InputGroup>
              {formik.errors.tags && (
                <p className="fs-8 text-danger mb-0">{formik.errors.tags}</p>
              )}
            </Form.Group>
            {type == 'add' && (
              <Form.Group className="mb-3">
                <Form.Label>Video</Form.Label>
                <Form.Control
                  type="file"
                  accept="video/*"
                  id="video"
                  multiple
                  required
                  onChange={(e) => {
                    formik.setFieldValue('video', e.currentTarget.files[0]);
                  }}
                />
                {formik.errors.video && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.video}</p>
                )}
              </Form.Group>
            )}
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isUploadingVideo || isUpdatingVideo}
        >
          {isUploadingVideo || isUpdatingVideo ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddVideo;
