// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  Accordion,
  Button,
  OverlayTrigger,
  Image,
  Tooltip,
  Tab,
  Nav,
  ListGroup,
  Popover,
  Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  useEnrollStudentForCourseMutation,
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetLevel2CertificateMutation,
  useGetProgramByIdMutation,
  useGetStudentAssignmentResultsMutation,
  useGetStudentQuizResultsMutation,
  useGetTopicsMutation,
  useGetUserVideosMutation,
  useSaveVideoProgressMutation,
} from 'pages/redux/features/user/userApi';
import { Loader } from 'pages/components/Loader';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import GKAccordionProgress from '../dashboard/components/GKAccordionProgress';
import { toast } from 'react-toastify';

import { ChevronRight } from 'react-feather';
import {
  flattenedArray,
  isSubstringInArray,
  sortAscending,
  sortByTagName,
  sortWeeksArray,
  updateObjects,
} from 'pages/helper/formatArray';
import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import GKYouTube from 'components/marketing/common/video/GKYouTube';
import { mdiBook, mdiChevronDown, mdiPlay } from '@mdi/js';
import Icon from 'react-icons-kit';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { book } from 'react-icons-kit/fa/book';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  saveProgrammesStarted,
  saveUser,
} from 'pages/redux/features/auth/authSlice';
import { extractObjectValues } from 'pages/helper/formatObjectToArray';
import { BASE_URL } from 'pages/redux/features/url';
import AccessProgramme from '../dashboard/AccessProgramme';
import { WarningModal } from 'pages/components/Modals/WarningModal';
import { CustomButton } from 'pages/components/CustomButton';
import { programbackground } from 'pages/assets/images/imageUrl';
import { truncateText } from 'pages/helper/formatText';
import Level1 from './Level1';
import Level2 from './Level2';
import MasterClass from './MasterClass';
const SingleProgramme = (props) => {
  const route_id = props.match.params?.id;

  const {
    user: userInfo,
    programmesStarted,
    programmes,
  } = useSelector((state) => state.userAuth);

  const level = programmes?.find((x) => x?.id == props.match.params?.id);
  const history = useHistory();
  const dispatch = useDispatch();

  const [programDetails, setProgramDetails] = useState({});
  const [masterIndex, setMasterIndex] = useState([]);
  const [level1Index, setLevel1Index] = useState([]);
  const [currentStream, setCurrentStream] = useState(null);
  const [initialThumbnail, setInitialThumbnail] = useState('');
  const [userVideos, setUserVideos] = useState([]);
  const [isSorting, setIsSorting] = useState(true);
  const [topics, setTopics] = useState([]);
  const [problems, setProblems] = useState([]);

  const [classInstructor, setClassInstructor] = useState(null);
  const [courses, setCourses] = useState([]);
  const [classes, setClasses] = useState([]);
  const [classesArranged, setClassesArranged] = useState({});
  const [weeksArranged, setWeeksArranged] = useState([]);
  const [weekSelected, setWeekSelected] = useState('Week 1');
  const [previousWeekQuizzes, setPreviousWeekQuizzes] = useState([]);
  const [previousWeekClasses, setPreviousWeekClasses] = useState({});
  const [previousWeek, setPreviousWeek] = useState('Week 1');
  const [classSelected, setClassSelected] = useState('Class #1');
  const [coursesArranged, setCoursesArranged] = useState([]);
  const [courseSelected, setCourseSelected] = useState(null);
  const [playPreClassVideo, setPlayPreClassVideo] = useState(false);
  const [instructorId, setInstructorId] = useState(null);
  const [seekTo, setSeekTo] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isCourseAvailable, setIsCourseAvailable] = useState(false);
  const [isCertificateAvailable, setIsCertificateAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [checkLevel2, setCheckLevel2] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState(null);
  const [topicKey, setTopicKey] = useState(null);
  const [activeTopicKey, setActiveTopicKey] = useState('videos');
  const [activeQuizKey, setActiveQuizKey] = useState(null);
  const [activeQuiz, setActiveQuiz] = useState(null);
  const [activeAssignment, setActiveAssignment] = useState(null);
  const [activeAssignmentKey, setActiveAssignmentKey] = useState(null);
  const [prevStream, setPrevStream] = useState(null);
  const [enrollVisible, setEnrollVisible] = useState(false);
  const [modalError, setModalError] = useState('');
  const [modalErrorVisible, setModalErrorVisible] = useState('');
  const [saveVideoProgress, { isLoading: isSavingVideoProgress }] =
    useSaveVideoProgressMutation();
  const [getCourses, { isLoading: isLoadingCourses }] =
    useGetCoursesLiteMutation();
  const [getClasses, { isLoading: isLoadingClasses }] = useGetClassesMutation();
  const [getTopics, { isLoading: isLoadingTopics }] = useGetTopicsMutation();
  const [getProgramById, { isLoading }] = useGetProgramByIdMutation();
  const [getUserVideos, { isLoading: isLoadingUserVideos }] =
    useGetUserVideosMutation();

  const [enrollStudent, { isLoading: isEnrolling }] =
    useEnrollStudentForCourseMutation();
  const [getLevel2Certificate, { isLoading: isLoadingLevel2Certificate }] =
    useGetLevel2CertificateMutation();
  const [getStudentQuizResults, { isLoading: isGettingResults }] =
    useGetStudentQuizResultsMutation();
  const [
    getStudentAssignmentResults,
    { isLoading: isGettingAssignmentResults },
  ] = useGetStudentAssignmentResultsMutation();

  const fetchQuizResult = async ({ id, next = () => {} }) => {
    const res = await getStudentQuizResults({ id });
    if (res?.data?.success) {
      setActiveQuiz(res?.data?.data);
    } else {
      setActiveQuiz(null);
    }

    return next();
  };
  const fetchAssignmentResult = async ({ id, next = () => {} }) => {
    const res = await getStudentAssignmentResults({ id });
    if (res?.data?.success) {
      setActiveAssignment(res?.data?.data);
    } else {
      setActiveAssignment(null);
    }

    return next();
  };
  const checkPreviousQuizzes = async ({ quizzes }) => {
    let available = true;
    for (let i = 0; i < quizzes.length; i++) {
      const element = quizzes[i];
      const res = await getStudentQuizResults({ id: element?.id });
      if (res?.data?.success) {
        available = true;
      } else {
        available = false;
      }
    }
    return available;
  };

  const fetchProgramById = async () => {
    setLoading(true);
    const res = await getProgramById({ id: route_id });
    if (res?.data?.success) {
      setProgramDetails(res?.data?.data?.programme);
      dispatch(
        saveProgrammesStarted(
          updateObjects(
            programmesStarted,
            (obj) => obj?.id == route_id,
            'started',
            true
          )
        )
      );
      await fetchCourses(res?.data?.data?.programme?.courses);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
    setLoading(false);
  };

  const fetchCourses = async (courseExt) => {
    const res = await getCourses();
    if (res?.data?.success) {
      setCourses(
        res?.data?.data?.courses?.filter((course) =>
          courseExt.find((ext) => course?.id == ext?.id)
        )
      );
      const classesExt = flattenedArray(
        res?.data?.data?.courses
          ?.filter((course) => courseExt.find((ext) => course.id == ext?.id))
          .map((course) => {
            return course?.classes;
          })
      );
      await fetchClasses(classesExt);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  const fetchClasses = async (classesExt) => {
    const res = await getClasses();
    if (res?.data?.success) {
      setClasses(
        res?.data?.data?.classes?.filter((clas) =>
          classesExt?.find((ext) => clas?.id == ext?.id)
        )
      );
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  const fetchTopics = async () => {
    const res = await getTopics();
    if (res?.data?.success) {
      setTopics(res?.data?.data?.topics);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  const fetchUserVideos = async () => {
    const res = await getUserVideos();
    if (res?.data?.success) {
      setUserVideos(res?.data?.data?.videos);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    if (
      !userInfo?.programmes.some((obj) => obj.id == route_id) &&
      !(
        isSubstringInArray('speciali', [level?.title || level?.name]) &&
        isSubstringInArray('level 2', [level?.nick_name]) &&
        userInfo?.application &&
        userInfo?.application?.status_level_2?.includes('admitted')
      )
    ) {
      toast.error('Error! access to programme', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  }, [route_id]);

  useEffect(() => {
    setCurrentStream(null);
    fetchProgramById();
    fetchTopics();
  }, [route_id]);

  useEffect(() => {
    if (
      programDetails &&
      (programDetails?.name?.toLowerCase()?.includes('masterclass') ||
        programDetails?.price == '0' ||
        programDetails?.price == 0)
    ) {
      fetchUserVideos();
    }
  }, [programDetails]);

  useEffect(() => {
    setIsSorting(true);
    if (userVideos?.masterclass) {
      let videos = userVideos?.masterclass;
      const sortedArray = sortByTagName([...videos]);
      setMasterIndex([
        {
          id: 1,
          title: 'Available Classes',
          total_videos: sortedArray?.length,
          topics: sortedArray?.map((x, index) => {
            return {
              ...x,
              topic: x?.title,
              // duratoin: '1m 41s',
              status: 'pending',
              locked: x?.url ? false : true,
            };
          }),
        },
        {
          id: 3,
          title: 'Additional Information',
          summary: `Complete your application to get more masterclass videos and access other programmes`,
          topics: [], // It's compulsary to show summary, because it's used for conditional rendering
        },
      ]);
      setInitialThumbnail(
        sortedArray[0]?.thumbnail || sortedArray[0]?.screenshot || ''
      );
    }
    if (userVideos?.level1?.length > 0) {
      let videos = userVideos?.level1;
      const unsortedArray = [...videos];
      const sortedArray = unsortedArray.sort((a, b) => a?.title < b?.title);
      setLevel1Index([
        {
          id: 1,
          title: 'Available Classes',
          total_videos: sortedArray?.length,
          topics: sortedArray?.map((x, index) => {
            return {
              ...x,
              topic: x?.title,
              duratoin: '1m 41s',
              status: 'pending',
              locked: x?.url ? false : true,
            };
          }),
        },
        {
          id: 3,
          title: 'Additional Information',
          summary: `Complete this programme to access next level`,
          topics: [], // It's compulsary to show summary, because it's used for conditional rendering
        },
      ]);
      setInitialThumbnail(
        sortedArray[0]?.thumbnail || sortedArray[0]?.screenshot || ''
      );
    }
    setIsSorting(false);
  }, [route_id, userInfo, userVideos]);

  const exposeTags = (data) => {
    return data.map((item) => {
      const tags = item.tags.map((tag) => tag.name);
      return { ...item, tags };
    });
  };

  const classObj = (classArray) => {
    return classArray.reduce((result, cls) => {
      cls.tags.forEach((tag) => {
        if (tag.includes('Week')) {
          const weekNum = tag.trim();
          if (!result[weekNum]) {
            result[weekNum] = {};
          }
          cls.tags.forEach((tag) => {
            if (!tag.includes('Week')) {
              const classNum = tag.trim();
              if (!result[weekNum][classNum]) {
                result[weekNum][classNum] = {};
              }
              cls.tags.forEach((tag) => {
                if (!tag.includes('Week') && !tag.includes(classNum)) {
                  const dayName = tag.trim();
                  result[weekNum]['instructor'] = cls?.instructor;
                  result[weekNum]['course_id'] = cls?.course_id;
                  result[weekNum][classNum][dayName] = {
                    ...cls,
                  };
                }
              });
            }
          });
        }
      });
      return result;
    }, {});
  };

  function groupWeekByClass(orgObj) {
    const groupedObj = orgObj;
    for (const week of Object.keys(groupedObj)) {
      groupedObj[week] = { ...groupByClass(groupedObj[week]) };
    }
    return groupedObj;
  }

  function groupByClass(objArr) {
    const groupedObj = {};
    objArr.forEach((obj) => {
      obj.tags.forEach((tag) => {
        const classMatch = tag.match(/^Class #(\d+)/);
        if (classMatch) {
          const classNum = classMatch[0];
          if (!groupedObj[classNum]) {
            groupedObj[classNum] = [];
          }
          groupedObj[classNum].push(obj);
        }
      });
    });
    return groupedObj;
  }

  function groupByWeeks(objArr) {
    const groupedObj = {};
    objArr.forEach((obj) => {
      obj.tags.forEach((tag) => {
        const weekMatch = tag.match(/^Week (\d+)/);
        if (weekMatch) {
          const weekNum = weekMatch[0];
          if (!groupedObj[weekNum]) {
            groupedObj[weekNum] = [];
          }
          groupedObj[weekNum].push(obj);
        }
      });
    });

    return groupWeekByClass(groupedObj);
  }

  function extractAllTopics(obj) {
    const topicsArray = [];

    // Iterate through each class
    for (const className in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, className)) {
        const classData = obj[className];

        // Iterate through each item in the class data
        for (const item of classData) {
          if (item.hasOwnProperty('topics')) {
            topicsArray.push(item.topics);
          }
        }
      }
    }

    return topicsArray.flat();
  }

  useEffect(() => {
    setIsAvailable(false);
    if (
      classes.length > 0 &&
      topics.length > 0 &&
      !isSubstringInArray(programDetails?.nick_name, ['level 2']) &&
      !isSubstringInArray(programDetails?.name || programDetails?.title, [
        'speciali',
      ])
    ) {
      const exposed = exposeTags(classes || []);
      const arranged = groupByWeeks(exposed);
      setClassesArranged(arranged);
      setWeeksArranged(Object.keys(arranged));

      if (topics?.length > 0) {
        const extrTopic = extractAllTopics(arranged[previousWeek]);
        const updatedQuizzes = [];
        extrTopic?.map((x) => {
          return topics?.map((y) => {
            if (x?.id == y?.id && y?.quizzes?.length > 0) {
              return updatedQuizzes.push(...y?.quizzes);
            }
          });
        });

        setPreviousWeekClasses(arranged[previousWeek]);
        setPreviousWeekQuizzes(updatedQuizzes);
        if (previousWeek == 'Week 1' && weekSelected == 'Week 1') {
          setIsAvailable(true);
        } else {
          const checkPrevQuizzes = async () => {
            const available = await checkPreviousQuizzes({
              quizzes: updatedQuizzes,
            });
            setIsAvailable(available);
          };
          try {
            checkPrevQuizzes();
          } catch (error) {
            toast.error('error' || 'Something went wrong', {
              position: toast.POSITION.TOP_RIGHT,
              delay: 5000,
            });
          }
        }
      }
    }
    if (
      (isSubstringInArray(programDetails?.nick_name, ['level 2']) ||
        isSubstringInArray(programDetails?.name, ['speciali'])) &&
      courses?.length > 0
    ) {
      setIsCertificateAvailable(false);
      setIsCourseAvailable(false);

      const exposed = exposeTags(classes || []);
      const arranged = groupByClass(exposed);
      setClassesArranged(arranged);
      setCoursesArranged(courses);
      if (topics?.length > 0 && courseSelected) {
        const checkEnrollment = async () => {
          const available = await enrollStudent({
            user_id: userInfo?.id,
            course_id: courseSelected?.id,
          });
          const certAvailable = await getLevel2Certificate({
            download: false,
            course_id: courseSelected?.id,
          });
          setIsCourseAvailable(available?.data?.success);
          setIsCertificateAvailable(certAvailable?.data?.success);
        };
        try {
          checkEnrollment();
        } catch (error) {
          toast.error(
            error?.message ||
              error?.description ||
              (typeof error === 'string' && error) ||
              'Access check failed',
            {
              position: toast.POSITION.TOP_RIGHT,
              delay: 5000,
            }
          );
        }
      }
    }
  }, [
    classes,
    isLoading,
    weekSelected,
    courseSelected,
    topics,
    check,
    programDetails,
  ]);

  useEffect(() => {
    setCourseSelected(null);
    if (
      (isSubstringInArray(programDetails?.nick_name, ['level 2']) ||
        isSubstringInArray(programDetails?.name, ['speciali'])) &&
      courses?.length > 0
    ) {
      setIsCourseAvailable(false);
      if (selectedCourse) {
        const checkEnrollment = async () => {
          const available = await enrollStudent({
            user_id: userInfo?.id,
            course_id: selectedCourse?.id,
          });

          setIsCourseAvailable(available?.data?.success);
        };
        try {
          checkEnrollment();
        } catch (error) {
          toast.error(
            error?.message ||
              error?.description ||
              (typeof error === 'string' && error) ||
              'Access check failed',
            {
              position: toast.POSITION.TOP_RIGHT,
              delay: 5000,
            }
          );
        }
      }
    }
  }, [selectedCourse, checkLevel2]);

  const classesInWeek = useMemo(() => {
    let filtered = {};
    if (
      !isSubstringInArray(programDetails?.nick_name || '', ['level 2']) &&
      !isSubstringInArray(programDetails?.name || '', ['speciali'])
    ) {
      if (Object.keys(classesArranged)?.length > 0) {
        filtered = classesArranged;
        filtered = filtered[weekSelected];
        filtered = Object.keys(filtered).reduce((acc, key) => {
          if (key?.toLowerCase()?.startsWith('class')) {
            acc[key] = filtered[key];
          }
          return acc;
        }, {});
      }
    }
    return filtered;
  }, [
    courses,
    classes,
    weekSelected,
    weeksArranged,
    coursesArranged,
    classesArranged,
  ]);

  const classesInCourse = useMemo(() => {
    let filtered = {};
    if (classes?.length > 0) {
      const exposed = exposeTags(
        classes?.filter((x) => x?.course?.id === courseSelected?.id) || []
      );
      const arranged = exposed;
      filtered = arranged;
    }
    return filtered;
  }, [
    courses,
    classes,
    weekSelected,
    weeksArranged,
    coursesArranged,
    classesArranged,
    courseSelected,
  ]);

  const topicInClass = useMemo(() => {
    let filtered = {};
    if (selectedTopics && topicKey) {
      filtered = topics?.find((t) => t?.id == topicKey);
    }
    return filtered;
  }, [
    courses,
    classes,
    weekSelected,
    weeksArranged,
    coursesArranged,
    classesArranged,
    selectedTopics,
    topicKey,
  ]);

  const masterClassVideos = useMemo(() => {
    let filtered = [];
    if (userVideos?.masterclass) {
      let videos = userVideos?.masterclass;
      const sortedArray = sortByTagName([...videos]);
      filtered = [
        {
          id: 1,
          title: 'Available Classes',
          total_videos: sortedArray?.length,
          topics: sortedArray?.map((x, index) => {
            return {
              ...x,
              topic: x?.title,
              // duratoin: '1m 41s',
              status: currentStream?.id == x?.id ? 'continue' : 'pending',
              locked: x?.url ? false : true,
            };
          }),
        },
        {
          id: 3,
          title: 'Additional Information',
          summary: `Complete your application to get more masterclass videos and access other programmes`,
          topics: [], // It's compulsary to show summary, because it's used for conditional rendering
        },
      ];
    }
    return filtered;
  }, [currentStream, route_id, userInfo, userVideos]);

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleVideoProgress = async (state) => {
    if (playedSeconds !== Math.floor(state?.playedSeconds)) {
      setPlayedSeconds(state.playedSeconds);
      const res = await saveVideoProgress({
        video_id: currentStream?.id,
        duration: Math.floor(duration),
        position: Math.floor(state?.playedSeconds),
      });
    }
  };
  const playerRef = useRef(null);

  const handlePlay = async () => {
    window.scrollTo(0, 0);
    const currentSeek = userInfo?.videos_progress?.find(
      (x) => x?.video_id == currentStream?.id
    )?.position;
    const currentTime = playerRef.current.getCurrentTime();
    playerRef.current.seekTo(
      currentStream?.id !== prevStream?.id ? currentSeek : currentTime
    );
    setPrevStream(currentStream);
  };

  const seekto = useMemo(() => {
    let seek = 0;
    return seek;
  }, [currentStream]);

  const handleOpenEnroll = () => {
    setEnrollVisible(true);
  };
  const downloadImage = (dataUrl, filename) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = filename;
    link.click();
  };

  const handleCertificate = async () => {
    if (
      !userInfo?.application?.photo_id ||
      !userInfo?.application?.highest_certificate
    ) {
      let errorArray = [];
      !userInfo?.application?.highest_certificate &&
        errorArray.push(
          'You need to upload your highest educational certificate'
        );
      !userInfo?.application?.photo_id &&
        errorArray.push('You need to upload your photo ID');
      setModalError(errorArray);
      setModalErrorVisible(true);
      return;
    }
    const res = await getLevel2Certificate({
      course_id: courseSelected?.id,
      download: true,
    });

    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful certificate download', {
        position: toast.POSITION.TOP_RIGHT,
      });
      downloadImage(
        res?.data?.image?.encoded,
        'level_2.' + res?.data?.image?.extension
      );
    } else {
      setModalError(
        res?.error?.data?.message || res?.data?.message || 'Failed!'
      );
      setModalErrorVisible(true);
    }
  };

  const containerStyle = {
    backgroundImage: `url(${programbackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  };

  const base_url = BASE_URL.slice(0, -4);
  if (
    loading ||
    isLoading ||
    isLoadingUserVideos ||
    isSorting ||
    isLoadingClasses ||
    isLoadingCourses ||
    isLoadingTopics
  ) {
    return <Loader />;
  }
  if (
    programDetails?.name?.toLowerCase()?.includes('masterclass') &&
    (programDetails?.price == '0' || programDetails?.price == 0)
  ) {
    return (
      <Fragment>
        <div style={containerStyle}>
          <MasterClass
            programDetails={programDetails}
            currentStream={currentStream}
            setCurrentStream={setCurrentStream}
            history={history}
            playerRef={playerRef}
            base_url={base_url}
            initialThumbnail={initialThumbnail}
            handleVideoProgress={handleVideoProgress}
            handleDuration={handleDuration}
            handlePlay={handlePlay}
            masterIndex={masterIndex}
            masterClassVideos={masterClassVideos}
            level1Index={level1Index}
          />
        </div>
      </Fragment>
    );
  } else if (
    isSubstringInArray(programDetails?.nick_name, ['level 2']) ||
    isSubstringInArray(programDetails?.name, ['speciali'])
  ) {
    return (
      <Fragment>
        <div style={containerStyle}>
          <Level2
            courseSelected={courseSelected}
            setCourseSelected={setCourseSelected}
            classSelected={classSelected}
            setClassSelected={setClassSelected}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            activeAssignment={activeAssignment}
            setActiveAssignment={setActiveAssignment}
            activeQuiz={activeQuiz}
            setActiveQuiz={setActiveQuiz}
            activeAssignmentKey={activeAssignmentKey}
            setActiveAssignmentKey={setActiveAssignmentKey}
            activeQuizKey={activeQuizKey}
            setActiveQuizKey={setActiveQuizKey}
            coursesArranged={coursesArranged}
            setCoursesArranged={setCoursesArranged}
            classesArranged={classesArranged}
            setClassesArranged={setClassesArranged}
            activeTopicKey={activeTopicKey}
            setActiveTopicKey={setActiveTopicKey}
            setTopicKey={setTopicKey}
            topicKey={topicKey}
            setInstructorId={setInstructorId}
            instructorId={instructorId}
            currentStream={currentStream}
            setCurrentStream={setCurrentStream}
            isCourseAvailable={isCourseAvailable}
            setIsCourseAvailable={setIsCourseAvailable}
            isCertificateAvailable={isCertificateAvailable}
            setIsCertificateAvailable={setIsCertificateAvailable}
            isEnrolling={isEnrolling}
            isLoadingLevel2Certificate={isLoadingLevel2Certificate}
            classesInCourse={classesInCourse}
            topicInClass={topicInClass}
            handleOpenEnroll={handleOpenEnroll}
            check={check}
            setCheck={setCheck}
            playPreClassVideo={playPreClassVideo}
            setPlayPreClassVideo={setPlayPreClassVideo}
            base_url={base_url}
            classInstructor={classInstructor}
            setClassInstructor={setClassInstructor}
            handleCertificate={handleCertificate}
            programDetails={programDetails}
            fetchQuizResult={fetchQuizResult}
            fetchAssignmentResult={fetchAssignmentResult}
            isGettingAssignmentResults={isGettingAssignmentResults}
            isGettingResults={isGettingResults}
            history={history}
          />
        </div>
        <AccessProgramme
          show={enrollVisible}
          handleClose={() => {
            setEnrollVisible(false);
            setCheck(!check);
            setCheckLevel2(!checkLevel2);
          }}
          type={'edit'}
          size="md"
          scholarshipUsed={
            'verified' ==
              userInfo.application?.scholarship_code_status_level_2?.toLowerCase() &&
            isSubstringInArray('admitted', [
              userInfo.application?.status_level_2,
            ])
          }
          currentLevelId={programDetails?.id}
          amount={programDetails?.price}
          level={programDetails}
          course={selectedCourse}
        />
        <WarningModal
          showModal={modalErrorVisible}
          setShowModal={setModalErrorVisible}
          text={modalError}
          title="Incomplete Criteria for Certificate Approval"
          size="md"
        />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div style={containerStyle}>
          <Level1
            courseSelected={courseSelected}
            setCourseSelected={setCourseSelected}
            classSelected={classSelected}
            setClassSelected={setClassSelected}
            selectedCourse={selectedCourse}
            setSelectedCourse={setSelectedCourse}
            selectedClass={selectedClass}
            setSelectedClass={setSelectedClass}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            activeAssignment={activeAssignment}
            setActiveAssignment={setActiveAssignment}
            activeQuiz={activeQuiz}
            setActiveQuiz={setActiveQuiz}
            activeAssignmentKey={activeAssignmentKey}
            setActiveAssignmentKey={setActiveAssignmentKey}
            activeQuizKey={activeQuizKey}
            setActiveQuizKey={setActiveQuizKey}
            coursesArranged={coursesArranged}
            setCoursesArranged={setCoursesArranged}
            classesArranged={classesArranged}
            setClassesArranged={setClassesArranged}
            activeTopicKey={activeTopicKey}
            setActiveTopicKey={setActiveTopicKey}
            setTopicKey={setTopicKey}
            topicKey={topicKey}
            setInstructorId={setInstructorId}
            instructorId={instructorId}
            currentStream={currentStream}
            setCurrentStream={setCurrentStream}
            isCourseAvailable={isCourseAvailable}
            setIsCourseAvailable={setIsCourseAvailable}
            isCertificateAvailable={isCertificateAvailable}
            setIsCertificateAvailable={setIsCertificateAvailable}
            isEnrolling={isEnrolling}
            isLoadingLevel2Certificate={isLoadingLevel2Certificate}
            classesInCourse={classesInCourse}
            topicInClass={topicInClass}
            handleOpenEnroll={handleOpenEnroll}
            check={check}
            setCheck={setCheck}
            playPreClassVideo={playPreClassVideo}
            setPlayPreClassVideo={setPlayPreClassVideo}
            base_url={base_url}
            classInstructor={classInstructor}
            setClassInstructor={setClassInstructor}
            handleCertificate={handleCertificate}
            programDetails={programDetails}
            fetchQuizResult={fetchQuizResult}
            fetchAssignmentResult={fetchAssignmentResult}
            isGettingAssignmentResults={isGettingAssignmentResults}
            isGettingResults={isGettingResults}
            history={history}
            weeksArranged={weeksArranged}
            weekSelected={weekSelected}
            setWeekSelected={setWeekSelected}
            setPreviousWeek={setPreviousWeek}
            classesInWeek={classesInWeek}
            isAvailable={isAvailable}
          />
        </div>
      </Fragment>
    );
  }
};

export const WeekCards = ({
  weeks = [],
  weekSelected,
  setWeekSelected,
  setPreviousWeek,
  setSelectedClass,
  setSelectedTopics,
  setTopicKey,
  setInstructorId,
  setCurrentStream,
}) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: weeks?.length > 7 ? 7 : weeks?.length,
    },
    laptop: {
      breakpoint: { max: 1600, min: 1024 },
      items: weeks?.length > 5 ? 5 : weeks?.length,
    },
    miniLaptop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const sorted = [...weeks].sort((a, b) => {
    const weekA = parseInt(a.match(/(\d+)/)[1]);
    const weekB = parseInt(b.match(/(\d+)/)[1]);
    return weekA > weekB || weekA - weekB || a.localeCompare(b);
  });

  return (
    <Carousel responsive={responsive}>
      {sorted?.map((week, index) => (
        <Card
          style={{
            width: 150,
            height: 64,
            alignContent: 'center',
          }}
          className={weekSelected == week ? 'bg-primary' : 'bg-white'}
          onClick={() => {
            setPreviousWeek(sorted[index > 0 ? index - 1 : 0]);
            setWeekSelected(week);
            setSelectedTopics(null);
            weekSelected != week && setCurrentStream(null);
            setTopicKey(null);
            setSelectedClass(null);
            setInstructorId(null);
          }}
          key={week + index}
        >
          <Card.Body className="text-center">
            <Card.Title className="mb-2">
              {week?.replace('Week', 'Module')}
            </Card.Title>
          </Card.Body>
        </Card>
      ))}
    </Carousel>
  );
};

export const CourseCardGrid = ({
  courses = [],
  courseSelected,
  setCourseSelected,
  selectedCourse,
  setSelectedCourse,
  setSelectedClass,
  setSelectedTopics,
  isCourseAvailable,
  setTopicKey,
  setInstructorId,
  handleOpenEnroll,
  setCurrentStream,
  check,
  setCheck,
  isEnrolling,
}) => {
  const sorted = [...courses];
  return (
    <Container
      fluid={true}
      className="bg-white py-5 justify-content-center align-items-center"
      style={{ borderRadius: 10 }}
    >
      <Row className="px-4 mb-2">
        <h4>Select a course below to start learning</h4>
      </Row>
      <Row md={12} className="align-items-center px-4 py-2">
        {sorted?.map((course, index) => (
          <Col
            md={4}
            xl={3}
            lg={3}
            sm={12}
            className="d-flex justify-content-center align-item-center "
          >
            <Card
              style={{
                width: 250,
                padding: 10,
                minHeight: 250,
                marginBottom: 20,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              className={'bg-black'}
              onClick={() => {
                !isEnrolling && setCheck(!check);
                setSelectedCourse(course);
                setSelectedTopics(null);
                setTopicKey(null);
                setSelectedClass(null);
                setInstructorId(null);
                selectedCourse?.id != course?.id && setCurrentStream(null);
              }}
              key={course?.title + index}
            >
              {selectedCourse?.id == course?.id ? (
                <div className="justify-content-center align-items-center">
                  <h5 className="mb-2 text-white text-uppercase text-center ">
                    {course?.title}
                  </h5>
                  <p className="text-white text-center fs-6">
                    {truncateText(course?.description, 150)}
                  </p>
                  <div className="d-flex mt-4 justify-content-center align-items-center">
                    <CustomButton
                      text={isCourseAvailable ? 'PROCEED' : 'ENROL NOW'}
                      loading={isEnrolling}
                      onClick={
                        !isCourseAvailable
                          ? handleOpenEnroll
                          : () => {
                              setCourseSelected(course);
                              setSelectedTopics(null);
                              setTopicKey(null);
                              setSelectedClass(null);
                              setInstructorId(null);
                              courseSelected?.id != course?.id &&
                                setCurrentStream(null);
                            }
                      }
                      style={{ zIndex: 100, minWidth: 100 }}
                    />
                  </div>
                </div>
              ) : (
                <h4 className="mb-0 text-white text-uppercase text-center justify-content-center align-items-center">
                  {course?.title}
                </h4>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export const ClassAccordion = ({
  classes,
  classSelected,
  setClassSelected,
  setSelectedClass,
  selectedClass,
  setSelectedTopics,
  setTopicKey,
  setInstructorId,
  isAvailable,
  isGettingResults,
  restrictionMessage,
  setCurrentStream,
}) => {
  if (Object.keys(classes)?.length > 0) {
    return Object.keys(classes).map((classKey) => (
      <Accordion
        defaultActiveKey={Object.keys(classes)[0]}
        activeKey={classSelected}
      >
        <Accordion.Item eventKey={classKey} key={uuid()}>
          <Accordion.Header
            onClick={() => {
              setClassSelected(classKey);
            }}
          >
            <p className="text-black mb-0">{classKey}</p>
          </Accordion.Header>
          <Accordion.Body>
            {classes[classKey].map((dayKey) => {
              if (!isAvailable) {
                return (
                  <OverlayTrigger
                    key={uuid()}
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                      <Popover id="popover-basic" placement={'bottom'}>
                        <Popover.Header as="h3">
                          Restricted Access
                        </Popover.Header>
                        <Popover.Body>
                          {restrictionMessage ||
                            "This class is inaccessible because you haven't completed the previous module"}
                        </Popover.Body>
                      </Popover>
                    }
                    onEntering={() => {
                      setSelectedTopics(null);
                      setTopicKey(null);
                      setSelectedClass(null);
                      setInstructorId(null);
                    }}
                  >
                    <Button
                      style={{
                        border: 'none',
                        backgroundColor: 'transparent',
                        color: 'inherit',
                        textDecoration: 'none',
                        cursor: 'pointer',
                        padding: 0,
                        margin: 0,
                        width: '100%',
                      }}
                    >
                      <ListItem
                        key={uuid()}
                        title={isGettingResults ? 'Loading...' : dayKey?.title}
                        children={<i className="fe fe-lock fs-4"></i>}
                        isActive={selectedClass?.id == dayKey?.id}
                      />
                    </Button>
                  </OverlayTrigger>
                );
              } else {
                return (
                  <Button
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: 'inherit',
                      textDecoration: 'none',
                      cursor: 'pointer',
                      padding: 0,
                      margin: 0,
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    <ListItem
                      key={uuid()}
                      title={isGettingResults ? 'Loading...' : dayKey?.title}
                      children={<ChevronRight />}
                      onClick={() => {
                        setSelectedTopics(dayKey?.topics);
                        setTopicKey(dayKey?.topics[0]?.id);
                        setSelectedClass(dayKey);
                        setInstructorId(dayKey?.course?.instructor);
                        selectedClass?.id != dayKey?.id &&
                          setCurrentStream(null);
                      }}
                      isActive={selectedClass?.id == dayKey?.id}
                    />
                  </Button>
                );
              }
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    ));
  }
};

export const ClassList = ({
  classes,
  classSelected,
  setClassSelected,
  setSelectedClass,
  selectedClass,
  setSelectedTopics,
  setTopicKey,
  setInstructorId,
  isAvailable,
  isGettingResults,
  restrictionMessage,
  setCurrentStream,
}) => {
  if (Object.keys(classes)?.length > 0) {
    return classes.map((dayKey) => {
      if (!isAvailable) {
        return (
          <OverlayTrigger
            key={uuid()}
            trigger={['hover', 'focus']}
            placement="bottom"
            overlay={
              <Popover id="popover-basic" placement={'bottom'}>
                <Popover.Header as="h3">Restricted Access</Popover.Header>
                <Popover.Body>
                  {restrictionMessage ||
                    "This class is inaccessible because you haven't completed the previous module"}
                </Popover.Body>
              </Popover>
            }
            onEntering={() => {
              setSelectedTopics(null);
              setTopicKey(null);
              setSelectedClass(null);
              setInstructorId(null);
            }}
          >
            <Button
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer',
                padding: 0,
                margin: 0,
                width: '100%',
              }}
            >
              <ListItem
                key={uuid()}
                title={isGettingResults ? 'Loading...' : dayKey?.title}
                children={<i className="fe fe-lock fs-4"></i>}
                isActive={selectedClass?.id == dayKey?.id}
              />
            </Button>
          </OverlayTrigger>
        );
      } else {
        return (
          <Button
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              color: 'inherit',
              textDecoration: 'none',
              cursor: 'pointer',
              padding: 0,
              margin: 0,
              width: '100%',
              textAlign: 'left',
            }}
          >
            <ListItem
              key={uuid()}
              title={isGettingResults ? 'Loading...' : dayKey?.title}
              children={<ChevronRight />}
              onClick={() => {
                setSelectedTopics(dayKey?.topics);
                setTopicKey(dayKey?.topics[0]?.id);
                setSelectedClass(dayKey);
                setInstructorId(dayKey?.course?.instructor);
                selectedClass?.id != dayKey?.id && setCurrentStream(null);
              }}
              isActive={selectedClass?.id == dayKey?.id}
            />
          </Button>
        );
      }
    });
  }
};

export const Header = ({ title, backAction, programDetails }) => {
  const history = useHistory();
  const defaultBackAction = () => history.goBack();
  return (
    <Card className="mb-2">
      <Card.Body>
        <div className="d-flex justify-content-left align-items-center">
          <i
            className="fa fa-arrow-left fs-3 text-black"
            onClick={title && backAction ? backAction : defaultBackAction}
          ></i>
          <h3 className="fw-semi-bold mx-4 mt-2">
            {title || programDetails?.name}
          </h3>
        </div>
      </Card.Body>
    </Card>
  );
};

export default SingleProgramme;
