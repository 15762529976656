// import node module libraries
import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

// import layouts
import NavbarBrandOnly from 'pages/layouts/marketing/navbars/NavbarBrandOnly';

const NotFound = (props) => {
  useEffect(() => {
    document.body.style.backgroundColor = 'white';
  });

  return (
    <div id="db-wrapper" className="bg-white">
      <Container className="d-flex flex-column min-vh-100">
        <NavbarBrandOnly />
        {props.children}
      </Container>
    </div>
  );
};

export default NotFound;
