// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  Accordion,
  Button,
  OverlayTrigger,
  Image,
  Tooltip,
  Tab,
  Nav,
  ListGroup,
  Popover,
  Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  useEnrollStudentForCourseMutation,
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetLevel2CertificateMutation,
  useGetProgramByIdMutation,
  useGetStudentAssignmentResultsMutation,
  useGetStudentQuizResultsMutation,
  useGetTopicsMutation,
  useGetUserVideosMutation,
  useSaveVideoProgressMutation,
} from 'pages/redux/features/user/userApi';
import { Loader } from 'pages/components/Loader';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import GKAccordionProgress from '../dashboard/components/GKAccordionProgress';
import { toast } from 'react-toastify';

import { ChevronRight } from 'react-feather';
import {
  flattenedArray,
  isSubstringInArray,
  sortAscending,
  sortByTagName,
  sortWeeksArray,
  updateObjects,
} from 'pages/helper/formatArray';
import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import GKYouTube from 'components/marketing/common/video/GKYouTube';
import { mdiBook, mdiChevronDown, mdiPlay } from '@mdi/js';
import Icon from 'react-icons-kit';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { book } from 'react-icons-kit/fa/book';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  saveProgrammesStarted,
  saveUser,
} from 'pages/redux/features/auth/authSlice';
import { extractObjectValues } from 'pages/helper/formatObjectToArray';
import { BASE_URL } from 'pages/redux/features/url';
import AccessProgramme from '../dashboard/AccessProgramme';
import { WarningModal } from 'pages/components/Modals/WarningModal';
import { CustomButton } from 'pages/components/CustomButton';
import { programbackground } from 'pages/assets/images/imageUrl';
import { truncateText } from 'pages/helper/formatText';
import { ClassAccordion, Header, WeekCards } from './SingleProgramme';

const MasterClass = ({
  programDetails,
  currentStream,
  setCurrentStream,
  history,
  playerRef,
  base_url,
  initialThumbnail,
  handleVideoProgress,
  handleDuration,
  handlePlay,
  masterIndex,
  masterClassVideos,
  level1Index,
}) => {
  return (
    <Container className="py-lg-5 py-5 overflow-auto min-vh-100">
      <Col>
        <Row className="mb-4 mb-xl-0">
          <Card className="mb-5">
            <Card.Body>
              <div className="d-flex align-items-center">
                <i
                  className="fa fa-arrow-left fs-3 text-black"
                  onClick={() => history.goBack()}
                ></i>
                <h3 className="fw-semi-bold  mx-4 mt-2">
                  {programDetails?.name}
                </h3>
                {currentStream && (
                  <>
                    <h3
                      className="fw-semi-bold"
                      style={{
                        marginLeft: '10px',
                        width: 35,
                      }}
                    >
                      &nbsp;{'->'}&nbsp;
                    </h3>
                    <h3 className="fw-semi-bold">
                      {currentStream?.name || currentStream?.title}
                    </h3>
                  </>
                )}
              </div>
            </Card.Body>
          </Card>
        </Row>

        {currentStream?.link || currentStream?.url ? (
          <Row>
            <Col lg={12} md={12} sm={12} className="mb-5">
              <div
                className="rounded-3 position-relative w-100 d-block overflow-hidden p-0"
                // style={{ height: '600px' }}
              >
                <ReactPlayer
                  ref={playerRef}
                  url={base_url + (currentStream?.url || initialThumbnail)}
                  playing={!!currentStream}
                  controls={true}
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                      // forceVideo: true,
                    },
                  }}
                  progressInterval={5000}
                  onProgress={handleVideoProgress}
                  onDuration={handleDuration}
                  onPlay={handlePlay}
                />
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        <Card>
          <Card.Header>
            <h3 className="fw-semi-bold mb-2">
              {programDetails?.name?.toLowerCase()?.includes('master')
                ? 'Playlist'
                : 'Classes'}
            </h3>
          </Card.Header>
          {masterIndex?.length > 0 && (
            <GKAccordionProgress
              accordionItems={
                programDetails?.name?.toLowerCase()?.includes('master') ||
                programDetails?.price
                  ? masterClassVideos
                  : level1Index
              }
              currentStream={currentStream}
              setCurrentStream={setCurrentStream}
            />
          )}
        </Card>
      </Col>
    </Container>
  );
};

export default MasterClass;
