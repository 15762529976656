// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useCreateQuizMutation,
  useUpdateQuizMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AddQuiz = ({ show, handleClose, type, id, initialState }) => {
  const [unit, setUnit] = useState('minutes');

  const [createQuiz, { isLoading: isCreatingQuiz }] = useCreateQuizMutation();

  const [updateQuiz, { isLoading: isUpdatingQuiz }] = useUpdateQuizMutation();

  const handleQuizCreation = async (data) => {
    const res =
      type == 'edit'
        ? await updateQuiz({ id: initialState?.id, data })
        : await createQuiz(data);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    instructions: Yup.string().required('Required').typeError('Text expected'),
    allowed_time: Yup.number()
      .when('timed', {
        is: 'true',
        then: Yup.number()
          .min(1, 'Must be higher than zero')
          .required('This field is required'),
      })
      .typeError('Number expected'),
    total: Yup.number()
      .required('Required')
      .moreThan(
        Yup.ref('pass_mark'),
        'Total mark must be higher than pass mark'
      )
      .typeError('Number expected'),
    pass_mark: Yup.number().required('Required').typeError('Number expected'),
    topic_id: Yup.number().required('Required').typeError('Number expected'),
    timed: Yup.boolean().required().typeError('Yes/No expected'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      instructions: initialState?.instructions || '',
      timed: true,
      allowed_time: initialState?.allowed_time / 60 || null,
      total: initialState?.total || null,
      pass_mark: initialState?.pass_mark || null,
      topic_id: id || null,
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        timed: JSON.parse(values.timed),
        allowed_time:
          values?.allowed_time &&
          (values?.timed == 'true' || values?.timed) &&
          (unit === 'minutes'
            ? values?.allowed_time * 60
            : values?.allowed_time * 60 * 60),
      };
      // !resolveValues?.timed && delete resolveValues?.allowed_time;
      handleQuizCreation(resolveValues);
    },
  });

  function handleUnitSelect(e) {
    setUnit(e.target?.value);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type == 'edit' ? 'Edit Quiz' : 'Add Quiz'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Quiz Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="instructions"
                placeholder="Instructions"
                required
                value={formik.values?.instructions}
                onChange={formik.handleChange}
              />
              {formik.errors.instructions && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.instructions}
                </p>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Is it timed</Form.Label>
              <Form.Control
                as="select"
                id="timed"
                placeholder="Yes/No"
                required
                value={formik.values?.timed}
                onChange={formik.handleChange}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
              {formik.errors?.timed && (
                <p className="fs-8 text-danger mb-0">{formik.errors?.timed}</p>
              )}
            </Form.Group> */}
            {(formik.values?.timed === 'true' || formik.values?.timed) && (
              <Form.Group className="mb-3">
                <Form.Label>Allowed Time</Form.Label>
                <InputGroup>
                  <FormControl
                    type="number"
                    placeholder="Enter a duration"
                    id="allowed_time"
                    min={1}
                    value={formik.values?.allowed_time}
                    onChange={formik.handleChange}
                  />
                  <Form.Control
                    as="select"
                    id="tag3"
                    placeholder="minutes"
                    required
                    value={unit}
                    onChange={handleUnitSelect}
                    style={{ minWidth: 'unset' }}
                  >
                    {['minutes', 'hours']?.map((x) => (
                      <option value={x}>{x}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
                {formik.errors?.allowed_time && (
                  <p className="fs-8 text-danger mb-0">
                    {formik.errors?.allowed_time}
                  </p>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Total mark</Form.Label>
              <FormControl
                type="number"
                placeholder="Enter a number"
                id="total"
                value={formik.values?.total}
                onChange={formik.handleChange}
              />
              {formik.errors?.total && (
                <p className="fs-8 text-danger mb-0">{formik.errors?.total}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pass mark</Form.Label>
              <FormControl
                type="number"
                placeholder="Enter a number"
                id="pass_mark"
                value={formik.values?.pass_mark}
                onChange={formik.handleChange}
              />
              {formik.errors?.pass_mark && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.pass_mark}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isCreatingQuiz || isUpdatingQuiz || !formik.isValid}
        >
          {isCreatingQuiz || isUpdatingQuiz ? <Spinner size="sm" /> : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddQuiz;
