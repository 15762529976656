// import node module libraries
import { Fragment, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

// import custom components
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAdmitWithoutScholarshipByIdMutation,
  useAdmitWithScholarshipByIdMutation,
  useRejectByIdMutation,
  useRejectToReapplyByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChangeAdminStatus = ({ show, handleClose, id }) => {
  const [selected, setSelected] = useState(null);
  const [comment, setComment] = useState('');
  const parentOptions = [
    { value: 'admitwithout', label: 'Admit without Scholarship' },
    { value: 'admitwith', label: 'Admit with Scholarship' },
    { value: 'reapply', label: 'Reject to Reapply' },
    { value: 'reject', label: 'Reject' },
  ];

  const [admitWithScholarshipById, { isLoading: admittingWithScholarship }] =
    useAdmitWithScholarshipByIdMutation();
  const [
    admitWithoutScholarshipById,
    { isLoading: admittingWithoutScholarship },
  ] = useAdmitWithoutScholarshipByIdMutation();
  const [rejectById, { isLoading: rejecting }] = useRejectByIdMutation();
  const [rejectToReapplyById, { isLoading: rejectingToReapply }] =
    useRejectToReapplyByIdMutation();

  const handleProcessAplication = async () => {
    const opt = selected?.value;
    if (opt) {
      const res =
        opt === 'admitwithout'
          ? await admitWithoutScholarshipById(id)
          : opt === 'admitwith'
          ? await admitWithScholarshipById(id)
          : opt === 'reject'
          ? await rejectById({ id, comment })
          : opt === 'reapply'
          ? await rejectToReapplyById({ id, comment })
          : null;

      if (res?.data?.success) {
        toast.success(res?.data?.message || 'Successful!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      } else {
        toast.error(res?.error?.data?.message || 'Failed!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Change Application status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Application status</Form.Label>
              <MultiSelectDropdown
                options={parentOptions}
                selected={selected}
                setSelected={(e) => {
                  setSelected(e);
                }}
                placeholder={'Select status'}
              />
            </Form.Group>
            {(selected?.value === 'reject' ||
              selected?.value === 'reapply') && (
              <Form.Group className="mb-1">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="comment"
                  placeholder="Comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e?.target?.value);
                  }}
                />
                {!comment && (
                  <p className="fs-8 text-danger mb-0">{'Required'}</p>
                )}
                {comment && comment.length < 15 && (
                  <p className="fs-8 text-danger mb-0">
                    {'Enter a longer/descriptive comment'}
                  </p>
                )}
              </Form.Group>
            )}
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={handleProcessAplication}
          disabled={
            ((selected?.value === 'reject' || selected?.value === 'reapply') &&
              comment.length < 15) ||
            !selected ||
            admittingWithScholarship ||
            admittingWithoutScholarship ||
            rejectingToReapply ||
            rejectingToReapply
          }
        >
          {admittingWithScholarship ||
          admittingWithoutScholarship ||
          rejectingToReapply ||
          rejectingToReapply ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeAdminStatus;
