import React, { Fragment, useEffect, useState } from 'react';
import { Card, Modal, Button, Row, Spinner } from 'react-bootstrap';
import {
  useDeleteProfileMutation,
  useGetAdminByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import ChangeAdminStatus from './ChangeAdminStatus';
import { handleProfileDeletion } from './InstructorDetails';
import { useSelector } from 'react-redux';
import { isSubstringInArray } from 'pages/helper/formatArray';

const AdminDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const { user } = useSelector((state) => state?.userAuth);
  const [details, setDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const [getAdminById, { isFetching, isLoading }] = useGetAdminByIdMutation();
  const [deleteProfile, { isLoading: isDeleting }] = useDeleteProfileMutation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteClick = async () =>
    await handleProfileDeletion(details?.email, deleteProfile, history);

  const fetchAdmin = async () => {
    const res = await getAdminById({ id: route_id });
    if (res?.data?.data) {
      setDetails(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, [refresh]);

  if (isLoading || details?.length) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="h4">Admin</Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0">
                  {details?.firstname + ' ' + details?.lastname}
                </h4>
              </div>
            </div>
            <div>
              <div className="ms-3">
                <h4>Type</h4>
                <h4>{details?.firstname}</h4>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        {/* start date  */}
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">First name</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">
                  {details?.firstname}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Body className="border-top py-3">
          <div
            className="d-flex justify-content-between
                      align-items-center"
          >
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Last name</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">
                  {details?.lastname}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Body className="border-top py-3">
          <div
            className="d-flex justify-content-between
                      align-items-center"
          >
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Email address</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">{details?.email}</p>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Body className="border-top py-3">
          <div
            className="d-flex justify-content-between
                      align-items-center"
          >
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Phone number</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">{details?.phone}</p>
              </div>
            </div>
          </div>
        </Card.Body>

        <Card.Body className="border-top py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Application date</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">
                  {new Date(details?.created_at).toDateString()}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-between">
        {/* <Button variant="primary" onClick={handleShow}>
          Change status
        </Button> */}
        {typeof user?.roles[0] == 'string' &&
        user?.roles[0]?.includes('Super') ? (
          <Button variant="secondary" onClick={handleDeleteShow}>
            Delete
          </Button>
        ) : (
          <></>
        )}
        <ChangeAdminStatus
          show={show}
          handleClose={handleClose}
          id={route_id}
        />

        <Modal
          show={deleteShow}
          onHide={isDeleting ? () => null : handleDeleteClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Application</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this admin</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            <Button
              variant="outline-white"
              onClick={handleDeleteClose}
              disabled={isDeleting}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleDeleteClick}
              disabled={isDeleting}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AdminDetails;
