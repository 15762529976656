import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Tab,
  Nav,
  Col,
  Table,
  Dropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Accordion,
  ListGroup,
} from 'react-bootstrap';
import {
  useDeleteClassMutation,
  useDeleteCourseMutation,
  useDeleteProgramMutation,
  useDeleteVideoMutation,
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetProgramByIdMutation,
  useGetTeachersMutation,
  useGetUserVideosMutation,
  useRemoveInstructorFromCourseMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import AddNewProgram from './AddNewProgram';
import GlobalFilter from 'pages/components/elements/advance-table/GlobalFilter';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'pages/components/elements/advance-table/Pagination';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { useMemo } from 'react';
import AddCourse from './AddCourse';
import { Icon } from 'react-icons-kit';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { trash } from 'react-icons-kit/fa/trash';
import { VideoModal } from 'pages/components/Modals/DisplayModal';
import { ConfirmDeleteModal } from 'pages/components/Modals/ConfirmDeleteModal';
import moment from 'moment/moment';
import AddClass from './AddClass';
import AddTopic from '../topics/AddTopic';
import { ListItem } from '../admissions/ApplicationDetails';
import AssignInstructor from './AssignInstructor';
import {
  arrangedMasterClassVideos,
  isSubstringInArray,
  sortByProperty,
  sortByTagName,
} from 'pages/helper/formatArray';
import { mdiPlay } from '@mdi/js';
import AddVideo from './AddVideo';

const ProgramDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [topicDetails, setTopicDetails] = useState({});
  const [classDetails, setClassDetails] = useState({});
  const [courseDetails, setCourseDetails] = useState({});
  const [show, setShow] = useState(false);
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showAddCourse, setShowAddCourse] = useState(false);
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [showAssignInstructor, setShowAssignInstructor] = useState(false);
  const [key, setKey] = useState('1');
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteCourseShow, setDeleteCourseShow] = useState(false);

  const [list, setList] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [deleteName, setDeleteName] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, setVideos] = useState([]);

  const [showDisplayModal, setShowDisplayModal] = useState(false);
  const [type, setType] = useState('add');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const toggleDisplayModal = () => setShowDisplayModal(!showDisplayModal);

  const [getTeachers, { isLoading: fetchingTeachers }] =
    useGetTeachersMutation();
  const [getUserVideos, { isLoading: fetchingVideos }] =
    useGetUserVideosMutation();
  const [getClasses, { isLoading: fetchingClasses }] = useGetClassesMutation();
  const [getCourses, { isLoading: fetchingCourses }] =
    useGetCoursesLiteMutation();
  const [getProgramById, { isFetching, isLoading }] =
    useGetProgramByIdMutation();
  const [deleteVideo, { isLoading: isDeletingVideo }] =
    useDeleteVideoMutation();
  const [deleteProgram, { isLoading: isDeleting }] = useDeleteProgramMutation();
  const [deleteCourse, { isLoading: isDeletingCourse }] =
    useDeleteCourseMutation();
  const [deleteClass, { isLoading: isDeletingClass }] =
    useDeleteClassMutation();
  const [
    removeInstructorFromCourse,
    { isLoading: isRemovingInstructorFromCourse },
  ] = useRemoveInstructorFromCourseMutation();

  useEffect(() => {
    fetchInstructors();
  }, [refresh]);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleCloseAddCourse = () => {
    setShowAddCourse(false);
    setRefresh(!refresh);
  };
  const handleCloseAddVideo = () => {
    setShowAddVideo(false);
    setRefresh(!refresh);
  };
  const handleCloseAssignInstructor = () => {
    setShowAssignInstructor(false);
    setRefresh(!refresh);
  };
  const handleCloseAddClass = () => {
    setShowAddClass(false);
    setRefresh(!refresh);
  };
  const handleCloseAddTopic = () => {
    setShowAddTopic(false);
    setRefresh(!refresh);
  };
  const handleShowAddCourse = () => {
    setShowAddCourse(true);
  };
  const handleShowAddVideo = () => {
    setShowAddVideo(true);
  };
  const handleShowAssignInstructor = () => {
    setShowAssignInstructor(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => {
    setDeleteShow(false);
    setRefresh(!refresh);
  };

  const handleDeleteProgram = async () => {
    const res = await deleteProgram({ id: details.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDeleteVideo = async () => {
    const res = await deleteVideo({ id: selectedVideo?.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRemoveInstructor = async (instructorId) => {
    const res = await removeInstructorFromCourse({
      user_id: instructorId,
      course_id: courseDetails?.id,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Removed successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteCourse = async () => {
    const res = await deleteCourse({ id: selectedCourse.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteClass = async () => {
    const res = await deleteClass({ id: selectedClass.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteClick = () => {
    deleteName == 'class'
      ? handleDeleteClass()
      : deleteName == 'course'
      ? handleDeleteCourse()
      : deleteName == 'video'
      ? handleDeleteVideo()
      : handleDeleteProgram();
  };

  const fetchInstructors = async () => {
    const res = await getTeachers();
    if (res?.data?.success) {
      setInstructors(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchCourses = async () => {
    const res = await getCourses();
    if (res?.data?.success) {
      setCourses(
        res?.data?.data?.courses?.filter((x) => x?.programme_id == route_id)
      );
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchClasses = async () => {
    const res = await getClasses();
    if (res?.data?.success) {
      setClasses(res?.data?.data?.classes);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchProgram = async () => {
    const res = await getProgramById({ id: route_id });
    if (res?.data) {
      setDetails(res?.data?.data?.programme);
      if (
        isSubstringInArray('masterclass', [res?.data?.data?.programme?.name])
      ) {
        const videos = await getUserVideos();
        if (res?.data) {
          setVideos(videos?.data?.data?.videos?.masterclass);
        }
      }
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };

  useEffect(() => {
    fetchProgram();
    fetchCourses();
    fetchClasses();
  }, [refresh]);

  useEffect(() => {
    setCourseDetails(courses[0]);
    setKey(courses[0]?.id);
  }, [courses]);

  const classList = useMemo(() => {
    let filtered = courseDetails?.classes || [];
    filtered = classes.filter((x, index) => {
      return courseDetails?.classes?.find((y) => y?.id === x?.id);
    });
    return filtered;
  }, [courseDetails, classes]);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'title',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              style={{
                backgroundColor: row.original?.deleted_at ? 'red' : 'green',
              }}
              to={`/admin/topic/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'description',
        Header: 'DESCRIPTION',
        Cell: ({ value, row }) => {
          return (
            <Link to={`/admin/topic/${row?.original?.id}`}>
              <h5 className="text-primary-hover">{value?.slice(0, 20)}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'deleted_at',
        Header: 'Is deleted',
        Cell: ({ value }) => {
          return value ? 'True' : 'False';
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    let filtered = selectedClass?.topics || [];
    filtered = sortByProperty(filtered, 'created_at', 'desc');

    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [selectedClass, courseDetails, classDetails]);

  const filteredVideos = useMemo(() => {
    let filtered = videos;
    filtered = sortByTagName(filtered);
    return filtered;
  }, [videos]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const getRowProps = useCallback(
    (row) => ({
      style: {
        backgroundColor: row.values.deleted_at ? '#DC00001A' : 'white',
      },
    }),
    []
  );

  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionClick = (eventKey) => {
    if (activeKey === eventKey) {
      // If the same accordion is clicked again, close it
      setActiveKey(null);
    } else {
      setActiveKey(eventKey);
      setSelectedClass(classList.find((y) => y.id === eventKey));
    }
  };

  if (
    isLoading ||
    details?.length ||
    fetchingClasses ||
    fetchingCourses ||
    fetchingVideos
  ) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Programme
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {details?.name}
                </h4>
              </div>
            </div>
            <Button variant="primary" onClick={handleShow}>
              Edit Programme
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <ListItem title={'Title'} children={details?.name} />
        <ListItem title={'Nick name'} children={details?.nick_name || 'N/A'} />
        <ListItem title={'Price'} children={details?.price / 100 || 'N/A'} />
        <ListItem
          title={'Start date'}
          children={details?.start_time || 'N/A'}
        />
        <ListItem title={'Duration'} children={details?.duration || 'N/A'} />
      </Card>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Description
              </Card.Title>
              <div className="d-flex align-items-center">
                <p className="mb-0 text-capitalize text-black">
                  {details?.description
                    ? details?.description
                    : 'No description'}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      {details?.name && isSubstringInArray('masterclass', [details?.name]) ? (
        <Card className="mb-4">
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title as="p" className="text-uppercase">
                Videos
              </Card.Title>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setType('add');
                  handleShowAddVideo();
                }}
              >
                Add New Video
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="py-3">
            {filteredVideos?.map((x, index) => (
              <ListGroup className="mt-2">
                <ListGroup.Item
                  key={'index'}
                  className="d-flex justify-content-between"
                >
                  <Link
                    to="#"
                    onClick={() => {
                      setSelectedUrl(x?.url);
                      setSelectedThumbnail(x?.screenshot);
                      toggleDisplayModal();
                      return;
                    }}
                    className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                  >
                    <div className="text-truncate ">
                      <span
                        className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                      >
                        <Icon icon={playCircleO} size={30} />
                      </span>
                      <span className="fs-5">{x?.title}</span>
                    </div>
                  </Link>
                  <div className="d-flex justify-content-end">
                    <Button
                      size="sm"
                      className="mx-2"
                      onClick={() => {
                        setSelectedVideo(x);
                        setType('edit');
                        handleShowAddVideo();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => {
                        setDeleteName('video');
                        setSelectedVideo(x);
                        handleDeleteShow();
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            ))}
          </Card.Body>
        </Card>
      ) : (
        <Card className="mb-4">
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <Card.Title as="p" className="text-uppercase">
                Courses
              </Card.Title>
              <Button
                variant="primary"
                size="sm"
                onClick={() => {
                  setType('add');
                  handleShowAddCourse();
                }}
              >
                Add New Course
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="py-3">
            <Tab.Container
              justify
              activeKey={key}
              onSelect={(k) => {
                setSelectedCourse(courses?.find((x) => x?.id == k));
                setCourseDetails(courses?.find((x) => x?.id == k));
                setKey(k);
              }}
            >
              <Card.Header className="border-bottom-0 p-0 bg-white">
                <Nav className="nav-lb-tab">
                  {courses.map((x) => (
                    <Nav.Item>
                      <Nav.Link eventKey={x?.id} className="mb-sm-3 mb-md-0">
                        {x?.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Card.Header>
              <Card.Body className="p-0">
                <Tab.Content>
                  {courses.map((x) => (
                    <>
                      <Tab.Pane eventKey={x?.id} className="pb-4">
                        <Card className="mb-4">
                          <ListItem title={'Title'} children={x?.title} />
                          <ListItem
                            title={'Description'}
                            children={x?.description}
                          />
                          <ListItem
                            title={'Instructor'}
                            children={
                              <div className="d-flex">
                                <h5
                                  style={{
                                    marginRight: 15,
                                    alignSelf: 'center',
                                  }}
                                >
                                  {x?.instructor &&
                                    x?.instructor?.firstname +
                                      ' ' +
                                      x?.instructor?.lastname}
                                </h5>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    !x?.instructor && setType('add');
                                    !x?.instructor
                                      ? handleShowAssignInstructor()
                                      : handleRemoveInstructor(
                                          x?.instructor?.id
                                        );
                                  }}
                                >
                                  {!x?.instructor
                                    ? 'Assign Instructor'
                                    : 'Remove Instructor'}
                                </Button>
                              </div>
                            }
                          />
                          <ListItem
                            title={'Pre class video'}
                            children={
                              x?.pre_class_video ? (
                                <div
                                  onClick={() => {
                                    setSelectedUrl(x?.pre_class_video);
                                    setSelectedThumbnail(
                                      x?.pre_class_video_thumbnail
                                    );
                                    toggleDisplayModal();
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Play Video</Tooltip>}
                                  >
                                    <div
                                      className="position-relative"
                                      style={{ maxWidth: 200 }}
                                    >
                                      <Image
                                        src={x?.pre_class_video_thumbnail}
                                        alt="Video Thumbnail"
                                        style={{ maxWidth: 200 }}
                                      />
                                      <div className="position-absolute top-50 start-50 translate-middle">
                                        <Icon icon={playCircleO} size={50} />
                                      </div>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              ) : (
                                '...'
                              )
                            }
                          />
                          <ListItem
                            title={'Date created'}
                            children={
                              x?.created_at
                                ? moment(x?.created_at).format(
                                    'ddd MMM DD, YYYY'
                                  )
                                : '...'
                            }
                          />
                          <ListItem
                            title={'Last updated'}
                            children={
                              x?.updated_at
                                ? moment(x?.updated_at).format(
                                    'ddd MMM DD, YYYY'
                                  )
                                : '...'
                            }
                          />
                          {x?.deleted_at && (
                            <ListItem
                              title={'Deleted at'}
                              children={
                                x?.deleted_at
                                  ? moment(x?.deleted_at).format(
                                      'ddd MMM DD, YYYY'
                                    )
                                  : '...'
                              }
                            />
                          )}

                          <Card.Body className="border-top py-3">
                            <div className="d-flex justify-content-between align-items-center">
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => {
                                  setDeleteName('course');
                                  setSelectedCourse(x);
                                  handleDeleteShow();
                                }}
                              >
                                Delete Course
                              </Button>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                  setType('edit');
                                  handleShowAddCourse();
                                }}
                              >
                                Edit Course
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>

                        <Accordion className="mb-3" activeKey={'0'}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <p className="text-uppercase text-black fs-5 mb-0">
                                Classes
                              </p>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex justify-content-between align-item-center mb-3">
                                <p className="text-black fs-4 mb-0">
                                  {x?.title} Classes
                                </p>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    setType('add');
                                    setSelectedCourse(x);
                                    setShowAddClass(true);
                                  }}
                                >
                                  Create New Class
                                </Button>
                              </div>
                              {classList?.map((y) => (
                                <Accordion key={y?.id} activeKey={activeKey}>
                                  <Accordion.Item eventKey={y?.id} key={y?.id}>
                                    <Accordion.Header
                                      onClick={() => handleAccordionClick(y.id)}
                                    >
                                      <p className="text-black mb-0">
                                        {y?.title}
                                      </p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <Card className="mb-4">
                                        <ListItem
                                          title={'Class Title'}
                                          children={y?.title}
                                        />
                                        <ListItem
                                          title={'Class Description'}
                                          children={y?.description}
                                        />
                                        <ListItem
                                          title={'Learning outcomes'}
                                          children={y?.learning_outcomes}
                                        />
                                        <ListItem
                                          title={'Instructor Recommendations'}
                                          children={
                                            y?.instructor_recommendations
                                          }
                                        />
                                        <ListItem
                                          title={'Tags'}
                                          children={
                                            y?.tags?.map((x) => x.name + ' ') ||
                                            '...'
                                          }
                                        />
                                        <ListItem
                                          title={'Pre class video'}
                                          children={
                                            y?.pre_class_video ? (
                                              <div
                                                onClick={() => {
                                                  setSelectedUrl(
                                                    y?.pre_class_video
                                                  );
                                                  setSelectedThumbnail(
                                                    y?.pre_class_video_thumbnail
                                                  );
                                                  toggleDisplayModal();
                                                }}
                                              >
                                                <OverlayTrigger
                                                  placement="bottom"
                                                  overlay={
                                                    <Tooltip>
                                                      Play Video
                                                    </Tooltip>
                                                  }
                                                >
                                                  <div
                                                    className="position-relative"
                                                    style={{ maxWidth: 200 }}
                                                  >
                                                    <Image
                                                      src={
                                                        y?.pre_class_video_thumbnail
                                                      }
                                                      alt="Video Thumbnail"
                                                      style={{
                                                        maxWidth: 200,
                                                      }}
                                                    />
                                                    <div className="position-absolute top-50 start-50 translate-middle">
                                                      <Icon
                                                        icon={playCircleO}
                                                        size={50}
                                                      />
                                                    </div>
                                                  </div>
                                                </OverlayTrigger>
                                              </div>
                                            ) : (
                                              '...'
                                            )
                                          }
                                        />
                                        <ListItem
                                          title={'Date'}
                                          children={
                                            y?.date
                                              ? moment(y?.date).format(
                                                  'ddd MMM DD, YYYY'
                                                )
                                              : '...'
                                          }
                                        />
                                        <ListItem
                                          title={'Start time'}
                                          children={
                                            y?.start_time
                                              ? y?.start_time
                                              : '...'
                                          }
                                        />
                                        <ListItem
                                          title={'Stop time'}
                                          children={
                                            y?.end_time ? y?.end_time : '...'
                                          }
                                        />
                                        <ListItem
                                          title={'Date created'}
                                          children={
                                            y?.created_at
                                              ? moment(y?.created_at).format(
                                                  'ddd MMM DD, YYYY'
                                                )
                                              : '...'
                                          }
                                        />
                                        <ListItem
                                          title={'Last updated'}
                                          children={
                                            y?.created_at
                                              ? moment(y?.updated_at).format(
                                                  'ddd MMM DD, YYYY'
                                                )
                                              : '...'
                                          }
                                        />

                                        <Card.Body className="border-top py-3 d-flex justify-content-between align-items-center">
                                          <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => {
                                              setDeleteName('class');
                                              setSelectedClass(y);
                                              handleDeleteShow();
                                            }}
                                          >
                                            Delete Class
                                          </Button>
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={() => {
                                              setType('edit');
                                              setClassDetails(y);
                                              setShowAddClass(true);
                                            }}
                                          >
                                            Edit Class
                                          </Button>
                                        </Card.Body>
                                        <Card.Body className="border-top py-3 ">
                                          <div className="d-flex justify-content-between align-items-center">
                                            <h4>Topics</h4>
                                            <Button
                                              variant="primary"
                                              size="sm"
                                              onClick={() => {
                                                setType('add');
                                                setCourseDetails(x);
                                                setClassDetails(y);
                                                setSelectedCourse(x);
                                                setSelectedClass(y);
                                                setShowAddTopic(true);
                                              }}
                                            >
                                              Create New Topic
                                            </Button>
                                          </div>

                                          {data?.length > 0 && (
                                            <div className="table-responsive">
                                              <Table
                                                {...getTableProps()}
                                                className="text-nowrap"
                                              >
                                                <thead className="table-light">
                                                  {headerGroups.map(
                                                    (headerGroup) => (
                                                      <tr
                                                        {...headerGroup.getHeaderGroupProps()}
                                                      >
                                                        {headerGroup.headers.map(
                                                          (column) => (
                                                            <th
                                                              {...column.getHeaderProps()}
                                                            >
                                                              {column.render(
                                                                'Header'
                                                              )}
                                                            </th>
                                                          )
                                                        )}
                                                      </tr>
                                                    )
                                                  )}
                                                </thead>
                                                <tbody {...getTableBodyProps()}>
                                                  {page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                      <tr
                                                        {...row.getRowProps(
                                                          getRowProps(row)
                                                        )}
                                                      >
                                                        {row.cells.map(
                                                          (cell) => {
                                                            return (
                                                              <td
                                                                {...cell.getCellProps()}
                                                              >
                                                                {cell.render(
                                                                  'Cell'
                                                                )}
                                                              </td>
                                                            );
                                                          }
                                                        )}
                                                      </tr>
                                                    );
                                                  })}
                                                </tbody>
                                              </Table>
                                            </div>
                                          )}
                                          {data?.length > 0 && (
                                            <Pagination
                                              previousPage={previousPage}
                                              pageCount={pageCount}
                                              pageIndex={pageIndex}
                                              gotoPage={gotoPage}
                                              nextPage={nextPage}
                                            />
                                          )}
                                        </Card.Body>
                                      </Card>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Tab.Pane>
                    </>
                  ))}
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card.Body>
        </Card>
      )}
      <div className="d-flex justify-content-start">
        <Button
          variant="secondary"
          onClick={() => {
            setDeleteName('programs');
            setSelectedCourse(null);
            handleDeleteShow();
          }}
        >
          Delete Program
        </Button>
        <AddNewProgram
          show={show}
          handleClose={handleClose}
          type={'edit'}
          initialState={details}
        />
        <AddCourse
          show={showAddCourse}
          handleClose={handleCloseAddCourse}
          id={details?.id}
          initialState={type == 'edit' ? courseDetails : null}
          type={type}
        />
        <AddVideo
          show={showAddVideo}
          handleClose={handleCloseAddVideo}
          id={details?.id}
          initialState={type == 'edit' ? selectedVideo : null}
          type={type}
        />
        <AddClass
          show={showAddClass}
          handleClose={handleCloseAddClass}
          id={selectedCourse?.id || courseDetails?.id}
          initialState={type == 'edit' ? classDetails : null}
          type={type}
        />
        <AddTopic
          show={showAddTopic}
          handleClose={handleCloseAddTopic}
          id={selectedClass?.id || classDetails?.id}
          initialState={type == 'edit' ? topicDetails : null}
          type={type}
          courseId={selectedCourse?.id || courseDetails?.id}
        />
        <AssignInstructor
          show={showAssignInstructor}
          handleClose={handleCloseAssignInstructor}
          id={selectedCourse?.id || courseDetails?.id}
        />
        <VideoModal
          showModal={showDisplayModal}
          toggleModal={toggleDisplayModal}
          url={selectedUrl}
          thumbnail={selectedThumbnail}
        />
        <Modal show={deleteShow} onHide={handleDeleteClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Delete {deleteName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this {deleteName}</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ProgramDetails;
