// import node module libraries
import {
  Container,
  Row,
  Col,
  CardGroup,
  OverlayTrigger,
  Overlay,
  Card,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

// import custom components
import SectionHeadingCenter from './SectionHeadingCenter';
import CourseCard from './CourseCard';

import { useSelector } from 'react-redux';
import { useGetProgramsLiteMutation } from 'pages/redux/features/user/userApi';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { sortProgramsByName } from 'pages/helper/sortProgramsByName';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';

const CoursesSection = ({ bgColor }) => {
  const { completeApplication, user, programmes } = useSelector(
    (state) => state.userAuth
  );
  const [programsDetails, setProgramsDetails] = useState([]);
  const title = 'Programmes';
  const subtitle = 'KAP Academy';
  const history = useHistory();
  const [getPrograms, { isLoading }] = useGetProgramsLiteMutation();

  const fetchPrograms = async () => {
    Array.isArray(programmes) &&
      setProgramsDetails(sortProgramsByName(programmes));
  };
  useEffect(() => {
    fetchPrograms();
  }, [programmes]);

  const [selectedCard, setSelectedCard] = useState(null);
  const handleCardClick = (key) => {
    setSelectedCard(key);
  };

  return (
    <div className={`py-4 py-lg-4 ${bgColor || 'bg-white'}`}>
      <Container>
        <SectionHeadingCenter title={title} subtitle={subtitle} />
        <Row className="mb-6">
          <CardGroup>
            {programsDetails?.map((item, index) => (
              <Fragment key={uuid()}>
                <Card onClick={() => handleCardClick(item?.id)}>
                  <CourseCard item={item} key={index} />
                </Card>
                {selectedCard === item?.id && (
                  <OverlayTrigger
                    trigger="click"
                    placement="top"
                    overlay={
                      <Overlay>
                        <CourseCard item={item} key={index} />
                      </Overlay>
                    }
                  >
                    <div style={{ display: 'none' }}></div>
                  </OverlayTrigger>
                )}
              </Fragment>
            ))}
          </CardGroup>
        </Row>
        {!completeApplication && (
          <Row className="justify-content-center">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-center"
            >
              <Link
                to="/complete-registration"
                className="popup-youtube btn btn-primary btn-lg fs-4 mx-3"
              >
                Complete Application
              </Link>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default CoursesSection;
