// import node module libraries
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  Accordion,
  Button,
  OverlayTrigger,
  Image,
  Tooltip,
  Tab,
  Nav,
  ListGroup,
  Popover,
  Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
  useEnrollStudentForCourseMutation,
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetLevel2CertificateMutation,
  useGetProgramByIdMutation,
  useGetStudentAssignmentResultsMutation,
  useGetStudentQuizResultsMutation,
  useGetTopicsMutation,
  useGetUserVideosMutation,
  useSaveVideoProgressMutation,
} from 'pages/redux/features/user/userApi';
import { Loader } from 'pages/components/Loader';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import GKAccordionProgress from '../dashboard/components/GKAccordionProgress';
import { toast } from 'react-toastify';

import { ChevronRight } from 'react-feather';
import {
  flattenedArray,
  isSubstringInArray,
  sortAscending,
  sortByTagName,
  sortWeeksArray,
  updateObjects,
} from 'pages/helper/formatArray';
import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import GKYouTube from 'components/marketing/common/video/GKYouTube';
import { mdiBook, mdiChevronDown, mdiPlay } from '@mdi/js';
import Icon from 'react-icons-kit';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { book } from 'react-icons-kit/fa/book';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  saveProgrammesStarted,
  saveUser,
} from 'pages/redux/features/auth/authSlice';
import { extractObjectValues } from 'pages/helper/formatObjectToArray';
import { BASE_URL } from 'pages/redux/features/url';
import AccessProgramme from '../dashboard/AccessProgramme';
import { WarningModal } from 'pages/components/Modals/WarningModal';
import { CustomButton } from 'pages/components/CustomButton';
import { programbackground } from 'pages/assets/images/imageUrl';
import { truncateText } from 'pages/helper/formatText';
import { ClassAccordion, Header, WeekCards } from './SingleProgramme';

const Level1 = ({
  courseSelected,
  setCourseSelected,
  classSelected,
  setClassSelected,
  topicSelected,
  setTopicSelected,
  selectedCourse,
  setSelectedCourse,
  selectedClass,
  setSelectedClass,
  selectedTopics,
  setSelectedTopics,
  activeAssignment,
  setActiveAssignment,
  activeQuiz,
  setActiveQuiz,
  activeAssignmentKey,
  setActiveAssignmentKey,
  activeQuizKey,
  setActiveQuizKey,
  coursesArranged,
  setCoursesArranged,
  classesArranged,
  setClassesArranged,
  activeTopicKey,
  setActiveTopicKey,
  setTopicKey,
  topicKey,
  setInstructorId,
  instructorId,
  currentStream,
  setCurrentStream,
  isCourseAvailable,
  setIsCourseAvailable,
  isCertificateAvailable,
  setIsCertificateAvailable,
  isEnrolling,
  isLoadingLevel2Certificate,
  classesInCourse,
  topicInClass,
  handleOpenEnroll,
  check,
  setCheck,
  playPreClassVideo,
  setPlayPreClassVideo,
  base_url,
  classInstructor,
  setClassInstructor,
  handleCertificate,
  programDetails,
  fetchQuizResult,
  fetchAssignmentResult,
  isGettingAssignmentResults,
  isGettingResults,
  history,
  weeksArranged,
  weekSelected,
  setWeekSelected,
  setPreviousWeek,
  classesInWeek,
  isAvailable,
}) => {
  return (
    <Container className="py-lg-5 py-5 overflow-auto min-vh-100">
      <Row className="mb-2 mb-xl-0">
        <Header programDetails={programDetails} />

        <Card>
          {weeksArranged?.length ? (
            <WeekCards
              weeks={weeksArranged}
              weekSelected={weekSelected}
              setWeekSelected={setWeekSelected}
              setPreviousWeek={setPreviousWeek}
              setSelectedClass={setSelectedClass}
              setSelectedTopics={setSelectedTopics}
              setTopicKey={setTopicKey}
              setInstructorId={setInstructorId}
              setCurrentStream={setCurrentStream}
            />
          ) : (
            <></>
          )}
        </Card>
      </Row>
      {currentStream?.url && (
        <Row className="mt-2 mb-2">
          <Card>
            <Card.Title as={'h3'} className="text-uppercase p-4 mb-0">
              {selectedClass?.title}
            </Card.Title>
            <Card.Body>
              <Row>
                <Col lg={12} md={12} sm={12} className="mb-3">
                  <h3>{currentStream?.title}</h3>
                  <div className="rounded-3 position-relative w-100 d-block overflow-hidden p-0">
                    {playPreClassVideo ? (
                      <ReactPlayer
                        url={base_url + currentStream?.url}
                        playing={playPreClassVideo}
                        controls={true}
                        width="100%"
                        height="100%"
                        config={{
                          file: {
                            attributes: {
                              controlsList: 'nodownload',
                            },
                          },
                        }}
                        // config={{
                        //   file: {
                        //     forceHLS: true, // force the use of HLS stream
                        //   },
                        // }}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setPlayPreClassVideo(true);
                        }}
                      >
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="bottom"
                          overlay={<Tooltip>Play Video</Tooltip>}
                        >
                          <div style={{ minHeight: 200 }}>
                            <Image
                              src={currentStream?.thumbnail}
                              alt="Video Thumbnail"
                              className="fluid"
                              width={'100%'}
                              height={'100%'}
                            />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <Icon icon={playCircleO} size={50} />
                            </div>
                          </div>
                        </OverlayTrigger>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
      )}

      <Row>
        <Col xl={8} lg={8} md={12} sm={12} className="mb-3">
          {selectedClass ? (
            <>
              <Col className="mb-1 mt-3">
                <Card className="mb-2">
                  <Card.Body>
                    <h3 className="mb-3 text-black">About Class</h3>
                    <p className="mb-0 text-uppercase">Description</p>
                    <p>{selectedClass?.description}</p>
                    <p className="mb-0 text-uppercase">
                      Instructor Recommendations
                    </p>
                    <p>{selectedClass?.instructor_recommendations}</p>
                  </Card.Body>
                </Card>
              </Col>
              {classInstructor ? (
                <Col className="mb-1 ">
                  <Card>
                    <Card.Body>
                      <h5 className="mb-0 text-uppercase">Instructor</h5>
                      <p className="mb-0">Instructor Name</p>
                    </Card.Body>
                  </Card>
                </Col>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Col className="mb-1 mt-3">
                <Card className="mb-2">
                  <Card.Body>
                    <h3 className="mb-3 text-black">About Programme</h3>
                    <p className="mb-0 text-uppercase">Description</p>
                    <p>{programDetails?.description}</p>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
          {selectedTopics?.length > 0 ? (
            <Col className="mb-4">
              <Card className="mt-2 mb-2">
                <Tab.Container
                  justify
                  activeKey={topicKey}
                  onSelect={(k) => {
                    setTopicKey(k);
                  }}
                >
                  <Card.Header className="border-bottom-0 p-0 bg-white">
                    <Nav className="nav-lb-tab">
                      {selectedTopics.map((x) => (
                        <Nav.Item>
                          <Nav.Link
                            eventKey={x?.id}
                            className="mb-sm-3 mb-md-0"
                          >
                            {x?.title}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Tab.Content>
                      {selectedTopics.map((x) => (
                        <>
                          <Tab.Pane eventKey={x?.id} className="pb-4">
                            <Card className="mb-4">
                              <Card.Title className="d-flex p-2 px-2">
                                <Button
                                  className="mx-2"
                                  size="sm"
                                  variant={
                                    activeTopicKey == 'videos'
                                      ? 'primary'
                                      : 'outline'
                                  }
                                  onClick={() => {
                                    setActiveTopicKey('videos');
                                  }}
                                >
                                  Videos
                                </Button>
                                <Button
                                  className="mx-2"
                                  size="sm"
                                  variant={
                                    activeTopicKey == 'notes'
                                      ? 'primary'
                                      : 'outline'
                                  }
                                  onClick={() => {
                                    setActiveTopicKey('notes');
                                  }}
                                >
                                  Notes
                                </Button>
                                <Button
                                  className="mx-2"
                                  size="sm"
                                  variant={
                                    activeTopicKey == 'quiz'
                                      ? 'primary'
                                      : 'outline'
                                  }
                                  onClick={() => {
                                    setActiveTopicKey('quiz');
                                  }}
                                >
                                  Quiz
                                </Button>
                                <Button
                                  className="mx-2"
                                  size="sm"
                                  variant={
                                    activeTopicKey == 'assignment'
                                      ? 'primary'
                                      : 'outline'
                                  }
                                  onClick={() => {
                                    setActiveTopicKey('assignment');
                                  }}
                                >
                                  Assignment
                                </Button>
                              </Card.Title>
                              {activeTopicKey == 'videos' ? (
                                <Card.Body className="border-top py-3">
                                  {topicInClass?.videos?.map((video, index) => (
                                    <ListGroup className="mt-2">
                                      <ListGroup.Item key={index}>
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            setCurrentStream({
                                              title: topicInClass.title,
                                              id: video?.id,
                                              url: video?.url,
                                              thumbnail: video?.screenshot,
                                            });
                                            return;
                                          }}
                                          className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                                        >
                                          <div className="text-truncate ">
                                            <span
                                              className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                                            >
                                              <Icon
                                                icon={playCircleO}
                                                size={24}
                                              />
                                            </span>
                                            <span className="fs-5">
                                              {'Video ' + (index + 1)}
                                            </span>
                                          </div>
                                        </Link>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  ))}
                                </Card.Body>
                              ) : activeTopicKey == 'notes' ? (
                                <Card.Body className="border-top py-3">
                                  {topicInClass?.note}
                                </Card.Body>
                              ) : activeTopicKey == 'quiz' ? (
                                <Card.Body className="border-top py-3">
                                  {topicInClass?.quizzes?.map((x, index) => (
                                    <ListGroup className="mt-2">
                                      <ListGroup.Item key={index}>
                                        <Link
                                          to="#"
                                          onClick={async () => {
                                            if (activeQuizKey !== x.id) {
                                              await fetchQuizResult({
                                                id: x.id,
                                                next: () =>
                                                  setActiveQuizKey(
                                                    activeQuizKey == x.id
                                                      ? ''
                                                      : x?.id
                                                  ),
                                              });
                                            } else {
                                              setActiveQuizKey(
                                                activeQuizKey == x.id
                                                  ? ''
                                                  : x?.id
                                              );
                                            }
                                          }}
                                          className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                                        >
                                          <div className="text-truncate">
                                            <span
                                              className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                                            >
                                              <Icon icon={book} size={24} />
                                            </span>
                                            <span className="fs-5">
                                              {x?.title}
                                            </span>
                                          </div>
                                          {isGettingResults ? (
                                            <Spinner
                                              style={{
                                                color: '#e0bf00',
                                              }}
                                              size="sm"
                                            />
                                          ) : (
                                            <ChevronRight
                                              onClick={async () => {
                                                if (activeQuizKey !== x.id) {
                                                  await fetchQuizResult({
                                                    id: x.id,
                                                    next: () =>
                                                      setActiveQuizKey(
                                                        activeQuizKey == x.id
                                                          ? ''
                                                          : x?.id
                                                      ),
                                                  });
                                                } else {
                                                  setActiveQuizKey(
                                                    activeQuizKey == x.id
                                                      ? ''
                                                      : x?.id
                                                  );
                                                }
                                              }}
                                            />
                                          )}
                                        </Link>
                                      </ListGroup.Item>
                                      <ListGroup.Item
                                        key={x?.id}
                                        className={
                                          activeQuizKey == x?.id
                                            ? 'd-block'
                                            : 'd-none'
                                        }
                                      >
                                        <ListItem
                                          title={'Instructions'}
                                          children={x?.instructions}
                                        />
                                        {typeof activeQuiz?.total_score ===
                                          'number' && (
                                          <ListItem
                                            title={'Total score'}
                                            children={`${activeQuiz?.total_score}/${activeQuiz?.obtainable_score}`}
                                          />
                                        )}
                                        {activeQuiz?.remark && (
                                          <ListItem
                                            title={'Remarks'}
                                            children={activeQuiz?.remark}
                                          />
                                        )}
                                        {/* <ListItem
                                                title={'Pass mark'}
                                                children={x?.pass_mark}
                                              />
                                              <ListItem
                                                title={'Total Obtainable mark'}
                                                children={x?.total}
                                              /> */}
                                        <Button
                                          className="w-100 mt-2"
                                          size="sm"
                                          variant={'primary'}
                                          onClick={() => {
                                            history.push(`/quiz`, {
                                              quiz: x,
                                              topic: topicInClass,
                                              class: selectedClass,
                                              program: programDetails,
                                              retake:
                                                activeAssignment?.remark ==
                                                'failed',
                                            });
                                          }}
                                        >
                                          {activeQuiz?.remark == 'failed'
                                            ? 'Retake Quiz'
                                            : 'Proceed to Quiz'}
                                        </Button>
                                      </ListGroup.Item>
                                    </ListGroup>
                                  ))}
                                </Card.Body>
                              ) : (
                                activeTopicKey == 'assignment' && (
                                  <Card.Body className="border-top py-3">
                                    {topicInClass?.assignments?.map(
                                      (x, index) => (
                                        <ListGroup className="mt-2">
                                          <ListGroup.Item key={index}>
                                            <Link
                                              to="#"
                                              onClick={async () => {
                                                if (
                                                  activeAssignmentKey !== x.id
                                                ) {
                                                  await fetchAssignmentResult({
                                                    id: x.id,
                                                    next: () =>
                                                      setActiveAssignmentKey(
                                                        activeAssignmentKey ==
                                                          x.id
                                                          ? ''
                                                          : x?.id
                                                      ),
                                                  });
                                                } else {
                                                  setActiveAssignmentKey(
                                                    activeAssignmentKey == x.id
                                                      ? ''
                                                      : x?.id
                                                  );
                                                }
                                              }}
                                              className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                                            >
                                              <div className="text-truncate">
                                                <span
                                                  className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                                                >
                                                  <Icon icon={book} size={24} />
                                                </span>
                                                <span className="fs-5">
                                                  {x?.title}
                                                </span>
                                              </div>
                                              {isGettingAssignmentResults ? (
                                                <Spinner
                                                  style={{
                                                    color: '#e0bf00',
                                                  }}
                                                  size="sm"
                                                />
                                              ) : (
                                                <ChevronRight
                                                  onClick={async () => {
                                                    if (
                                                      activeAssignmentKey !==
                                                      x.id
                                                    ) {
                                                      await fetchAssignmentResult(
                                                        {
                                                          id: x.id,
                                                          next: () =>
                                                            setActiveAssignmentKey(
                                                              activeAssignmentKey ==
                                                                x.id
                                                                ? ''
                                                                : x?.id
                                                            ),
                                                        }
                                                      );
                                                    } else {
                                                      setActiveAssignmentKey(
                                                        activeAssignmentKey ==
                                                          x.id
                                                          ? ''
                                                          : x?.id
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                            </Link>
                                          </ListGroup.Item>
                                          <ListGroup.Item
                                            key={x?.id}
                                            className={
                                              activeAssignmentKey == x?.id
                                                ? 'd-block'
                                                : 'd-none'
                                            }
                                          >
                                            <ListItem
                                              title={'Instructions'}
                                              children={x?.instructions}
                                            />
                                            {/* <ListItem
                                                  title={'Pass mark'}
                                                  children={x?.pass_mark}
                                                />
                                                <ListItem
                                                  title={'Total mark'}
                                                  children={x?.total}
                                                /> */}
                                            {activeAssignment?.total_score && (
                                              <ListItem
                                                title={'Total score'}
                                                children={
                                                  activeAssignment?.total_score
                                                }
                                              />
                                            )}
                                            {activeAssignment?.remark && (
                                              <ListItem
                                                title={'Remarks'}
                                                children={
                                                  activeAssignment?.remark
                                                }
                                              />
                                            )}
                                            <Button
                                              className="w-100 mt-2"
                                              size="sm"
                                              variant={'primary'}
                                              onClick={() => {
                                                history.push(`/assignment`, {
                                                  assignment: x,
                                                  topic: topicInClass,
                                                  class: selectedClass,
                                                  program: programDetails,
                                                  retake:
                                                    activeAssignment?.remark ==
                                                    'failed',
                                                });
                                              }}
                                            >
                                              {activeAssignment?.remark ==
                                              'failed'
                                                ? 'Retake Assignment'
                                                : 'Proceed to Assignment'}
                                            </Button>
                                          </ListGroup.Item>
                                        </ListGroup>
                                      )
                                    )}
                                  </Card.Body>
                                )
                              )}
                            </Card>
                          </Tab.Pane>
                        </>
                      ))}
                    </Tab.Content>
                  </Card.Body>
                </Tab.Container>
              </Card>
            </Col>
          ) : (
            <Col className="mb-4">
              <Card className="mt-2 mb-2">
                <Card.Body>
                  <h3 className="mb-0 text-black">No topics in this class</h3>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Col>
        <Col xl={4} lg={4} md={12} sm={12}>
          <Col>
            <Card className="mt-2 mt-3 mb-2 p-3">
              <h3 className="mb-3 text-black">{'Classes'}</h3>
              {!!classesInWeek &&
                classesInWeek !== {} &&
                Object.keys(classesInWeek)?.length > 0 && (
                  <ClassAccordion
                    classes={classesInWeek}
                    classSelected={classSelected}
                    setClassSelected={setClassSelected}
                    selectedClass={selectedClass}
                    setSelectedClass={setSelectedClass}
                    setSelectedTopics={setSelectedTopics}
                    setTopicKey={setTopicKey}
                    setInstructorId={setInstructorId}
                    isAvailable={isAvailable}
                    isGettingResults={isGettingResults}
                    setCurrentStream={setCurrentStream}
                  />
                )}
            </Card>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default Level1;
