import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Tab,
  Nav,
  Col,
  Table,
  Dropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Accordion,
  ListGroup,
} from 'react-bootstrap';
import {
  useGetClassesMutation,
  useGetCourseByIdMutation,
  useGetProgramByIdMutation,
  useGetTeachersMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import GlobalFilter from 'pages/components/elements/advance-table/GlobalFilter';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'pages/components/elements/advance-table/Pagination';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { useMemo } from 'react';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { VideoModal } from 'pages/components/Modals/DisplayModal';
import { ConfirmDeleteModal } from 'pages/components/Modals/ConfirmDeleteModal';
import moment from 'moment/moment';
import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import { mdiPlay } from '@mdi/js';
import Icon from '@mdi/react';
import { removeDeletedObjs, sortByProperty } from 'pages/helper/formatArray';
import { removeUndefinedKeys } from 'pages/helper/formatObject';
import { truncateText } from 'pages/helper/formatText';
import AddClass from 'pages/kapAdmin/programmes/AddClass';

const InstructorCourseDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [courseDetails, setCourseDetails] = useState({});
  const [contentType, setContentType] = useState('Videos');

  const [refresh, setRefresh] = useState(false);

  const [classes, setClasses] = useState([]);
  const [course, setCourse] = useState(null);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [showFullNote, setShowFullNote] = useState(false);
  const [selectedClass, setSelectedClass] = useState([]);
  const [showAddClass, setShowAddClass] = useState(false);
  const [showAddTopic, setShowAddTopic] = useState(false);

  const [getTeachers, { isLoading: fetchingTeachers }] =
    useGetTeachersMutation();
  const [getClasses, { isLoading: fetchingClasses }] = useGetClassesMutation();
  const [getProgramById, { isFetching, isLoading }] =
    useGetProgramByIdMutation();
  const [
    getCourseById,
    { isFetching: isFetchingCourse, isLoading: isLoadingCourse },
  ] = useGetCourseByIdMutation();

  useEffect(() => {
    fetchCourse();
    fetchClasses();
  }, [refresh]);

  const fetchClasses = async () => {
    const res = await getClasses();
    if (res?.data?.success) {
      setClasses(res?.data?.data?.classes);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchCourse = async () => {
    const res = await getCourseById({ id: route_id });

    if (res?.data) {
      setCourseDetails(res?.data?.data?.course);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };

  const [showDisplayModal, setShowDisplayModal] = useState(false);
  const [type, setType] = useState('add');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const toggleDisplayModal = () => setShowDisplayModal(!showDisplayModal);

  const handleCloseAddClass = () => {
    setShowAddClass(false);
    setRefresh(!refresh);
  };

  const classList = useMemo(() => {
    let filtered = classes || [];
    if (courseDetails?.classes) {
      filtered = filtered?.filter((x) =>
        courseDetails?.classes.find((y) => y?.id === x?.id)
      );
    }
    return filtered;
  }, [courseDetails, classes]);

  const data = useMemo(() => {
    let filtered = selectedClass?.topics || [];
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [courseDetails, selectedClass]);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'title',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/instructor/topic/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'description',
        Header: 'DESCRIPTION',
        Cell: ({ value, row }) => {
          return (
            <Link to={`/instructor/topics/${row?.original?.id}`}>
              <h5 className="text-primary-hover text-truncate">
                {truncateText(value, 30)}
              </h5>
            </Link>
          );
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  if (isLoadingCourse) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Course
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {courseDetails?.title}
                </h4>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <ListItem title={'Title'} children={courseDetails?.title} />
        <ListItem title={'Description'} children={courseDetails?.description} />
        <Card.Body>
          <Accordion className="mb-3" activeKey={'0'}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <p className="text-uppercase text-black fs-5 mb-0">Classes</p>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex justify-content-between align-item-center mb-3">
                  <p className="text-black fs-4 mb-0">
                    {courseDetails?.title} Classes
                  </p>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      setType('add');
                      setShowAddClass(true);
                    }}
                  >
                    Create New Class
                  </Button>
                </div>
                {classList?.map((y) => (
                  <Accordion>
                    <Accordion.Item eventKey={y?.id}>
                      <Accordion.Header
                        onClick={() => {
                          setSelectedClass(y);
                        }}
                      >
                        <p className="text-black mb-0">{y?.title}</p>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Card className="mb-4">
                          <ListItem title={'Class Title'} children={y?.title} />
                          <ListItem
                            title={'Class Description'}
                            children={y?.description}
                          />
                          <ListItem
                            title={'Learning outcomes'}
                            children={y?.learning_outcomes}
                          />
                          <ListItem
                            title={'Instructor Recommendations'}
                            children={y?.instructor_recommendations}
                          />

                          <ListItem
                            title={'Pre class video'}
                            children={
                              y?.pre_class_video ? (
                                <div
                                  onClick={() => {
                                    setSelectedUrl(y?.pre_class_video);
                                    setSelectedThumbnail(
                                      y?.pre_class_video_thumbnail
                                    );
                                    toggleDisplayModal();
                                  }}
                                >
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Play Video</Tooltip>}
                                  >
                                    <div
                                      className="position-relative"
                                      style={{ maxWidth: 200 }}
                                    >
                                      <Image
                                        src={y?.pre_class_video_thumbnail}
                                        alt="Video Thumbnail"
                                        style={{
                                          maxWidth: 200,
                                        }}
                                      />
                                      <div className="position-absolute top-50 start-50 translate-middle">
                                        <Icon icon={playCircleO} size={50} />
                                      </div>
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              ) : (
                                '...'
                              )
                            }
                          />
                          <ListItem
                            title={'Date'}
                            children={
                              y?.date
                                ? moment(y?.date).format('ddd MMM DD, YYYY')
                                : '...'
                            }
                          />
                          <ListItem
                            title={'Start time'}
                            children={y?.start_time ? y?.start_time : '...'}
                          />
                          <ListItem
                            title={'Stop time'}
                            children={y?.end_time ? y?.end_time : '...'}
                          />

                          {/* <Card.Body className="border-top py-3 d-flex justify-content-between align-items-center">
                            <Button
                              variant="secondary"
                              size="sm"
                              onClick={() => {
                                setDeleteName('class');
                                setSelectedClass(y);
                                handleDeleteShow();
                              }}
                            >
                              Delete Class
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => {
                                setType('edit');
                                setSelectedClass(y);
                                setShowAddClass(true);
                              }}
                            >
                              Edit Class
                            </Button>
                          </Card.Body> */}
                          <Card.Body className="border-top py-3 ">
                            <div className="d-flex justify-content-between align-items-center">
                              <h4>Topics</h4>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                  setType('add');
                                  setSelectedClass(y);
                                  setShowAddTopic(true);
                                }}
                              >
                                Create New Topic
                              </Button>
                            </div>

                            <div className="table-responsive">
                              <Table
                                {...getTableProps()}
                                className="text-nowrap"
                              >
                                <thead className="table-light">
                                  {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                      {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                          {column.render('Header')}
                                        </th>
                                      ))}
                                    </tr>
                                  ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                  {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                      <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                          return (
                                            <td {...cell.getCellProps()}>
                                              {cell.render('Cell')}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </div>

                            <Pagination
                              previousPage={previousPage}
                              pageCount={pageCount}
                              pageIndex={pageIndex}
                              gotoPage={gotoPage}
                              nextPage={nextPage}
                            />
                          </Card.Body>
                        </Card>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

      <VideoModal
        showModal={showDisplayModal}
        toggleModal={toggleDisplayModal}
        url={selectedUrl}
        thumbnail={selectedThumbnail}
      />
      <AddClass
        show={showAddClass}
        handleClose={handleCloseAddClass}
        id={courseDetails?.id}
        initialState={type == 'edit' ? selectedClass : null}
        type={type}
      />
      <ToastContainer />
    </Fragment>
  );
};

export default InstructorCourseDetails;
