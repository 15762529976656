// import node module libraries
import { Fragment, useEffect } from 'react';
import { Col, Row, Nav, Tab, Card, Container } from 'react-bootstrap';

import Avatar3 from 'assets/images/avatar/avatar-3.jpg';

// import data files
import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';
import MainSection from 'pages/kapStudent/dashboard/components/MainSection';
import { useSelector } from 'react-redux';
import { workingguys } from 'pages/assets/images/imageUrl';
import { useHistory } from 'react-router-dom';

const JobsDashboard = () => {
  const history = useHistory();
  const { completeApplication } = useSelector((state) => state.userAuth);
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  });

  const mainSection = {
    title: 'Jobs',
    description:
      'Graduates may automatically upload their certificates and get access to jobs or freelancing opportunities via our job board in partnership with jobberman, NGCareers, Freelancer.com, LinkedIn, etc.',
    mainRedirect: 'View Jobs',
    secondaryRedirect: !completeApplication ? 'Complete Application' : '',
    image: workingguys,
    // mainRedirectLink: '/finance',
    secondaryRedirectLink: '/complete-registration',
    popOverOnMain: !completeApplication,
  };

  return (
    <Fragment>
      <MainSection
        item={mainSection}
        setMainBtnPressed={(e) => {
          history.push(`/jobs/all`);
        }}
      />
      <CoursesSection />
    </Fragment>
  );
};
export default JobsDashboard;
