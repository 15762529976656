import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Image,
  Modal,
  Button,
  Form,
  FormSelect,
  Col,
  Row,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import ChangeApplicationStatus from './ChangeApplicationStatus';
import {
  useGetApplicationByIdMutation,
  useGetApplicationByIdQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
} from 'pages/redux/features/user/userApi';
import DotBadge from 'pages/components/elements/bootstrap/DotBadge';
import {
  eduOptions,
  genderOptions,
  itemsOptions,
  powerOptions,
  residenceOptions,
  salaryOptions,
  occupationOptions,
  communityOptions,
  disabilityOptions,
  secondarySchoolOptions,
  internallyDisplacedOptions,
  heardFromOptions,
  previousTrainingInFilmOptions,
} from 'pages/kapStudent/dashboard/CompleteRegistration';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { checkUrlFileType } from 'pages/helper/checkType';
import { PdfRenderer } from 'pages/components/PdfRenderer';
import { downloadFile } from 'pages/helper/downloadFile';

export const ListItem = ({
  title,
  children,
  type = 'text',
  onClick = () => {},
  isActive,
}) => {
  return (
    children && (
      <Card.Body className="border-top py-3" onClick={onClick}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div style={{ marginRight: 15 }}>
              <h5
                className={`mb-0 ${
                  isActive ? 'text-primary' : ''
                } text-align-left`}
              >
                {title}
              </h5>
            </div>
          </div>
          <div>
            <div>
              {typeof children != 'string' ? (
                children
              ) : (
                <p className="text-dark mb-0 fw-semi-bold">{children}</p>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    )
  );
};

const ApplicationDetails = (props) => {
  const level2Status = props.match?.path === '/admin/application/level_2/:id';
  const [details, setDetails] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleCloseImageModal = () => setShowImageModal(false);

  const { data: states, isFetching: fetchingStates } = useGetStatesQuery();
  const { data: countries, isFetching: fetchingCountries } =
    useGetCountriesQuery();
  const [getApplicationById, { isFetching, isLoading }] =
    useGetApplicationByIdMutation();

  const fetchApplication = async () => {
    const res = await getApplicationById(props.match.params.id);
    if (res?.data) {
      setDetails(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchApplication();
  }, [refresh]);

  if (isFetching || details?.length || fetchingCountries || fetchingStates) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="h4">Applicant</Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0">
                  {details?.user?.firstname + ' ' + details?.user?.lastname}
                </h4>
              </div>
            </div>
            <div>
              <div className="ms-3">
                <h4>Status</h4>
                {
                  <div className="d-flex">
                    <DotBadge
                      bg={
                        (level2Status
                          ? details?.status_level_2
                          : details?.status
                        )?.includes('admitted')
                          ? 'success'
                          : 'warning'
                      }
                    ></DotBadge>
                    <h5 className="mb-0">
                      {(level2Status
                        ? details?.status_level_2
                        : details?.status
                      )
                        ?.replace(/_/g, ' ')
                        .replace(/\b\w/g, (match) => match.toUpperCase())}
                    </h5>
                  </div>
                }
              </div>
            </div>
          </div>
        </Card.Body>
        {level2Status && (
          <ListItem
            title={'Level 1 Status'}
            children={details?.status
              ?.replace(/_/g, ' ')
              .replace(/\b\w/g, (match) => match.toUpperCase())}
          />
        )}
      </Card>
      <Card className="mb-4">
        <ListItem title={'First name'} children={details?.user?.firstname} />
        <ListItem title={'Last name'} children={details?.user?.lastname} />
        <ListItem title={'Email address'} children={details?.user?.email} />
        <ListItem title={'Phone number'} children={details?.user?.phone} />
        <ListItem
          title={'Current level'}
          children={details?.current_level && 'Level ' + details?.current_level}
        />
        <ListItem
          title={'Gender'}
          children={
            details?.gender &&
            genderOptions?.filter((x) => x.value == details?.gender)[0]?.label
          }
        />
        <ListItem
          title={'Date of Birth'}
          children={moment(details?.dob).format('DD - MMM - YYYY')}
        />
        <ListItem title={'Address'} children={details?.address} />
        <ListItem
          title={'State'}
          children={
            states?.data &&
            details?.state_id &&
            states?.data?.filter((x) => x.id == details?.state_id)[0].state
          }
        />
        <ListItem
          title={'Country'}
          children={
            countries?.data &&
            details?.country_id &&
            countries?.data?.filter((x) => x.id == details?.country_id)[0]
              .country_name
          }
        />
        <ListItem
          title={'Physical disability'}
          children={details?.disability && details?.disability}
        />
        <ListItem
          title={'Highest Qualification'}
          children={
            details?.education &&
            eduOptions?.filter((x) => x.value == details?.education)[0]?.label
          }
        />
        <ListItem
          title={'Secondary School type'}
          children={
            details?.secondary_school &&
            secondarySchoolOptions?.filter(
              (x) => x.value == details?.secondary_school
            )[0]?.label
          }
        />
        <ListItem
          title={'Internally displaced person'}
          children={
            details?.internally_displaced &&
            internallyDisplacedOptions?.filter(
              (x) => x.value == details?.internally_displaced
            )[0]?.label
          }
        />
        <ListItem
          title={'Occupation Type'}
          children={
            details?.occupation &&
            occupationOptions?.filter((x) => x.value == details?.occupation)[0]
              ?.label
          }
        />
        <ListItem
          title={'Monthly Income Range'}
          children={
            details?.monthly_income &&
            salaryOptions?.filter((x) => x.value == details?.monthly_income)[0]
              ?.label
          }
        />
        <ListItem
          title={'Community'}
          children={
            details?.community &&
            communityOptions?.filter((x) => x.value == details?.community)[0]
              ?.label
          }
        />
        <ListItem
          title={'Items owned'}
          children={
            details?.items_owned &&
            itemsOptions
              ?.filter((y) => {
                return details?.items_owned?.includes(y.value);
              })
              .map((x) => {
                return x?.label;
              })
              .toString()
          }
        />
        <ListItem
          title={'Heard about program'}
          children={
            details?.heard_from &&
            heardFromOptions?.filter((x) => x.value == details?.heard_from)[0]
              ?.label
          }
        />
        <ListItem
          title={'Previous Training'}
          children={
            details?.previous_training &&
            previousTrainingInFilmOptions?.filter(
              (x) => x.value == details?.previous_training
            )[0]?.label
          }
        />
        <ListItem
          title={'Why Film making'}
          children={details?.why_filmmaking && details?.why_filmmaking}
        />
        <ListItem
          title={'Why Kap Academy'}
          children={details?.why_kap && details?.why_kap}
        />

        <ListItem
          title={'Highest Certificate'}
          children={
            <ListGroup.Item key={details?.highest_certificate}>
              <Button
                variant="link"
                href={details?.highest_certificate}
                download={'Certificate'}
              >
                Certificate
              </Button>
              <Button
                variant="primary"
                size={'sm'}
                onClick={() =>
                  downloadFile(details?.highest_certificate, 'Certificate')
                }
              >
                Download
              </Button>
            </ListGroup.Item>
          }
        />
        <ListItem
          title={'Personal ID'}
          children={
            <ListGroup.Item key={details?.photo_id}>
              <Button
                variant="link"
                href={details?.photo_id}
                download={'Personal ID'}
              >
                Personal ID
              </Button>
              <Button
                variant="primary"
                size={'sm'}
                onClick={() => downloadFile(details?.photo_id, 'Personal ID')}
              >
                Download
              </Button>
            </ListGroup.Item>
          }
        />
        <ListItem
          title={'Application date'}
          children={new Date(details?.created_at).toDateString()}
        />
      </Card>
      <div className="d-flex justify-content-between">
        <Button variant="primary" onClick={handleShow}>
          Change Application status
        </Button>
        <Button variant="secondary" onClick={handleDeleteShow}>
          Delete
        </Button>
        <ChangeApplicationStatus
          show={show}
          handleClose={handleClose}
          id={props.match.params.id}
          level={details?.current_level}
        />

        <Modal show={deleteShow} onHide={handleDeleteClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Delete Application</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this application</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            {/*  Action Buttons  */}
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteClose}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showImageModal} onHide={handleCloseImageModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{selectedImage?.title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Image src={selectedImage?.url} fluid />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseImageModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ApplicationDetails;
