export const sortProgramsByName = (array) => {
  return [...array].sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();

    if (aName.startsWith('master') && !bName.startsWith('master')) {
      return -1;
    } else if (bName.startsWith('master') && !aName.startsWith('master')) {
      return 1;
    } else if (
      aName.startsWith('introduction') &&
      !bName.startsWith('introduction')
    ) {
      return -1;
    } else if (
      bName.startsWith('introduction') &&
      !aName.startsWith('introduction')
    ) {
      return 1;
    } else {
      return 0;
    }
  });
};
