// import node module libraries
import { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  Tab,
  Nav,
  Breadcrumb,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
// import media files
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveUser,
  setCompleteApplication,
} from 'pages/redux/features/auth/authSlice';
import FileUploadInput from 'pages/components/FileUploadInput';
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import * as Yup from 'yup';
import {
  useCreateApplicationMutation,
  useGetCountriesQuery,
  useGetProfileMutation,
  useGetStatesQuery,
} from 'pages/redux/features/user/userApi';
import Icon from 'react-icons-kit';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import { toast } from 'react-toastify';
import { CustomButton } from 'pages/components/CustomButton';

export const genderOptions = [
  { id: 1, label: 'Female', value: 'female' },
  {
    id: 2,
    label: 'Male',
    value: 'male',
  },
];
export const eduOptions = [
  { id: 1, label: 'Secondary School (SSCE)', value: 'secondary_school_ssce' },
  {
    id: 2,
    label: 'NCE',
    value: 'nce',
  },
  { id: 3, label: 'Diploma (OND)', value: 'diploma_ond' },
  { id: 4, label: 'Degree/HND', value: 'degree_hnd' },
  { id: 5, label: 'Master/PhD', value: 'masters_phd' },
  { id: 6, label: 'Vocational Certificate', value: 'vocational_certificate' },
  { id: 7, label: 'Other', value: 'other' },
];

export const occupationOptions = [
  { id: 1, label: 'Unemployed', value: 'unemployed' },
  { id: 2, label: 'Student', value: 'student' },
  {
    id: 3,
    label:
      'Artisan {Tailor, Mechanic, Hairdresser, carpenter make-up artist etc.}',
    value: 'artisan',
  },
  {
    id: 4,
    label: 'Entrepreneur / Business owner',
    value: 'entrepreneur',
  },
  {
    id: 5,
    label: 'Industry professional {Lawyer, Doctor, Teacher etc.} ',
    value: 'industry_professional',
  },
  {
    id: 6,
    label: 'Other ',
    value: 'other',
  },
];

export const salaryOptions = [
  { id: 1, value: 'up_to_10k', label: 'Up to ₦10,000' },
  { id: 2, value: '10k_to_30k', label: '₦10,000 to less than ₦30,000' },
  { id: 3, value: '30k_to_50k', label: '₦30,000 to less than ₦50,000' },
  { id: 4, value: '50k_to_100k', label: '₦50,000 to less than ₦100,000' },
  { id: 5, value: '100k_to_250k', label: '₦100,000 to less than ₦250,000' },
  { id: 6, value: '250k_to_500k', label: '₦250,000 to less than ₦500,000' },
  { id: 7, value: '500k_to_1M', label: '₦500,000 to less than ₦1 million' },
  { id: 8, value: '1M_and_above', label: '₦1 million or more' },
];

export const residenceOptions = [
  {
    id: 1,
    label: 'Rented house with thatched roof, tiled roof or nylon sheet',
    value: 'rented_thatch_hut',
  },
  {
    id: 2,
    label: 'Hostel/Religious Shelter',
    value: 'hostel_or_regious_shelter',
  },
  {
    id: 3,
    label: 'Rented 1 room tenement in a building or a BQ',
    value: 'rented_one_room',
  },
  {
    id: 4,
    label: 'Rented flat or apartment with minimum of 1 room',
    value: 'rented_mini_flat',
  },
  {
    id: 5,
    label: 'Rented flat or apartment with minimum of 2 rooms',
    value: 'rented_two_bedroom_flat',
  },
  {
    id: 6,
    label:
      'Rented fully built individual house, bungalow or duplex with minimum of 3 rooms',
    value: 'rented_three_bedroom_flat',
  },
  {
    id: 7,
    label: 'Rented Mansion or Duplex',
    value: 'rented_mansion_or_duplex',
  },
  { id: 8, label: 'owner_thatch_hut', value: 'owner_thatch_hut' },
  {
    id: 9,
    label: 'Owner of flat or apartment with 1 room only',
    value: 'owner_one_room',
  },
  { id: 10, label: 'Owner of Mini Flat', value: 'owner_mini_flat' },
  {
    id: 11,
    label: 'Owner of flat or apartment with minimum of 2 bedrooms',
    value: 'owner_two_bedroom_flat',
  },
  {
    id: 12,
    label:
      'Owner of fully built individual house or bungalow of minimum 3 rooms',
    value: 'owner_three_bedroom_flat',
  },
  {
    id: 13,
    label: 'Owner of Mansion or Duplex',
    value: 'owner_mansion_or_duplex',
  },
];
export const powerOptions = [
  { id: 1, label: 'None', value: 'none' },
  {
    id: 2,
    label: 'Petrol Generator 0.5KV to 0.8KV',
    value: 'petrol_generator',
  },
  { id: 3, label: 'Diesel Generator 10KV or more', value: 'diesel_generator' },
  { id: 4, label: 'Inverter less than 1.5KV', value: 'inverter_less_than_1.5' },
  { id: 5, label: 'Inverter more than 1.5KV', value: 'inverter_more_than_1.5' },
  { id: 6, label: 'Solar Panel', value: 'solar_panel' },
  { id: 7, label: 'Any other source', value: 'other' },
];
export const disabilityOptions = [
  { id: 1, label: 'Yes', value: 'Yes' },
  { id: 2, label: 'No', value: 'No' },
  { id: 3, label: 'Rather not say', value: 'Rather not say' },
];
export const secondarySchoolOptions = [
  { id: 1, label: 'Private', value: 'private' },
  { id: 2, label: 'Public', value: 'public' },
];
export const communityOptions = [
  { id: 1, label: 'Village or rural community', value: 'village_rural' },
  { id: 2, label: 'Semi urban/town', value: 'semi_urban_town' },
  { id: 3, label: 'Urban/City', value: 'urban_city' },
];
export const internallyDisplacedOptions = [
  { id: 1, label: 'Yes', value: 'Yes' },
  { id: 2, label: 'No', value: 'No' },
];
export const heardFromOptions = [
  { id: 1, label: 'Instagram', value: 'instagram' },
  { id: 2, label: 'Twitter', value: 'twitter' },
  { id: 3, label: 'YouTube', value: 'youtube' },
  { id: 4, label: 'Facebook', value: 'facebook' },
  { id: 5, label: 'From a friend', value: 'a_friend' },
  { id: 6, label: 'Agency', value: 'agency' },
  { id: 7, label: 'Other', value: 'other' },
];
export const previousTrainingInFilmOptions = [
  { id: 1, label: 'Yes', value: 'Yes' },
  { id: 2, label: 'No', value: 'No' },
];
export const itemsOptions = [
  { id: 1, label: 'Smartphone', value: 'smart_phone' },
  { id: 2, label: 'PC/Laptop ', value: 'pc_laptop' },
];

const CompleteRegistration = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  });
  const { user: userInfo } = useSelector((state) => state.userAuth);

  const [tabs, setTabs] = useState(['personal']);
  const [key, setKey] = useState('personal');
  const [field, setField] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const { data: statesOptions, isFetchingStates } = useGetStatesQuery();
  const { data: countriesOptions, isFetchingCountries } =
    useGetCountriesQuery();
  const [createApplication, { isLoading }] = useCreateApplicationMutation();
  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();
  const [applicationSuccess, setApplicationSuccess] = useState(false);

  const handleRegistration = async (formData) => {
    const res = await createApplication(formData);
    if (res?.data?.success) {
      dispatch(setCompleteApplication(true));
      const res1 = await getProfile();
      if (res1?.data?.success) {
        dispatch(
          saveUser({
            ...res1.data?.data?.user,
            programmes: res1.data?.programmes,
            videos: res1.data?.videos,
          })
        );
        setApplicationSuccess(true);
      } else {
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
          delay: 5000,
        });
        history.push('/');
      }
    } else {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
    }
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Required')
      .label('First name')
      .typeError('Required'),
    lastname: Yup.string()
      .required('Required')
      .label('Last name')
      .typeError('Required'),
    gender: Yup.object()
      .required('Required')
      .label('Gender')
      .typeError('Required'),
    dob: Yup.string()
      .required('Required')
      .label('Date of birth')
      .typeError('Required'),
    address: Yup.string()
      .required('Required')
      .label('Address')
      .typeError('Required'),
    why_filmmaking: Yup.string()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.string()
          .required('Required')
          .min(15, 'Be more expressive')
          .label('Interest in kap academy')
          .typeError('Required'),
        otherwise: Yup.string().nullable(),
      }),
    why_kap: Yup.string()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.string()
          .required('Required')
          .min(15, 'Be more expressive')
          .label('Interest in kap academy')
          .typeError('Required'),
        otherwise: Yup.string().nullable(),
      }),
    heard_from: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    education: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    occupation: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    monthly_income: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    items_owned: Yup.array()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.array().required('Required').typeError('Required'),
        otherwise: Yup.array().nullable(),
      }),
    previous_training: Yup.object()
      .label('Previous training check')
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object()?.nullable(),
      }),
    community: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    state_id: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    country_id: Yup.object()
      .required('Required')
      .label('Country')
      .typeError('Required'),
    secondary_school: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    internally_displaced: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    disability: Yup.object()
      .nullable()
      .when('country_id.label', {
        is: 'Nigeria',
        then: Yup.object().required('Required').typeError('Required'),
        otherwise: Yup.object().nullable(),
      }),
    highest_certificate: Yup.array().nullable().label('Highest certificate'),
    photo_id: Yup.array().nullable().label('Government ID'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: userInfo?.firstname,
      lastname: userInfo?.lastname,
      phone: userInfo?.phone,
      email: userInfo?.email,
      gender: '',
      dob: '',
      address: '',
      education: null,
      occupation: null,
      monthly_income: null,
      items_owned: null,
      state_id: null,
      country_id: null,
      highest_certificate: null,
      photo_id: null,
      disability: null,
      secondary_school: null,
      community: null,
      internally_displaced: null,
      heard_from: null,
      previous_training: null,
      why_filmmaking: null,
      why_kap: null,
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: async (values) => {
      const extrValues = {
        ...values,
        gender: values?.gender?.value,
        education: values?.education?.value,
        occupation: values?.occupation?.value,
        monthly_income: values?.monthly_income?.value,
        items_owned: values?.items_owned?.map((x) => x?.value),
        state_id: values?.state_id?.value,
        country_id: values?.country_id?.value,
        disability: values?.disability?.value,
        secondary_school: values?.secondary_school?.value,
        community: values?.community?.value,
        internally_displaced: values?.internally_displaced?.value,
        heard_from: values?.heard_from?.value,
        previous_training: values?.previous_training?.value,
        highest_certificate:
          values?.highest_certificate &&
          values?.highest_certificate.length > 0 &&
          values?.highest_certificate[0],
        photo_id:
          values?.photo_id &&
          values?.photo_id.length > 0 &&
          values?.photo_id[0],
      };
      if (values?.country_id?.label != 'Nigeria') {
        delete extrValues?.state_id;
        delete extrValues?.disability;
        delete extrValues?.highest_certificate;
        delete extrValues?.education;
        delete extrValues?.occupation;
        delete extrValues?.monthly_income;
        delete extrValues?.items_owned;
        delete extrValues?.community;
        delete extrValues?.secondary_school;
        delete extrValues?.internally_displaced;
        delete extrValues?.heard_from;
        delete extrValues?.previous_training;
        delete extrValues?.why_kap;
        delete extrValues?.why_filmmaking;
      }
      const resolveValues = objectToFormData(extrValues);
      logFormData(resolveValues);
      handleRegistration(resolveValues);
    },
  });

  useEffect(() => {
    formik.setFieldValue('firstname', userInfo?.firstname);
    formik.setFieldValue('lastname', userInfo?.lastname);
    formik.setFieldValue('email', userInfo?.email);
    formik.setFieldValue('phone', userInfo?.phone);
  }, [userInfo, isFetchingStates, isFetchingCountries]);

  useEffect(() => {
    if (
      formik.values?.country_id &&
      formik.values?.country_id?.label == 'Nigeria'
    ) {
      setTabs(['personal', 'background', 'document']);
    } else setTabs(['personal']);
  }, [formik.values?.country_id]);

  const countryBasedValid = () => {
    if (
      formik.values?.country_id &&
      formik.values?.country_id?.label?.toLowerCase() == 'nigeria'
    ) {
      return formik.values?.state_id && formik.values?.disability;
    } else return true;
  };

  const pageOneValid =
    formik.values?.firstname &&
    formik.values?.lastname &&
    formik.values?.email &&
    formik.values?.phone &&
    formik.values?.gender &&
    formik.values?.dob &&
    formik.values?.address &&
    formik.values?.country_id &&
    countryBasedValid();
  const pageTwoValid =
    formik.values?.education &&
    formik.values?.occupation &&
    formik.values?.monthly_income &&
    formik.values?.items_owned;
  const pageThreeValid =
    formik.values?.firstname &&
    formik.values?.lastname &&
    formik.values?.email &&
    formik.values?.phone &&
    formik.values?.gender &&
    formik.values?.dob &&
    formik.values?.address &&
    formik.values?.country_id &&
    countryBasedValid() &&
    formik.values?.education &&
    formik.values?.occupation &&
    formik.values?.monthly_income &&
    formik.values?.items_owned;

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 13);

  if (isLoading || isFetchingStates || isFetchingCountries) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }
  return (
    <Fragment>
      <Row className="d-flex justify-content-center align-items-center g-0 min-vh-100">
        {/* <Col xl={7} lg={6} md={0} className='text-lg-end text-center'>
          <Image src={BackgroundImage} alt='' className='img-fluid' />
        </Col> */}
        <Col lg={6} md={8} className="my-3 py-8 py-xl-0  align-items-center">
          {applicationSuccess ? (
            <Col>
              <Card className="d-flex p-4 justify-content-center align-items-center">
                <Icon
                  icon={checkCircle}
                  size={120}
                  style={{ color: '#e0bf00' }}
                />
                <h3 className="text-primary mt-3">
                  Application Submitted successfully
                </h3>
                <Button
                  variant="primary"
                  className="w-40 mt-3"
                  onClick={() => history.push('/')}
                >
                  {'Ok'}
                </Button>
              </Card>
            </Col>
          ) : (
            <Tab.Container
              justify
              activeKey={key}
              onSelect={(k) => console.log(k)}
            >
              <Card>
                <Card.Header className="border-bottom-0 p-0 bg-white">
                  <Nav className="nav-lb-tab">
                    {tabs?.map((x, index) => (
                      <Nav.Item>
                        <Nav.Link
                          key={index}
                          eventKey={x}
                          className="mb-sm-3 mb-md-0 text-capitalize"
                        >
                          {x}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Card.Header>
                <Card.Body className="p-0">
                  <Form onSubmit={formik.handleSubmit}>
                    <Tab.Content>
                      <Tab.Pane eventKey="personal" className="pb-4">
                        <Card>
                          <Card.Body className="px-6 py-3">
                            <div>
                              <h3 className="mb-3 fw-bold">
                                Personal Information
                              </h3>
                            </div>
                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                id="firstname"
                                placeholder="First name"
                                value={formik.values?.firstname}
                                disabled
                                onChange={formik.handleChange}
                              />
                              {formik.errors?.firstname && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.firstname}
                                </p>
                              )}
                            </Col>
                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                id="lastname"
                                placeholder="Last name"
                                value={formik.values?.lastname}
                                disabled
                                onChange={formik.handleChange}
                              />
                              {formik.errors?.lastname && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.lastname}
                                </p>
                              )}
                            </Col>

                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Email Address</Form.Label>
                              <Form.Control
                                required
                                type="email"
                                id="email"
                                disabled
                                placeholder="Enter Email address"
                                value={formik.values?.email}
                                onChange={formik.handleChange}
                              />
                              {formik.errors?.email && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.email}
                                </p>
                              )}
                            </Col>
                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Phone Number</Form.Label>
                              <InputGroup className="mb-0">
                                {/* <InputGroup.Text id="basic-addon1">
                                  +234
                                </InputGroup.Text> */}
                                <Form.Control
                                  required
                                  type="text"
                                  id="phone"
                                  disabled
                                  placeholder="Enter phone number"
                                  value={formik.values?.phone}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      'phone',
                                      e?.target?.value
                                    );
                                  }}
                                />
                                {/* <PhoneInput
                                  country={'ng'}
                                  value={formik.values?.phone}
                                  onChange={formik.handleChange}
                                  inputStyle={{
                                    width: '100%',
                                    height: '47px',
                                  }}
                                  disabled
                                /> */}
                              </InputGroup>
                              {formik.errors?.phone && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.phone}
                                </p>
                              )}
                            </Col>
                            {/* <PhoneInput
                              placeholder="Enter phone number"
                              value={formik.values?.phone}
                              onChange={formik.handleChange}
                              error={(value) => {
                                if (!value) {
                                  return 'Phone number is required';
                                } else if (!isValidPhoneNumber(value)) {
                                  return 'Invalid phone number';
                                }
                              }}
                              inputProps={{
                                name: 'phone',
                                id: 'phone',
                                required: true,
                              }}
                              inputClassName="my-custom-class"
                              inputStyle={{ color: 'blue', fontSize: '16px' }}
                            /> */}
                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Gender</Form.Label>
                              <MultiSelectDropdown
                                required
                                options={genderOptions}
                                selected={formik.values?.gender}
                                setSelected={(e) => {
                                  formik.setFieldValue('gender', e);
                                }}
                                placeholder={'Select gender'}
                              />
                              {formik.errors?.gender && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.gender}
                                </p>
                              )}
                            </Col>
                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                required
                                type="date"
                                id="dob"
                                placeholder="mm/dd/yy"
                                max={maxDate.toISOString().split('T')[0]}
                                onChange={formik.handleChange}
                              />
                              {formik.errors?.dob && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.dob}
                                </p>
                              )}
                            </Col>

                            <Col lg={12} md={12} className="mb-3">
                              {/* email */}
                              <Form.Label>Address</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                id="address"
                                placeholder="Address"
                                onChange={formik.handleChange}
                              />
                              {formik.errors?.address && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.address}
                                </p>
                              )}
                            </Col>

                            <Col lg={12} md={12} className="mb-3">
                              <Form.Label>Country</Form.Label>

                              {countriesOptions?.success ? (
                                <MultiSelectDropdown
                                  required
                                  options={countriesOptions?.data?.map((x) => {
                                    return {
                                      id: x?.id,
                                      label: x?.country_name,
                                      value: x?.id,
                                    };
                                  })}
                                  selected={formik.values?.country_id}
                                  setSelected={(e) => {
                                    formik.setFieldValue('country_id', e);
                                  }}
                                  placeholder={'Select country'}
                                />
                              ) : (
                                <div>
                                  <Spinner size="sm" />
                                </div>
                              )}
                              {formik.errors?.country_id && (
                                <p className="fs-8 text-danger mb-0">
                                  {formik.errors?.country_id}
                                </p>
                              )}
                            </Col>

                            {formik?.values?.country_id?.label == 'Nigeria' && (
                              <>
                                <Col lg={12} md={12} className="mb-3">
                                  <Form.Label>State</Form.Label>
                                  {statesOptions?.success ? (
                                    <MultiSelectDropdown
                                      required
                                      options={statesOptions?.data?.map((x) => {
                                        return {
                                          id: x.id,
                                          label: x.state,
                                          value: x.id,
                                        };
                                      })}
                                      selected={formik.values?.state_id}
                                      setSelected={(e) => {
                                        formik.setFieldValue('state_id', e);
                                      }}
                                      placeholder={'Select state'}
                                    />
                                  ) : (
                                    <div>
                                      <Spinner size="sm" />
                                    </div>
                                  )}
                                  {formik.errors?.state_id && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.state_id}
                                    </p>
                                  )}
                                </Col>
                                <Col lg={12} md={12} className="mb-3">
                                  <Form.Label>
                                    Do you consider yourself to have a physical
                                    disability?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    required
                                    options={disabilityOptions}
                                    selected={formik.values?.disability}
                                    setSelected={(e) => {
                                      formik.setFieldValue('disability', e);
                                    }}
                                    placeholder={'Select state'}
                                  />

                                  {formik.errors?.disability && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.disability}
                                    </p>
                                  )}
                                </Col>
                              </>
                            )}

                            <Col
                              lg={12}
                              md={12}
                              className="mb-0 d-grid gap-2 justify-content-end"
                            >
                              <CustomButton
                                disabled={!pageOneValid}
                                variant={
                                  formik.values?.country_id &&
                                  formik.values?.country_id?.label !== 'Nigeria'
                                    ? 'primary'
                                    : 'secondary'
                                }
                                onClick={() => {
                                  formik.values?.country_id &&
                                  formik.values?.country_id?.label !== 'Nigeria'
                                    ? formik.handleSubmit()
                                    : setKey('background');
                                }}
                                text={
                                  formik.values?.country_id &&
                                  formik.values?.country_id?.label !== 'Nigeria'
                                    ? 'Submit'
                                    : 'Save & Next'
                                }
                              />
                            </Col>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane eventKey="background" className="pb-4">
                        <Card>
                          <Card.Body className="px-6 py-3">
                            <div>
                              <h3 className="mb-3 fw-bold">
                                Background Information
                              </h3>
                            </div>
                            <Row>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    What is the highest level of formal
                                    education that you have attained?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    required
                                    options={eduOptions}
                                    selected={formik.values?.education}
                                    setSelected={(e) => {
                                      formik.setFieldValue('education', e);
                                    }}
                                    placeholder={'Select options'}
                                  />
                                  {formik.errors?.education && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.education}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    What best describes what you do for a
                                    living?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={occupationOptions}
                                    selected={formik.values?.occupation}
                                    setSelected={(e) => {
                                      formik.setFieldValue('occupation', e);
                                    }}
                                    placeholder={'Select options'}
                                  />
                                  {formik.errors?.occupation && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.occupation}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    On average, how much do you earn per month?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={salaryOptions}
                                    selected={formik.values?.monthly_income}
                                    setSelected={(e) => {
                                      formik.setFieldValue('monthly_income', e);
                                    }}
                                    placeholder={'Select options'}
                                  />
                                  {formik.errors?.monthly_income && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.monthly_income}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    Which of these do you own?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={itemsOptions}
                                    isMulti
                                    selected={formik.values?.items_owned}
                                    setSelected={(e) => {
                                      formik.setFieldValue('items_owned', e);
                                    }}
                                    placeholder={'Select item(s)'}
                                  />
                                  {formik.errors?.items_owned && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.items_owned}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    Which of these secondary school did you
                                    attend?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={secondarySchoolOptions}
                                    selected={formik.values?.secondary_school}
                                    setSelected={(e) => {
                                      formik.setFieldValue(
                                        'secondary_school',
                                        e
                                      );
                                    }}
                                    placeholder={'Select secondary_school'}
                                  />
                                  {formik.errors?.secondary_school && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.secondary_school}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    Which of these best describes your
                                    community/Area of residence?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={communityOptions}
                                    selected={formik.values?.community}
                                    setSelected={(e) => {
                                      formik.setFieldValue('community', e);
                                    }}
                                    placeholder={'Select community'}
                                  />
                                  {formik.errors?.community && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.community}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    Are you an internally displaced person?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={internallyDisplacedOptions}
                                    selected={
                                      formik.values?.internally_displaced
                                    }
                                    setSelected={(e) => {
                                      formik.setFieldValue(
                                        'internally_displaced',
                                        e
                                      );
                                    }}
                                    placeholder={'Select'}
                                  />
                                  {formik.errors?.internally_displaced && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.internally_displaced}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    How did you hear about the program?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={heardFromOptions}
                                    selected={formik.values?.heard_from}
                                    setSelected={(e) => {
                                      formik.setFieldValue('heard_from', e);
                                    }}
                                    placeholder={'Select'}
                                  />
                                  {formik.errors?.heard_from && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.heard_from}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Group>
                                  <Form.Label>
                                    Have you had any training in film and
                                    television?
                                  </Form.Label>
                                  <MultiSelectDropdown
                                    options={previousTrainingInFilmOptions}
                                    selected={formik.values?.previous_training}
                                    setSelected={(e) => {
                                      formik.setFieldValue(
                                        'previous_training',
                                        e
                                      );
                                    }}
                                    placeholder={'Select'}
                                  />
                                  {formik.errors?.previous_training && (
                                    <p className="fs-8 text-danger mb-0">
                                      {formik.errors?.previous_training}
                                    </p>
                                  )}
                                </Form.Group>
                              </Col>

                              <Col lg={12} md={12} className="mb-3">
                                {/* email */}
                                <Form.Label>
                                  Why do you want to be part of the filmmaking
                                  program?
                                </Form.Label>
                                <Form.Control
                                  required
                                  as="textarea"
                                  rows={3}
                                  id="why_filmmaking"
                                  placeholder="Why filmmaking?"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors?.why_filmmaking && (
                                  <p className="fs-8 text-danger mb-0">
                                    {formik.errors?.why_filmmaking}
                                  </p>
                                )}
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                {/* email */}
                                <Form.Label>
                                  Why did you choose KAP (Kunle Afolayan
                                  Production) academy?
                                </Form.Label>
                                <Form.Control
                                  required
                                  as="textarea"
                                  rows={3}
                                  id="why_kap"
                                  placeholder="Why kap?"
                                  onChange={formik.handleChange}
                                />
                                {formik.errors?.why_kap && (
                                  <p className="fs-8 text-danger mb-0">
                                    {formik.errors?.why_kap}
                                  </p>
                                )}
                              </Col>

                              <Col
                                lg={12}
                                md={12}
                                className="mb-0 d-grid d-flex gap-2 justify-content-between"
                              >
                                <Button
                                  variant="light"
                                  onClick={() => setKey('personal')}
                                >
                                  {'Previous'}
                                </Button>
                                <Button
                                  disabled={!pageTwoValid}
                                  variant="secondary"
                                  onClick={() => setKey('document')}
                                >
                                  {'Save & Next'}
                                </Button>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                      <Tab.Pane eventKey="document" className="pb-4">
                        <Card>
                          <Card.Body className="px-6 py-3">
                            <div>
                              <h3 className="mb-1 fw-bold">Document Uploads</h3>
                            </div>
                            {/* Form */}
                            <Row>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Label>
                                  Upload result <strong>(OPTIONAL)</strong>
                                  <div className="px-5 mb-0">
                                    <li>
                                      (SSCE, GCE, NECO, OND, HND, University
                                      degrees, NCE, Teacher Training Cert, City
                                      and Guild, Technical college certificates,
                                      Diploma or one-year certificate from
                                      recognized institutions)
                                    </li>
                                  </div>
                                </Form.Label>
                                <FileUploadInput
                                  selectedFile={
                                    formik.values?.highest_certificate
                                  }
                                  setSelectedFile={(e) => {
                                    formik.setFieldValue(
                                      'highest_certificate',
                                      e
                                    );
                                  }}
                                  fileformats={['jpg', 'png', 'pdf']}
                                />
                                {formik.errors?.highest_certificate && (
                                  <p className="fs-8 text-danger mb-0">
                                    {formik.errors?.highest_certificate}
                                  </p>
                                )}
                              </Col>
                              <Col lg={12} md={12} className="mb-3">
                                <Form.Label>
                                  Upload of government issued ID{' '}
                                  <strong>(OPTIONAL)</strong>
                                  <div className="px-5 mb-0">
                                    <li>
                                      (Voter's card, International Passport,
                                      NIN, Driver's license)
                                    </li>
                                  </div>
                                </Form.Label>
                                <FileUploadInput
                                  selectedFile={formik.values?.photo_id}
                                  setSelectedFile={(e) => {
                                    formik.setFieldValue('photo_id', e);
                                  }}
                                  fileformats={['jpg', 'png', 'pdf']}
                                />
                                {formik.errors?.photo_id && (
                                  <p className="fs-8 text-danger mb-0">
                                    {formik.errors?.photo_id}
                                  </p>
                                )}
                              </Col>
                              {(Object.keys(formik.errors).length !== 0 ||
                                !pageThreeValid) && (
                                <p className="fs-8 text-danger">
                                  You need to attend to the errors in the
                                  previous pages of the form before being able
                                  to submit
                                  {JSON.stringify(formik.errors)}
                                </p>
                              )}
                              <Col
                                lg={12}
                                md={12}
                                className="mb-0 d-grid d-flex gap-2 justify-content-between"
                              >
                                <Button
                                  variant="light"
                                  onClick={() => setKey('background')}
                                >
                                  {'Previous'}
                                </Button>
                                <Button
                                  disabled={!pageThreeValid || !formik.isValid}
                                  variant="primary"
                                  onClick={formik.handleSubmit}
                                >
                                  {'Submit'}
                                </Button>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Tab.Pane>
                    </Tab.Content>
                  </Form>
                </Card.Body>
              </Card>
            </Tab.Container>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};
export default CompleteRegistration;
