import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Tab,
  Nav,
  Table,
  Pagination,
  Col,
} from 'react-bootstrap';
import {
  useDeleteAssignmentMutation,
  useDeleteProblemMutation,
  useGetAssignmentByIdMutation,
  useGetInstructorAssignmentMutation,
  useGetInstructorStudentsMutation,
  useGetProblemsMutation,
  useGetTopicByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';

import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import { convertToYesNo } from 'pages/helper/formatText';
import AddAssignment from 'pages/kapAdmin/topics/AddAssignment';
import moment from 'moment';
import AddProblem from './AddProblem';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import GlobalFilter from 'pages/components/elements/advance-table/GlobalFilter';
import { original } from '@reduxjs/toolkit';
import { sortByProperty } from 'pages/helper/formatArray';

const AssignmentDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [topicDetails, setTopicDetails] = useState(null);
  const [problemDetails, setProblemDetails] = useState(null);
  const [problems, setProblems] = useState([]);

  const [type, setType] = useState('add');
  const [key, setKey] = useState('Videos');
  const [refresh, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteName, setDeleteName] = useState(false);
  const [instructorStudents, setInstructorStudents] = useState(false);

  const [getInstructorStudents, { isLoading: isLoadingInstructorStudents }] =
    useGetInstructorStudentsMutation();
  const [getTopicById, { isLoading: isLoadingTopic }] =
    useGetTopicByIdMutation();
  const [getInstructorAssignment, { isLoading: isLoadingAssignment }] =
    useGetInstructorAssignmentMutation();
  const [getAssignmentById, { isLoading: isLoadingAssignmentById }] =
    useGetAssignmentByIdMutation();

  const [deleteAssignment, { isLoading: isDeleting }] =
    useDeleteAssignmentMutation();
  const [deleteProblem, { isLoading: isDeletingProblem }] =
    useDeleteProblemMutation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleCloseAdd = () => {
    setShowAdd(false);
    setRefresh(!refresh);
  };

  const handleShowAdd = () => {
    setShowAdd(true);
  };

  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => {
    setDeleteShow(false);
    setRefresh(!refresh);
  };

  const handleAddContent = () => {
    setType('add');
    handleShowAdd();
  };

  const handleDeleteAssignment = async () => {
    const res = await deleteAssignment({ id: assignmentDetails.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteProblem = async () => {
    const res = await deleteProblem({ id: problemDetails?.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteClick = () => {
   return deleteName == 'Assignment'
      ? handleDeleteAssignment()
      : deleteName == 'Problem'
      ? handleDeleteProblem()
      : null;
  };

  const fetchInstructorStudents = async () => {
    const res = await getInstructorStudents();
    if (res?.data) {
      setInstructorStudents(res?.data?.data?.students);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };
  const fetchTopic = async (id) => {
    const res = await getTopicById({ id });
    if (res?.data) {
      setTopicDetails(res?.data?.data?.topic);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };
  const fetchAssignment = async () => {
    const res1 = await getInstructorAssignment({ id: route_id });
    const res = await getAssignmentById({ id: route_id });
    if (res?.data) {
      setAssignmentDetails(res?.data?.data?.assignment);
      setProblems(
        res?.data?.data?.assignment?.problems?.map((problem) => {
          return {
            ...problem,
            correct_options: problem?.options?.filter(
              (option) => option?.correct == 1
            ),
          };
        }) || []
      );
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchAssignment();
    fetchInstructorStudents();
  }, [refresh]);

  useEffect(() => {
    assignmentDetails?.topic_id && fetchTopic(assignmentDetails?.topic_id);
  }, [assignmentDetails?.topic_id]);

  const columns = useMemo(
    () => [
      { accessor: 'number', Header: 'number' },
      {
        accessor: 'type',
        Header: 'Type',
      },
      {
        accessor: 'statement',
        Header: 'Statement',
      },
      {
        accessor: 'options',
        Header: 'Options',
        Cell: ({ value }) => {
          return (
            <div key={JSON.stringify(value)}>
              {value?.map((x) => (
                <p key={x?.label + ':' + ' ' + x?.text}>
                  {x?.label + ':' + ' ' + x?.text}
                </p>
              ))}
            </div>
          );
        },
      },
      {
        accessor: 'correct_options',
        Header: 'Correct options',
        Cell: ({ value }) => {
          return (
            <div>
              {value?.map((x) => (
                <p>{x?.label + ':' + ' ' + x?.text}</p>
              ))}
            </div>
          );
        },
      },
      {
        accessor: 'score',
        Header: 'Score',
      },
      {
        accessor: 'action',
        Header: 'ACTION',
        Cell: ({ value, row }) => {
          return (
            <>
              <Button
                size="sm"
                className="mx-2"
                onClick={() => {
                  setProblemDetails(row?.original);
                  setType('edit');
                  handleShowAdd();
                }}
              >
                Edit
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  setDeleteName('Problem');
                  setProblemDetails(row?.original);
                  handleDeleteShow();
                }}
              >
                Delete
              </Button>
            </>
          );
        },
      },
    ],
    [problems]
  );
  const columns1 = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      {
        accessor: 'firstname',
        Header: 'First Name',
      },
      {
        accessor: 'lastname',
        Header: 'Last Name',
      },

      {
        accessor: 'action',
        Header: 'ACTION',
        Cell: ({ value, row }) => {
          return (
            <Button
              size="sm"
              className="mx-2"
              onClick={() => {
                history.push('/instructor/assignment/review', {
                  student_id: row?.original?.id,
                  assignment_id: assignmentDetails?.id || route_id,
                });
              }}
            >
              Review
            </Button>
          );
        },
      },
    ],
    [instructorStudents]
  );

  const data = useMemo(() => {
    let filtered = problems;
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    filtered = [...filtered].sort((a, b) => a?.number - b?.number);
    return filtered;
  }, [problems]);
  const data1 = useMemo(() => {
    let filtered = instructorStudents || [];
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });
    return filtered;
  }, [instructorStudents]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );
  const {
    getTableProps: getTableProps1,
    getTableBodyProps: getTableBodyProps1,
    headerGroups: headerGroups1,
    page: page1,
    nextPage: nextPage1,
    previousPage: previousPage1,
    state: state1,
    gotoPage: gotoPage1,
    pageCount: pageCount1,
    prepareRow: prepareRow1,
    setGlobalFilter: setGlobalFilter1,
  } = useTable(
    {
      columns: columns1,
      data: data1,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns1.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;
  const { pageIndex1, globalFilter1 } = state1;

  if (isLoadingAssignment) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Assignment
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {assignmentDetails?.title}
                </h4>
              </div>
            </div>
            <div>
              <Button variant="primary" size="sm" onClick={handleShow}>
                Edit Assignment
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <ListItem title={'Title'} children={assignmentDetails?.title} />
        <ListItem title={'Topic'} children={topicDetails?.title} />
        <ListItem
          title={'Instructions'}
          children={assignmentDetails?.instructions || 'N/A'}
        />
        <ListItem
          title={'Allowed time'}
          children={
            (assignmentDetails?.timed &&
              moment
                .utc(assignmentDetails?.allowed_time)
                ?.format('hh:mm:ss')) ||
            'N/A'
          }
        />
        <ListItem
          title={'Deadline'}
          children={
            moment(assignmentDetails?.deadline)?.format(
              'hh:mm a MMM DD, YYYY'
            ) || 'N/A'
          }
        />
        <ListItem
          title={'Pass mark'}
          children={assignmentDetails?.pass_mark || 'N/A'}
        />
        <ListItem
          title={'Total mark'}
          children={assignmentDetails?.total || 'N/A'}
        />
        <ListItem
          title={'Has attachments'}
          children={convertToYesNo(assignmentDetails?.has_attachments) || 'N/A'}
        />
      </Card>

      <Card className="mb-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title as="p" className="text-uppercase">
              Problems
            </Card.Title>
            <Button size="sm" onClick={handleAddContent}>
              Add New Problem
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive">
            <div className=" overflow-hidden">
              <Row>
                <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
                  <GlobalFilter
                    filter={globalFilter}
                    setFilter={setGlobalFilter}
                    placeholder="Search List"
                  />
                </Col>
              </Row>
            </div>

            <Table {...getTableProps()} className="text-nowrap">
              <thead className="table-light">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              previousPage={previousPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              gotoPage={gotoPage}
              nextPage={nextPage}
            />
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title as="p" className="text-uppercase">
              Students
            </Card.Title>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="table-responsive">
            <div className=" overflow-hidden">
              <Row>
                <Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
                  <GlobalFilter
                    filter={globalFilter1}
                    setFilter={setGlobalFilter1}
                    placeholder="Search List"
                  />
                </Col>
              </Row>
            </div>

            <Table {...getTableProps1()} className="text-nowrap">
              <thead className="table-light">
                {headerGroups1.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps1()}>
                {page1.map((row) => {
                  prepareRow1(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Pagination
              previousPage={previousPage1}
              pageCount={pageCount1}
              pageIndex={pageIndex1}
              gotoPage={gotoPage1}
              nextPage={nextPage1}
            />
          </div>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-start">
        <AddAssignment
          show={show}
          handleClose={handleClose}
          initialState={assignmentDetails}
          type={'edit'}
          id={topicDetails?.id}
        />
        <AddProblem
          show={showAdd}
          handleClose={handleCloseAdd}
          initialState={type == 'edit' ? problemDetails : null}
          type={type}
          parent_type={'assignment'}
          id={assignmentDetails?.id}
        />
        <Button
          variant="secondary"
          onClick={() => {
            setDeleteName('Assignment');
            handleDeleteShow();
          }}
          size="sm"
        >
          Delete Assignment
        </Button>

        <Modal show={deleteShow} onHide={handleDeleteClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Delete {deleteName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this {deleteName}</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AssignmentDetails;
