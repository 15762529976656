import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Dropdown, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StatRightIcon from 'components/dashboard/common/stats/StatRightIcon';
import { useGetDashboardStatsMutation } from 'pages/redux/features/user/userApi';
import { formatKobo } from 'pages/helper/formatText';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Link
    to=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Link>
));

const AdminDashboard = () => {
  const [getDashboardStats, { isLoading }] = useGetDashboardStatsMutation();
  const [stats, setStats] = useState(null);

  const fetchDashboardStats = async () => {
    const stats = await getDashboardStats();

    if (stats?.data?.success) {
      setStats(stats?.data?.data);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Dashboard</h1>
            </div>
          </div>
        </Col>
      </Row>
      {isLoading ? (
        <Row className="d-flex justify-content-center align-items-center min-vh-100">
          <Spinner style={{ color: '#e0bf00' }} />
        </Row>
      ) : (
        <Row>
          {stats?.applicants && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="APPLICANTS"
                value={stats?.applicants?.total}
                summary="this month"
                summaryValue={stats?.applicants?.this_months_applications}
                summaryIcon="up"
                showSummaryIcon
                iconName="user-check"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}

          {(stats?.students?.total || stats?.students?.total == 0) && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="STUDENTS"
                value={stats?.students?.total}
                summary="this month"
                summaryValue={stats?.students?.this_months_students}
                summaryIcon="up"
                showSummaryIcon
                iconName="users"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}

          {(stats?.instructors?.total || stats?.instructors?.total == 0) && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="INSTRUCTOR"
                value={stats?.instructors?.total}
                iconName="user-check"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}
          {(stats?.programmes?.total || stats?.programmes?.total == 0) && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="PROGRAMMES"
                value={stats?.programmes?.total}
                iconName="book-open"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}
          {(stats?.courses?.total || stats?.courses?.total == 0) && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="COURSES"
                value={stats?.courses?.total}
                iconName="book-open"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}
          {(stats?.revenue?.total || stats?.revenue?.total == 0) && (
            <Col xl={4} lg={6} md={12} sm={12}>
              <StatRightIcon
                title="REVENUE"
                value={formatKobo(stats?.revenue?.total)}
                iconName="activity"
                iconColorVariant="primary"
                classValue="mb-4"
              />
            </Col>
          )}
        </Row>
      )}

      {/* <Row>
        <Col xl={4} lg={12} md={12} className='mb-4'>
          <Card>
            <Card.Header className='align-items-center card-header-height d-flex justify-content-between align-items-center'>
              <div>
                <h4 className='mb-0'>Traffic</h4>
              </div>
              <div>
                <ChartActionMenu />
              </div>
            </Card.Header>
            <Card.Body className='py-lg-7'>
              <div id='chart'>
                <ApexCharts
                  options={TrafficChartOptions}
                  series={TrafficChartSeries}
                  type='donut'
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <Row>
        {/* <Col xl={4} lg={6} md={12} className='mb-4'>
          <PopularInstructor title='Popular Instructor' />
        </Col>
        <Col xl={4} lg={6} md={12} className='mb-4'>
          <RecentCourses title='Recent Courses' />
        </Col> */}

        {/* <Col xl={4} lg={6} md={12} className='mb-4'>
          <Activity title='Activity' />
        </Col> */}
      </Row>
    </div>
  );
};

export default AdminDashboard;
