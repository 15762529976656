// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  InputGroup,
} from 'react-bootstrap';

// import media files
import { useFormik } from 'formik';
import {
  useFacebookSignupMutation,
  useLoginUserMutation,
  useTwitterSignupMutation,
} from 'pages/redux/features/auth/authApi';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  logout,
  saveSisDetails,
  setApplicationStatus,
  setAuthState,
  setCompleteApplication,
  setCredentials,
  setRole,
} from 'pages/redux/features/auth/authSlice';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'pages/components/Loader';
import localStorage from 'redux-persist/es/storage';
import { CustomButton } from 'pages/components/CustomButton';
import { logosblack } from 'pages/assets/images/imageUrl';

const Login = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleRememberMeChange = (event) => setRememberMe(event.target.checked);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const [loginUser, { isLoading: isLoggingIn }] = useLoginUserMutation();
  const [facebookSignup, { isLoading: isLoadingFacebookSignup }] =
    useFacebookSignupMutation();
  const [twitterSignup, { isLoading: isLoadingTwitterSignup }] =
    useTwitterSignupMutation();
  const handleTwitterSignup = async () => {
    const res = await facebookSignup();
    console.log('handleTwitterSignup', res);
  };
  const handleFacebookSignup = async () => {
    const res = await facebookSignup();
    console.log('handleFacebookSignup', res);
    // if (res?.data?.success) {
    //   dispatch(
    //     setCredentials({
    //       user: res?.data?.data,
    //       token: res?.data?.data?.token,
    //     }),
    //   );
    //   dispatch(setAuthState(true));
    //   dispatch(setRole('student'));
    //   history.push('/');
    // } else {
    //   alert(res?.error?.data?.message || 'Something went wrong');
    // }
  };
  const handleLogin = async (values) => {
    const res = await loginUser(values);
    if (res?.data?.success) {
      if (rememberMe) {
        localStorage.setItem('emailKap', values?.email);
        localStorage.setItem('passwordKap', values?.password);
      } else {
        localStorage.removeItem('emailKap');
        localStorage.removeItem('passwordKap');
      }
      if (res?.data?.data?.roles.includes('student')) {
        localStorage.setItem('userAccountKap', res?.data?.data);
        dispatch(
          setCredentials({
            user: res?.data?.data,
            token: res?.data?.data?.token,
          })
        );

        dispatch(saveSisDetails(res?.data?.data?.sis_details));
        dispatch(setAuthState(true));
        dispatch(setCompleteApplication(res?.data?.data?.application !== null));
        dispatch(
          setApplicationStatus(
            res?.data?.data?.application !== null
              ? res?.data?.data?.application?.status.includes('admitted')
              : false
          )
        );
        dispatch(setRole('student'));
        history.push('/');
      }
      if (res?.data?.data?.roles[0].toLowerCase().includes('admin')) {
        dispatch(
          setCredentials({
            user: res?.data?.data,
            token: res?.data?.data?.token,
          })
        );
        dispatch(setAuthState(true));
        dispatch(setRole('admin'));
        history.push('/admin');
      }
      if (res?.data?.data?.roles[0].toLowerCase().includes('teacher')) {
        dispatch(
          setCredentials({
            user: res?.data?.data,
            token: res?.data?.data?.token,
          })
        );
        dispatch(setAuthState(true));
        dispatch(setRole('instructor'));
        history.push('/instructor');
      }
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  useEffect(() => {
    const remember = async () => {
      const storedEmail = await localStorage.getItem('emailKap');
      const storedPassword = await localStorage.getItem('passwordKap');

      if (storedEmail && storedPassword) {
        formik.setValues({
          email: storedEmail,
          password: storedPassword,
        });
        setRememberMe(true);
      }
      setLoading(false);
    };
    remember();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        {/* <Col xl={7} lg={6} md={0} className='text-lg-end text-center'>
          <Image src={BackgroundImage} alt='' className='img-fluid' />
        </Col> */}
        <Col lg={9} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Header className="mb-1 ">
              <Image src={logosblack} alt="" className="img-fluid w-50" />
            </Card.Header>
            <Card.Body className="p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Login</h1>
                <span>
                  Don't have an account?
                  <Link to="/register" className="ms-1">
                    Register
                  </Link>
                </span>
              </div>
              {/* Form */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Username or email */}
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email address here"
                      required
                      onChange={formik.handleChange}
                      defaultValue={formik?.values?.email}
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    <Form.Label>Password </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        id="password"
                        placeholder="**************"
                        required
                        defaultValue={formik?.values?.password}
                        onChange={formik.handleChange}
                      />

                      <Button
                        variant="outline-secondary"
                        onClick={togglePassword}
                      >
                        <Icon icon={passwordShown ? eye : eyeOff} size={18} />
                      </Button>
                    </InputGroup>
                  </Col>
                  ;
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <div className="d-md-flex justify-content-between align-items-center">
                      <Form.Group
                        className="mb-3 mb-md-0"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Remember me"
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                        />
                      </Form.Group>
                      <Link to="/forgot-password">Forgot your password?</Link>
                    </div>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <CustomButton
                      variant="primary"
                      type="submit"
                      loading={isLoggingIn}
                      text="Login"
                    />
                  </Col>
                </Row>
              </Form>
              <hr className="my-4" />
              <div className="mt-4 text-center">
                {/* <Link
                  to="#"
                  onClick={() => handleFacebookSignup()}
                  className="btn-social btn-social-outline btn-facebook"
                >
                  <i className="fab fa-facebook"></i>
                </Link>
                <Link
                  to="#"
                  onClick={() => handleTwitterSignup()}
                  className="btn-social btn-social-outline btn-twitter"
                >
                  <i className="fab fa-twitter"></i>
                </Link> */}
                {/* <Link
                  to='#'
                  className='btn-social btn-social-outline btn-linkedin'>
                  <i className='fab fa-linkedin'></i>
                </Link>
                <Link
                  to='#'
                  className='btn-social btn-social-outline btn-google'>
                  <i className='fab fa-google'></i>
                </Link> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default Login;
