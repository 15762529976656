import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBase from '../customFetchBaseQuery';
import { BASE_URL } from '../url';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getProfile: builder.mutation({
      query: () => {
        return {
          url: 'users/profile',
          method: 'GET',
        };
      },
    }),
    deleteProfile: builder.mutation({
      query: ({ email }) => {
        return {
          url: `delete/${email}`,
          method: 'DELETE',
        };
      },
    }),
    createApplication: builder.mutation({
      query: (formData) => {
        return {
          url: 'users/create-application',
          method: 'POST',
          body: formData,
        };
      },
    }),
    updateApplication: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/update-application/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    createAdmin: builder.mutation({
      query: (data) => {
        return {
          url: 'users/create-admin',
          method: 'POST',
          body: data,
        };
      },
    }),
    createTeacher: builder.mutation({
      query: ({ firstname, lastname, email, phone }) => {
        return {
          url: 'users/create-teacher',
          method: 'POST',
          body: { firstname, lastname, email, phone },
        };
      },
    }),
    getApplications: builder.mutation({
      query: ({ take = 100, skip = 0, status }) => {
        return {
          url: 'users/applications',
          method: 'GET',
          params: { take, skip, status },
        };
      },
    }),
    getLevel2Applications: builder.mutation({
      query: ({ take = 100, skip = 0, status }) => {
        return {
          url: 'users/applications/level-2',
          method: 'GET',
          params: { take, skip, status },
        };
      },
    }),
    getApplicationById: builder.mutation({
      query: (id) => {
        return {
          url: `users/application/${id}`,
          method: 'GET',
        };
      },
    }),
    admitWithScholarshipById: builder.mutation({
      query: (id) => {
        return {
          url: `users/applications/admit-with-scholarship/${id}`,
          method: 'PATCH',
        };
      },
    }),
    admitWithoutScholarshipById: builder.mutation({
      query: (id) => {
        return {
          url: `users/applications/admit-without-scholarship/${id}`,
          method: 'PATCH',
        };
      },
    }),
    rejectToReapplyById: builder.mutation({
      query: ({ id, comment }) => {
        return {
          url: `users/applications/reject-to-reapply/${id}`,
          method: 'PATCH',
          body: { comment },
        };
      },
    }),
    rejectById: builder.mutation({
      query: ({ id, comment }) => {
        return {
          url: `users/applications/reject/${id}`,
          method: 'PATCH',
          body: { comment },
        };
      },
    }),
    admitWithScholarshipByIdLevel2: builder.mutation({
      query: (id) => {
        return {
          url: `users/applications/level-2/admit-with-scholarship/${id}`,
          method: 'PATCH',
        };
      },
    }),
    admitWithoutScholarshipByIdLevel2: builder.mutation({
      query: (id) => {
        return {
          url: `users/applications/level-2/admit-without-scholarship/${id}`,
          method: 'PATCH',
        };
      },
    }),
    rejectToReapplyByIdLevel2: builder.mutation({
      query: ({ id, comment }) => {
        return {
          url: `users/applications/level-2/reject-to-reapply/${id}`,
          method: 'PATCH',
          body: { comment },
        };
      },
    }),
    rejectByIdLevel2: builder.mutation({
      query: ({ id, comment }) => {
        return {
          url: `users/applications/level-2/reject/${id}`,
          method: 'PATCH',
          body: { comment },
        };
      },
    }),
    getUsers: builder.mutation({
      query: () => {
        return {
          url: 'users',
          method: 'GET',
        };
      },
    }),
    getIncompleteApplications: builder.mutation({
      query: ({ take = 100, skip = 0 }) => {
        return {
          url: 'users/incomplete-app',
          method: 'GET',
          params: { take, skip },
        };
      },
    }),
    getSearchUsers: builder.mutation({
      query: ({
        term,
        type = 'application',
        status,
        level = 1,
        take = 100,
        skip = 0,
      }) => {
        return {
          url: 'users/search',
          method: 'POST',
          body: { term, type, status, take, skip, level },
        };
      },
    }),
    getAdmins: builder.mutation({
      query: () => {
        return {
          url: 'users/admins',
          method: 'GET',
        };
      },
    }),
    getTeachers: builder.mutation({
      query: () => {
        return {
          url: 'users/teachers',
          method: 'GET',
        };
      },
    }),
    getStudents: builder.mutation({
      query: () => {
        return {
          url: 'users/level-1',
          method: 'GET',
        };
      },
    }),
    getAdminById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/admin/${id}`,
          method: 'GET',
        };
      },
    }),
    getTeacherById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/teacher/${id}`,
          method: 'GET',
        };
      },
    }),
    getStates: builder.query({
      query: () => ({
        url: 'states',
      }),
    }),
    getCountries: builder.query({
      query: () => ({
        url: 'countries',
      }),
    }),
    makePayment: builder.mutation({
      query: ({ level }) => {
        return {
          url: `users/make-payment`,
          method: 'POST',
          body: { level },
        };
      },
    }),
    verifyPayment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/verify-payment/${id}`,
          method: 'GET',
        };
      },
    }),
    verifyScholarshipCode: builder.mutation({
      query: ({ code }) => {
        return {
          url: `users/scholarship/${code}`,
          method: 'GET',
        };
      },
    }),
    verifyScholarshipCodeLevel2: builder.mutation({
      query: ({ code, course_id }) => {
        return {
          url: `users/scholarship/level-2/${code}`,
          method: 'GET',
          params: { course_id },
        };
      },
    }),
    recordPayment: builder.mutation({
      query: (data) => {
        return {
          url: `users/record-payment`,
          method: 'POST',
          body: data,
        };
      },
    }),
    createProgram: builder.mutation({
      query: ({ name, description, duration, nick_name, price, starts }) => {
        return {
          url: 'users/programmes/create',
          method: 'POST',
          body: { name, description, duration, nick_name, price, starts },
        };
      },
    }),
    updateProgram: builder.mutation({
      query: ({ data, id }) => {
        return {
          url: `users/programmes/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    deleteProgram: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/programmes/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getPrograms: builder.mutation({
      query: () => {
        return {
          url: 'programmes',
          method: 'GET',
        };
      },
    }),
    getProgramsLite: builder.mutation({
      query: () => {
        return {
          url: 'programmeslite',
          method: 'GET',
        };
      },
    }),
    getProgramById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `programmes/${id}`,
          method: 'GET',
        };
      },
    }),
    updateAvatar: builder.mutation({
      query: (formData) => {
        return {
          url: 'users/update-avatar',
          method: 'POST',
          body: formData,
        };
      },
    }),
    createCourse: builder.mutation({
      query: (formData) => {
        return {
          url: 'users/courses/create',
          method: 'POST',
          body: formData,
        };
      },
    }),
    enrollStudentForCourse: builder.mutation({
      query: ({ user_id, course_id }) => {
        return {
          url: 'users/courses/enroll',
          method: 'POST',
          body: { user_id, course_id },
        };
      },
    }),
    assignInstructorToCourse: builder.mutation({
      query: ({ user_id, course_id }) => {
        return {
          url: 'users/courses/assign',
          method: 'POST',
          body: { user_id, course_id },
        };
      },
    }),
    removeInstructorFromCourse: builder.mutation({
      query: ({ user_id, course_id }) => {
        return {
          url: 'users/courses/remove',
          method: 'POST',
          body: { user_id, course_id },
        };
      },
    }),
    deleteCourse: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/courses/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateCourse: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/courses/update/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getCourses: builder.mutation({
      query: () => {
        return {
          url: 'courses',
          method: 'GET',
        };
      },
    }),
    getCoursesLite: builder.mutation({
      query: () => {
        return {
          url: 'courseslite',
          method: 'GET',
        };
      },
    }),
    getCourseById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `courses/${id}`,
          method: 'GET',
        };
      },
    }),
    createClass: builder.mutation({
      query: (formData) => {
        return {
          url: 'users/classes/create',
          method: 'POST',
          body: formData,
        };
      },
    }),
    deleteClass: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/classes/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateClass: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/classes/update/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getClasses: builder.mutation({
      query: () => {
        return {
          url: 'users/classes',
          method: 'GET',
        };
      },
    }),
    getClassById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/classes/${id}`,
          method: 'GET',
        };
      },
    }),
    createTopic: builder.mutation({
      query: (formData) => {
        return {
          url: 'users/topics/create',
          method: 'POST',
          body: formData,
        };
      },
    }),
    addTopicToClass: builder.mutation({
      query: ({ topic_id, class_id }) => {
        return {
          url: 'users/classes/add-topic',
          method: 'POST',
          body: { topic_id, class_id },
        };
      },
    }),
    removeTopicFromClass: builder.mutation({
      query: ({ topic_id, class_id }) => {
        return {
          url: 'users/classes/remove_topic',
          method: 'POST',
          body: { topic_id, class_id },
        };
      },
    }),
    deleteTopic: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/topics/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateTopic: builder.mutation({
      query: ({ id, formData }) => {
        return {
          url: `users/topics/update/${id}`,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getTopics: builder.mutation({
      query: () => {
        return {
          url: 'topics',
          method: 'GET',
        };
      },
    }),
    getTopicsLite: builder.mutation({
      query: () => {
        return {
          url: 'topicslite',
          method: 'GET',
        };
      },
    }),
    getTopicById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/topics/${id}`,
          method: 'GET',
        };
      },
    }),
    createAssignment: builder.mutation({
      query: (data) => {
        return {
          url: 'users/assignments/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteAssignment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/assignments/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateAssignment: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/assignments/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getAssignments: builder.mutation({
      query: () => {
        return {
          url: 'users/assignments',
          method: 'GET',
        };
      },
    }),
    getAssignmentById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/assignments/${id}`,
          method: 'GET',
        };
      },
    }),
    submitAssignment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/assignments/submit/${id}`,
          method: 'PATCH',
        };
      },
    }),
    createProblem: builder.mutation({
      query: (data) => {
        return {
          url: 'users/problems/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteProblem: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/problems/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateProblem: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/problems/update/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getProblems: builder.mutation({
      query: () => {
        return {
          url: 'users/problems',
          method: 'GET',
        };
      },
    }),
    getProblemById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/problems/${id}`,
          method: 'GET',
        };
      },
    }),
    submitAnswer: builder.mutation({
      query: (data) => {
        return {
          url: `users/problems/answer`,
          method: 'POST',
          body: data,
        };
      },
    }),
    createQuiz: builder.mutation({
      query: (data) => {
        return {
          url: 'users/quizzes/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteQuiz: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/quizzes/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateQuiz: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/quizzes/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    resetQuiz: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/quizzes/reset/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getQuizzes: builder.mutation({
      query: () => {
        return {
          url: 'users/quizzes',
          method: 'GET',
        };
      },
    }),
    getQuizById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/quizzes/${id}`,
          method: 'GET',
        };
      },
    }),
    startQuiz: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/quizzes/start/${id}`,
          method: 'POST',
        };
      },
    }),
    submitQuiz: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/quizzes/submit/${id}`,
          method: 'PATCH',
        };
      },
    }),
    getAllVideos: builder.mutation({
      query: () => {
        return {
          url: `users/videos`,
          method: 'GET',
        };
      },
    }),
    saveVideoProgress: builder.mutation({
      query: (data) => {
        return {
          url: `users/videos/save-progress`,
          method: 'POST',
          body: data,
        };
      },
    }),
    uploadVideo: builder.mutation({
      query: (data) => {
        return {
          url: `users/videos/upload`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateVideo: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/videos/update/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteVideo: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/videos/delete/${id}`,
          method: 'DELETE',
          body: data,
        };
      },
    }),
    getUserVideos: builder.mutation({
      query: () => {
        return {
          url: `users/my-videos`,
          method: 'GET',
        };
      },
    }),
    streamVideoById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `videos/get/${id}`,
          method: 'GET',
        };
      },
    }),
    getDashboardStats: builder.mutation({
      query: () => {
        return {
          url: `users/dashboard/stats`,
          method: 'GET',
        };
      },
    }),
    getInstructorCourses: builder.mutation({
      query: () => {
        return {
          url: `instructors/courses`,
          method: 'GET',
        };
      },
    }),
    getInstructorQuiz: builder.mutation({
      query: ({ id }) => {
        return {
          url: `instructors/quiz/${id}`,
          method: 'GET',
        };
      },
    }),
    getInstructorAssignment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `instructors/assignment/${id}`,
          method: 'GET',
        };
      },
    }),
    getInstructorStudents: builder.mutation({
      query: () => {
        return {
          url: `instructors/students`,
          method: 'GET',
        };
      },
    }),
    getInstructorStudentQuiz: builder.mutation({
      query: ({ student_id, quiz_id }) => {
        return {
          url: `instructors/student/${student_id}/quiz/${quiz_id}`,
          method: 'GET',
        };
      },
    }),
    getInstructorStudentAssignment: builder.mutation({
      query: ({ student_id, assignment_id }) => {
        return {
          url: `instructors/student/${student_id}/assignment/${assignment_id}`,
          method: 'GET',
        };
      },
    }),
    scoreAnswer: builder.mutation({
      query: ({ answer_id, score }) => {
        return {
          url: `instructors/answer/score`,
          method: 'POST',
          body: { answer_id, score },
        };
      },
    }),
    getStudentCourses: builder.mutation({
      query: () => {
        return {
          url: `students/courses`,
          method: 'GET',
        };
      },
    }),
    getStudentProgrammes: builder.mutation({
      query: () => {
        return {
          url: `students/programmes`,
          method: 'GET',
        };
      },
    }),
    getStudentQuizzes: builder.mutation({
      query: () => {
        return {
          url: `students/quizzes`,
          method: 'GET',
        };
      },
    }),
    getStudentAssignments: builder.mutation({
      query: () => {
        return {
          url: `students/assignments`,
          method: 'GET',
        };
      },
    }),
    getStudentQuiz: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/quiz/${id}`,
          method: 'GET',
        };
      },
    }),
    getStudentAssignment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/assignment/${id}`,
          method: 'GET',
        };
      },
    }),
    getStudentQuizAnswers: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/quiz/answers/${id}`,
          method: 'GET',
        };
      },
    }),
    getStudentAssignmentAnswers: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/assignment/answers/${id}`,
          method: 'GET',
        };
      },
    }),
    getStudentQuizResults: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/quiz/result/${id}`,
          method: 'GET',
        };
      },
    }),
    getStudentAssignmentResults: builder.mutation({
      query: ({ id }) => {
        return {
          url: `students/assignment/result/${id}`,
          method: 'GET',
        };
      },
    }),
    createVacancy: builder.mutation({
      query: (data) => {
        return {
          url: 'users/vacancies/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteVacancy: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/vacancies/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateVacancy: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/vacancies/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    restoreVacancy: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/vacancies/restore/${id}`,
          method: 'PATCH',
        };
      },
    }),
    getVacancies: builder.mutation({
      query: () => {
        return {
          url: 'users/vacancies',
          method: 'GET',
        };
      },
    }),
    getVacancyById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/vacancies/${id}`,
          method: 'GET',
        };
      },
    }),
    createFinance: builder.mutation({
      query: (data) => {
        return {
          url: 'users/finances/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteFinance: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/finances/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateFinance: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/finances/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    restoreFinance: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/finances/restore/${id}`,
          method: 'PATCH',
        };
      },
    }),
    getFinances: builder.mutation({
      query: () => {
        return {
          url: 'users/finances',
          method: 'GET',
        };
      },
    }),
    getFinanceById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/finances/${id}`,
          method: 'GET',
        };
      },
    }),
    createResource: builder.mutation({
      query: (data) => {
        return {
          url: 'users/resources/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteResource: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/resources/delete/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateResource: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/resources/update/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    restoreResource: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/resources/restore/${id}`,
          method: 'PATCH',
        };
      },
    }),
    getResources: builder.mutation({
      query: () => {
        return {
          url: 'users/resources',
          method: 'GET',
        };
      },
    }),
    getResourceById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/resources/${id}`,
          method: 'GET',
        };
      },
    }),

    createSurvey: builder.mutation({
      query: (data) => {
        return {
          url: 'users/surveys/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteSurvey: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/surveys/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateSurvey: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `users/surveys/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getSurveys: builder.mutation({
      query: () => {
        return {
          url: 'users/surveys',
          method: 'GET',
        };
      },
    }),
    getSurveyById: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/surveys/${id}`,
          method: 'GET',
        };
      },
    }),

    createSurveyQuestion: builder.mutation({
      query: (data) => {
        return {
          url: 'users/surveys/questions',
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteSurveyQuestion: builder.mutation({
      query: ({ survey_id, question_id }) => {
        return {
          url: `users/surveys/${survey_id}/questions/${question_id}`,
          method: 'DELETE',
        };
      },
    }),
    updateSurveyQuestion: builder.mutation({
      query: ({ survey_id, question_id, data }) => {
        return {
          url: `users/surveys/${survey_id}/questions/${question_id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getSurveyQuestions: builder.mutation({
      query: () => {
        return {
          url: 'users/surveys/questions/all',
          method: 'GET',
        };
      },
    }),
    getSurveyQuestionById: builder.mutation({
      query: ({ survey_id, question_id }) => {
        return {
          url: `users/surveys/${survey_id}/questions/${question_id}`,
          method: 'GET',
        };
      },
    }),
    submitSurveyQuestionAnswer: builder.mutation({
      query: (data) => {
        return {
          url: `users/surveys/answers`,
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteSurveyQuestionAnswer: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/surveys/answers/${id}`,
          method: 'DELETE',
        };
      },
    }),
    updateSurveyQuestionAnswer: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/surveys/answers/${id}`,
          method: 'PATCH',
        };
      },
    }),
    getSurveyQuestionAnswer: builder.mutation({
      query: ({ id }) => {
        return {
          url: `users/surveys/answers/${id}`,
          method: 'GET',
        };
      },
    }),
    getAnalytics: builder.mutation({
      query: () => {
        return {
          url: `analytics/get`,
          method: 'GET',
        };
      },
    }),
    getSurveyQuestionAnswers: builder.mutation({
      query: () => {
        return {
          url: `users/surveys/answers/all`,
          method: 'GET',
        };
      },
    }),
    getCertificateSample: builder.mutation({
      query: ({ email }) => {
        return {
          url: `certificate/sample/${email}`,
          method: 'GET',
        };
      },
    }),
    getLevel1Certificate: builder.mutation({
      query: () => {
        return {
          url: `certificate/level1/get`,
          method: 'GET',
        };
      },
    }),
    getLevel2Certificate: builder.mutation({
      query: (data) => {
        return {
          url: `certificate/level2/get`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getStudent: builder.mutation({
      query: (id) => {
        return {
          url: `/users/level-1/user/${id}`,
          method: 'GET',
        };
      },
    }),
    updateStudent: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/users/level-1/user/edit/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getStudentNotifications: builder.mutation({
      query: (params) => {
        return {
          url: `/students/notifications`,
          method: 'GET',
          params: params,
        };
      },
    }),
    queryStudentNotifications: builder.query({
      query: (params) => {
        return {
          url: `/students/notifications`,
          method: 'GET',
          params: params,
        };
      },
    }),
    getStudentNotification: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/students/notifications/${id}`,
          method: 'GET',
        };
      },
    }),
    markNotification: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/students/notifications/${id}`,
          method: 'GET',
        };
      },
    }),
    getAnnouncements: builder.mutation({
      query: () => {
        return {
          url: `/users/admin/notifications`,
          method: 'GET',
        };
      },
    }),
    getAnnouncement: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/users/admin/notifications/${id}`,
          method: 'GET',
        };
      },
    }),
    createAnnouncement: builder.mutation({
      query: (data) => {
        return {
          url: `/users/admin/notifications/create`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateAnnouncement: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/users/admin/notifications/edit`,
          method: 'POST',
          body: { ...data, id },
        };
      },
    }),
    getLiveClasses: builder.mutation({
      query: () => {
        return {
          url: `/meet/list`,
          method: 'GET',
        };
      },
    }),
    getLiveClass: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/meet/show/${id}`,
          method: 'GET',
        };
      },
    }),
    createLiveClass: builder.mutation({
      query: (data) => {
        return {
          url: `/meet/create`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateLiveClass: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/meet/edit/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getPosts: builder.mutation({
      query: () => {
        return {
          url: `/forum/posts`,
          method: 'GET',
        };
      },
    }),
    getPost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/posts/${id}`,
          method: 'GET',
        };
      },
    }),
    deletePost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/posts/${id}`,
          method: 'DELETE',
        };
      },
    }),
    publishPost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/posts/publish/${id}`,
          method: 'PATCH',
        };
      },
    }),
    rejectPost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/posts/reject/${id}`,
          method: 'PATCH',
        };
      },
    }),
    restorePost: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/posts/restore/${id}`,
          method: 'PATCH',
        };
      },
    }),
    createPost: builder.mutation({
      query: (data) => {
        return {
          url: `/forum/posts`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updatePost: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/forum/posts/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getComments: builder.mutation({
      query: () => {
        return {
          url: `/forum/comments`,
          method: 'GET',
        };
      },
    }),
    getComment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/comments/${id}`,
          method: 'GET',
        };
      },
    }),
    pullLatestComments: builder.query({
      query: ({ id }) => {
        return {
          url: `/forum/comments/pull/${id}`,
          method: 'GET',
        };
      },
    }),
    deleteComment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/comments/${id}`,
          method: 'DELETE',
        };
      },
    }),
    replyComment: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/forum/comments/reply/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    restoreComment: builder.mutation({
      query: ({ id }) => {
        return {
          url: `/forum/comments/restore/${id}`,
          method: 'PATCH',
        };
      },
    }),
    createComment: builder.mutation({
      query: (data) => {
        return {
          url: `/forum/comments`,
          method: 'POST',
          body: data,
        };
      },
    }),
    updateComment: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/forum/comments/${id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetProfileMutation,
  useGetStatesQuery,
  useGetCountriesQuery,
  useCreateApplicationMutation,
  useGetApplicationsMutation,
  useGetSearchUsersMutation,
  useGetLevel2ApplicationsMutation,
  useGetAdminsMutation,
  useGetTeachersMutation,
  useCreateAdminMutation,
  useCreateTeacherMutation,
  useGetApplicationByIdMutation,
  useAdmitWithScholarshipByIdMutation,
  useAdmitWithoutScholarshipByIdMutation,
  useRejectToReapplyByIdMutation,
  useRejectByIdMutation,
  useAdmitWithScholarshipByIdLevel2Mutation,
  useAdmitWithoutScholarshipByIdLevel2Mutation,
  useRejectToReapplyByIdLevel2Mutation,
  useRejectByIdLevel2Mutation,
  useGetAdminByIdMutation,
  useGetTeacherByIdMutation,
  useMakePaymentMutation,
  useVerifyPaymentMutation,
  useRecordPaymentMutation,
  useCreateProgramMutation,
  useDeleteProgramMutation,
  useGetProgramsMutation,
  useGetProgramsLiteMutation,
  useGetProgramByIdMutation,
  useAssignInstructorToCourseMutation,
  useEnrollStudentForCourseMutation,
  useCreateCourseMutation,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
  useGetCoursesMutation,
  useGetCoursesLiteMutation,
  useUpdateProgramMutation,
  useGetCourseByIdMutation,
  useUpdateAvatarMutation,
  useStreamVideoByIdMutation,
  useGetStudentsMutation,
  useAddTopicToClassMutation,
  useRemoveTopicFromClassMutation,
  useDeleteTopicMutation,
  useUpdateTopicMutation,
  useGetClassesMutation,
  useGetClassByIdMutation,
  useCreateClassMutation,
  useDeleteClassMutation,
  useUpdateClassMutation,
  useGetTopicsMutation,
  useGetTopicsLiteMutation,
  useGetTopicByIdMutation,
  useCreateTopicMutation,
  useGetUserVideosMutation,
  useCreateQuizMutation,
  useDeleteQuizMutation,
  useUpdateQuizMutation,
  useResetQuizMutation,
  useGetQuizzesMutation,
  useGetQuizByIdMutation,
  useStartQuizMutation,
  useSubmitQuizMutation,
  useGetDashboardStatsMutation,
  useGetInstructorCoursesMutation,
  useGetInstructorStudentsMutation,
  useGetStudentCoursesMutation,
  useGetStudentAssignmentsMutation,
  useGetStudentProgrammesMutation,
  useGetStudentQuizzesMutation,
  useRemoveInstructorFromCourseMutation,
  useCreateAssignmentMutation,
  useDeleteAssignmentMutation,
  useUpdateAssignmentMutation,
  useGetAssignmentsMutation,
  useGetAssignmentByIdMutation,
  useSubmitAssignmentMutation,
  useCreateProblemMutation,
  useDeleteProblemMutation,
  useUpdateProblemMutation,
  useGetProblemsMutation,
  useGetProblemByIdMutation,
  useSubmitAnswerMutation,
  useGetInstructorQuizMutation,
  useGetInstructorAssignmentMutation,
  useGetStudentQuizMutation,
  useGetStudentAssignmentMutation,
  useDeleteProfileMutation,
  useGetIncompleteApplicationsMutation,
  useVerifyScholarshipCodeMutation,
  useVerifyScholarshipCodeLevel2Mutation,
  useGetAllVideosMutation,
  useUpdateVideoMutation,
  useUploadVideoMutation,
  useDeleteVideoMutation,
  useSaveVideoProgressMutation,
  useCreateVacancyMutation,
  useDeleteVacancyMutation,
  useGetVacanciesMutation,
  useGetVacancyByIdMutation,
  useUpdateVacancyMutation,
  useRestoreVacancyMutation,
  useCreateFinanceMutation,
  useDeleteFinanceMutation,
  useGetFinancesMutation,
  useGetFinanceByIdMutation,
  useUpdateFinanceMutation,
  useRestoreFinanceMutation,
  useCreateResourceMutation,
  useDeleteResourceMutation,
  useGetResourcesMutation,
  useGetResourceByIdMutation,
  useUpdateResourceMutation,
  useRestoreResourceMutation,
  useUpdateApplicationMutation,
  useGetInstructorStudentAssignmentMutation,
  useGetInstructorStudentQuizMutation,
  useScoreAnswerMutation,
  useGetStudentAssignmentAnswersMutation,
  useGetStudentAssignmentResultsMutation,
  useGetStudentQuizAnswersMutation,
  useGetStudentQuizResultsMutation,
  useCreateSurveyMutation,
  useDeleteSurveyMutation,
  useGetSurveyByIdMutation,
  useGetSurveysMutation,
  useUpdateSurveyMutation,
  useCreateSurveyQuestionMutation,
  useUpdateSurveyQuestionMutation,
  useDeleteSurveyQuestionMutation,
  useGetSurveyQuestionByIdMutation,
  useGetSurveyQuestionsMutation,
  useGetSurveyQuestionAnswerMutation,
  useGetSurveyQuestionAnswersMutation,
  useDeleteSurveyQuestionAnswerMutation,
  useUpdateSurveyQuestionAnswerMutation,
  useSubmitSurveyQuestionAnswerMutation,
  useGetAnalyticsMutation,
  useGetLevel1CertificateMutation,
  useGetLevel2CertificateMutation,
  useUpdateStudentMutation,
  useGetStudentMutation,
  useGetStudentNotificationsMutation,
  useQueryStudentNotificationsQuery,
  useGetStudentNotificationMutation,
  useGetAnnouncementMutation,
  useCreateAnnouncementMutation,
  useGetAnnouncementsMutation,
  useUpdateAnnouncementMutation,
  useCreateLiveClassMutation,
  useGetLiveClassesMutation,
  useGetLiveClassMutation,
  useUpdateLiveClassMutation,
  useMarkNotificationMutation,
  useCreatePostMutation,
  useGetPostMutation,
  useGetPostsMutation,
  useUpdatePostMutation,
  useDeletePostMutation,
  usePublishPostMutation,
  useRestorePostMutation,
  useRejectPostMutation,
  useCreateCommentMutation,
  useGetCommentMutation,
  useGetCommentsMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useReplyCommentMutation,
  useRestoreCommentMutation,
  usePullLatestCommentsQuery,
} = userApi;
