// import node module libraries
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
  Image,
  Card,
  Row,
  Col,
  ProgressBar,
  ListGroup,
  Badge,
  OverlayTrigger,
  Popover,
  Button,
} from 'react-bootstrap';

// import tippy tooltip
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale.css';

// import custom components
import Ratings from 'components/marketing/common/ratings/Ratings';
import LevelIcon from 'components/marketing/common/miscellaneous/LevelIcon';

// import utility file
import { numberWithCommas } from 'helper/utils';
import CardHeader from 'react-bootstrap/esm/CardHeader';

import { useSelector } from 'react-redux';
import { WarningModal } from 'pages/components/Modals/WarningModal';
import { truncateText, TruncateText } from 'pages/helper/formatText';
import { isSubstringInArray } from 'pages/helper/formatArray';
import {
  level1Img,
  level2Img,
  masterClassImg,
} from 'pages/assets/images/imageUrl';

const CourseCard = ({
  item,
  free,
  viewby,
  showprogressbar,
  extraclass,
  setSelected,
}) => {
  const { user } = useSelector((state) => state.userAuth);
  const [showWarning, setShowWarning] = useState(false);
  const [warningText, setWarningText] = useState(
    'You need to complete your application and get admitted to access this programme.'
  );
  const history = useHistory();
  const handleOverlayClick = (e) => {
    e.stopPropagation();
  };

  const GridView = () => {
    return (
      <Card
        className={`mb-2 card-hover ${extraclass} mx-2 
        `}
        style={{
          backgroundColor: 'rgba(245, 245, 245, 0.4)',
          opacity: !user?.application
            ? item?.name?.toLowerCase()?.includes('master')
              ? 1
              : 0.75
            : user?.programmes?.filter((x) => x?.id === item?.id)?.length > 0
            ? 1
            : 0.75,
        }}
        onClick={() => {
          // if (item?.name?.toLowerCase()?.includes('master')) {
          history.push('/info', item);
          window.scrollTo(0, 0);
          // } else if (
          //   !!user?.application &&
          //   user?.programmes?.filter((x) => x?.id === item?.id)?.length > 0
          // ) {
          //   history.push('/info', item);
          // } else {
          //   setWarningText(
          //     !user?.application
          //       ? 'You need to complete your application or get admitted to access this programme.'
          //       : 'You need to have a level 1 certification or get admitted to access this programme.'
          //   );
          //   setShowWarning(true);
          //   return;
          // }
        }}
      >
        <Card.Img
          style={{ height: '220px' }}
          className="card-img-top rounded-top-md src={item.image} img-fluid"
          src={
            item?.name?.toLowerCase().includes('introduction to') &&
            isSubstringInArray(' 1', [item?.nick_name])
              ? level1Img
              : item?.name?.toLowerCase().includes('speciali') &&
                isSubstringInArray(' 2', [item?.nick_name])
              ? level2Img
              : masterClassImg
          }
        />
        {/* <Link to={item.link}>
            <Image
              src={item.image}
              alt=''
              className='card-img-top rounded-top-md src={item.image} h-80'
            />
          </Link> */}
        {/* </CardHeader> */}
        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2 ">{item?.name}</h3>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <i className="far fa-clock me-1"></i>
              {item?.duration}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <LevelIcon level={item?.nick_name} />

              {truncateText(item?.description, 150)}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
        {/* Card Footer */}
        {/* <Card.Footer>
          <span className={`${showprogressbar ? '' : 'd-none'}`}>
            {' '}
            <ProgressBar
              variant='success'
              now={item.progress}
              className='mt-3'
              style={{height: '5px'}}
            />
          </span>
        </Card.Footer> */}
      </Card>
    );
  };

  return (
    <Fragment>
      <GridView />
      <WarningModal
        showModal={showWarning}
        setShowModal={setShowWarning}
        text={warningText}
      />
    </Fragment>
  );
};

// Specifies the default values for props
CourseCard.defaultProps = {
  free: false,
  viewby: 'grid',
  showprogressbar: false,
  extraclass: '',
};

// Typechecking With PropTypes
CourseCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
};

export default CourseCard;
