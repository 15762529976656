import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

function MatrixQuestion({ questions, options, answers, setAnswers }) {
  function handleAnswerChange(questionIndex, optionIndex) {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = options[optionIndex];
    setAnswers(newAnswers);
  }

  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th></th>
          {options.map((option, index) => (
            <th key={index}>{option}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {questions.map((question, questionIndex) => (
          <tr key={questionIndex}>
            <td>{question}</td>
            {options.map((option, optionIndex) => (
              <td key={optionIndex}>
                <input
                  type="radio"
                  name={`question-${questionIndex}`}
                  value={option}
                  checked={answers[questionIndex] === option}
                  onChange={() =>
                    handleAnswerChange(questionIndex, optionIndex)
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default MatrixQuestion;
