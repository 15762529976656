import React, { Fragment, useEffect, useState } from 'react';
import { Card, Modal, Button, Row, Spinner } from 'react-bootstrap';
import {
  useDeleteFinanceMutation,
  useGetAdminByIdMutation,
  useGetFinanceByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ListItem } from '../admissions/ApplicationDetails';
import AddNewFinance from './AddNewFinance';

const FinanceDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);

  const [getFinanceById, { isLoading }] = useGetFinanceByIdMutation();
  const [deleteFinance, { isLoading: isDeleting }] = useDeleteFinanceMutation();

  const fetchFinance = async () => {
    const res = await getFinanceById({ id: route_id });
    if (res?.data) {
      setDetails(res?.data?.data?.finance);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };
  const handleFinanceDelete = async () => {
    const res = await deleteFinance({ id: route_id });
    if (res?.data?.success) {
      toast.success(res?.error?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchFinance();
  }, [refresh]);

  if (isLoading || details?.length) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Finance Linkage
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {details?.title}
                </h4>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <ListItem title={'Company name'} children={details?.company_name} />
        <ListItem title={'Title'} children={details?.title} />
        <ListItem title={'Description'} children={details?.description} />
        <ListItem title={'Requirements'} children={details?.requirements} />
        <ListItem title={'Amount'} children={details?.amount} />

        <ListItem
          title={'Link'}
          children={
            <a href={details?.link} target="_blank" rel="noopener noreferrer">
              {details?.link}
            </a>
          }
        />
        <ListItem
          title={'Deadline'}
          children={
            details?.deadline &&
            moment(details?.deadline).format('ddd MMM DD, YYYY')
          }
        />
        <ListItem title={'Comment'} children={details?.comment} />

        <ListItem
          title={'Date created'}
          children={
            details?.created_at &&
            moment(details?.created_at).format('ddd MMM DD, YYYY')
          }
        />
        <ListItem
          title={'Date updated'}
          children={
            details?.updated_at &&
            moment(details?.updated_at).format('ddd MMM DD, YYYY')
          }
        />
      </Card>
      <div className="d-flex justify-content-between">
        <Button variant="primary" onClick={handleShow}>
          Edit
        </Button>
        <Button variant="secondary" onClick={handleDeleteShow}>
          Delete
        </Button>
        <AddNewFinance
          show={show}
          handleClose={handleClose}
          initialState={details}
          type={'edit'}
        />
        <Modal show={deleteShow} onHide={handleDeleteClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Finance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this Finance</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            {/*  Action Buttons  */}
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFinanceDelete}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default FinanceDetails;
