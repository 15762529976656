// import node module libraries
import { Fragment, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Modal } from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useCreateTeacherMutation } from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';

const AddNewCourse = ({ show, handleClose, size = 'lg' }) => {
  const contentTypeOptions = [
    { id: 1, value: 'liveclass', label: 'Live classes' },
    { id: 2, value: 'video', label: 'Videos' },
    { id: 3, value: 'note', label: 'Notes' },
    { id: 4, value: 'quiz', label: 'Quiz' },
    { id: 5, value: 'assignment', label: 'Assignment' },
  ];
  const programOptions = [
    { id: 1, value: 'masterclass', label: 'Masterclass' },
    { id: 2, value: 'level1', label: 'Level 1' },
    { id: 3, value: 'level2', label: 'Level 2' },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .min(5, 'At least 5 letters long'),
    description: Yup.string()
      .required('Description is required')
      .min(20, 'At least 20 letters long'),
    contentTypes: Yup.array()
      .required('Content type is required')
      .typeError('Content type is required'),
    program: Yup.object()
      .required('Programme is required')
      .typeError('Programme is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      contentTypes: '',
      program: '',
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      // handleCourseCreation(values);
    },
  });

  const [createTeacher, { isLoading }] = useCreateTeacherMutation();

  const handleCourseCreation = async (values) => {
    const { title, description, contentTypes } = values;
    const res = await createTeacher({
      title,
      description,
      contentTypes,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Course</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Course title"
                required
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                onChange={formik.handleChange}
              />
              {formik.errors.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Programme</Form.Label>
              <MultiSelectDropdown
                required
                options={programOptions}
                placeholder="Programme"
                selected={formik.values?.program}
                setSelected={(e) => {
                  formik.setFieldValue('program', e);
                }}
              />
              {formik.errors.program && (
                <p className="fs-8 text-danger mb-0">{formik.errors.program}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Content types</Form.Label>
              <MultiSelectDropdown
                required
                isMulti={true}
                options={contentTypeOptions}
                placeholder="Course Content Types"
                selected={formik.values?.contentTypes}
                setSelected={(e) => {
                  formik.setFieldValue('contentTypes', e);
                }}
              />
              {formik.errors.contentTypes && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.contentTypes}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-contentTypes-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading}
        >
          Add Course
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewCourse;
