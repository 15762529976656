export const flattenedArray = (originalArray) => {
  return originalArray?.reduce((acc, innerArray) => {
    return [...acc, ...innerArray];
  }, []);
};

export function removeDeletedObjs(arr) {
  return arr?.filter((obj) => obj.deleted_at === null) || [];
}

export function getPropertyArray(array, property) {
  return array?.map(function (obj) {
    return obj[property];
  });
}

export function isSubstringInArray(substring, stringArray) {
  if (Array.isArray(substring)) {
    substring = substring.join(' ');
  }
  if (substring) {
    const upperCaseSubstring = substring.toUpperCase();
    for (let i = 0; i < stringArray?.length; i++) {
      const upperCaseString = stringArray[i]?.toUpperCase();
      if (
        upperCaseString?.includes(upperCaseSubstring) ||
        upperCaseSubstring?.includes(upperCaseString)
      ) {
        return true;
      }
    }
    return false;
  }

  return false;
}

export function arrangedMasterClassVideos(array) {
  return [...array]?.sort((a, b) => {
    const numA = parseInt(a?.title?.match(/\d+$/)[0]);
    const numB = parseInt(b?.title?.match(/\d+$/)[0]);
    return numA - numB;
  });
}

export function sortWeeksArray(array) {
  return [...array]?.sort((a, b) => {
    const weekA = parseInt(a?.match(/(\d+)/)[1]);
    const weekB = parseInt(b?.match(/(\d+)/)[1]);
    return weekA > weekB || weekA - weekB || a?.localeCompare(b);
  });
}

export function sortAscending(arr) {
  return [...arr]?.sort((a, b) => a?.localeCompare(b));
}

export const sortedDataByCreatedAt = (data) =>
  [...data]?.sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

export function sortByProperty(array, property, order = 'asc') {
  const sortOrder = order === 'desc' ? -1 : 1;
  if (array?.length == 0) {
    return array;
  } else {
    return [...array]?.sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];
      if (aValue < bValue) {
        return -1 * sortOrder;
      }
      if (aValue > bValue) {
        return 1 * sortOrder;
      }
      return 0;
    });
  }
}

export function generateNaturalNumbers(start, end) {
  let result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function updateObjects(arr, condition, propName, propValue) {
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    if (condition(arr[i])) {
      let newObj = { ...arr[i], [propName]: propValue };
      newArr.push(newObj);
    } else {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

export function getAdjacentObject(
  array,
  currentPropValue,
  propName,
  direction
) {
  const currentIndex = array.findIndex(
    (obj) => obj[propName] === currentPropValue
  );
  const adjacentIndex =
    direction === 'next' ? currentIndex + 1 : currentIndex - 1;
  return array[adjacentIndex] || null;
}

export function isLastObjectByProperty(arr, obj, prop) {
  const lastIndex = arr?.length - 1;
  const lastElement = arr[lastIndex];
  return lastElement[prop] === obj[prop];
}

export function getFilenames(fileList) {
  let filenames = [];
  for (let i = 0; i < fileList.length; i++) {
    filenames.push(fileList[i].name);
  }
  return filenames;
}

export function extractPropsByInputArray(arr, inPropArr, inProp, outProp) {
  const extProps = [];
  for (const obj of arr) {
    if (inPropArr?.includes(obj[inProp])) {
      extProps?.push(obj[outProp]);
    }
  }
  return extProps;
}

export function areFileListsEqual(fileList1, fileList2) {
  if (
    (fileList1 == '' || fileList1 == undefined) &&
    (fileList2 == '' || fileList2 == undefined)
  ) {
    return true;
  }
  if (fileList1?.length !== fileList2?.length) {
    return false;
  }

  for (let i = 0; i < fileList1?.length; i++) {
    const file1 = fileList1?.item(i);
    let found = false;
    for (let j = 0; j < fileList2?.length; j++) {
      const file2 = fileList2?.item(j);
      if (file1?.name === file2?.name && file1?.size === file2?.size) {
        found = true;
        break;
      }
    }
    if (!found) {
      return false;
    }
  }

  return true;
}

export function sortByTagName(array) {
  return [...array].sort((a, b) => {
    const tagA = a?.tags[0]?.name?.toUpperCase();
    const tagB = b?.tags[0]?.name?.toUpperCase();

    if (tagA < tagB) {
      return -1;
    }
    if (tagA > tagB) {
      return 1;
    }

    return 0;
  });
}

export function parseRelativeTime(timeAgo) {
  const timeParts = timeAgo.split(' ');
  const timeValue = parseInt(timeParts[0]);
  const timeUnit = timeParts[1];

  const now = new Date();

  if (timeUnit.includes('minute')) {
    now.setMinutes(now.getMinutes() - timeValue);
  } else if (timeUnit.includes('hour')) {
    now.setHours(now.getHours() - timeValue);
  } else if (timeUnit.includes('day')) {
    now.setDate(now.getDate() - timeValue);
  } else if (timeUnit.includes('week')) {
    now.setDate(now.getDate() - timeValue * 7);
  } else if (timeUnit.includes('month')) {
    now.setMonth(now.getMonth() - timeValue);
  } else if (timeUnit.includes('year')) {
    now.setFullYear(now.getFullYear() - timeValue);
  }

  return Math.floor(now.getTime() / 1000);
}

export function sortAndRemoveDuplicates(arr) {
  const updatedArray = arr.map((item) => ({
    ...item,
    parsed_created_at: parseRelativeTime(item.created_at),
  }));

  updatedArray.sort((a, b) => b.parsed_created_at - a.parsed_created_at);

  const uniqueMap = new Map();
  const uniqueArray = [];

  for (const item of updatedArray) {
    if (!uniqueMap.has(item.id)) {
      uniqueMap.set(item.id, true);
      uniqueArray.push(item);
    }
  }

  return uniqueArray || [];
}
