import { Button, Image, Modal } from 'react-bootstrap';
import WarnSign from 'pages/assets/images/warning-sign.png';

export const WarningModal = ({
  setShowModal,
  showModal,
  size = 'sm',
  text,
  title = 'Restricted',
}) => {
  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size={size}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Image style={{ alignSelf: 'center' }} src={WarnSign} width="200px" />
        {Array.isArray(text) ? (
          text.map((str, index) => (
            <p key={index} className="mb-0">
              {str}
            </p>
          ))
        ) : (
          <p className="text-center">{text}</p>
        )}
      </Modal.Body>
    </Modal>
  );
};
