// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Modal,
  Toast,
} from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useCreateProgramMutation,
  useCreateTeacherMutation,
  useUpdateProgramMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const AddNewProgram = ({
  show,
  handleClose,
  size = 'lg',
  type = 'add',
  initialState,
}) => {
  const parentOptions = [{ value: 'academic', label: 'Academic' }];

  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required').typeError('Text expected'),
    description: Yup.string().required('Required').typeError('Text expected'),
    // duration: Yup.number('Number expected')
    //   .required('Duration required')
    //   .typeError('Number expected'),
    // range: Yup.string()
    //   .required('Pick an interval')
    //   .typeError('Pick an interval'),
    // starts: Yup.string().required('Required').typeError('Text expected'),
    // price: Yup.number('Number expected')
    //   .required('Required')
    //   .typeError('Number expected'),
    // nick_name: Yup.string().required('Required').typeError('Text expected'),
  });

  const formik = useFormik({
    initialValues: {
      name: initialState?.name || '',
      description: initialState?.description || '',
      duration: initialState?.duration?.split(' ')[0] || '',
      starts:
        (initialState?.starts &&
          moment(initialState?.starts).format('YYYY-MM-DD')) ||
        '',
      price:
        (initialState?.price &&
          (typeof initialState?.price == 'number'
            ? initialState?.price / 100
            : initialState?.price?.slice(0, -3))) ||
        '',
      nick_name: initialState?.nick_name || '',
      range: initialState?.duration?.split(' ')[1] || '',
    },
    validateOnMount: false,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema,
    onSubmit: (values) => {
      handleProgramCreation(values);
    },
  });

  const [createProgram, { isLoading }] = useCreateProgramMutation();
  const [updateProgram, { isLoading: isUpdating }] = useUpdateProgramMutation();

  const handleProgramCreation = async (values) => {
    const { name, description, starts, duration, range, price, nick_name } =
      values;

    if (type === 'add') {
      const res = await createProgram({
        name,
        description,
        starts,
        duration: duration + ' ' + range,
        price,
        nick_name,
      });
      if (res?.data?.success) {
        toast.success(res?.data?.message || 'Successful!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      } else {
        toast.error(res?.error?.data?.message || 'Failed!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const res = await updateProgram({
        data: {
          name,
          description,
          starts,
          duration: duration + ' ' + range,
          price,
          nick_name,
        },
        id: initialState?.id,
      });
      if (res?.data?.success) {
        toast.success(res?.data?.message || 'Successful!', {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      } else {
        toast.error(res?.error?.data?.message || 'Failed!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type !== 'add' ? 'Edit Program' : 'Create New Program'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                id="name"
                placeholder="e.g. Introduction to Film-making..."
                required
                value={formik?.values?.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <p className="fs-8 text-danger mb-0">{formik.errors.name}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nick Name</Form.Label>
              <Form.Control
                type="text"
                id="nick_name"
                placeholder="e.g Level 1, Level 2, Level 3..."
                required
                value={formik?.values?.nick_name}
                onChange={formik.handleChange}
              />
              {formik.errors.nick_name && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.nick_name}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                id="price"
                placeholder="e.g 25000, 125000..."
                required
                value={formik?.values?.price}
                onChange={formik.handleChange}
              />
              {formik.errors.price && (
                <p className="fs-8 text-danger mb-0">{formik.errors.price}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik?.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                type="date"
                id="startDate"
                min={new Date().toISOString().split('T')[0]}
                placeholder="Start Date"
                required
                value={formik?.values?.starts}
                onChange={(e) => {
                  formik.setFieldValue('starts', e?.target?.value);
                }}
              />
              {formik.errors.starts && (
                <p className="fs-8 text-danger mb-0">{formik.errors.starts}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Duration </Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  id="duration"
                  placeholder="Duration"
                  required
                  value={initialState?.duration?.split(' ')[0]}
                  onChange={formik.handleChange}
                />
                <Form.Control
                  as="select"
                  id="range"
                  placeholder="Interval"
                  required
                  value={initialState?.duration?.split(' ')[1]}
                  onChange={formik.handleChange}
                >
                  <option value={null}>Pick an interval</option>
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="months">Months</option>
                  <option value="years">Years</option>
                </Form.Control>
              </InputGroup>
              {(formik.errors.duration || formik.errors.range) && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.duration || formik.errors.range}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading || isUpdating}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading || isUpdating}
        >
          {type !== 'add' ? 'Confirm' : 'Add New Program'}
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewProgram;
