import { Loader } from 'pages/components/Loader';
import {
  areFileListsEqual,
  extractPropsByInputArray,
  generateNaturalNumbers,
  getAdjacentObject,
  getFilenames,
  getNextObjectById,
  isLastObjectByProperty,
  isSubstringInArray,
  sortByProperty,
  updateObjects,
} from 'pages/helper/formatArray';
import {
  useEnrollStudentForCourseMutation,
  useGetAssignmentByIdMutation,
  useGetStudentAssignmentAnswersMutation,
  useGetStudentAssignmentMutation,
  useGetStudentAssignmentResultsMutation,
  // useStartAssignmentMutation,
  useSubmitAnswerMutation,
  useSubmitAssignmentMutation,
} from 'pages/redux/features/user/userApi';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { Radio, RadioGroup } from 'react-radio-group';
import CheckboxGroup from 'react-checkbox-group';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import UnansweredModal from './UnansweredModal';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import ProgramDetails from 'pages/kapAdmin/programmes/ProgramDetails';
import { capitalize } from 'pages/helper/formatText';

const Assignment = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state?.userAuth);
  const id = location?.state?.assignment?.id;
  const title = location?.state?.assignment?.title;
  const course_id = location?.state?.topic?.course_id;
  const selectedClass = location?.state?.class;
  const topic = location?.state?.topic;
  const program = location?.state?.program;
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [results, setResults] = useState({});
  const [answers, setAnswers] = useState([]);
  const [problems, setProblems] = useState([]);
  const [problemDetails, setProblemDetails] = useState(null);
  const [questionKey, setQuestionKey] = useState(0);
  const [multiSelectAnswer, setMultiSelectAnswer] = useState([]);
  const [unansweredQuestions, setUnansweredQuestion] = useState(null);
  const [showUnanswered, setShowUnanswered] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  const [totalLeft, setTotalLeft] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);

  const [submitAnswer, { isLoading: isSubmittingAnswer }] =
    useSubmitAnswerMutation();
  // const [startAssignment, { isLoading: isStartingAssignment }] =
  //   useStartAssignmentMutation();
  const [submitAssignment, { isLoading: isSubmittingAssignment }] =
    useSubmitAssignmentMutation();
  const [getStudentAssignment, { isLoading }] =
    useGetStudentAssignmentMutation();
  const [getAssignmentById, { isLoading: isLoadingAssignment }] =
    useGetAssignmentByIdMutation();
  const [enrollStudentForCourse, { isLoading: isEnrolling }] =
    useEnrollStudentForCourseMutation();
  const [getStudentAssignmentAnswers, { isLoading: isGettingAnswers }] =
    useGetStudentAssignmentAnswersMutation();
  const [getStudentAssignmentResults, { isLoading: isGettingResults }] =
    useGetStudentAssignmentResultsMutation();

  const handleUnansweredClose = () => {
    setShowUnanswered(false);
  };

  const fetchStudentAssignmentResults = async () => {
    const res = await getStudentAssignmentResults({ id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Submitted successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setResults(res?.data?.data?.result);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // await fetchAssignmentById();
    }
  };
  const fetchStudentAssignmentAnswers = async () => {
    const res = await getStudentAssignmentResults({ id });
    // const res = await getStudentAssignmentAnswers({ id });
    if (res?.data?.success) {
      setIsSubmitted(true);
      setShowTimer(false);
      setAnswers(res?.data?.data?.answer);
      setAssignmentDetails(res?.data?.data?.assignment);
      setResults(res?.data?.data);
      if (res?.data?.data?.answers?.length > 0) {
        const problemExt = res?.data?.data?.answers?.map((x) => {
          return {
            ...x?.problem,
            ...x,
          };
        });
        let extProblems = sortByProperty([...problemExt], 'number', 'asc');
        for (let i = 0; i < extProblems.length; i++) {
          const ans = res?.data?.data?.answers?.find(
            (x) => x?.problem_id == extProblems[i]?.problem?.id
          );
          let newObj = {
            ...extProblems[i],
            correctAnswer:
              extProblems[i]?.problem?.type == 'essay'
                ? null
                : ans?.problem?.options?.find(
                    (y) => y?.correct == '1' || y?.correct == 1
                  ) || null,
            answer:
              extProblems[i]?.problem?.type == 'essay'
                ? ans?.essay_response
                : extProblems[i]?.problem?.type == 'multichoice'
                ? ans?.problem?.options?.find((y) => y?.id == ans?.option_id)
                : extProblems[i]?.problem?.type == 'binary'
                ? ans?.binary_answer
                : extProblems[i]?.problem?.type == 'multiselect'
                ? Array.isArray(
                    ans?.problem?.options?.find((y) => y?.id == ans?.option_id)
                  )
                  ? ans?.problem?.options
                      ?.find((y) => y?.id == ans?.option_id)
                      ?.map((x) => {
                        return x?.text;
                      })
                  : typeof ans?.problem?.options?.find(
                      (y) => y?.id == ans?.option_id
                    ) == 'object'
                  ? [
                      ans?.problem?.options?.find(
                        (y) => y?.id == ans?.option_id
                      )?.text,
                    ]
                  : []
                : null,
            answerScore: ans?.score || 0,
            answer_id: ans?.id,
            files: '',
            totalScore: ans?.problem?.score,
          };
          extProblems[i] = newObj;
        }

        setProblems(extProblems);
        setQuestionKey(questionKey || 1);
        setProblemDetails(
          problemDetails
            ? extProblems?.find((x) => problemDetails?.id == x?.id)
            : extProblems[0]
        );
      }
      // await fetchStudentAssignmentResults();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
      await fetchAssignmentById();
    }
  };
  const handleAssignmentSubmission = async () => {
    const res = await submitAssignment({ id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Submitted successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchAssignmentById = async () => {
    const res = await getStudentAssignment({ id });
    if (res?.error?.status == 401) {
      const res1 = await enrollStudentForCourse({
        user_id: user?.id,
        course_id,
      });

      if (res1?.data?.success) {
        fetchAssignmentById();
      }
    } else if (res?.data?.success) {
      setAssignmentDetails(res?.data?.data?.assignment);
      if (res?.data?.data?.assignment?.problems?.length > 0) {
        let extProblems = sortByProperty(
          [...res?.data?.data?.assignment?.problems],
          'number',
          'asc'
        );
        for (let i = 0; i < extProblems.length; i++) {
          let newObj = {
            ...extProblems[i],
            answer: extProblems[i]?.type == 'multiselect' ? [] : null,
            files: '',
          };
          extProblems[i] = newObj;
        }
        setProblems(extProblems);
        setQuestionKey(1);
        setProblemDetails(res?.data?.data?.assignment?.problems[0]);
      }
      // const res2 = await startAssignment({ id: id });
      // if (
      //   !res2?.data?.success &&
      //   !isSubstringInArray('User already started', [
      //     res2?.error?.data?.message || '',
      //   ])
      // ) {
      //   toast.error(res2?.error?.data?.message || 'Error!', {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   history.goBack();
      // }
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    setTotalLeft(
      problems?.filter(
        (x) =>
          (!x?.answer && x?.type != 'multiselect') ||
          (x?.type == 'multiselect' &&
            Array.isArray(x?.answer) &&
            x?.answer?.length == 0)
      )?.length
    );
  }, [problems]);

  useEffect(() => {
    id && user && fetchStudentAssignmentAnswers();
  }, [id]);

  useEffect(() => {
    if (assignmentDetails?.allowed_time) {
      const startTime = localStorage.getItem('startTimeQ' + id);
      if (startTime) {
        const elapsedTime = Date.now() - parseInt(startTime);
        const initialTime = assignmentDetails?.allowed_time * 1000;

        const timeLeft = Math.max(0, initialTime - elapsedTime);
        setRemainingTime(timeLeft);
      } else {
        const initialTime = assignmentDetails?.allowed_time * 1000;
        localStorage.setItem('startTimeQ' + id, Date.now().toString());
        setRemainingTime(initialTime);
      }
    }
  }, [assignmentDetails?.allowed_time]);

  useEffect(() => {
    const timer = setInterval(() => {
      const timeLeft = Math.max(0, remainingTime - 1000);
      setRemainingTime(timeLeft);
    }, 1000);
    return () => clearInterval(timer);
  }, [remainingTime]);

  const hours = Math.floor(remainingTime / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  const handleAnswerChange = (answer) => {
    setProblemDetails({ ...problemDetails, answer });
  };

  const fileInputRef = useRef(null);

  const handleDefaultFileNames = () => {
    const filenames = getFilenames(problemDetails?.files);
    const files = filenames?.map((filename) => new File([], filename));
    fileInputRef.current.defaultValue = '';
    // fileInputRef.current.files = files;
  };

  const handleDeleteFile = (fileToDelete) => {
    const filesArray = Array.from(problemDetails?.files);
    const filteredFilesArray = filesArray.filter(
      (file) => file.name !== fileToDelete.name
    );
    const newFileList = createFileList(filteredFilesArray);
    const updatedObject = Object.assign({}, problemDetails, {
      files: newFileList,
    });
    setProblemDetails(updatedObject);
  };

  const createFileList = (array) => {
    const list = new DataTransfer();
    for (let i = 0; i < array.length; i++) {
      list.items.add(array[i]);
    }
    return list.files;
  };

  useEffect(() => {
    problemDetails?.id && problemDetails?.files && handleDefaultFileNames();
  }, [problemDetails?.id]);

  const handleAnswerSubmission = async (problem) => {
    let answer = {};
    if (problem?.type == 'essay') {
      answer.problem_id = problem?.id;
      answer.essay_response = problem?.answer;
      if (problem?.has_attachment == 1) {
        answer.attachment = problem?.has_attachment;
        answer.files = problem?.files;
      }
    } else if (problem?.type == 'binary') {
      answer.problem_id = problem?.id;
      answer.binary_answer = problem?.answer?.toLowerCase();
      if (problem?.has_attachment == 1) {
        answer.attachment = problem?.has_attachment;
        answer.files = problem?.files;
      }
    } else if (problem?.type == 'multiselect') {
      answer.problem_id = problem?.id;
      answer.option_id = extractPropsByInputArray(
        problem?.options,
        problem?.answer,
        'text',
        'id'
      );
      if (problem?.has_attachment == 1) {
        answer.attachment = problem?.has_attachment;
        answer.files = problem?.files;
      }
    } else {
      answer.problem_id = problem?.id;
      answer.option_id = problem?.options?.find(
        (x) => x?.text == problem?.answer
      )?.id;
      if (problem?.has_attachment == 1) {
        answer.attachment = problem?.has_attachment;
        answer.files = problem?.files;
      }
    }
    const formData = objectToFormData(answer);

    logFormData(formData);

    const res = await submitAnswer(formData);
    if (!res?.data?.success) {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
      isSubstringInArray('assignment time is already over', [
        res?.error?.data?.message || '',
      ]) && history.goBack();
    }
  };

  const handleNextQuestion = async () => {
    let newProb = problems;
    if (
      problemDetails?.answer !=
      problems?.find((obj) => obj?.id == problemDetails?.id)?.answer
    ) {
      newProb = updateObjects(
        newProb,
        (obj) => obj?.id == problemDetails?.id,
        'answer',
        problemDetails?.answer
      );
      setProblems(newProb);
    }
    if (!!problemDetails?.files) {
      newProb = updateObjects(
        newProb,
        (obj) => obj?.id == problemDetails?.id,
        'files',
        problemDetails?.files
      );
      setProblems(newProb);
    }
    if (
      problemDetails?.answer !=
        problems?.find((obj) => obj?.id == problemDetails?.id)?.answer ||
      !areFileListsEqual(
        problemDetails?.files,
        problems?.find((obj) => obj?.id == problemDetails?.id)?.files
      )
    ) {
      await handleAnswerSubmission(problemDetails);
    }
    const nextProb = getAdjacentObject(
      problems,
      problemDetails?.id,
      'id',
      'next'
    );
    if (nextProb) {
      setQuestionKey(nextProb?.number);
      setProblemDetails(problems?.find((y) => y?.number == nextProb?.number));
    }
  };

  if (
    isLoading ||
    isLoadingAssignment ||
    isEnrolling ||
    !assignmentDetails ||
    !problems
  ) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Container className="py-lg-5 py-5 overflow-auto min-vh-100">
        <Row className="mb-2 mb-xl-0">
          <Card className="mb-5">
            <Card.Body>
              <Link
                className="d-flex justify-content-between align-items-center"
                to={`programme/${program?.id}`}
              >
                <h3 className="fw-semi-bold">{program?.name}</h3>
              </Link>
            </Card.Body>
          </Card>
          <Card className="mb-2">
            <Card.Body>
              <div className="d-flex align-items-center">
                <p className="text-uppercase">{selectedClass?.title}</p>
                <p style={{ marginLeft: '10px', marginRight: '10px' }}>
                  &nbsp;{'->'}&nbsp;
                </p>
                <p className="text-uppercase">{topic?.title}</p>
              </div>
              <Row>
                <Col>
                  <h3 className="fw-semi-bold">{title}</h3>
                </Col>
                {results?.total_score ? (
                  <Col md="auto">
                    <Row
                      style={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                      md="auto"
                    >
                      <p className="mb-0 fs-4 fw-semi-bold d-flex">
                        <p className="mb-0 text-black">Total Score:&nbsp;</p>
                        {`${results?.total_score}/${results?.obtainable_score}`}
                      </p>
                    </Row>
                    <Row
                      style={{
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                      md="auto"
                    >
                      <p className="mb-0 fs-4 fw-semi-bold d-flex">
                        <p className="mb-0 text-black">Remarks:&nbsp;</p>
                        {`${results?.remark}`}
                      </p>
                    </Row>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <h4>
            Questions {questionKey}/{problems?.length || '0'}
          </h4>
          {assignmentDetails?.allowed_time && showTimer && (
            <h4>{`${hours}:${minutes.toString().padStart(2, '0')}:${seconds
              .toString()
              .padStart(2, '0')}`}</h4>
          )}
        </div>
        <Row>
          {problems?.map((x, index) => (
            <Button
              className="justify-content-center"
              style={{
                width: 40,
                height: 40,
                marginRight: 15,
                marginBottom: 15,
                padding: 0,
                backgroundColor:
                  questionKey == x?.number
                    ? '#e0bf00'
                    : (x?.answer && x?.type != 'multiselect') ||
                      (x?.type == 'multiselect' &&
                        Array.isArray(x?.answer) &&
                        x?.answer?.length > 0)
                    ? '#219653'
                    : '#EDF2F7',
                color:
                  (x?.answer && x?.type != 'multiselect') ||
                  (x?.type == 'multiselect' &&
                    Array.isArray(x?.answer) &&
                    x?.answer?.length > 0)
                    ? 'white'
                    : 'black',
              }}
              variant="transparent"
              onClick={() => {
                setQuestionKey(x?.number);
                setProblemDetails(
                  problems?.find((y) => y?.number == x?.number)
                );
              }}
            >
              {x?.number}
            </Button>
          ))}
        </Row>
        <Row className="mt-3 mb-xl-0">
          <Card className="mb-2">
            <Card.Title className="mt-4 mx-3 mb-0">
              <h4 className="fw-semi-bold">{problemDetails?.statement}</h4>
            </Card.Title>
            <Card.Body className="mt-0" style={{ minHeight: 250 }}>
              {problemDetails?.type == 'binary' && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={capitalize(problemDetails?.answer)}
                  onChange={(e) => {
                    handleAnswerChange(e);
                  }}
                  disabled={isSubmitted}
                >
                  {problemDetails?.options?.map((y) => (
                    <Col className="d-flex align-item-center">
                      <Radio
                        key={uuid()}
                        className="my-radio"
                        value={y?.text}
                        style={{
                          width: '18px',
                          height: '18px',
                          marginTop: '3px',
                          '--active-color': 'green',
                        }}
                        disabled={isSubmitted}
                      />
                      <p className=" fs-4 fw-semi-bold mx-3">{y?.text}</p>
                    </Col>
                  ))}
                </RadioGroup>
              )}
              {problemDetails?.type == 'multichoice' && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={
                    problemDetails?.answer?.text || problemDetails?.answer
                  }
                  onChange={(e) => {
                    handleAnswerChange(e);
                  }}
                  disabled={isSubmitted}
                >
                  {problemDetails?.options?.map((y) => (
                    <Col className="d-flex align-item-center">
                      <Radio
                        key={uuid()}
                        className="my-radio"
                        value={y?.text}
                        style={{
                          width: '18px',
                          height: '18px',
                          marginTop: '3px',
                          '--active-color': 'green',
                        }}
                        disabled={isSubmitted}
                      />
                      <p className=" fs-4 fw-semi-bold mx-3">{y?.text}</p>
                    </Col>
                  ))}
                </RadioGroup>
              )}
              {problemDetails?.type == 'essay' && (
                <Form.Control
                  as="textarea"
                  rows={10}
                  id="answer"
                  placeholder="Answer"
                  required
                  disabled={isSubmitted}
                  value={problemDetails?.answer}
                  onChange={(e) => handleAnswerChange(e?.target?.value)}
                />
              )}
              {problemDetails?.type == 'multiselect' && (
                <CheckboxGroup
                  key={uuid()}
                  name="checkboxGroup"
                  value={problemDetails?.answer}
                  onChange={(e) => {
                    setProblemDetails({ ...problemDetails, answer: e });
                  }}
                  disabled={isSubmitted}
                >
                  {(Checkbox) => (
                    <>
                      {problemDetails?.options?.map((y) => (
                        <Col className="d-flex align-item-center">
                          <Checkbox
                            key={uuid()}
                            className="my-checkbox"
                            value={y?.text}
                            style={{
                              width: '18px',
                              height: '18px',
                              marginTop: '3px',
                              '--active-color': 'green',
                            }}
                            disabled={isSubmitted}
                          />
                          <p className=" fs-4 fw-semi-bold mx-3">{y?.text}</p>
                        </Col>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              )}

              {problemDetails?.has_attachment &&
              problemDetails?.has_attachment != 0 ? (
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Attachments</Form.Label>
                  {Array.from(problemDetails?.files).map((file) => (
                    <div className="me-3 mb-3 d-flex align-items-center justify-content-between">
                      <p className="mb-0">{file.name}</p>
                      <i
                        className="fe fe-trash"
                        onClick={() => handleDeleteFile(file)}
                      ></i>
                    </div>
                  ))}
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    disabled={isSubmitted}
                    id={problemDetails?.number}
                    key={problemDetails?.number}
                    multiple
                    onChange={(e) => {
                      setProblemDetails({
                        ...problemDetails,
                        files: e.target.files,
                      });
                    }}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
              {isSubmitted && problemDetails?.correctAnswer?.text && (
                <Row
                  style={{ justifyContent: 'flex-end', alignItems: 'center' }}
                  md="auto"
                >
                  <p className="mt-2 fs-4 fw-semi-bold d-flex">
                    <p className="mb-0 text-black">Correct answer:&nbsp;</p>
                    {problemDetails?.correctAnswer?.text || ''}
                  </p>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Row>
        {problemDetails && !isSubmitted && (
          <Col className="d-flex justify-content-between mt-4">
            <Button
              onClick={() => {
                const prevProb = getAdjacentObject(
                  problems,
                  problemDetails?.id,
                  'id',
                  'prev'
                );
                if (prevProb) {
                  setQuestionKey(prevProb?.number);
                  setProblemDetails(
                    problems?.find((y) => y?.number == prevProb?.number)
                  );
                }
              }}
              variant="secondary"
              disabled={
                isSubmittingAssignment || isSubmittingAnswer || isLoading
              }
            >
              Previous
            </Button>
            <Button
              disabled={
                isSubmittingAssignment || isSubmittingAnswer || isLoading
              }
              onClick={
                isLastObjectByProperty(problems, problemDetails, 'id') &&
                ((problems?.find((y) => y?.id == problemDetails?.id)?.answer &&
                  problems?.find((y) => y?.id == problemDetails?.id)?.type !=
                    'multiselect') ||
                  (problems?.find((y) => y?.id == problemDetails?.id)?.type ==
                    'multiselect' &&
                    Array.isArray(
                      problems?.find((y) => y?.id == problemDetails?.id)?.answer
                    ) &&
                    problems?.find((y) => y?.id == problemDetails?.id)?.answer
                      ?.length > 0)) &&
                !(
                  problemDetails?.answer !=
                    problems?.find((obj) => obj?.id == problemDetails?.id)
                      ?.answer ||
                  !areFileListsEqual(
                    problemDetails?.files,
                    problems?.find((obj) => obj?.id == problemDetails?.id)
                      ?.files
                  )
                )
                  ? () => setShowUnanswered(true)
                  : handleNextQuestion
              }
            >
              {isLastObjectByProperty(problems, problemDetails, 'id') &&
              ((problems?.find((y) => y?.id == problemDetails?.id)?.answer &&
                problems?.find((y) => y?.id == problemDetails?.id)?.type !=
                  'multiselect') ||
                (problems?.find((y) => y?.id == problemDetails?.id)?.type ==
                  'multiselect' &&
                  Array.isArray(
                    problems?.find((y) => y?.id == problemDetails?.id)?.answer
                  ) &&
                  problems?.find((y) => y?.id == problemDetails?.id)?.answer
                    ?.length > 0)) &&
              !(
                problemDetails?.answer !=
                  problems?.find((obj) => obj?.id == problemDetails?.id)
                    ?.answer ||
                !areFileListsEqual(
                  problemDetails?.files,
                  problems?.find((obj) => obj?.id == problemDetails?.id)?.files
                )
              )
                ? 'Submit'
                : isLastObjectByProperty(problems, problemDetails, 'id')
                ? 'Save'
                : 'Save & Next'}
            </Button>
          </Col>
        )}
      </Container>
      <UnansweredModal
        show={showUnanswered}
        handleClose={handleUnansweredClose}
        totalLeft={totalLeft}
        submit={handleAssignmentSubmission}
        unansweredQuestions={unansweredQuestions}
      />
    </Fragment>
  );
};

export default Assignment;
