// import node module libraries
import { Link } from 'react-router-dom';
import { Clock, Video, Users } from 'react-feather';
import {
  Container,
  Image,
  Button,
  Row,
  Col,
  Card,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiEmail } from '@mdi/js';

const ProgramInfo = ({
  item,
  bgColor,
  mainTextColor,
  mainBtnPressed,
  setMainBtnPressed,
}) => {
  return (
    <div className={`py-md-10 py-6 ${bgColor || 'bg-black'}`}>
      <Container>
        <Row>
          <Col xl={6} lg={6} md={12}>
            <div className="mb-4 mb-xl-0 text-center text-md-start">
              {/*  Caption  */}
              <h1
                className={`display-3 fw-bold mb-3 ls-sm ${
                  mainTextColor || 'text-primary'
                }`}
              >
                {item?.title || item?.name}
              </h1>
              {item?.description && (
                <p className="mb-4 lead text-white">{item?.description}</p>
              )}
              {item?.mainRedirectLink ? (
                <Link
                  to={item?.mainRedirectLink}
                  className="popup-youtube btn btn-white btn-lg fs-4"
                >
                  {item?.mainRedirect}
                </Link>
              ) : (
                <></>
              )}
              {item?.mainRedirectAction ? (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    item?.mainRedirectAction();
                  }}
                  className="popup-youtube btn btn-white btn-lg fs-4"
                >
                  {item?.mainRedirect}
                </Button>
              ) : (
                <></>
              )}
              {item?.secondaryRedirect ? (
                <Link
                  to={item?.secondaryRedirectLink || '#'}
                  className="popup-youtube btn btn-primary btn-lg fs-4 mx-3"
                >
                  {item?.secondaryRedirect}
                </Link>
              ) : (
                <></>
              )}
            </div>
          </Col>
          {item?.image && (
            <Col xl={6} lg={6} md={12} className="m-auto">
              <Card style={{ zIndex: 1 }}>
                <Image
                  src={item?.image}
                  // className='d-block mx-auto img-fluid '
                />
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default ProgramInfo;
