// import node module libraries
import { Fragment, useEffect, useState } from 'react';

import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';
import MainSection from 'pages/kapStudent/dashboard/components/MainSection';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import {
  useGetProfileMutation,
  useGetProgramsLiteMutation,
  useGetUserVideosMutation,
} from 'pages/redux/features/user/userApi';
import { saveProgrammes, saveUser } from 'pages/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { Loader } from 'pages/components/Loader';
import { masterClassImg } from 'pages/assets/images/imageUrl';
import SurveysModal from './components/SurveysModal';
import Icon from 'react-icons-kit';
import { fileText } from 'react-icons-kit/feather';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import StatsSection from './components/StatsSection';
import { isSubstringInArray } from 'pages/helper/formatArray';

const StudentDashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { completeApplication, user, programmes } = useSelector(
    (state) => state.userAuth
  );
  const [masterClass, setMasterClass] = useState({});
  const [currentProgramme, setCurrentProgramme] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userVideos, setUserVideos] = useState(null);

  const [getPrograms, { isLoading }] = useGetProgramsLiteMutation();
  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();
  const [getUserVideos, { isLoading: isLoadingUserVideos }] =
    useGetUserVideosMutation();
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const [surveys, setSurveys] = useState([]);

  const fetchProfile = async () => {
    const res1 = await getProfile();
    if (res1?.data?.success) {
      dispatch(
        saveUser({
          ...res1.data?.data?.user,
          ...res1.data?.data,
          programmes: res1.data?.data?.programmes,
          videos: res1.data?.data?.videos,
          videos_progress: res1.data?.data?.videos_progress,
          uncompleted_surveys: res1.data?.data?.uncompleted_surveys,
          application: res1.data?.data?.application,
        })
      );

      setCurrentProgramme(
        res1.data?.data?.programmes?.filter((x) =>
          x?.name?.toLowerCase().includes('masterclass')
        )
      );
    } else {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
      history.push('/');
    }
  };

  const fetchPrograms = async () => {
    const res = await getPrograms();
    if (res?.data?.success) {
      setMasterClass(
        res?.data?.data?.programmes?.find(
          (x) => x?.id === currentProgramme[0]?.id
        )
      );
      dispatch(saveProgrammes(res?.data?.data?.programmes));
      setLoading(false);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push('/login');
    }
  };
  useEffect(() => {
    setLoading(true);
    fetchProfile();
  }, []);
  useEffect(() => {
    setMasterClass(programmes?.find((x) => x?.id === currentProgramme[0]?.id));
    currentProgramme && !programmes && fetchPrograms();
  }, [currentProgramme]);

  useEffect(() => {
    if (user?.uncompleted_surveys?.length > 0) {
      const extrSurveys =
        user?.application?.status?.includes('admitted') &&
        user?.application?.current_level == 1
          ? user?.uncompleted_surveys?.filter((x) =>
              isSubstringInArray(x?.title, ['KAP Academy Post Training Survey'])
            )
          : user?.application?.current_level == 2
          ? user?.uncompleted_surveys
          : [];
      setSurveys(extrSurveys);
    }
  }, [user?.uncompleted_surveys]);

  const mainSection = {
    title: masterClass?.name,
    description:
      (masterClass?.description?.length < 350
        ? masterClass?.description
        : masterClass?.description?.slice(0, 350) + '....') || '',
    mainRedirect: !user?.application ? 'Watch Series' : 'Continue series',
    mainRedirectLink: `/programme/${masterClass?.id}`,
    secondaryRedirect: !completeApplication ? 'Complete Application' : '',
    image: masterClassImg,
    secondaryRedirectLink: '/complete-registration',
    popOverOnMain: false,
  };
  const [mainBtnPressed, setMainBtnPressed] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
    if (user?.application && user?.application?.status?.includes('admitted')) {
      history.push('/sis/redirect');
    }
    fetchUserVideos();
  }, []);

  const fetchUserVideos = async () => {
    const res = await getUserVideos();
    if (res?.data?.success) {
      setUserVideos(res?.data?.data?.videos);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  if ((isLoading && !programmes) || (isGettingProfile && !user)) {
    return <Loader />;
  }

  return (
    <Fragment>
      <MainSection
        item={mainSection}
        mainBtnPressed={mainBtnPressed}
        setMainBtnPressed={(e) => {
          history.push(`/programme/${masterClass?.id}`);
        }}
      />
      {masterClass && (
        <StatsSection
          item={masterClass}
          videos={userVideos}
          bgColor={'bg-primary'}
          mainTextColor={'text-white'}
        />
      )}

      <CoursesSection />
      <OverlayTrigger placement="top" overlay={<Tooltip>Surveys</Tooltip>}>
        <div
          className="position-fixed bottom-0 end-0 m-6"
          style={{
            zIndex: 1031,
            justifyContent: 'center',
          }}
          onClick={() => setSurveyModalShow(true)}
        >
          <div
            className="bg-primary"
            style={{
              borderRadius: '50%',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Icon
              icon={fileText}
              size={30}
              className="px-3"
              style={{ color: 'white' }}
            />
          </div>
          <h4 className="mb-0 text-primary">Surveys</h4>
        </div>
      </OverlayTrigger>
      <SurveysModal
        show={surveyModalShow}
        onHide={() => setSurveyModalShow(false)}
        data={surveys}
      />
    </Fragment>
  );
};
export default StudentDashboard;
