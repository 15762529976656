import { BASE_URL } from 'pages/redux/features/url';
import React, { useState } from 'react';
import { Button, Image, Modal, Row, Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player';

export const VideoModal = ({
  toggleModal,
  showModal,
  size,
  url,
  thumbnail,
  centered,
  title,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      size={size}
      centered={centered}
    >
      <Modal.Body>
        {url ? (
          <ReactPlayer
            url={url?.includes('http') ? url : BASE_URL.slice(0, -4) + url}
            controls={true}
            width="100%"
            height="100%"
          />
        ) : thumbnail ? (
          <Image src={thumbnail} width="100%" height="100%" />
        ) : (
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{ height: 200 }}
          >
            <Spinner style={{ color: '#e0bf00' }} />
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ImageModal = ({
  toggleModal,
  showModal,
  size,
  url,
  centered,
  title,
}) => {
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <Modal
      show={showModal}
      onHide={toggleModal}
      size={size}
      centered={centered}
    >
      {title && <Modal.Header>{title}</Modal.Header>}
      <Modal.Body>
        {imageLoading && (
          <Row
            className="d-flex justify-content-center align-items-center"
            style={{
              height: 100,
              width: '100%',
            }}
          >
            <Spinner style={{ color: '#e0bf00' }} />
          </Row>
        )}
        {url && (
          <Image
            src={url?.includes('http') ? url : BASE_URL.slice(0, -4) + url}
            width="100%"
            height="100%"
            onLoad={() => setImageLoading(false)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggleModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
