// import node module libraries
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, ListGroup, Image, Col } from 'react-bootstrap';

// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';

// import context file
import { ChatContext } from 'context/Context';
import { truncateText } from 'pages/helper/formatText';
import TruncateTextWithToggle from 'pages/components/text/TruncateTextWithToggle';
import { arrowDownRight } from 'react-icons-kit/feather';
import Icon from 'react-icons-kit';
import { chevronsRight } from 'react-icons-kit/feather';
import WarnSign from 'pages/assets/images/warning-sign.png';

const SurveysModal = (props) => {
  const history = useHistory();
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      {/* <Modal.Header closeButton={!props?.data || props?.data?.length < 1}> */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Surveys</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <ListGroup
          bsPrefix="list-unstyled"
          as="ul"
          className="contacts-list mb-0"
        >
          {props?.data?.length < 1 ? (
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Image src={WarnSign} width="100px" />
              <p className="text-center">No Surveys yet</p>
            </Col>
          ) : (
            props?.data?.map((item, index) => {
              return (
                <ListGroup.Item
                  as="li"
                  bsPrefix=" "
                  key={index}
                  className="py-1 mb-1 px-4 chat-item contacts-item"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className=" ms-2">
                      <div
                        className="d-flex justify-content-between mb-2"
                        // as={Link}
                        onClick={() => history.push(`/survey`, { ...item })}
                      >
                        <h5 className="mb-0">{item?.title}</h5>
                        <Link>
                          <p className="mb-0 text-primary">
                            Take survey
                            <Icon
                              icon={chevronsRight}
                              size={24}
                              color="bg-primary"
                            />
                          </p>
                        </Link>
                      </div>
                      <TruncateTextWithToggle
                        text={item?.description}
                        maxLength={150}
                      />
                    </div>
                  </div>
                  {/* <div>
                    <small className="text-muted">2/10/2021</small>
                  </div> */}
                </ListGroup.Item>
              );
            })
          )}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};

export default SurveysModal;
