// import node module libraries
import { Fragment, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Modal } from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  useCreateTeacherMutation,
  useCreateResourceMutation,
  useUpdateResourceMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNewResource = ({
  show,
  handleClose,
  size = 'lg',
  type,
  initialState,
}) => {
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      description: initialState?.description || '',
      link: initialState?.link || '',
      comment: initialState?.comment || '',
    },
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const resolveValues = {
        ...values,
      };
      handleResourceCreation(resolveValues);
    },
  });

  const [createResource, { isLoading }] = useCreateResourceMutation();
  const [updateResource, { isLoading: isUpdating }] =
    useUpdateResourceMutation();

  const handleResourceCreation = async (values) => {
    const res =
      type == 'edit'
        ? await updateResource({ id: initialState?.id, values })
        : await createResource(values);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Resource Linkage' : 'Add Resource Linkage'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Title"
                required
                onChange={formik.handleChange}
                value={formik.values?.title}
              />
              {formik.errors.title && formik.touched.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                onChange={formik.handleChange}
                value={formik.values?.description}
              />
              {formik.errors.description && formik.touched.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                id="link"
                placeholder="Resource link"
                required
                onChange={formik.handleChange}
                value={formik.values?.link}
              />
              {formik.errors.link && formik.touched.link && (
                <p className="fs-8 text-danger mb-0">{formik.errors.link}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="comment"
                placeholder="Comment"
                onChange={formik.handleChange}
                value={formik.values?.comment}
              />
              {formik.errors.comment && formik.touched.comment && (
                <p className="fs-8 text-danger mb-0">{formik.errors.comment}</p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading}
        >
          Add Resource
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewResource;
