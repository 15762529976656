import moment from 'moment';
import React from 'react';
import { Card } from 'react-bootstrap';

export function truncateText(text, maxLength = 30) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  } else {
    return text;
  }
}

export function convertToYesNo(value) {
  if (value === 1) {
    return 'Yes';
  } else {
    return 'No';
  }
}

export function timeFormatCheck(timeString) {
  const timeRegex = /^([0-9]{1,2}):([0-9]{2}):([0-9]{2})$/;
  return timeRegex.test(timeString);
}

export function formatKobo(number) {
  return (
    typeof number == 'string' ? parseInt(number) / 100 : number / 100
  )?.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function extractEndNumber(word) {
  const regex = /\d+$/; // Match one or more digits at the end of the string
  const match = regex.exec(word); // Try to match the regex against the input string
  if (match) {
    return parseInt(match[0]); // Return the matched number as an integer
  } else {
    return null; // If no match was found, return null
  }
}

export const TruncateText = ({ text, lines }) => {
  const textRef = React.useRef(null);
  const [isTruncated, setIsTruncated] = React.useState(true);

  React.useEffect(() => {
    const node = textRef.current;
    const lineHeight = parseInt(
      getComputedStyle(node).getPropertyValue('line-height')
    );
    const maxHeight = lineHeight * lines;
    const currentHeight = node.clientHeight;

    if (currentHeight > maxHeight) {
      setIsTruncated(true);
      node.style.maxHeight = `${maxHeight}px`;
    } else {
      setIsTruncated(false);
    }
  }, [text, lines]);

  return (
    <React.Fragment>
      <p ref={textRef}>{text}</p>
      {isTruncated && <div>...</div>}
    </React.Fragment>
  );
};

export const capitalize = (str) => {
  if (!str || typeof str !== 'string') return '';
  else
    return str
      ?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
};

export const formatTimePast = (timestamp) => {
  return moment().isSame(timestamp, 'day')
    ? moment(timestamp).format('hh:mm a') + ' Today'
    : moment(timestamp).format('hh:mma | DD/MM/YY');
};
