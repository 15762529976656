// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAdmitWithScholarshipByIdMutation,
  useCreateClassMutation,
  useCreateCourseMutation,
  useGetCoursesLiteMutation,
  useGetTeachersMutation,
  useUpdateClassMutation,
  useUpdateCourseMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';

const AddClass = ({ show, handleClose, id, type, initialState }) => {
  const [courses, setCourses] = useState([]);
  const dayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];
  const [getCourses, { isLoading: fetchingCourses }] = useGetCoursesLiteMutation();

  const [createClass, { isLoading: isCreatingClass }] =
    useCreateClassMutation();

  const [updateClass, { isLoading: isUpdatingClass }] =
    useUpdateClassMutation();

  const [getTeachers, { isLoading: fetchingTeachers }] =
    useGetTeachersMutation();

  const fetchCourses = async () => {
    const res = await getCourses();
    if (res?.data?.success) {
      setCourses(
        res?.data?.data?.courses?.map((x, index) => {
          return {
            label: x?.title,
            value: x?.id,
            id: x?.id,
          };
        })
      );
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleClassCreation = async (formData) => {
    const res =
      type == 'edit'
        ? await updateClass({ id: initialState?.id, formData })
        : await createClass(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    description: Yup.string().required('Required').typeError('Text expected'),
    date: Yup.string().required('Required').typeError('Text expected'),
    start_time: Yup.string().required('Required').typeError('Text expected'),
    end_time: Yup.string().required('Required').typeError('Text expected'),
    tag1: Yup.string().required('Required').typeError('Text expected'),
    tag2: Yup.string().required('Required').typeError('Text expected'),
    tag3: Yup.string().required('Required').typeError('Text expected'),
    learning_outcomes: Yup.string().typeError('Text expected'),
    instructor_recommendations: Yup.string().typeError('Text expected'),
    course_id: Yup.string()
      .required('Required')
      .typeError('Course Id expected'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      description: initialState?.description || '',
      course_id: id,
      pre_class_video: initialState?.pre_class_video || '',
      start_time: initialState?.start_time || '',
      end_time: initialState?.end_time || '',
      date: initialState?.date || '',
      tag1:
        (initialState?.tags &&
          initialState?.tags[0]?.name?.replace(/[^0-9]+/g, '')) ||
        '',
      tag2:
        (initialState?.tags &&
          initialState?.tags[1]?.name?.replace(/[^0-9]+/g, '')) ||
        '',
      tag3: (initialState?.tags && initialState?.tags[2]?.name) || '',
      learning_outcomes: initialState?.learning_outcomes || '',
      instructor_recommendations:
        initialState?.instructor_recommendations || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        tags: [`Class #` + values?.tag1, `Week ` + values?.tag2, values?.tag3],
        start_time:
          values.start_time?.length === 5
            ? values.start_time + ':00'
            : values.start_time,
        end_time:
          values.end_time?.length === 5
            ? values.end_time + ':00'
            : values.end_time,
      };
      delete resolveValues?.tag1;
      delete resolveValues?.tag2;
      delete resolveValues?.tag3;
      !(values?.pre_class_video instanceof File) &&
        delete resolveValues?.pre_class_video;
      const formData = objectToFormData(resolveValues);
      logFormData(formData);
      handleClassCreation(formData);
    },
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Course' : 'Add Course'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Learning Outcome</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="learning_outcomes"
                placeholder="Learning Outcome"
                required
                value={formik.values?.learning_outcomes}
                onChange={formik.handleChange}
              />
              {formik.errors.learning_outcomes && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.learning_outcomes}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Instructor Recommendations</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="instructor_recommendations"
                placeholder="Instructor Recommendations"
                required
                value={formik.values?.instructor_recommendations}
                onChange={formik.handleChange}
              />
              {formik.errors?.instructor_recommendations && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.instructor_recommendations}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                id="date"
                min={new Date().toISOString().split('T')[0]}
                placeholder="Date"
                required
                value={formik.values?.date}
                onChange={(e) => {
                  formik.setFieldValue('date', e?.target?.value);
                }}
              />
              {formik.errors?.date && (
                <p className="fs-8 text-danger mb-0">{formik.errors?.date}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start time</Form.Label>
              <Form.Control
                type="time"
                id="start_time"
                placeholder="Date"
                required
                value={formik.values?.start_time}
                onChange={(e) => {
                  formik.setFieldValue('start_time', e?.target?.value);
                }}
              />
              {formik.errors?.start_time && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.start_time}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>End time</Form.Label>
              <Form.Control
                type="time"
                id="end_time"
                placeholder="Date"
                required
                value={formik.values?.end_time}
                onChange={(e) => {
                  formik.setFieldValue('end_time', e?.target?.value);
                }}
              />
              {formik.errors?.end_time && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.end_time}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pre class video</Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                id="pre_class_video"
                multiple
                required
                onChange={(e) => {
                  formik.setFieldValue(
                    'pre_class_video',
                    e.currentTarget.files[0]
                  );
                }}
              />
              {formik.errors.pre_class_video && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.pre_class_video}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tags</Form.Label>
              <InputGroup>
                <InputGroup.Text>Class #</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="tag1"
                  placeholder="1"
                  required
                  value={formik.values?.tag1}
                  onChange={formik.handleChange}
                />
                <InputGroup.Text>Week</InputGroup.Text>
                <Form.Control
                  type="number"
                  id="tag2"
                  placeholder="1"
                  required
                  value={formik.values?.tag2}
                  onChange={formik.handleChange}
                />
                <InputGroup.Text>Day</InputGroup.Text>
                <Form.Control
                  as="select"
                  id="tag3"
                  placeholder="Wednesday"
                  required
                  value={formik.values?.tag3}
                  onChange={formik.handleChange}
                >
                  <option value={null}>Pick a day</option>
                  {dayOptions?.map((x) => (
                    <option value={x?.value}>{x?.label}</option>
                  ))}
                </Form.Control>
              </InputGroup>
              <div className="d-flex justify-content-between">
                {formik.errors.tag1 && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.tag1}</p>
                )}
                {formik.errors.tag2 && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.tag2}</p>
                )}
                {formik.errors.tag3 && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.tag3}</p>
                )}
              </div>
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isCreatingClass || isUpdatingClass}
        >
          {isCreatingClass || isUpdatingClass ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddClass;
