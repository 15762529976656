// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  ListGroup,
  Toast,
  Spinner,
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import { applications } from '../data/ApplicationData';
import AddNewAdmin from './AddNewAdmin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetAdminsMutation } from 'pages/redux/features/user/userApi';
import { sortByProperty } from 'pages/helper/formatArray';

const AdminList = () => {
  const [show, setShow] = useState(false);

  const [list, setList] = useState([]);
  const [getAdmins, { isLoading }] = useGetAdminsMutation();
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const fetchAdmins = async () => {
    const res = await getAdmins();
    if (res?.data?.success) {
      const value = res?.data?.data?.admins;
      if (
        typeof value === 'object' &&
        !Array.isArray(value) &&
        value !== null
      ) {
        const extr = Object.values(value);
        setList(extr);
      } else setList(value);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, [refresh]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = () => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary mx-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {' '}
            <Send size="18px" className="dropdown-item-icon" /> Activate
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            {' '}
            <Inbox size="18px" className="dropdown-item-icon" /> Deactivate
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {' '}
            <Trash size="18px" className="dropdown-item-icon" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // { accessor: 'id', Header: 'ID' },
      {
        accessor: 'firstname',
        Header: 'FIRST NAME',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/admin/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'lastname',
        Header: 'LAST NAME',
        Cell: ({ value, row }) => {
          return (
            <Link to={`/admin/admin/${row?.original?.id}`}>
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      { accessor: 'email', Header: 'EMAIL' },
      { accessor: 'phone', Header: 'PHONE' },
      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    let filtered = list;
    filtered = sortByProperty(filtered, 'created_at', 'desc');

    filtered = filtered?.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     {
    //       id: 'selection',
    //       Header: ({getToggleAllRowsSelectedProps}) => (
    //         <Checkbox {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({row}) => <Checkbox {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...columns,
    //   ]);
    // },
  );

  const { pageIndex, globalFilter } = state;

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Admins</h1>
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                Add New Admin
              </Button>
              <AddNewAdmin show={show} handleClose={handleClose} size="lg" />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div className=" overflow-hidden">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="mb-lg-0 mb-2 px-5 py-4"
                  >
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                      placeholder="Search List"
                    />
                  </Col>
                </Row>
              </div>

              <div className="table-responsive">
                <Table {...getTableProps()} className="text-nowrap">
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              {/* Pagination @ Footer */}
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default AdminList;
