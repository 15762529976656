import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Form,
  InputGroup,
  Col,
} from 'react-bootstrap';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useDeletePostMutation,
  useGetAdminByIdMutation,
  useGetPostMutation,
  useReplyCommentMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ListItem } from '../admissions/ApplicationDetails';
import AddNewPost from './AddNewForum';
import { User } from 'react-feather';
import { useFormik } from 'formik';
import { CustomButton } from 'pages/components/CustomButton';
import * as Yup from 'yup';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import FileUploadInput from 'pages/components/FileUploadInput';
import { useSelector } from 'react-redux';
import { isSubstringInArray } from 'pages/helper/formatArray';
import { formatTimePast } from 'pages/helper/formatText';
import { openInNewTab } from 'pages/helper/downloadFile';
import { ImageModal, VideoModal } from 'pages/components/Modals/DisplayModal';

const ForumDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const { user: userInfo } = useSelector((state) => state.userAuth);

  const [details, setDetails] = useState(props.location?.state);
  const [show, setShow] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedReply, setSelectedReply] = useState(null);
  const [reply, setReply] = useState('');
  const [attachments, setAttachments] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [clearFiles, setClearFiles] = useState(false);
  const [clearReplyFiles, setClearReplyFiles] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [file, setFile] = useState({ type: '', url: '' });

  const [showAllReplies, setShowAllReplies] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleReplyOpen = (val) => {
    setSelectedComment(val);
  };
  const handleReplyClose = () => {
    setSelectedComment(null);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);

  const [getPost, { isLoading }] = useGetPostMutation();
  const [createComment, { isLoading: isCreatingComment }] =
    useCreateCommentMutation();
  const [deleteComment, { isLoading: isDeletingComment }] =
    useDeleteCommentMutation();
  const [replyComment, { isLoading: isReplyingComment }] =
    useReplyCommentMutation();
  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();

  const fetchPost = async () => {
    const res = await getPost({ id: route_id });
    console.log('getPost', res);
    if (res?.data) {
      history.replace({
        pathname: '/admin/forums/' + route_id,
        state: res?.data?.data?.post,
      });
      setDetails(res?.data?.data?.post);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };
  const handlePostDelete = async () => {
    const res = await deletePost({ id: route_id });
    if (res?.data?.success) {
      toast.success(res?.error?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDeleteComment = async (id) => {
    const res = await deleteComment({ id });
    if (res?.data?.success) {
      toast.success(res?.error?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleComment = async (values) => {
    if (!values?.attachments) {
      delete values.attachments;
    }
    const resolveValues = {
      ...values,
      post_id: route_id,
    };

    const formData = objectToFormData(resolveValues);
    logFormData(formData);
    const res = await createComment(formData);
    if (res?.data?.success) {
      toast.success(res?.error?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // setFieldValue('attachments', null);
      setClearFiles(true);
      resetForm();
      setRefresh(!refresh);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setRefresh(!refresh);
    }
  };
  const handleReply = async ({ id, values }) => {
    if (!values?.attachments) {
      delete values.attachments;
    }
    const resolveValues = {
      ...values,
      post_id: route_id,
    };

    const formData = objectToFormData(resolveValues);
    logFormData(formData);
    const res = await replyComment({ id, data: formData });
    if (res?.data?.success) {
      toast.success(res?.error?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setReply('');
      setClearReplyFiles(true);
      setAttachments(null);
      setRefresh(!refresh);
      // setFieldValue('attachments', null);
      // resetForm();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchPost();
  }, [refresh]);

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required('Required'),
  });

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isValid,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: { comment: '' },
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleComment({
        text: values.comment,
        attachments: values.attachments,
      });
    },
  });

  const videoExt = [
    '.mp4',
    '.avi',
    '.mov',
    '.wmv',
    '.mkv',
    '.flv',
    '.webm',
    '.m4v',
    '.mpg',
    '.mpeg',
    '.3gp',
    '.rm',
    '.swf',
    '.vob',
    '.ogv',
    '.ts',
    '.m2ts',
    '.mts',
  ];

  const audioExt = [
    'mp3',
    'wav',
    'flac',
    'aac',
    'ogg',
    'wma',
    'm4a',
    'aiff',
    'alac',
    'amr',
    'opus',
    'mid',
    'midi',
  ];

  const imageExt = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
    'svg',
    'webp',
    'ico',
  ];

  if (!details) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <VideoModal
        centered
        showModal={fileModal && file?.type == 'video'}
        toggleModal={() => {
          setFileModal(!fileModal);
          setFile({ type: '', url: '' });
        }}
        url={file?.url}
        title={file?.url?.slice(-10)?.toLowerCase()}
      />
      <ImageModal
        centered
        showModal={fileModal && file?.type == 'image'}
        toggleModal={() => {
          setFileModal(!fileModal);
          setFile({ type: '', url: '' });
        }}
        url={file?.url}
        title={file?.url?.slice(-10)?.toLowerCase()}
      />
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div>
            <Card.Title as="p" className="text-uppercase">
              Post
            </Card.Title>
            <div className="d-flex align-items-center">
              <h4 className="text-capitalize text-bold">{details?.title}</h4>
            </div>
            <p>{details?.body}</p>
            {details?.attachments?.length > 0 && (
              <Row>
                <p className="mb-1 text-black" style={{ fontSize: 12 }}>
                  Attachments
                </p>
                {details?.attachments?.map((attachment) => (
                  <CustomButton
                    style={{
                      width: 130,
                      height: 30,
                      paddingTop: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      paddingRight: 0,
                      borderColor: 'transparent',
                    }}
                    onClick={
                      isSubstringInArray(attachment?.url?.slice(-3), videoExt)
                        ? () => {
                            setFileModal(true);
                            setFile({
                              type: 'video',
                              url: attachment?.url,
                            });
                          }
                        : isSubstringInArray(
                            attachment?.url?.slice(-3),
                            imageExt
                          )
                        ? () => {
                            setFileModal(true);
                            setFile({
                              type: 'image',
                              url: attachment?.url,
                            });
                          }
                        : () => {
                            openInNewTab(attachment?.url);
                          }
                    }
                    variant="outline-secondary"
                    text={
                      isSubstringInArray(
                        attachment?.url?.slice(-3),
                        videoExt
                      ) ? (
                        <p className="mb-0">
                          <i className={`nav-icon fe fe-film me-2`}></i>
                          {attachment?.url?.slice(-10)?.toLowerCase()}
                        </p>
                      ) : isSubstringInArray(
                          attachment?.url?.slice(-3),
                          audioExt
                        ) ? (
                        <p className="mb-0">
                          <i className={`nav-icon fe fe-music me-2`}></i>
                          {attachment?.url?.slice(-10)?.toLowerCase()}
                        </p>
                      ) : isSubstringInArray(
                          attachment?.url?.slice(-3),
                          imageExt
                        ) ? (
                        <p className="mb-0">
                          <i className={`nav-icon fe fe-image me-2`}></i>
                          {attachment?.url?.slice(-10)?.toLowerCase()}
                        </p>
                      ) : (
                        <p className="mb-0">
                          <i className={`nav-icon fe fe-file me-2`}></i>
                          {attachment?.url?.slice(-10)?.toLowerCase()}
                        </p>
                      )
                    }
                  />
                ))}
              </Row>
            )}
            <div className="d-flex justify-content-end align-items-center">
              <p className="mb-0">
                {isSubstringInArray(userInfo?.roles, ['admin']) && 'Created: '}
                {details?.created_at &&
                  moment(details?.created_at).format('DD/MM/YYYY')}
              </p>
              {details?.deleted_at && (
                <p className="mb-0">
                  Deleted: {moment(details?.deleted_at).format('DD/MM/YYYY')}
                </p>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Header as="p" className="text-uppercase">
          Comments
        </Card.Header>
        <Card.Body className="mt-0 pt-0 pb-2 p-0">
          {details?.comments
            ?.filter((z) => !z?.replied_to)
            ?.map((x) => (
              <div className="border-bottom mt-3 pb-3">
                <div className="px-4">
                  <p className="mb-1">{x?.text}</p>
                  {x?.attachments?.length > 0 && (
                    <Row>
                      {x?.attachments?.map((attachment) => (
                        <CustomButton
                          style={{
                            width: 130,
                            height: 30,
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderColor: 'transparent',
                          }}
                          onClick={
                            isSubstringInArray(
                              attachment?.url?.slice(-3),
                              videoExt
                            )
                              ? () => {
                                  setFileModal(true);
                                  setFile({
                                    type: 'video',
                                    url: attachment?.url,
                                  });
                                }
                              : isSubstringInArray(
                                  attachment?.url?.slice(-3),
                                  imageExt
                                )
                              ? () => {
                                  setFileModal(true);
                                  setFile({
                                    type: 'image',
                                    url: attachment?.url,
                                  });
                                }
                              : () => {
                                  openInNewTab(attachment?.url);
                                }
                          }
                          variant="outline-secondary"
                          text={
                            isSubstringInArray(
                              attachment?.url?.slice(-3),
                              videoExt
                            ) ? (
                              <p className="mb-0">
                                <i className={`nav-icon fe fe-film me-2`}></i>
                                {attachment?.url?.slice(-10)?.toLowerCase()}
                              </p>
                            ) : isSubstringInArray(
                                attachment?.url?.slice(-3),
                                audioExt
                              ) ? (
                              <p className="mb-0">
                                <i className={`nav-icon fe fe-music me-2`}></i>
                                {attachment?.url?.slice(-10)?.toLowerCase()}
                              </p>
                            ) : isSubstringInArray(
                                attachment?.url?.slice(-3),
                                imageExt
                              ) ? (
                              <p className="mb-0">
                                <i className={`nav-icon fe fe-image me-2`}></i>
                                {attachment?.url?.slice(-10)?.toLowerCase()}
                              </p>
                            ) : (
                              <p className="mb-0">
                                <i className={`nav-icon fe fe-file me-2`}></i>
                                {attachment?.url?.slice(-10)?.toLowerCase()}
                              </p>
                            )
                          }
                        />
                      ))}
                    </Row>
                  )}
                  <p className="fs-6 mb-0 text-end">
                    {formatTimePast(x?.created_at)}
                  </p>
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex align-items-center">
                      {x?.replies?.length == 0 && (
                        <div
                          onClick={() =>
                            selectedComment?.id === x?.id
                              ? handleReplyClose()
                              : handleReplyOpen(x)
                          }
                        >
                          <p className="fs-6 mb-0 text-start text-primary text-bold">
                            {selectedComment?.id === x?.id ? 'Cancel' : 'Reply'}
                          </p>
                        </div>
                      )}
                      {isSubstringInArray(userInfo?.roles, ['admin']) &&
                        userInfo?.email == x?.user?.email &&
                        x?.replies?.length == 0 && (
                          <p
                            className="fs-6 mb-0 ms-2 text-start text-primary text-bold"
                            onClick={() => handleDeleteComment(x?.id)}
                          >
                            Delete
                          </p>
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                      <p className="fs-6 mb-0 text-end text-primary">
                        {x?.user?.firstname + ' ' + x?.user?.lastname}
                      </p>
                      {x?.user?.avatar ? (
                        <img
                          src={x?.user?.avatar}
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            marginLeft: 5,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 30,
                            height: 30,
                            borderRadius: 15,
                            marginLeft: 5,
                            backgroundColor: '#0000001A',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <i
                            className="fa fa-user text-secondary fs-4"
                            style={{
                              alignSelf: 'center',
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                  </div>
                  {x?.replies
                    ?.slice(
                      0,
                      showAllReplies && selectedComment?.id === x?.id
                        ? x?.replies?.length
                        : 5
                    )
                    ?.map((y, index) => (
                      <div
                        className={`border-top px-3 py-2 d-flex justify-content-between align-items-center ${
                          index + 1 == x?.replies?.length
                            ? 'border-bottom mb-2'
                            : ''
                        } ${index + 1 == 1 ? 'mt-4' : ''}`}
                      >
                        <div>
                          <p className="mb-0" style={{ fontSize: 13 }}>
                            {y?.text}
                          </p>
                          {y?.attachments?.length > 0 && (
                            <Row>
                              {y?.attachments?.map((attachment) => (
                                <CustomButton
                                  style={{
                                    width: 130,
                                    height: 30,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderColor: 'transparent',
                                  }}
                                  onClick={
                                    isSubstringInArray(
                                      attachment?.url?.slice(-3),
                                      videoExt
                                    )
                                      ? () => {
                                          setFileModal(true);
                                          setFile({
                                            type: 'video',
                                            url: attachment?.url,
                                          });
                                        }
                                      : isSubstringInArray(
                                          attachment?.url?.slice(-3),
                                          imageExt
                                        )
                                      ? () => {
                                          setFileModal(true);
                                          setFile({
                                            type: 'image',
                                            url: attachment?.url,
                                          });
                                        }
                                      : () => {
                                          openInNewTab(attachment?.url);
                                        }
                                  }
                                  variant="outline-secondary"
                                  text={
                                    isSubstringInArray(
                                      attachment?.url?.slice(-3),
                                      videoExt
                                    ) ? (
                                      <p className="mb-0">
                                        <i
                                          className={`nav-icon fe fe-film me-2`}
                                        ></i>
                                        {attachment?.url
                                          ?.slice(-10)
                                          ?.toLowerCase()}
                                      </p>
                                    ) : isSubstringInArray(
                                        attachment?.url?.slice(-3),
                                        audioExt
                                      ) ? (
                                      <p className="mb-0">
                                        <i
                                          className={`nav-icon fe fe-music me-2`}
                                        ></i>
                                        {attachment?.url
                                          ?.slice(-10)
                                          ?.toLowerCase()}
                                      </p>
                                    ) : isSubstringInArray(
                                        attachment?.url?.slice(-3),
                                        imageExt
                                      ) ? (
                                      <p className="mb-0">
                                        <i
                                          className={`nav-icon fe fe-image me-2`}
                                        ></i>
                                        {attachment?.url
                                          ?.slice(-10)
                                          ?.toLowerCase()}
                                      </p>
                                    ) : (
                                      <p className="mb-0">
                                        <i
                                          className={`nav-icon fe fe-file me-2`}
                                        ></i>
                                        {attachment?.url
                                          ?.slice(-10)
                                          ?.toLowerCase()}
                                      </p>
                                    )
                                  }
                                />
                              ))}
                            </Row>
                          )}
                        </div>
                        <div className="d-flex">
                          <p
                            className="mb-0 ms-1 text-end"
                            style={{ fontSize: 11 }}
                          >
                            {formatTimePast(y?.created_at)}
                          </p>
                          <p
                            className="mb-0 ms-1 text-end text-primary"
                            style={{ fontSize: 11 }}
                          >
                            {y?.user?.firstname + ' ' + y?.user?.lastname}
                          </p>
                        </div>
                      </div>
                    ))}
                  {x?.replies?.length > 5 && (
                    <div
                      onClick={() => {
                        setShowAllReplies(!showAllReplies);
                        setSelectedComment(x);
                      }}
                    >
                      <p
                        className={`fs-6 mb-0 text-end text-primary text-bold ${
                          selectedComment?.id === x?.id ? 'mb-2' : ''
                        }`}
                      >
                        {showAllReplies && selectedComment?.id === x?.id
                          ? 'Show less'
                          : `Show ${x?.replies?.length - 5} more`}
                      </p>
                    </div>
                  )}
                  {x?.replies?.length > 0 && (
                    <div
                      onClick={() =>
                        selectedComment?.id === x?.id
                          ? handleReplyClose()
                          : handleReplyOpen(x)
                      }
                    >
                      <p
                        className={`fs-6 mb-0 text-start text-primary text-bold ${
                          selectedComment?.id === x?.id ? 'mb-2' : ''
                        }`}
                      >
                        {selectedComment?.id === x?.id ? 'Cancel' : 'Reply'}
                      </p>
                    </div>
                  )}
                  {selectedComment?.id === x?.id && (
                    <Fragment>
                      <Form.Group className="mb-3">
                        <InputGroup>
                          <Form.Control
                            type="text"
                            id="reply"
                            placeholder="Your reply"
                            required
                            value={reply}
                            style={{ height: 40 }}
                            onChange={(e) => setReply(e.target.value)}
                          />
                          <CustomButton
                            variant="primary"
                            style={{ minWidth: 80 }}
                            onClick={() =>
                              handleReply({
                                id: selectedComment?.id,
                                values: { text: reply, attachments },
                              })
                            }
                            disabled={
                              isLoading ||
                              isReplyingComment ||
                              isCreatingComment ||
                              isDeletingComment ||
                              !reply
                            }
                            loading={isReplyingComment}
                          >
                            {'Send'}
                          </CustomButton>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group controlId="commentAttachment">
                        <FileUploadInput
                          selectedFile={attachments}
                          setSelectedFile={(e) => {
                            setAttachments(e);
                          }}
                          padding={'2px'}
                          showAccepted={false}
                          clearFiles={clearReplyFiles}
                          maxFiles={10}
                          // fileformats={['jpg','jpeg', 'png', 'pdf']}
                        />
                      </Form.Group>
                    </Fragment>
                  )}
                </div>
              </div>
            ))}
        </Card.Body>
        <Card.Footer>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Add a comment</Form.Label>
              <Form.Control
                as="textarea"
                id="comment"
                placeholder="Your comment"
                required
                value={values?.comment}
                onChange={handleChange}
              />
              {errors.comment && touched.comment && (
                <p className="fs-8 text-danger mb-0">{errors.comment}</p>
              )}
            </Form.Group>
            <Form.Group controlId="commentAttachment">
              <FileUploadInput
                selectedFile={values.attachments}
                setSelectedFile={(e) => {
                  setFieldValue('attachments', e);
                }}
                padding={'2px'}
                showAccepted={false}
                clearFiles={clearFiles}
                maxFiles={10}
                // fileformats={['jpg','jpeg', 'png', 'pdf']}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <CustomButton
                variant="primary"
                style={{ minWidth: 80 }}
                onClick={handleSubmit}
                disabled={
                  !isValid ||
                  isLoading ||
                  isReplyingComment ||
                  isCreatingComment ||
                  isDeletingComment
                }
                loading={isCreatingComment}
              >
                {'Post'}
              </CustomButton>
            </div>
          </Form>
        </Card.Footer>
      </Card>

      <ToastContainer />
    </Fragment>
  );
};

export default ForumDetails;
