import { Button, Spinner } from 'react-bootstrap';

export const CustomButton = ({
  disabled = false,
  loading = false,
  variant = 'primary',
  type = 'submit',
  onClick = () => {},
  text = '',
  children = '',
  className = '',
  style = {},
  size = 'sm',
  ldSize = 'sm',
  ldColor = 'white',
}) => {
  return (
    <Button
      size={size}
      variant={variant}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={className}
      style={style}
    >
      {loading ? <Spinner size={ldSize} color={ldColor} /> : text || children}
    </Button>
  );
};
