// import node module libraries
import { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

import { useFormik } from 'formik';
import { useForgotPasswordOtpMutation } from 'pages/redux/features/auth/authApi';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomButton } from 'pages/components/CustomButton';
import { logosblack } from 'pages/assets/images/imageUrl';

const ForgotPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [forgotPasswordOtp, { isLoading }] = useForgotPasswordOtpMutation();
  const handleForgotPassword = async (values) => {
    const res = await forgotPasswordOtp(values);
    if (res?.data?.success) {
      history.push({
        pathname: '/reset-password',
        state: {
          detail: res?.data?.message,
          email: values?.email,
          type: 'forgot-password',
        },
      });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
  });
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={9} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Header className="mb-1">
              <Image src={logosblack} alt="" className="img-fluid w-50" />
            </Card.Header>
            <Card.Body className="p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Forgot Password</h1>
                <span>Enter your registered email to reset your password.</span>
              </div>
              {/* Form */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/*  email */}
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email address here"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3 d-grid gap-2">
                    {/* Button */}
                    <CustomButton
                      variant="primary"
                      type="submit"
                      text="Send Code"
                      loading={isLoading}
                      disabled={formik.isSubmitting || isLoading}
                    />
                  </Col>
                </Row>
                <span>
                  Return to <Link to="/login">Login</Link>
                </span>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default ForgotPassword;
