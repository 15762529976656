// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';

// import media files
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  setAuthState,
  setCredentials,
  setRole,
} from 'pages/redux/features/auth/authSlice';
import { useSelector } from 'react-redux';
import { useVerifyOtpMutation } from 'pages/redux/features/auth/authApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomButton } from 'pages/components/CustomButton';
import { logosblack } from 'pages/assets/images/imageUrl';

const VerifyAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [message, setMessage] = useState(location?.state?.detail);
  useEffect(() => {
    setMessage(location?.state?.detail);
  }, [location]);

  const [verifyOtp, { isLoading: isVerifying }] = useVerifyOtpMutation();

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: (values) => {
      handleOtp(values);
    },
  });

  const handleOtp = async (values) => {
    const res = await verifyOtp({ otp: values?.code });
    if (res?.data?.success) {
      history.push('/login');
      toast.success('Verification successful. Login with your new account', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 7000,
      });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 7000,
      });
    }
  };

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={9} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Header className="mb-1">
              <Image src={logosblack} alt="" className="img-fluid w-50" />
            </Card.Header>
            <Card.Body className="p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Verify Account</h1>
                <span>
                  {message || 'Enter the code sent to your email/phone number.'}
                </span>
              </div>
              {/* Form */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/*  email */}
                    <Form.Label>Code</Form.Label>
                    <Form.Control
                      type="text"
                      id="code"
                      placeholder="Enter your code"
                      required
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3 d-grid gap-2">
                    <CustomButton
                      variant="primary"
                      type="submit"
                      text="Verify"
                      loading={isVerifying}
                    />
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default VerifyAccount;
