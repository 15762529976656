import { v4 as uuid } from 'uuid';
/**
 *  All Dashboard Routes
 *
 *  Understanding name/value pairs for Dashboard routes
 *
 *  Applicable for main/root/level 1 routes
 *  icon 		: String - It's only for main menu or you can consider 1st level menu item to specify icon name.
 * 				: Object - Icon as an object added from v1.4.0.
 *
 *  Applicable for main/root/level 1 and subitems routes
 * 	id 			: Number - You can use uuid() as value to generate unique ID using uuid library, you can also assign constant unique ID for react dynamic objects.
 *  title 		: String - If menu contains childern use title to provide main menu name.
 *  badge 		: String - (Optional - Default - '') If you specify badge value it will be displayed beside the menu title or menu item.
 * 	badgecolor 	: String - (Optional - Default - 'primary' ) - Used to specify badge background color.
 *
 *  Applicable for subitems / children items routes
 *  name 		: String - If it's menu item in which you are specifiying link, use name ( don't use title for that )
 *  children	: Array - Use to specify submenu items
 *
 *  Used to segrigate menu groups
 *  grouptitle : Boolean - (Optional - Default - false ) If you want to group menu items you can use grouptitle = true,
 *  ( Use title : value to specify group title  e.g. COMPONENTS , DOCUMENTATION that we did here. )
 *
 */

// import MDI icons
import Icon from '@mdi/react';
import { mdiTrello } from '@mdi/js';

export const DashboardMenu = [
  {
    id: uuid(),
    title: 'Dashboard',
    icon: 'home',
    link: '/admin',
  },
  {
    id: uuid(),
    title: 'Admissions',
    icon: 'award',
    children: [
      {
        id: uuid(),
        title: 'Level 1',
        children: [
          {
            id: uuid(),
            link: '/admin/applications/level1',
            name: 'Applications',
          },
          { id: uuid(), link: '/admin/paying/level1', name: 'Paying' },
          {
            id: uuid(),
            link: '/admin/scholarship/level1',
            name: 'Scholarship',
          },
          {
            id: uuid(),
            link: '/admin/pending/level1',
            name: 'Pending',
          },
        ],
      },
      {
        id: uuid(),
        title: 'Level 2',
        children: [
          {
            id: uuid(),
            link: '/admin/applications/level2',
            name: 'Applications',
          },
          { id: uuid(), link: '/admin/paying/level2', name: 'Paying' },
          {
            id: uuid(),
            link: '/admin/scholarship/level2',
            name: 'Scholarship',
          },
          {
            id: uuid(),
            link: '/admin/pending/level2',
            name: 'Pending',
          },
        ],
      },
    ],
  },
  {
    id: uuid(),
    title: 'Registrations',
    icon: 'users',
    link: '/admin/registrations',
  },

  {
    id: uuid(),
    title: 'User management',
    icon: 'user',
    children: [
      { id: uuid(), link: '/admin/admins', name: 'Admins' },
      { id: uuid(), link: '/admin/instructors', name: 'Instructor' },
      { id: uuid(), link: '/admin/students', name: 'Students' },
    ],
  },

  {
    id: uuid(),
    title: 'Programmes',
    icon: 'book',
    link: '/admin/programmes',
  },

  {
    id: uuid(),
    title: 'Topics',
    icon: 'book',
    link: '/admin/topics',
  },
  {
    id: uuid(),
    title: 'Live Classes',
    icon: 'video',
    link: '/admin/liveclass',
  },
  {
    id: uuid(),
    title: 'Announcements',
    icon: 'speaker',
    link: '/admin/announcements',
  },
  {
    id: uuid(),
    title: 'Forums',
    icon: 'rss',
    link: '/admin/forums',
  },
  {
    id: uuid(),
    title: 'Jobs',
    icon: 'briefcase',
    link: '/admin/jobs',
  },
  {
    id: uuid(),
    title: 'Finance',
    icon: 'trending-up',
    link: '/admin/finance',
  },
  {
    id: uuid(),
    title: 'Resources',
    icon: 'package',
    link: '/admin/resources',
  },
  {
    id: uuid(),
    title: 'Analytics',
    icon: 'bar-chart-2',
    link: '/admin/report',
  },
];

export const OperationsDashboardMenu = [
  {
    id: uuid(),
    title: 'Dashboard',
    icon: 'home',
    link: '/admin',
  },
  {
    id: uuid(),
    title: 'Announcements',
    icon: 'speaker',
    link: '/admin/announcements',
  },
  {
    id: uuid(),
    title: 'Jobs',
    icon: 'briefcase',
    link: '/admin/jobs',
  },
  {
    id: uuid(),
    title: 'Finance',
    icon: 'trending-up',
    link: '/admin/finance',
  },
  {
    id: uuid(),
    title: 'Resources',
    icon: 'package',
    link: '/admin/resources',
  },
  // {
  //   id: uuid(),
  //   title: 'Reports',
  //   icon: 'bar-chart-2',
  //   link: '/admin/report',
  // },
];

export const AcademicDashboardMenu = [
  {
    id: uuid(),
    title: 'Dashboard',
    icon: 'home',
    link: '/admin',
  },

  {
    id: uuid(),
    title: 'Admissions',
    icon: 'award',
    children: [
      {
        id: uuid(),
        title: 'Level 1',
        children: [
          { id: uuid(), link: '/admin/applications', name: 'Applications' },
          { id: uuid(), link: '/admin/paying', name: 'Paying' },
          {
            id: uuid(),
            link: '/admin/scholarship',
            name: 'Scholarship',
          },
          {
            id: uuid(),
            link: '/admin/pending',
            name: 'Pending',
          },
          { id: uuid(), link: '/admin/registrations', name: 'Registrations' },
        ],
      },
      {
        id: uuid(),
        title: 'Level 2',
        children: [
          { id: uuid(), link: '/admin/applications', name: 'Applications' },
          { id: uuid(), link: '/admin/paying', name: 'Paying' },
          {
            id: uuid(),
            link: '/admin/scholarship',
            name: 'Scholarship',
          },
          {
            id: uuid(),
            link: '/admin/pending',
            name: 'Pending',
          },
          { id: uuid(), link: '/admin/registrations', name: 'Registrations' },
        ],
      },
    ],
  },
  {
    id: uuid(),
    title: 'Programmes',
    icon: 'book',
    link: '/admin/programmes',
  },
  {
    id: uuid(),
    title: 'Topics',
    icon: 'book',
    link: '/admin/topics',
  },
  {
    id: uuid(),
    title: 'Live Classes',
    icon: 'video',
    link: '/admin/liveclass',
  },
  {
    id: uuid(),
    title: 'Announcements',
    icon: 'speaker',
    link: '/admin/announcements',
  },
  {
    id: uuid(),
    title: 'Forums',
    icon: 'rss',
    link: '/admin/forums',
  },
  {
    id: uuid(),
    title: 'Resources',
    icon: 'package',
    link: '/admin/resources',
  },
];

export const InstructorDashboardMenu = [
  {
    id: uuid(),
    title: 'Dashboard',
    icon: 'home',
    link: '/instructor',
  },
  {
    id: uuid(),
    title: 'Courses',
    icon: 'book',
    link: '/instructor/courses',
  },

  {
    id: uuid(),
    title: 'Topics',
    icon: 'book',
    link: '/instructor/topics',
  },
];
