// import node module libraries
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  ListGroup,
  Toast,
  Spinner,
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import AddNewInstructor from './AddNewJob';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetVacanciesMutation } from 'pages/redux/features/user/userApi';
import { sortByProperty } from 'pages/helper/formatArray';
import { truncateText } from 'pages/helper/formatText';

const JobList = () => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [getVacancies, { isLoading }] = useGetVacanciesMutation();
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const fetchVacancies = async () => {
    const res = await getVacancies();
    if (res?.data?.success) {
      setList(res?.data?.data?.vacancies);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchVacancies();
  }, [refresh]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ value }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary mx-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item eventKey="1">
            {' '}
            <Send size="18px" className="dropdown-item-icon" /> Activate
          </Dropdown.Item>
          <Dropdown.Item eventKey="2">
            {' '}
            <Inbox size="18px" className="dropdown-item-icon" /> Deactivate
          </Dropdown.Item>
          <Dropdown.Item eventKey="3">
            {' '}
            <Trash size="18px" className="dropdown-item-icon" /> Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'company_name',
        Header: 'Company Name',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={
                row.original?.deleted_at
                  ? '#'
                  : `/admin/job/${row?.original?.id}`
              }
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'title',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={
                row.original?.deleted_at
                  ? '#'
                  : `/admin/job/${row?.original?.id}`
              }
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },

      {
        accessor: 'description',
        Header: 'DESCRIPTION',
        Cell: ({ value, row }) => {
          return truncateText(value, 30);
        },
      },
      {
        accessor: 'link',
        Header: 'LINK',
        Cell: ({ value, row }) => {
          return (
            <a href={value} target="_blank">
              <p className="text-primary-hover mb-0">{value}</p>
            </a>
          );
        },
      },
      {
        accessor: 'deadline',
        Header: 'Deadline',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    let filtered = list;
    if (filtered.length > 0) {
      filtered = sortByProperty(filtered, 'created_at', 'desc');
      // filtered = filtered.filter((x, index) => !x?.deleted_at);
      filtered = filtered.map((x, index) => {
        return {
          ...x,
          sn: index + 1,
        };
      });
    }

    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const getRowProps = useCallback((row) => {
    return {
      style: {
        backgroundColor: row.original.deleted_at ? '#DC00001A' : 'white',
      },
    };
  }, []);

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Jobs</h1>
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                Add New Job
              </Button>
              <AddNewInstructor
                show={show}
                handleClose={handleClose}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <div className="overflow-hidden">
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className="mb-lg-0 mb-2 px-5 py-4"
                    >
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                        placeholder="Search List"
                      />
                    </Col>
                  </Row>
                </div>
                <Table
                  {...getTableProps()}
                  className="text-nowrap"
                  style={{ position: 'relative', zIndex: 1000 }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps(getRowProps(row))}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {/* Pagination @ Footer */}
                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default JobList;
