import { useGetStudentMutation } from 'pages/redux/features/user/userApi';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Button, Row, Spinner, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import UpdateStudentDetails from './UpdateStudentDetails';

export const ListItem = ({
  title,
  children,
  type = 'text',
  onClick = () => {},
  isActive,
}) => {
  return (
    children && (
      <Card.Body className="border-top py-3" onClick={onClick}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div style={{ marginRight: 15 }}>
              <h5 className={`mb-0 ${isActive ? 'text-primary' : ''}`}>
                {title}
              </h5>
            </div>
          </div>
          <div>
            <div>
              {typeof children != 'string' ? (
                children
              ) : (
                <p className="text-dark mb-0 fw-semi-bold">{children}</p>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    )
  );
};

const StudentDetails = (props) => {
  const location = useLocation();
  const [details, setDetails] = useState(location?.state || null);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const [getStudent, { isFetching, isLoading }] = useGetStudentMutation();

  const fetchStudent = async () => {
    const res = await getStudent(props.match.params.id);
    if (res?.data) {
      setDetails(res?.data?.data);
    }
  };

  useEffect(() => {
    fetchStudent();
  }, [refresh]);

  if (isLoading || !details) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Student's Details</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Card className="mb-4">
        <ListItem title={'First name'} children={details?.firstname} />
        <ListItem title={'Last name'} children={details?.lastname} />
        <ListItem title={'Email address'} children={details?.email} />
        <ListItem title={'Phone number'} children={details?.phone} />
        <ListItem title={'Address'} children={details?.application?.address} />
      </Card>
      <div className="d-flex justify-content-between">
        <Button variant="primary" onClick={handleShow}>
          Change Student Details
        </Button>
        {/* <Button variant="secondary" onClick={handleDeleteShow}>
          Delete
        </Button> */}
        <UpdateStudentDetails
          show={show}
          initial={details}
          handleClose={handleClose}
          id={props.match.params.id}
        />
      </div>
    </Fragment>
  );
};

export default StudentDetails;
