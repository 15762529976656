// import node module libraries
import { Fragment, useEffect } from 'react';
import { Col, Row, Nav, Tab, Card, Container } from 'react-bootstrap';

import Avatar3 from 'assets/images/avatar/avatar-3.jpg';

// import data files
import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';
import MainSection from 'pages/kapStudent/dashboard/components/MainSection';
import { useSelector } from 'react-redux';
import { extraImg3 } from 'pages/assets/images/imageUrl';
import { useHistory } from 'react-router-dom';
const ResourceDashboard = () => {
  const history = useHistory();
  const { completeApplication } = useSelector((state) => state.userAuth);
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  });

  const mainSection = {
    title: 'Resources',
    description:
      'Students will have access to an electronic library that houses different versions of text, audio and video content that will allow students and graduates have life-long learning opportunities. The library will consist of curated content as well as links to the e-library of partner academic institutions, local and foreign.',
    mainRedirect: 'View Resources',
    secondaryRedirect: !completeApplication ? 'Complete Application' : '',
    image: extraImg3,
    mainRedirectLink: '/resources/all',
    secondaryRedirectLink: '/complete-registration',
    popOverOnMain: !completeApplication,
  };

  return (
    <Fragment>
      <MainSection
        item={mainSection}
        setMainBtnPressed={(e) => {
          history.push(`/resources/all`);
        }}
      />
      <CoursesSection />
    </Fragment>
  );
};
export default ResourceDashboard;
