import React, { Fragment, useEffect, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Tab,
  Nav,
  Col,
  Table,
  Dropdown,
  Image,
  OverlayTrigger,
  Tooltip,
  Accordion,
  ListGroup,
} from 'react-bootstrap';
import {
  useDeleteClassMutation,
  useDeleteCourseMutation,
  useDeleteTopicMutation,
  useDeleteVideoMutation,
  useGetClassesMutation,
  useGetCoursesLiteMutation,
  useGetTopicByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import GlobalFilter from 'pages/components/elements/advance-table/GlobalFilter';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';
import Pagination from 'pages/components/elements/advance-table/Pagination';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { useMemo } from 'react';
import { playCircleO } from 'react-icons-kit/fa/playCircleO';
import { VideoModal } from 'pages/components/Modals/DisplayModal';
import { ConfirmDeleteModal } from 'pages/components/Modals/ConfirmDeleteModal';
import moment from 'moment/moment';
import AddTopic from 'pages/kapInstructor/topics/AddTopic';
import { ListItem } from 'pages/kapAdmin/admissions/ApplicationDetails';
import { mdiBook, mdiChevronDown, mdiPlay } from '@mdi/js';
import Icon from '@mdi/react';
import AddQuiz from './AddQuiz';
import AssignClass from './AssignClass';
import AddAssignment from 'pages/kapAdmin/topics/AddAssignment';
import { sortByProperty } from 'pages/helper/formatArray';

const TopicDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [programDetails, setProgramDetails] = useState({});
  const [topicDetails, setTopicDetails] = useState(null);
  const [quizDetails, setQuizDetails] = useState(null);
  const [assignmentDetails, setAssignmentDetails] = useState(null);
  const [courseDetails, setCourseDetails] = useState({});
  const [contentType, setContentType] = useState('Videos');
  const [show, setShow] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [showAddQuiz, setShowAddQuiz] = useState(false);
  const [showAddAssignment, setShowAddAssignment] = useState(false);
  const [showAddVideo, setShowAddVideo] = useState(false);
  const [showAddCourse, setShowAddCourse] = useState(false);
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [key, setKey] = useState('Videos');
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteCourseShow, setDeleteCourseShow] = useState(false);

  const [list, setList] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [topics, setTopics] = useState([]);
  const [classes, setClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [deleteName, setDeleteName] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [showFullDesc, setShowFullDesc] = useState(false);
  const [showFullNote, setShowFullNote] = useState(false);
  const [activeQuizKey, setActiveQuizKey] = useState(null);
  const [activeAssignmentKey, setActiveAssignmentKey] = useState(null);

  const [getClasses, { isLoading: fetchingClasses }] = useGetClassesMutation();
  const [getCourses, { isLoading: fetchingCourses }] =
    useGetCoursesLiteMutation();

  const [
    getTopicById,
    { isFetching: isFetchingTopic, isLoading: isLoadingTopic },
  ] = useGetTopicByIdMutation();
  const [deleteTopic, { isLoading: isDeletingTopic }] =
    useDeleteTopicMutation();
  const [deleteCourse, { isLoading: isDeletingCourse }] =
    useDeleteCourseMutation();
  const [deleteClass, { isLoading: isDeletingClass }] =
    useDeleteClassMutation();
  const [deleteVideo, { isLoading: isDeletingVideo }] =
    useDeleteVideoMutation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleCloseAssign = () => {
    setShowAssign(false);
    setRefresh(!refresh);
  };
  const handleShowAssign = () => {
    setShowAssign(true);
  };
  const handleCloseAddCourse = () => {
    setShowAddCourse(false);
    setRefresh(!refresh);
  };
  const handleCloseAddQuiz = () => {
    setShowAddQuiz(false);
    setRefresh(!refresh);
  };
  const handleCloseAddAssignment = () => {
    setShowAddAssignment(false);
    setRefresh(!refresh);
  };
  const handleCloseAddTopic = () => {
    setShowAddTopic(false);
    setRefresh(!refresh);
  };
  const handleShowAddCourse = () => {
    setShowAddCourse(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => {
    setDeleteShow(false);
    setRefresh(!refresh);
  };

  const handleAddContent = (e) => {
    e.preventDefault();
    contentType === 'Quiz'
      ? setShowAddQuiz(true)
      : contentType === 'Assignment'
      ? setShowAddAssignment(true)
      : handleShow();
  };

  const handleDeleteTopic = async () => {
    const res = await deleteTopic({ id: topicDetails.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      history.goBack();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteCourse = async () => {
    const res = await deleteCourse({ id: selectedCourse.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteClass = async () => {
    const res = await deleteClass({ id: selectedClass.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDeleteVideo = async () => {
    console.log('selectedVideo', selectedVideo);
    const res = await deleteVideo({ id: selectedVideo.id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Deleted successfully', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 6000,
      });
      handleDeleteClose();
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteClick = () => {
    deleteName == 'video'
      ? handleDeleteVideo()
      : deleteName == 'course'
      ? handleDeleteCourse()
      : handleDeleteTopic();
  };

  const fetchCourses = async () => {
    const res = await getCourses();
    if (res?.data?.success) {
      setCourses(res?.data?.data?.courses);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchClasses = async () => {
    const res = await getClasses();

    if (res?.data?.success) {
      setClasses(res?.data?.data?.classes);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchTopic = async () => {
    const res = await getTopicById({ id: route_id });

    if (res?.data) {
      setTopicDetails(res?.data?.data?.topic);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };

  useEffect(() => {
    fetchTopic();
    fetchCourses();
    fetchClasses();
    setKey('Videos');
    setContentType('Videos');
  }, [refresh]);

  useEffect(() => {
    setCourseDetails(courses[0]);
  }, [courses]);

  const [showDisplayModal, setShowDisplayModal] = useState(false);
  const [type, setType] = useState('add');
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const toggleDisplayModal = () => setShowDisplayModal(!showDisplayModal);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'title',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/topic/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'description',
        Header: 'DESCRIPTION',
        Cell: ({ value, row }) => {
          return (
            <Link to={`/admin/programme/${row?.original?.id}`}>
              <h5 className="text-primary-hover">{value?.slice(0, 20)}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    []
  );

  const data = useMemo(() => {
    let filtered = topics;
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
      };
    });

    return filtered;
  }, [topics]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  if (isLoadingTopic) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Topic
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {topicDetails?.title}
                </h4>
              </div>
            </div>
            <div>
              <Button
                variant="secondary"
                size="sm"
                onClick={handleShowAssign}
                style={{ marginRight: 10 }}
              >
                Assign to Class
              </Button>
              <Button variant="primary" size="sm" onClick={handleShow}>
                Edit Topic
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <ListItem title={'Title'} children={topicDetails?.title} />
        {/* <ListItem
          title={'Description'}
          children={topicDetails?.description || 'N/A'}
        />
        <ListItem title={'Note'} children={topicDetails?.note || 'N/A'} /> */}
        {/* <ListItem
          title={'Class'}
          children={topicDetails?.course_class_id || 'N/A'}
        /> */}
        <ListItem
          title={'Course'}
          children={
            courses?.find((x) => x?.id == topicDetails?.course_id)?.title ||
            'N/A'
          }
        />
      </Card>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Description
              </Card.Title>
              <Card.Text>
                {showFullDesc
                  ? topicDetails?.description
                  : topicDetails?.description.slice(0, 100) + '...'}
              </Card.Text>
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={() => setShowFullDesc(!showFullDesc)}
              >
                {showFullDesc ? 'Read less' : 'Read more'}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      {/* <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Note
              </Card.Title>
              <Card.Text>
                {showFullNote
                  ? topicDetails?.note
                  : topicDetails?.note.slice(0, 100) + '...'}
              </Card.Text>
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={() => setShowFullNote(!showFullNote)}
              >
                {showFullNote ? 'Read less' : 'Read more'}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card> */}

      <Card className="mb-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title as="p" className="text-uppercase">
              Topic content
            </Card.Title>
            <Button size="sm" onClick={handleAddContent}>
              {contentType !== 'Note' ? 'Add New ' : 'Update '}
              {contentType.endsWith('s')
                ? contentType.slice(0, -1)
                : contentType}
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="py-3">
          <Tab.Container
            justify
            activeKey={key}
            onSelect={(k) => {
              setContentType(k);
              setKey(k);
            }}
          >
            <Card.Header className="border-bottom-0 p-0 bg-white">
              <Nav className="nav-lb-tab">
                {['Videos', 'Note', 'Quiz', 'Assignment'].map((x) => (
                  <Nav.Item>
                    <Nav.Link eventKey={x} className="mb-sm-3 mb-md-0">
                      {x}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body className="p-0">
              <Tab.Content>
                <Tab.Pane eventKey={contentType} className="pb-0">
                  {contentType == 'Videos' &&
                    topicDetails?.videos?.map((x, index) => (
                      <ListGroup className="mt-2">
                        <ListGroup.Item key={index}>
                          <Link
                            to="#"
                            className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                          >
                            <div
                              className="text-truncate "
                              onClick={() => {
                                setSelectedUrl(x.url);
                                toggleDisplayModal();
                                return;
                              }}
                            >
                              <span
                                className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                              >
                                <Icon path={mdiPlay} size={0.8} />
                              </span>
                              <span className="fs-5">
                                {'Video ' + (index + 1)}
                              </span>
                            </div>
                            <Button
                              size="sm"
                              onClick={() => {
                                setDeleteName('video');
                                setSelectedVideo(x);
                                handleDeleteShow();
                              }}
                            >
                              Delete
                            </Button>
                          </Link>
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                  {contentType == 'Note' && (
                    <p className="mt-1">{topicDetails?.note}</p>
                  )}
                  {contentType == 'Quiz' &&
                    topicDetails?.quizzes?.map((x, index) => (
                      <ListGroup className="mt-2">
                        <ListGroup.Item key={index}>
                          <Link
                            to="#"
                            // onClick={() => {
                            //   setActiveQuizKey(
                            //     activeQuizKey == x.id ? '' : x?.id
                            //   );
                            // }}
                            className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                          >
                            <div
                              className="text-truncate"
                              onClick={() => {
                                history.push(`/instructor/topic/quiz/${x?.id}`);
                              }}
                            >
                              <span
                                className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                              >
                                <Icon path={mdiBook} size={0.8} />
                              </span>
                              <span className="fs-5">{x?.title}</span>
                            </div>
                            <Icon
                              path={mdiChevronDown}
                              size={0.8}
                              onClick={() => {
                                setActiveQuizKey(
                                  activeQuizKey == x.id ? '' : x?.id
                                );
                              }}
                            />
                          </Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          key={x?.id}
                          className={
                            activeQuizKey == x?.id ? 'd-block' : 'd-none'
                          }
                        >
                          <ListItem
                            title={'Instructions'}
                            children={x?.instructions}
                          />
                          <ListItem
                            title={'Pass mark'}
                            children={x?.pass_mark}
                          />
                          <ListItem title={'Total mark'} children={x?.total} />
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                  {contentType == 'Assignment' &&
                    topicDetails?.assignments?.map((x, index) => (
                      <ListGroup className="mt-2">
                        <ListGroup.Item key={index}>
                          <Link
                            to="#"
                            // onClick={() => {
                            //   setActiveQuizKey(
                            //     activeQuizKey == x.id ? '' : x?.id
                            //   );
                            // }}
                            className={`d-flex justify-content-between align-items-center text-${'inherit'} text-decoration-none`}
                          >
                            <div
                              className="text-truncate"
                              onClick={() => {
                                history.push(
                                  `/instructor/topic/assignment/${x?.id}`
                                );
                              }}
                            >
                              <span
                                className={`icon-shape bg-${'light'} text-${'primary'} icon-sm rounded-circle me-2`}
                              >
                                <Icon path={mdiBook} size={0.8} />
                              </span>
                              <span className="fs-5">{x?.title}</span>
                            </div>
                            <Icon
                              path={mdiChevronDown}
                              size={0.8}
                              onClick={() => {
                                setActiveAssignmentKey(
                                  activeAssignmentKey == x.id ? '' : x?.id
                                );
                              }}
                            />
                          </Link>
                        </ListGroup.Item>
                        <ListGroup.Item
                          key={x?.id}
                          className={
                            activeAssignmentKey == x?.id ? 'd-block' : 'd-none'
                          }
                        >
                          <ListItem
                            title={'Instructions'}
                            children={x?.instructions}
                          />
                          <ListItem
                            title={'Pass mark'}
                            children={x?.pass_mark}
                          />
                          <ListItem title={'Total mark'} children={x?.total} />
                        </ListGroup.Item>
                      </ListGroup>
                    ))}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-start">
        <Button
          variant="secondary"
          onClick={() => {
            setDeleteName('topic');
            setSelectedCourse(null);
            handleDeleteShow();
          }}
        >
          Delete Topic
        </Button>
        <AddTopic
          show={show}
          handleClose={handleClose}
          initialState={topicDetails}
          type={'edit'}
        />
        <AssignClass
          show={showAssign}
          handleClose={handleCloseAssign}
          id={topicDetails?.id}
        />
        <AddQuiz
          show={showAddQuiz}
          handleClose={handleCloseAddQuiz}
          initialState={type == 'edit' ? quizDetails : null}
          type={type}
          id={topicDetails?.id}
        />
        <AddAssignment
          show={showAddAssignment}
          handleClose={handleCloseAddAssignment}
          initialState={type == 'edit' ? assignmentDetails : null}
          type={type}
          id={topicDetails?.id}
        />
        <VideoModal
          showModal={showDisplayModal}
          toggleModal={toggleDisplayModal}
          url={selectedUrl}
          thumbnail={selectedThumbnail}
        />
        <Modal show={deleteShow} onHide={handleDeleteClose} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Delete {deleteName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this {deleteName}</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteClick}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default TopicDetails;
