import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  Card,
  Modal,
  Button,
  Row,
  Spinner,
  Tab,
  Nav,
  Dropdown,
  Table,
  Col,
} from 'react-bootstrap';
import {
  useGetAdminByIdMutation,
  useGetApplicationsMutation,
  useGetTeacherByIdMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import ChangeProgramStatus from './ChangeProgramStatus';
import { Inbox, MoreVertical, Send, Trash } from 'react-feather';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import GlobalFilter from 'pages/components/elements/advance-table/GlobalFilter';
import { useDispatch } from 'react-redux';
import Pagination from 'pages/components/elements/advance-table/Pagination';
import DotBadge from 'pages/components/elements/bootstrap/DotBadge';
import { sortByProperty } from 'pages/helper/formatArray';

const CourseDetails = (props) => {
  const route_id = props.match.params.id;
  const history = useHistory();
  const [details, setDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [key, setKey] = useState('description');
  const dispatch = useDispatch();
  const [applications, setApplications] = useState([]);
  const [
    getApplications,
    { isFetching: isFetchingApp, isLoading: isLoadingApp },
  ] = useGetApplicationsMutation();

  const fetchApplications = async () => {
    const res = await getApplications();
    if (res?.data?.success) {
      setApplications(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 7000,
      });
    }
  };
  useEffect(() => {
    fetchApplications();
  }, [refresh]);
  const handleAcceptTrigger = (details) => {};
  const handleRejectTrigger = () => {};
  const handleReapplyTrigger = () => {};

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);

  const [getTeacherById, { isFetching, isLoading }] =
    useGetTeacherByIdMutation();

  const fetchTeacher = async () => {
    const res = await getTeacherById({ id: route_id });
    if (res?.data) {
      setDetails(res?.data?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    fetchTeacher();
  }, [refresh]);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'firstname',
        Header: 'TITLE',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/application/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'lastname',
        Header: 'LAST NAME',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/application/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'email',
        Header: 'EMAIL',
      },
      {
        accessor: 'address',
        Header: 'ADDRESS',
        Cell: ({ value, row }) => {
          return value.toUpperCase();
        },
      },

      {
        accessor: 'status',
        Header: 'APPLICATION STATUS',
        Cell: ({ value }) => {
          return (
            <>
              <DotBadge
                bg={
                  value?.includes('admitted')
                    ? 'success'
                    : value?.includes('pending')
                    ? 'info'
                    : value === 'rejected'
                    ? 'danger'
                    : 'warning'
                }
              ></DotBadge>
              {value?.includes('admitted')
                ? 'Admitted'
                : value?.includes('reapply')
                ? 'Rejected to Reapply'
                : value?.includes('reject')
                ? 'Rejected'
                : 'Pending'}
            </>
          );
        },
      },

      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
    ],
    []
  );
  const data = useMemo(() => {
    let filtered = applications;
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        firstname: x?.user?.firstname,
        lastname: x?.user?.lastname,
        email: x?.user?.email,
      };
    });

    return filtered;
  }, [applications]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  if (isLoading || isLoadingApp || details?.length) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Card className="mb-4">
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title as="p" className="text-uppercase">
                Course
              </Card.Title>
              <div className="d-flex align-items-center">
                <h4 className="mb-0 text-capitalize text-bold">
                  {details?.firstname + ' ' + details?.lastname}
                </h4>
              </div>
            </div>
            <Button variant="primary" onClick={handleShow}>
              Add New Content
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        {/* start date  */}
        <Card.Body className="py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Title</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">
                  {details?.firstname}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Body className="border-top py-3">
          <div
            className="d-flex justify-content-between
                      align-items-center"
          >
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Program Attached to</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">Level 1</p>
              </div>
            </div>
          </div>
        </Card.Body>

        <Card.Body className="border-top py-3">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="ms-2">
                <h5 className="mb-0 text-body">Date created</h5>
              </div>
            </div>
            <div>
              <div>
                <p className="text-dark mb-0 fw-semi-bold">
                  {new Date(details?.created_at).toDateString()}
                </p>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Tab.Container justify activeKey={key} onSelect={(k) => setKey(k)}>
        <Card>
          <Card.Header className="border-bottom-0 p-0 bg-white">
            <Nav className="nav-lb-tab">
              <Nav.Item>
                <Nav.Link eventKey="description" className="mb-sm-3 mb-md-0">
                  Description
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="liveclass" className="mb-sm-3 mb-md-0">
                  Live Classes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="video" className="mb-sm-3 mb-md-0">
                  Videos
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="quiz" className="mb-sm-3 mb-md-0">
                  Quizzes
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assignment" className="mb-sm-3 mb-md-0">
                  Assignments
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Card.Body className="p-0">
            <Tab.Content>
              <Tab.Pane eventKey="description" className="pb-4 p-4">
                <p>
                  If you’re learning to program for the first time, or if you’re
                  coming from a different language, this course, JavaScript:
                  Getting Started, will give you the basics for coding in
                  JavaScript. First, you'll discover the types of applications
                  that can be built with JavaScript, and the platforms they’ll
                  run on.
                </p>
                <p>
                  If you’re learning to program for the first time, or if you’re
                  coming from a different language, this course, JavaScript:
                  Getting Started, will give you the basics for coding in
                  JavaScript. First, you'll discover the types of applications
                  that can be built with JavaScript, and the platforms they’ll
                  run on.
                </p>
                <p>
                  If you’re learning to program for the first time, or if you’re
                  coming from a different language, this course, JavaScript:
                  Getting Started, will give you the basics for coding in
                  JavaScript. First, you'll discover the types of applications
                  that can be built with JavaScript, and the platforms they’ll
                  run on.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="liveclass" className="pb-4 p-4">
                <div
                  className="table-responsive"
                  // style={{position: 'relative', zIndex: 9999}}
                >
                  <div className=" overflow-hidden">
                    <Row>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        className="mb-lg-0 mb-2 px-5 py-4"
                      >
                        <GlobalFilter
                          filter={globalFilter}
                          setFilter={setGlobalFilter}
                          placeholder="Search List"
                        />
                      </Col>
                    </Row>
                  </div>
                  <Table
                    {...getTableProps()}
                    className="text-nowrap"
                    style={{ position: 'relative', zIndex: 9999 }}
                  >
                    <thead className="table-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.length === 0 ? (
                        <tr>
                          <td colSpan={columns.length}>No Results</td>
                        </tr>
                      ) : (
                        page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                  <Pagination
                    previousPage={previousPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    gotoPage={gotoPage}
                    nextPage={nextPage}
                  />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="video" className="pb-4 p-4"></Tab.Pane>
              <Tab.Pane eventKey="quiz" className="pb-4 p-4"></Tab.Pane>
              <Tab.Pane eventKey="assignment" className="pb-4 p-4"></Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
      <div className="d-flex justify-content-between mt-3">
        <Button variant="secondary" onClick={handleDeleteShow}>
          Delete
        </Button>
        <ChangeProgramStatus
          show={show}
          handleClose={handleClose}
          id={route_id}
        />

        <Modal show={deleteShow} onHide={handleDeleteClose} size="sm">
          <Modal.Header closeButton>
            <Modal.Title>Delete Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this course</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
            <Button variant="outline-white" onClick={handleDeleteClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteClose}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CourseDetails;
