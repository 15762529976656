import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  role: '',
  token: '',
  pageState: null,
  states: [],
  isAuthenticated: null,
  completeApplication: false,
  applicationStatus: false,
  programmesStarted: [],
  programmes: [],
  sisDetails: '',
  stopSurvey: false,
  studentNotifications: [],
  unreadStudentNotifications: 0,
};

export const authSlice = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {
    saveUser: (state, { payload }) => {
      state.user = payload;
    },
    saveSisDetails: (state, { payload }) => {
      state.sisDetails = payload;
    },
    setRole: (state, { payload }) => {
      state.role = payload;
    },
    saveStates: (state, { payload }) => {
      state.states = payload;
    },
    saveToken: (state, { payload }) => {
      state.token = payload;
    },
    setAuthState: (state, { payload }) => {
      state.isAuthenticated = payload;
    },
    setPageState: (state, { payload }) => {
      state.pageState = payload;
    },
    setStopSurvey: (state, { payload }) => {
      state.stopSurvey = payload;
    },
    saveProgrammesStarted: (state, { payload }) => {
      state.programmesStarted = payload;
    },
    saveProgrammes: (state, { payload }) => {
      state.programmes = payload;
    },
    setCredentials: (state, { payload }) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    setCompleteApplication: (state, { payload }) => {
      state.completeApplication = payload;
    },
    setApplicationStatus: (state, { payload }) => {
      state.applicationStatus = payload;
    },
    clearUser: (state) => {
      state.user = {};
    },
    clearToken: (state) => {
      state.token = {};
    },
    logout: (state) => {
      state.token = '';
      state.user = {};
      state.isAuthenticated = null;
      state.role = '';
      state.pageState = null;
      state.states = [];
      state.isAuthenticated = null;
      state.completeApplication = false;
      state.applicationStatus = false;
      state.programmesStarted = [];
      state.sisDetails = '';
      state.stopSurvey = false;
    },
    saveToUserStore: (state, { payload }) => {
      state[payload?.key] = payload.value;
    },
  },
});

export const {
  saveUser,
  setRole,
  setAuthState,
  setPageState,
  clearUser,
  saveToken,
  setCompleteApplication,
  setApplicationStatus,
  clearToken,
  setCredentials,
  logout,
  saveStates,
  saveSisDetails,
  saveProgrammesStarted,
  saveProgrammes,
  setStopSurvey,
  saveToUserStore,
} = authSlice.actions;

export default authSlice.reducer;
