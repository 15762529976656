import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from 'react-icons-kit';
import { xCircle } from 'react-icons-kit/feather/xCircle';

const FileUploadInput = ({
  selectedFile,
  setSelectedFile,
  fileformats,
  padding,
  showAccepted = true,
  clearFiles,
  maxFiles = 1,
}) => {
  const maxSize = 1048576;
  const [showDropZone, setShowDropZone] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles);
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: ['image/png', 'image/jpeg', 'application/pdf'],
    minSize: 0,
    maxSize,
    maxFiles,
  });

  useEffect(() => {
    console.log('clearFiles', clearFiles);
    console.log('selectedFile', selectedFile);
    clearFiles && setSelectedFile(null);
  }, [clearFiles]);

  useEffect(() => {
    setShowDropZone(selectedFile && selectedFile.length > 0);
  }, [selectedFile]);

  const isFileTooLarge =
    rejectedFiles &&
    rejectedFiles.length > 0 &&
    rejectedFiles[0].size > maxSize;

  return (
    <div {...getRootProps()}>
      <ul className="list-group mt-2">
        {selectedFile?.length > 0 &&
          selectedFile?.map((acceptedFile) => (
            <li
              key={acceptedFile.name + Date.now()}
              className="list-group-item list-group-item-success w-100 mb-1"
            >
              <div className="d-flex">
                <p className="w-100 mb-0">{acceptedFile.name}</p>
                <Icon
                  icon={xCircle}
                  size={18}
                  className={'align-self-center'}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedFile(null);
                  }}
                />
              </div>
            </li>
          ))}
      </ul>
      {/* {!showDropZone && ( */}
      <div
        className="text-center mt-2"
        style={{
          borderStyle: 'dashed',
          borderColor: '#eeeeee',
          backgroundColor: '#fafafa',
          color: '#bdbdbd',
          cursor: 'pointer',
          marginBottom: '20px',
          padding: padding || '30px',
        }}
      >
        <input {...getInputProps()} />
        {!isDragActive && 'Click here or drop a file to upload!\n'}
        {isDragActive &&
          !isDragReject &&
          'Drop it\n Accepted file format are JPEG, PNG and PDF'}
        {isDragReject && 'File type not accepted, sorry!'}
        {isFileTooLarge && (
          <div className="text-danger mt-2">File is too large.</div>
        )}
        {showAccepted && (
          <p className="mb-0">Accepted file format are JPEG, PNG and PDF</p>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default FileUploadInput;
