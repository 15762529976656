// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  CloseButton,
  Image,
  Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import data files
import {
  useGetFinancesMutation,
  useGetStudentNotificationsMutation,
  useMarkNotificationMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { saveToUserStore } from 'pages/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Loader } from 'pages/components/Loader';
import {
  sortAndRemoveDuplicates,
  sortedDataByCreatedAt,
} from 'pages/helper/formatArray';
import moment from 'moment';
import { LinkageItem } from './Jobs';

const Finance = () => {
  const dispatch = useDispatch();
  const { studentNotifications } = useSelector((state) => state.userAuth);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [list, setList] = useState([]);
  const [getFinances, { isLoading }] = useGetFinancesMutation();

  const fetchList = async () => {
    const res = await getFinances();
    if (res?.data?.success) {
      const data = res?.data?.data?.finances;
      setList(data);
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchList();
  }, [page, refresh]);

  return (
    <Container className="mt-4 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 font-weight-bold">Finance Linkages</h1>
            </div>
            <div>
              <Link
                to="#"
                className="btn btn-white"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                {isLoading ? 'Refreshing' : 'Refresh'}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card
            className={`rounded-3 ${
              list?.length === 0 ? 'bg-transparent' : 'bg-white'
            }`}
          >
            <Card.Body
              className={`rounded-3 p-0 ${
                list?.length === 0 ? 'bg-transparent' : 'bg-white'
              }`}
            >
              {list?.length === 0 && isLoading ? (
                <Loader />
              ) : list?.length === 0 ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: window.innerHeight / 1.3 }}
                >
                  No Finance Linkage available
                </div>
              ) : (
                <ListGroup>
                  {list?.map((item, index) => {
                    return <LinkageItem item={item} index={index} />;
                  })}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Finance;
