// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Image } from 'react-bootstrap';
import { logosblack } from 'pages/assets/images/imageUrl';

const NavbarBrandOnly = () => {
  return (
    <Row>
      <Col xl={{ offset: 1, span: 2 }} lg={12} md={12}>
        <div className="mt-4">
          <Link to="/">
            <Image src={logosblack} alt="" width={200} />
          </Link>
        </div>
      </Col>
    </Row>
  );
};
export default NavbarBrandOnly;
