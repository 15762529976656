// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  CloseButton,
  Image,
  Container,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import data files
import {
  useGetStudentNotificationsMutation,
  useMarkNotificationMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { saveToUserStore } from 'pages/redux/features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Loader } from 'pages/components/Loader';
import {
  sortAndRemoveDuplicates,
  sortedDataByCreatedAt,
} from 'pages/helper/formatArray';

const Notifications = () => {
  const dispatch = useDispatch();
  const { studentNotifications } = useSelector((state) => state.userAuth);
  const [page, setPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [getStudentNotifications, { isLoading: isLoadingNotifications }] =
    useGetStudentNotificationsMutation();

  const fetchNotifications = async () => {
    const res = await getStudentNotifications({
      take: 10,
      skip: (parseInt(page) - 1) * 1,
    });
    if (res?.data?.success) {
      setHasMore(res?.data?.total_pages > res?.data?.current_page);
      const not = sortAndRemoveDuplicates(res?.data?.data);
      page === 1
        ? setNotifications(not)
        : setNotifications([...notifications, ...not]);
      dispatch(
        saveToUserStore({
          key: 'studentNotifications',
          value: page === 1 ? not : [...notifications, ...not],
        })
      );
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [page, refresh]);

  const [markNotification, { isLoading: isMarking }] =
    useMarkNotificationMutation();

  const handleMarkAsRead = async (id) => {
    const res = await markNotification({ id });
    if (res?.data?.success) {
      const updatedArray = notifications?.map((x) => {
        if (x?.id == id) {
          return { ...x, read_at: Date.now() };
        } else {
          return { ...x };
        }
      });
      setNotifications(updatedArray);
      dispatch(
        saveToUserStore({
          key: 'studentNotifications',
          value: updatedArray,
        })
      );
    } else {
      toast.error(res?.error?.data?.message || 'Failed', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function MarkAsRead({ removable, id }) {
    if (removable) {
      return (
        <Fragment>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">Mark as read</Tooltip>}
          >
            <Link to="#" onClick={async () => await handleMarkAsRead(id)}>
              <DotBadge bg="info"></DotBadge>
            </Link>
          </OverlayTrigger>
        </Fragment>
      );
    } else {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Read</Tooltip>}
        >
          <Link to="#" onClick={async () => await handleMarkAsRead(id)}>
            <DotBadge bg="secondary"></DotBadge>
          </Link>
        </OverlayTrigger>
      );
    }
  }

  return (
    <Container className="mt-4 mb-4">
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 font-weight-bold">Notifications</h1>
            </div>
            <div>
              <Link
                to="#"
                className="btn btn-white"
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                {isLoadingNotifications ? 'Refreshing' : 'Refresh'}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card
            className={`rounded-3 ${
              notifications?.length === 0 ? 'bg-transparent' : 'bg-white'
            }`}
          >
            <Card.Body
              className={`rounded-3 p-0 ${
                notifications?.length === 0 ? 'bg-transparent' : 'bg-white'
              }`}
            >
              {notifications?.length === 0 && isLoadingNotifications ? (
                <Loader />
              ) : notifications?.length === 0 ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: window.innerHeight / 1.3 }}
                >
                  No Notifications available
                </div>
              ) : (
                <ListGroup>
                  {notifications?.map((item, index) => {
                    return (
                      <ListGroup.Item className="py-4" key={index}>
                        <Row className="align-items-center">
                          <Col
                            onClick={async () =>
                              await handleMarkAsRead(item?.id)
                            }
                          >
                            <div className="d-flex align-items-center">
                              <Link to="#" className="rounded-circle">
                                <i className="fa fa-bell fs-3"></i>
                              </Link>
                              <div className="ms-3">
                                <Link to="#">
                                  <p className="mb-0 text-body">
                                    <span className="fw-bold mb-0 h5">
                                      {item?.subject}:
                                    </span>{' '}
                                  </p>
                                </Link>
                                <Link to="#">
                                  <p className="mb-0 text-body">{item?.body}</p>
                                </Link>
                                <span className="fs-6 text-muted">
                                  <span>{item?.created_at},</span>
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-auto text-center p-2">
                            {MarkAsRead({
                              removable: !item?.read_at,
                              id: item?.id,
                            })}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {notifications?.length > 0 ? (
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="border-bottom pb-4 mb-4 mt-2 d-md-flex align-items-center justify-content-center">
              <div>
                {isLoadingNotifications ? (
                  <Link to="#" className="btn btn-white">
                    Loading...
                  </Link>
                ) : !hasMore ? (
                  <Link to="#" className="btn btn-white">
                    No more
                  </Link>
                ) : (
                  <Link
                    to="#"
                    className="btn btn-white"
                    onClick={() => setPage(page + 1)}
                  >
                    Load more
                  </Link>
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Notifications;
