// import node module libraries
import { Fragment, useEffect, useState } from 'react';

import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';
import MainSection from 'pages/kapStudent/dashboard/components/MainSection';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginSISMutation } from 'pages/redux/features/auth/authApi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import {
  saveProgrammes,
  saveProgrammesStarted,
  saveToken,
  saveUser,
  setApplicationStatus,
  setAuthState,
  setCompleteApplication,
  setCredentials,
  setRole,
} from 'pages/redux/features/auth/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccessProgramme from './AccessProgramme';
import {
  useGetProfileMutation,
  useGetProgramsLiteMutation,
  useGetSurveysMutation,
} from 'pages/redux/features/user/userApi';
import { Loader } from 'pages/components/Loader';
import StatsSection from './components/StatsSection';
import SurveysModal from './components/SurveysModal';
import Icon from 'react-icons-kit';
import { fileText } from 'react-icons-kit/feather';
import localStorage from 'redux-persist/es/storage';
import SurveyCompleteModal from './components/SurveyCompleteModal';
import { level1Img, level2Img } from 'pages/assets/images/imageUrl';
import { isSubstringInArray } from 'pages/helper/formatArray';
import { WarningModal } from 'pages/components/Modals/WarningModal';

const StudentSIS = (props) => {
  const user_id = props.match.params.user_id;
  const path = props.match.params.path;
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    user: userInfo,
    sisDetails,
    programmesStarted,
    stopSurvey,
    programmes,
  } = useSelector((state) => state.userAuth);
  const [programsDetails, setProgramsDetails] = useState(programmes);
  const [mainBtnPressed, setMainBtnPressed] = useState(false);
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };
  const [showWarning, setShowWarning] = useState(false);

  const [warningText, setWarningText] = useState(
    'You need to get admitted to access this programme.'
  );

  const [available, setAvailable] = useState(false);
  // const [programsDetails, setProgramsDetails] = useState([]);
  const [level, setLevel] = useState({
    mainRedirect: 'Access Programme',
    image: level1Img,
    popOverOnMain: false,
    popupModal: true,
  });

  const { completeApplication } = useSelector((state) => state.userAuth);

  const [getPrograms, { isLoading: isLoadingPrograms }] =
    useGetProgramsLiteMutation();
  const [getSurveys, { isLoading: isLoadingSurveys }] = useGetSurveysMutation();

  useEffect(() => {
    if (user_id) {
      user_id !== 'redirect' ? handleCheck() : getData(sisDetails);
      setConfirmed(true);
    }
  }, [user_id, refresh]);

  const fetchSurveys = async () => {
    const res = await getSurveys();
    if (res?.data?.success) {
      setSurveys(res?.data?.data?.surveys);
      res?.data?.data?.surveys?.length > 0 && setSurveyModalShow(true);
    } else {
      setSurveyModalShow(false);
      toast.error(res?.error?.data?.message || 'No Surveys', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchPrograms = async () => {
    const res = await getPrograms();
    const setter = await localStorage.getItem('setter');
    if (res?.data?.success) {
      dispatch(saveProgrammes(res?.data?.data?.programmes));
      setProgramsDetails(res?.data?.data?.programmes);

      // setLevel({
      //   ...level,
      //   ...res?.data?.data?.programmes?.filter((x) =>
      //     x?.name?.toLowerCase()?.includes('introduction to film')
      //   )[0],
      // });
      let extProg = programmesStarted || [];
      if (!Object.isExtensible(extProg)) {
        extProg = [...extProg];
      }
      res?.data?.data?.programmes.forEach((obj1) => {
        let objectExists = false;
        if (extProg && extProg?.length > 0) {
          objectExists = extProg.some((obj) => obj.id === obj1.id);
        }
        if (!objectExists) {
          extProg.push({ ...obj1, started: false });
        }
      });
      dispatch(saveProgrammesStarted(extProg));
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push('/login');
    }
  };

  const [getProfile, { isLoading: isGettingProfile }] = useGetProfileMutation();

  const fetchProfile = async () => {
    const res1 = await getProfile();
    if (res1?.data?.success) {
      dispatch(
        saveUser({
          ...res1.data?.data?.user,
          ...res1.data?.data,
          programmes: res1.data?.data?.programmes,
          videos: res1.data?.data?.videos,
          videos_progress: res1.data?.data?.videos_progress,
          uncompleted_surveys: res1.data?.data?.uncompleted_surveys,
          application: res1.data?.data?.application,
        })
      );
    } else {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        delay: 5000,
      });
    }
  };

  useEffect(() => {
    fetchPrograms();
    fetchProfile();
    // fetchSurveys();
  }, [refresh]);

  useEffect(() => {
    if (level) {
      setAvailable(
        isSubstringInArray('masterclass', [level?.title || level?.name]) ||
          userInfo?.programmes?.find((x) => x?.id === level?.id) ||
          (isSubstringInArray('speciali', [level?.title || level?.name]) &&
            isSubstringInArray('level 2', [level?.nick_name]) &&
            userInfo?.application &&
            userInfo?.application?.status_level_2?.includes('admitted'))
      );
    }
  }, [level, userInfo, programmesStarted, refresh]);

  useEffect(() => {
    userInfo?.application?.current_level == 2
      ? setLevel({
          ...level,
          ...programmes?.filter((x) =>
            x?.name?.toLowerCase()?.includes('speciali')
          )[0],
          image: level1Img,
          popOverOnMain: false,
          popupModal: true,
        })
      : setLevel({
          ...level,
          ...programmes?.filter((x) =>
            x?.name?.toLowerCase()?.includes('introduction to film')
          )[0],
          image: level2Img,
          popOverOnMain: false,
          popupModal: true,
        });
  }, [userInfo, programmesStarted, refresh]);

  const [loginSIS, { isLoading }] = useLoginSISMutation();

  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  }, []);

  async function getData(url) {
    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      // Do something with the data
      if (data?.data && data?.data?.roles?.includes('student')) {
        dispatch(saveUser({ ...userInfo, ...data?.data }));
        dispatch(saveToken(data?.data?.token));
        dispatch(setAuthState(true));
        dispatch(setCompleteApplication(data?.data?.application !== null));
        dispatch(
          setApplicationStatus(
            data?.data?.application !== null
              ? data?.data?.application?.status.includes('admitted')
              : false
          )
        );
        dispatch(setRole('student'));
        setLoading(false);
      } else history.push('/login');
    } catch (error) {
      // Handle errors here
      setLoading(false);
      history.push('/login');
    }
  }

  const handleCheck = async () => {
    const res = await loginSIS({ id: user_id });
    if (res?.data?.success) {
      dispatch(
        setCredentials({
          user: res?.data?.data,
          token: res?.data?.data?.token,
        })
      );
      dispatch(setAuthState(true));
      dispatch(setCompleteApplication(res?.data?.data?.application !== null));
      dispatch(
        setApplicationStatus(
          res?.data?.data?.application !== null
            ? res?.data?.data?.application?.status.includes('admitted')
            : false
        )
      );
      dispatch(setRole('student'));
      setConfirmed(true);
      if (path) {
        history.push(`/${path.replace(/_/g, '/')}`);
      }
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push('/login');
    }
  };

  useEffect(() => {
    if (userInfo?.uncompleted_surveys?.length > 0) {
      const extrSurveys =
        userInfo?.application?.status?.includes('admitted') &&
        userInfo?.application?.current_level == 1
          ? userInfo?.uncompleted_surveys?.filter((x) =>
              isSubstringInArray(x?.title, ['KAP Academy Post Training Survey'])
            )
          : userInfo?.application?.current_level == 2
          ? userInfo?.uncompleted_surveys
          : [];
      setSurveys(extrSurveys);
    }
  }, [userInfo?.uncompleted_surveys]);

  // useEffect(() => {
  //   stopSurvey&& dispatch()
  // }, [stopSurvey])

  if (
    isLoading ||
    !confirmed ||
    (isLoadingPrograms && !programmes) ||
    loading
  ) {
    return <Loader />;
  }

  return (
    <Fragment>
      <MainSection
        item={level}
        mainBtnPressed={mainBtnPressed}
        setMainBtnPressed={(e) => {
          isSubstringInArray('speciali', [level?.title || level?.name]) &&
          isSubstringInArray('level 2', [level?.nick_name]) &&
          userInfo?.application
            ? userInfo?.application?.status_level_2?.includes('admitted')
              ? history.push('/programme/' + level?.id)
              : setShowWarning(true)
            : available
            ? history.push('/programme/' + level?.id)
            : handleShow();
          setMainBtnPressed(e);
        }}
      />
      <StatsSection
        item={level}
        bgColor={'bg-primary'}
        mainTextColor={'text-white'}
      />
      <CoursesSection />
      {/* <Link
        to="#"
        className="btn-primary rounded-circle icon-shape icon-md texttooltip me-1"
        style={{ position: 'absolute', right: 30 }}
        onClick={() => setSurveyModalShow(true)}
      >
        <Icon icon={user} size={30} className="px-3" />
        <div id="newchat" className="d-none">
          <span>Surveys</span>
        </div>
      </Link> */}
      <OverlayTrigger placement="top" overlay={<Tooltip>Surveys</Tooltip>}>
        <div
          className="position-fixed bottom-0 end-0 m-6"
          style={{
            zIndex: 1031,
            justifyContent: 'center',
          }}
          onClick={() => setSurveyModalShow(true)}
        >
          <div
            className="bg-primary"
            style={{
              borderRadius: '50%',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Icon
              icon={fileText}
              size={30}
              className="px-3"
              style={{ color: 'white' }}
            />
          </div>
          <h4 className="mb-0 text-primary">Surveys</h4>
        </div>
      </OverlayTrigger>
      <SurveysModal
        show={surveyModalShow}
        onHide={() => setSurveyModalShow(false)}
        data={surveys}
      />

      <WarningModal
        showModal={showWarning}
        setShowModal={setShowWarning}
        text={warningText}
      />

      <AccessProgramme
        show={show}
        handleClose={handleClose}
        type={'edit'}
        size="md"
        currentLevelId={level?.id}
        amount={level?.price}
        level={level}
      />
      <ToastContainer />
    </Fragment>
  );
};
export default StudentSIS;
