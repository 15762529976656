import React, { Fragment } from 'react';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';

const UnansweredModal = ({
  show,
  handleClose,
  totalLeft,
  submit,
  unansweredQuestions,
}) => {
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {unansweredQuestions?.length > 0 ? (
          <Fragment>
            <p>You have ({totalLeft}) unanswered questions</p>
            {unansweredQuestions?.length < 7 && (
              <Row style={{ justifyContent: 'center' }}>
                {unansweredQuestions?.map((x, index) => (
                  <Button
                    className="justify-content-center"
                    style={{
                      width: 40,
                      height: 40,
                      marginRight: 15,
                      marginBottom: 15,
                      padding: 0,
                      backgroundColor: '#EDF2F7',
                      color: 'black',
                    }}
                    variant="transparent"
                  >
                    {x?.number}
                  </Button>
                ))}
              </Row>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p>Are you sure you want to submit your selected answers. </p>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Back to Quiz
        </Button>
        <Button variant="primary" onClick={submit}>
          {false ? <Spinner size="sm" /> : 'Yes, I want to submit'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnansweredModal;
