// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  Tab,
  Nav,
  Spinner,
  FormSelect,
  Form,
  InputGroup,
  Container,
  // Pagination,
} from 'react-bootstrap';
import { Trash, Send, Inbox, MoreVertical } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import {
  useGetLevel2ApplicationsMutation,
  useGetSearchUsersMutation,
  useGetStatesQuery,
} from 'pages/redux/features/user/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { saveStates } from 'pages/redux/features/auth/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  extractObjectValues,
  isObject,
} from 'pages/helper/formatObjectToArray';
import {
  sortByProperty,
  sortedDataByCreatedAt,
} from 'pages/helper/formatArray';
import { BASE_URL } from 'pages/redux/features/url';
import { capitalize } from 'pages/helper/formatText';

const ApplicationList = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.userAuth);
  const [show, setShow] = useState(false);
  const [applications, setApplications] = useState([]);
  const [getApplications, { isFetching, isLoading }] =
    useGetLevel2ApplicationsMutation();
  const [refresh, setRefresh] = useState(false);
  const [states, setStates] = useState([]);
  const { data: statesOptions, isFetchingStates } = useGetStatesQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const [getSearchUsers, { isLoading: isSearching }] =
    useGetSearchUsersMutation();

  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleAcceptTrigger = (details) => {};
  const handleRejectTrigger = () => {};
  const handleReapplyTrigger = () => {};

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'firstname',
        Header: 'FIRST NAME',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/application/level_2/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'lastname',
        Header: 'LAST NAME',
        Cell: ({ value, row }) => {
          return (
            <Link
              className="text-inherit"
              to={`/admin/application/level_2/${row?.original?.id}`}
            >
              <h5 className="text-primary-hover">{value}</h5>
            </Link>
          );
        },
      },
      {
        accessor: 'email',
        Header: 'EMAIL',
      },
      {
        accessor: 'address',
        Header: 'ADDRESS',
      },
      {
        accessor: 'status_level_2',
        Header: 'APPLICATION STATUS',
        Cell: ({ value }) => {
          return (
            <>
              <DotBadge
                bg={
                  value?.includes('admitted')
                    ? 'success'
                    : value?.includes('pending')
                    ? 'info'
                    : value === 'rejected'
                    ? 'danger'
                    : 'warning'
                }
              ></DotBadge>
              {value?.includes('admitted_without_scholarship')
                ? 'Paying'
                : value?.includes('admitted_with_scholarship')
                ? 'Scholarship'
                : value?.includes('reapply')
                ? 'Rejected to Reapply'
                : value?.includes('reject')
                ? 'Rejected'
                : value?.includes('pending')
                ? 'Pending'
                : capitalize(value)}
            </>
          );
        },
      },

      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      // {accessor: 'date_updated', Header: 'DATE UPDATED'},
      // {
      //   accessor: 'action',
      //   Header: '',
      //   Cell: ({value, row}) => {
      //     return <ActionMenu details={row} />;
      //   },
      // },
    ],
    []
  );

  const data = useMemo(() => {
    let filtered = applications;
    filtered = sortByProperty(filtered, 'created_at', 'desc');
    filtered = filtered.map((x, index) => {
      return {
        ...x,
        sn: index + 1,
        firstname: x?.user?.firstname,
        lastname: x?.user?.lastname,
        email: x?.user?.email,
      };
    });

    return filtered;
  }, [applications]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state,
    gotoPage,
    setPageSize,
    pageCount,
    prepareRow,
    setGlobalFilter,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useRowSelect
  );

  const pageSizeOptions = [10, 20, 50, 100];

  const { pageIndex, globalFilter, pageSize } = state;

  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [pageNoInput, setPageNoInput] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const fetchApplications = async (pageNo) => {
    const resolveValues = {};
    if (searchTerm && searchTerm?.length > 0) {
      resolveValues.term = searchTerm;
      resolveValues.level = 2;
    }
    resolveValues.type = 'application';
    resolveValues.take = currentPageSize;
    resolveValues.skip = pageNo * currentPageSize;

    const res = resolveValues.term
      ? await getSearchUsers(resolveValues)
      : await getApplications({
          take: currentPageSize,
          skip: pageNo * currentPageSize,
        });
    if (res?.data?.success) {
      let filtered = res?.data?.data;
      if (isObject(filtered)) {
        filtered = Object.values(filtered);
      }
      if (resolveValues.term) {
        filtered = filtered?.filter((x) => x?.current_level == 2);
      }
      setTotalPageCount(res?.data?.total_pages);

      setApplications(filtered);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
        duration: 7000,
      });
    }
  };

  useEffect(() => {
    dispatch(saveStates(statesOptions));
  }, [statesOptions]);
  useEffect(() => {
    fetchApplications(currentPageNo - 1);
  }, [refresh, currentPageSize]);

  const handlePaginationClick = async (pageNumber) => {
    setCurrentPageNo(pageNumber);
    await fetchApplications(pageNumber - 1);
  };

  if (isLoading || isSearching) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Container>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Application List</h1>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Tab.Container defaultActiveKey="all">
            <Card>
              <Card.Body className="p-0">
                <div
                  className="table-responsive"
                  // style={{position: 'relative', zIndex: 9999}}
                >
                  <div className="overflow-hidden">
                    <Row>
                      <Col lg={4} md={12} sm={12}>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => {
                              setSearchTerm(e.target.value);
                              if (e.target.value === '') {
                                handlePaginationClick(1);
                              }
                            }}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={() => {
                              handlePaginationClick(1);
                            }}
                          >
                            <i className="fe fe-chevron-right"></i>
                          </Button>
                        </InputGroup>
                      </Col>
                      <Col lg={4} md={12} sm={12}>
                        <FormSelect
                          value={currentPageSize}
                          onChange={(e) => {
                            setCurrentPageSize(Number(e.target.value));
                          }}
                        >
                          {pageSizeOptions.map((size) => (
                            <option key={size} value={size}>
                              {size + '  /page'}
                            </option>
                          ))}
                        </FormSelect>
                      </Col>
                      <Col lg={4} md={12} sm={12}>
                        <InputGroup>
                          <InputGroup.Text>Page No</InputGroup.Text>
                          <Form.Control
                            type="number"
                            value={pageNoInput}
                            onChange={(e) => {
                              setPageNoInput(Number(e.target.value));
                              setCurrentPageNo(Number(e.target.value));
                            }}
                            max={totalPageCount - 1}
                            min={1}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={() => {
                              currentPageNo - 1 <= totalPageCount &&
                                handlePaginationClick(pageNoInput);
                            }}
                            disabled={
                              !applications ||
                              totalPageCount < 1 ||
                              pageNoInput > totalPageCount - 1
                            }
                          >
                            <i className="fe fe-chevron-right"></i>
                          </Button>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                  <Table
                    {...getTableProps()}
                    className="text-nowrap"
                    style={{ position: 'relative', zIndex: 1000 }}
                  >
                    <thead className="table-light">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {isLoading ? (
                        <tr>
                          <td
                            colspan={headerGroups[0].headers.length}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '30vh', // You can adjust this to your desired height
                            }}
                          >
                            <Spinner style={{ color: '#e0bf00' }} />
                          </td>
                        </tr>
                      ) : (
                        rows.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <td {...cell.getCellProps()}>
                                  {cell.render('Cell')}
                                </td>
                              ))}
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                  {/* Pagination @ Footer */}
                  <Pagination
                    previousPage={() => {
                      return handlePaginationClick(currentPageNo - 1);
                    }}
                    pageCount={totalPageCount}
                    pageIndex={currentPageNo - 1}
                    gotoPage={(index) => {
                      currentPageNo - 1 <= totalPageCount &&
                        handlePaginationClick(index + 1);
                    }}
                    nextPage={() => {
                      return (
                        currentPageNo - 1 <= totalPageCount &&
                        handlePaginationClick(currentPageNo + 1)
                      );
                    }}
                  />
                </div>
              </Card.Body>
            </Card>
          </Tab.Container>
        </Col>
      </Row>
      <ToastContainer />
    </Container>
  );
};

export default ApplicationList;
