// import node module libraries
import { Fragment, useEffect } from 'react';
import { Col, Row, Nav, Tab, Card, Container } from 'react-bootstrap';

// import data files
import CoursesSection from 'pages/kapStudent/dashboard/components/CoursesSection';
import MainSection from 'pages/kapStudent/dashboard/components/MainSection';
import { useSelector } from 'react-redux';
import { financegirl } from 'pages/assets/images/imageUrl';
import { useHistory } from 'react-router-dom';

const FinanceDashboard = () => {
  const history = useHistory();
  const { completeApplication } = useSelector((state) => state.userAuth);
  useEffect(() => {
    document.body.style.backgroundColor = '#f5f4f8';
  });

  const mainSection = {
    title: 'Finance',
    description:
      'Graduates will be provided with linkages to MSME finance products and can apply directly for grants, loans, investments etc through these links, and will be given assistance by the opportunities and placement team where required, including access to facilitating service providers/partners.',
    mainRedirect: 'View Finance',
    secondaryRedirect: !completeApplication ? 'Complete Application' : '',
    // mainRedirectLink: '/finance',
    secondaryRedirectLink: '/complete-registration',
    image: financegirl,
    popOverOnMain: !completeApplication,
  };

  return (
    <Fragment>
      <MainSection
        item={mainSection}
        setMainBtnPressed={(e) => {
          history.push(`/finance/all`);
        }}
      />
      <CoursesSection />
    </Fragment>
  );
};
export default FinanceDashboard;
