// import node module libraries
import { Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
import {
  NavDropdown,
  Badge,
  Overlay,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { WarningModal } from '../Modals/WarningModal';
import { v4 as uuid } from 'uuid';

const NavDropdownMain = (props) => {
  const { item, onClick } = props;
  const { user } = useSelector((state) => state.userAuth);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)',
  });

  const [showWarning, setShowWarning] = useState(false);
  const [warningText, setWarningText] = useState(
    'You need to complete your application and get admitted to access this programme.'
  );

  const getTitle = (item) => {
    return item.badge ? (
      <Fragment>
        {item.menuitem}
        <Badge
          className="ms-1"
          bg={item.badgecolor ? item.badgecolor : 'primary'}
        >
          {item.badge}
        </Badge>
      </Fragment>
    ) : (
      item.menuitem
    );
  };

  const NavbarDesktop = () => {
    return (
      <NavDropdown title={item.menuitem} show>
        {item.children.map((submenu, submenuindex) => {
          if (submenu.divider || submenu.header) {
            return submenu.divider ? (
              <NavDropdown.Divider bsPrefix="mx-3" key={submenuindex} />
            ) : (
              <h4 className="dropdown-header" key={submenuindex}>
                {/* Second level menu heading - its not a menu item */}
                {submenu.header_text}
              </h4>
            );
          } else {
            if (submenu.children === undefined) {
              // if (submenu?.available) {
              return (
                <NavDropdown.Item
                  key={submenuindex}
                  as={Link}
                  to={submenu.link}
                  onClick={(expandedMenu) => onClick(!expandedMenu)}
                >
                  {/* Second level menu item without having sub menu items */}
                  {submenu.menuitem}
                </NavDropdown.Item>
              );
              // } else {
              //   return (
              //     <OverlayTrigger
              //       key={uuid()}
              //       trigger={['hover', 'focus']}
              //       placement="right"
              //       overlay={
              //         <Popover id="popover-basic">
              //           <Popover.Header as="h3">
              //             Restricted Access
              //           </Popover.Header>
              //           <Popover.Body>
              //             You need to complete your application
              //           </Popover.Body>
              //         </Popover>
              //       }
              //     >
              //       <NavDropdown.Item
              //         key={submenuindex}
              //         onClick={(expandedMenu) => {
              //           setWarningText(
              //             !user?.application
              //               ? 'You need to complete your application or get admitted to access this programme.'
              //               : 'You need to have a level 1 certification or get admitted to access this programme.'
              //           );
              //           setShowWarning(true);
              //           return;
              //         }}
              //       >
              //         {/* Second level menu item without having sub menu items */}
              //         {submenu.menuitem}
              //       </NavDropdown.Item>
              //     </OverlayTrigger>
              //   );
              // }
            } else {
              return (
                <NavDropdown
                  title={getTitle(submenu)}
                  key={submenuindex}
                  bsPrefix="dropdown-item d-block"
                  className={`dropdown-submenu dropend py-0 `}
                  show
                >
                  {submenu.children.map((submenuitem, submenuitemindex) => {
                    if (submenuitem.divider || submenuitem.header) {
                      return submenuitem.divider ? (
                        <NavDropdown.Divider
                          bsPrefix="mx-3"
                          key={submenuitemindex}
                        />
                      ) : (
                        <Fragment key={submenuitemindex}>
                          {/* Third level menu heading with description  */}
                          <h5 className="dropdown-header text-dark">
                            {submenuitem.header_text}
                          </h5>
                          <p className="dropdown-text mb-0 text-wrap">
                            {submenuitem.description}
                          </p>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={submenuitemindex}>
                          {submenuitem.type === 'button' ? (
                            <div className="px-3 d-grid">
                              {/* Third Level with button format menu item */}
                              <Link
                                to={submenuitem.link}
                                className="btn btn-sm btn-primary text-center"
                              >
                                {submenuitem.menuitem}
                              </Link>
                            </div>
                          ) : (
                            <NavDropdown.Item
                              as={Link}
                              to={submenuitem.link}
                              onClick={(expandedMenu) => onClick(!expandedMenu)}
                            >
                              {/* Third Level menu item */}
                              {submenuitem.menuitem}
                            </NavDropdown.Item>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </NavDropdown>
              );
            }
          }
        })}
      </NavDropdown>
    );
  };

  const NavbarMobile = () => {
    return (
      <NavDropdown title={item.menuitem} className="mt-1">
        {item?.children.map((submenu, submenuindex) => {
          if (submenu.divider || submenu.header) {
            return submenu.divider ? (
              <NavDropdown.Divider bsPrefix="mx-3" key={submenuindex} />
            ) : (
              <h4 className="dropdown-header" key={submenuindex}>
                {/* Second level menu heading - its not a menu item */}
                {submenu.header_text}
              </h4>
            );
          } else {
            if (submenu.children === undefined) {
              if (submenu?.available) {
                return (
                  <NavDropdown.Item
                    key={submenuindex}
                    as={Link}
                    to={submenu.link}
                    onClick={(expandedMenu) => onClick(!expandedMenu)}
                  >
                    {/* Second level menu item without having sub menu items */}
                    {submenu.menuitem}
                  </NavDropdown.Item>
                );
              } else {
                return (
                  <OverlayTrigger
                    key={uuid()}
                    trigger={['hover', 'focus']}
                    placement="right"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Header as="h3">
                          Restricted Access
                        </Popover.Header>
                        <Popover.Body>
                          You need to complete your application
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <NavDropdown.Item
                      key={submenuindex}
                      onClick={(expandedMenu) => {
                        setWarningText(
                          !user?.application
                            ? 'You need to complete your application or get admitted to access this programme.'
                            : 'You need to have a level 1 certification or get admitted to access this programme.'
                        );
                        setShowWarning(true);
                        return;
                      }}
                    >
                      {/* Second level menu item without having sub menu items */}
                      {submenu.menuitem}
                    </NavDropdown.Item>
                  </OverlayTrigger>
                );
              }
            } else {
              return (
                <NavDropdown
                  title={getTitle(submenu)}
                  key={submenuindex}
                  bsPrefix="dropdown-item d-block"
                  className={`dropdown-submenu dropend py-0 `}
                >
                  {submenu.children.map((submenuitem, submenuitemindex) => {
                    if (submenuitem.divider || submenuitem.header) {
                      return submenuitem.divider ? (
                        <NavDropdown.Divider
                          bsPrefix="mx-3"
                          key={submenuitemindex}
                        />
                      ) : (
                        <Fragment key={submenuitemindex}>
                          {/* Third level menu heading with description  */}
                          <h5 className="dropdown-header text-dark">
                            {submenuitem.header_text}
                          </h5>
                          <p className="dropdown-text mb-0 text-wrap">
                            {submenuitem.description}
                          </p>
                        </Fragment>
                      );
                    } else {
                      return (
                        <Fragment key={submenuitemindex}>
                          {submenuitem.type === 'button' ? (
                            <div className="px-3 d-grid">
                              {/* Third Level with button format menu item */}
                              <Link
                                to={submenuitem.link}
                                className="btn-sm btn-primary text-center"
                              >
                                {submenuitem.menuitem}
                              </Link>
                            </div>
                          ) : (
                            <NavDropdown.Item
                              as={Link}
                              to={submenuitem.link}
                              onClick={(expandedMenu) => onClick(!expandedMenu)}
                            >
                              {/* Third Level menu item */}
                              {submenuitem.menuitem}
                            </NavDropdown.Item>
                          )}
                        </Fragment>
                      );
                    }
                  })}
                </NavDropdown>
              );
            }
          }
        })}
      </NavDropdown>
    );
  };
  return (
    <Fragment>
      {/* There is only one setting between NavbarDesktop and NavbarMobile component i.e. show property used with <NavDropdown show> tag */}
      {isDesktop ? <NavbarDesktop /> : <NavbarMobile />}
      <WarningModal
        showModal={showWarning}
        setShowModal={setShowWarning}
        text={warningText}
      />
    </Fragment>
  );
};
export default NavDropdownMain;
