// import node module libraries
import {
  Container,
  Row,
  Col,
  CardGroup,
  OverlayTrigger,
  Overlay,
  Card,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { v4 as uuid } from 'uuid';
import SectionHeadingCenter from './components/SectionHeadingCenter';
import ReactPlayer from 'react-player';

const AboutUs = ({ bgColor }) => {
  const title = 'KAP Academy';
  const subtitle = 'About Us';

  return (
    <Fragment>
      <div className={`py-4 py-lg-4 ${bgColor || 'bg-white'}`}>
        <Container>
          <SectionHeadingCenter title={title} subtitle={subtitle} />
          <Row className="mb-6">
            {/* <div>
              <ReactPlayer
                url={'https://youtu.be/QYHplv-Hz2Y'}
                playing={false}
                controls={true}
                width="100%"
                height="600px"
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
              />
            </div> */}
            <Card className="mb-1">
              <Card.Text className="fs-3 mb-1">
                {`Founded by Kunle Afolayan, one of Nigeria’s foremost film
                makers, KAP Film & Television Academy is a film production
                learning institute that imparts prospective filmmakers with
                knowledge and skills needed to fulfill their dream of becoming a
                filmmaker.`}
              </Card.Text>
              <Card.Text className="fs-3 mb-1">
                {`Through a robust curriculum both online and offline,
                we teach a wide range of film making courses from scriptwriting,
                editing, sound, business of film etc Our goal is to enable
                capacity building in the film industry both locally and
                internationally.`}
              </Card.Text>
              <Card.Text className="fs-3 mb-1">
                {`With first-hand learning experience from
                industry experts and thought leaders, we encourage our students
                to MAKE films, not just TALK film.`}
              </Card.Text>
            </Card>
          </Row>
        </Container>
      </div>
      <div className={`py-8 py-lg-8 bg-black`}>
        <Container>
          <SectionHeadingCenter
            title={'Contact Us'}
            textColor="text-primary"
            textStyle={{ justifyContent: 'flex-start' }}
          />
          <Row className="d-flex justify-content-center align-items-center">
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-mail me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                info@kap.academy
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-mail me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                support@kap.academy
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-instagram me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                @kapfilmandtelevisionacademy
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-twitter me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                @kapfilmacademy
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-facebook me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                KAP Film and Television
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-phone me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                +234 9168336311
              </p>
            </Col>
            <Col
              className="d-flex align-items-center mb-4 justify-content-center"
              xl={4}
              lg={4}
              md={6}
              sm={12}
            >
              <i
                className={`fe fe-youtube me-4 primary`}
                style={{ color: '#e0bf00', fontSize: '40px' }}
              ></i>
              <p
                className={`fs-3 mb-0`}
                style={{ color: '#e0bf00', fontSize: '25px' }}
              >
                KAPTV
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
export default AboutUs;
