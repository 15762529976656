import { Loader } from "pages/components/Loader";
import {
  areFileListsEqual,
  extractPropsByInputArray,
  generateNaturalNumbers,
  getAdjacentObject,
  getFilenames,
  getNextObjectById,
  isLastObjectByProperty,
  isSubstringInArray,
  sortByProperty,
  updateObjects,
} from "pages/helper/formatArray";
import {
  useEnrollStudentForCourseMutation,
  useGetQuizByIdMutation,
  useGetStudentQuizAnswersMutation,
  useGetStudentQuizMutation,
  useGetStudentQuizResultsMutation,
  useGetSurveyByIdMutation,
  useStartQuizMutation,
  useSubmitAnswerMutation,
  useSubmitQuizMutation,
  useSubmitSurveyQuestionAnswerMutation,
} from "pages/redux/features/user/userApi";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Radio, RadioGroup } from "react-radio-group";
import CheckboxGroup from "react-checkbox-group";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import { logFormData, objectToFormData } from "pages/helper/objectToFormData";
import ProgramDetails from "pages/kapAdmin/programmes/ProgramDetails";
import { capitalize } from "pages/helper/formatText";
import MatrixQuestion from "./components/MatrixQuestion";
import { useMemo } from "react";
import { setStopSurvey } from "pages/redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import SurveyCompleteModal from "../dashboard/components/SurveyCompleteModal";

const Survey = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.userAuth);
  const id = location?.state?.id || props?.match?.params?.id;
  const title = location?.state?.title || props?.match?.params?.title;

  const [surveyDetails, setSurveyDetails] = useState(null);

  const [completeModalShow, setCompleteModalShow] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [questionKey, setQuestionKey] = useState(1);
  const [unansweredQuestions, setUnansweredQuestion] = useState(null);
  const [showUnanswered, setShowUnanswered] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showTimer, setShowTimer] = useState(true);

  const [submitSurveyQuestionAnswer, { isLoading: isSubmittingAnswer }] = useSubmitSurveyQuestionAnswerMutation();
  const [getSurveyById, { isLoading: isLoadingSurvey }] = useGetSurveyByIdMutation();

  const handleUnansweredClose = () => {
    setShowUnanswered(false);
  };

  const fetchSurveyById = async () => {
    const res = await getSurveyById({ id });
    if (res?.data?.success) {
      setSurveyDetails(res?.data?.data?.survey);
      const qst = res?.data?.data?.survey?.questions?.map((x) => {
        if (x?.response_type === "gradation") {
          return {
            ...x,
            answer: new Array(x?.sub_questions?.length).fill(null),
          };
        } else {
          return x;
        }
      });
      setQuestions(qst);
      setQuestionDetails(qst[0]);
    } else {
      toast.error(res?.error?.data?.message || "Error!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.goBack();
    }
  };

  useEffect(() => {
    fetchSurveyById();
  }, [id]);

  const handleAnswerChange = (answer) => {
    setQuestionDetails({ ...questionDetails, answer });
  };

  const handleAnswerSubmission = async (question) => {
    let answer = {};
    answer.survey_id = id;
    if (question?.response_type == "gradation") {
      answer.survey_question_id = question?.id;
      if (question?.sub_questions?.length > 0) {
        let extr = [];
        question?.sub_questions?.map((x, index) => extr.push(x !== "" ? x + " - " + question?.answer[index] : question?.answer[index]));
        answer.response = extr.join(",\n");
      } else {
        answer.response = question?.answer;
      }
      const formData = objectToFormData(answer);

      logFormData(formData);
      const res = await submitSurveyQuestionAnswer(formData);
      if (res?.data?.success || res?.error?.data?.message?.toLowerCase()?.includes("duplicate entry")) {
        const nextProb = getAdjacentObject(questions, questionDetails?.id, "id", "next");
        if (nextProb) {
          setQuestionKey(nextProb?.number);
          setQuestionDetails(questions?.find((y) => y?.number == nextProb?.number));
        }
      } else {
        toast.error(res?.error?.data?.message || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      answer.survey_question_id = question?.id;
      answer.response = question?.answer;
      const res = await submitSurveyQuestionAnswer(answer);
      if (res?.data?.success || res?.error?.data?.message?.toLowerCase()?.includes("duplicate entry")) {
        if (
          questionDetails?.answer == "No" &&
          questionDetails?.question_statement?.toLowerCase()?.includes("do you consider yourself to have a physical disability")
        ) {
          const nextProb = getAdjacentObject(questions, questionDetails?.id, "id", "next");
          const nextNextProb = getAdjacentObject(questions, nextProb?.id, "id", "next");
          if (nextNextProb) {
            setQuestionKey(nextNextProb?.number);
            setQuestionDetails(questions?.find((y) => y?.number == nextNextProb?.number));
          }
        } else if (
          questionDetails?.answer == "I have not completed the KAP training program" &&
          questionDetails?.question_statement?.toLowerCase()?.includes("Please indicate how you completed the KAP program".toLowerCase())
        ) {
          dispatch(setStopSurvey(true));
          setCompleteModalShow(true);
        } else {
          const nextProb = getAdjacentObject(questions, questionDetails?.id, "id", "next");
          if (nextProb) {
            setQuestionKey(nextProb?.number);
            setQuestionDetails(questions?.find((y) => y?.number == nextProb?.number));
          }
        }
      } else {
        toast.error(res?.error?.data?.message || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  function checkArray(arr, length) {
    if (arr.length !== length) {
      return false;
    }

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === null) {
        return false;
      }
    }

    return true;
  }

  const handleNextQuestion = async () => {
    let newProb = questions;
    if (questionDetails?.answer !== questions?.find((obj) => obj?.id == questionDetails?.id)?.answer) {
      newProb = updateObjects(newProb, (obj) => obj?.id == questionDetails?.id, "answer", questionDetails?.answer);
      setQuestions(newProb);
    }

    if (questionDetails?.answer !== questions?.find((obj) => obj?.id == questionDetails?.id)?.answer) {
      await handleAnswerSubmission(questionDetails);
    }
  };

  const fileInputRef = useRef(null);
  const handleDeleteFile = (fileToDelete) => {
    const filesArray = Array.from(questionDetails?.files);
    const filteredFilesArray = filesArray.filter((file) => file.name !== fileToDelete.name);
    const newFileList = createFileList(filteredFilesArray);
    const updatedObject = Object.assign({}, questionDetails, {
      files: newFileList,
    });
    setQuestionDetails(updatedObject);
  };

  const createFileList = (array) => {
    const list = new DataTransfer();
    for (let i = 0; i < array.length; i++) {
      list.items.add(array[i]);
    }
    return list.files;
  };

  if (isLoadingSurvey || !surveyDetails || !questions) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Container className="py-lg-5 py-5 overflow-auto min-vh-100">
        {!props?.match?.params?.id && (
          <Row className="mb-2 mb-xl-0">
            <Card className="mb-2">
              <Card.Body>
                <div className="d-flex align-items-center">
                  <h3 className="fw-semi-bold">{surveyDetails?.title || title}</h3>
                </div>
              </Card.Body>
            </Card>
          </Row>
        )}
        <div className="d-flex justify-content-between mt-3 mb-3">
          <h4>
            Question {questionKey}/{questions?.length || "0"}
          </h4>
        </div>
        {/* <Row>
          {questions?.map((x, index) => (
            <Button
              className="justify-content-center"
              style={{
                width: 40,
                height: 40,
                marginRight: 15,
                marginBottom: 15,
                padding: 0,
                backgroundColor:
                  questionKey == x?.number
                    ? '#e0bf00'
                    : (x?.answer && x?.type != 'multiselect') ||
                      (x?.type == 'multiselect' &&
                        Array.isArray(x?.answer) &&
                        x?.answer?.length > 0)
                    ? '#219653'
                    : '#EDF2F7',
                color:
                  (x?.answer && x?.type != 'multiselect') ||
                  (x?.type == 'multiselect' &&
                    Array.isArray(x?.answer) &&
                    x?.answer?.length > 0)
                    ? 'white'
                    : 'black',
              }}
              variant="transparent"
              onClick={() => {
                setQuestionKey(x?.number);
                setQuestionDetails(
                  questions?.find((y) => y?.number == x?.number)
                );
              }}
            >
              {x?.number}
            </Button>
          ))}
        </Row> */}
        <Row className="mt-3 mb-xl-0">
          <Card className="mb-2">
            <Card.Title className="mt-4 mx-3 mb-0">
              <h4 className="fw-semi-bold">{questionDetails?.question_statement}</h4>
            </Card.Title>
            <Card.Body className="mt-0" style={{ minHeight: 300 }}>
              {questionDetails?.response_type == "options" && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={questionDetails?.answer}
                  onChange={(e) => {
                    handleAnswerChange(e);
                  }}
                  disabled={isSubmitted}
                >
                  {questionDetails?.options?.map((y) => (
                    <Col className="d-flex align-item-center">
                      <Radio
                        key={uuid()}
                        className="my-radio"
                        value={y}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "3px",
                          "--active-color": "green",
                        }}
                        disabled={isSubmitted}
                      />
                      <p className=" fs-4 fw-semi-bold mx-3">{y}</p>
                    </Col>
                  ))}
                </RadioGroup>
              )}
              {questionDetails?.response_type == "free_text" && (
                <Form.Control
                  as="textarea"
                  rows={10}
                  id="answer"
                  placeholder="Answer"
                  required
                  disabled={isSubmitted}
                  value={questionDetails?.answer}
                  onChange={(e) => handleAnswerChange(e?.target?.value)}
                />
              )}
              {questionDetails?.response_type == "gradation" && (
                <MatrixQuestion
                  key={uuid()}
                  questions={questionDetails?.sub_questions}
                  options={questionDetails?.gradation_labels}
                  answers={questionDetails?.answer}
                  setAnswers={(newAnswer) => {
                    // const newAnswers = [...questionDetails?.answer];
                    // newAnswers[index] = newAnswer[0];
                    setQuestionDetails({
                      ...questionDetails,
                      answer: newAnswer,
                    });
                  }}
                />
              )}
              {questionDetails?.response_type == "binary" && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={capitalize(questionDetails?.answer)}
                  onChange={(e) => {
                    handleAnswerChange(e);
                  }}
                  disabled={isSubmitted}
                >
                  {questionDetails?.options?.map((y) => (
                    <Col className="d-flex align-item-center">
                      <Radio
                        key={uuid()}
                        className="my-radio"
                        value={y?.text}
                        style={{
                          width: "18px",
                          height: "18px",
                          marginTop: "3px",
                          "--active-color": "green",
                        }}
                        disabled={isSubmitted}
                      />
                      <p className=" fs-4 fw-semi-bold mx-3">{y?.text}</p>
                    </Col>
                  ))}
                </RadioGroup>
              )}

              {questionDetails?.response_type == "multiselect" && (
                <CheckboxGroup
                  key={uuid()}
                  name="checkboxGroup"
                  value={questionDetails?.answer}
                  onChange={(e) => {
                    setQuestionDetails({ ...questionDetails, answer: e });
                  }}
                  disabled={isSubmitted}
                >
                  {(Checkbox) => (
                    <>
                      {questionDetails?.options?.map((y) => (
                        <Col className="d-flex align-item-center">
                          <Checkbox
                            key={uuid()}
                            className="my-checkbox"
                            value={y?.text}
                            style={{
                              width: "18px",
                              height: "18px",
                              marginTop: "3px",
                              "--active-color": "green",
                            }}
                            disabled={isSubmitted}
                          />
                          <p className=" fs-4 fw-semi-bold mx-3">{y?.text}</p>
                        </Col>
                      ))}
                    </>
                  )}
                </CheckboxGroup>
              )}

              {questionDetails?.has_attachment && questionDetails?.has_attachment != 0 ? (
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Attachments</Form.Label>
                  {Array.from(questionDetails?.files).map((file) => (
                    <div className="me-3 mb-3 d-flex align-items-center justify-content-between">
                      <p className="mb-0">{file.name}</p>
                      <i className="fe fe-trash" onClick={() => handleDeleteFile(file)}></i>
                    </div>
                  ))}
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    disabled={isSubmitted}
                    id={questionDetails?.number}
                    key={questionDetails?.number}
                    multiple
                    onChange={(e) => {
                      setQuestionDetails({
                        ...questionDetails,
                        files: e.target.files,
                      });
                    }}
                  />
                </Form.Group>
              ) : (
                <></>
              )}
            </Card.Body>
          </Card>
        </Row>
        {questionDetails && !isSubmitted && (
          <Col
            className={
              // questionKey === 1
              //   ?
              "d-flex justify-content-end mt-4"
              // : 'd-flex justify-content-between mt-4'
            }
          >
            {/* {questionKey !== 1 && (
              <Button
                onClick={() => {
                  const prevProb = getAdjacentObject(
                    questions,
                    questionDetails?.id,
                    'id',
                    'prev'
                  );
                  if (prevProb) {
                    setQuestionKey(prevProb?.number);
                    setQuestionDetails(
                      questions?.find((y) => y?.number == prevProb?.number)
                    );
                  }
                }}
                variant="secondary"
                disabled={isSubmittingAnswer}
              >
                Previous
              </Button>
            )} */}
            <Button
              disabled={
                isSubmittingAnswer ||
                (!(questionDetails?.answer && questionDetails?.response_type != "gradation") &&
                  !(
                    questionDetails?.response_type == "gradation" &&
                    Array.isArray(questionDetails?.answer) &&
                    checkArray(questionDetails?.answer, questionDetails?.sub_questions?.length)
                  ))
              }
              onClick={
                isLastObjectByProperty(questions, questionDetails, "id") &&
                ((questions?.find((y) => y?.id == questionDetails?.id)?.answer &&
                  questions?.find((y) => y?.id == questionDetails?.id)?.response_type != "gradation") ||
                  (questions?.find((y) => y?.id == questionDetails?.id)?.response_type == "gradation" &&
                    Array.isArray(questions?.find((y) => y?.id == questionDetails?.id)?.answer) &&
                    checkArray(questions?.find((y) => y?.id == questionDetails?.id)?.answer, questionDetails?.sub_questions?.length)))
                  ? () => {
                      dispatch(setStopSurvey(true));
                      setCompleteModalShow(true);
                    }
                  : handleNextQuestion
              }
            >
              {isLastObjectByProperty(questions, questionDetails, "id") &&
              ((questions?.find((y) => y?.id == questionDetails?.id)?.answer &&
                questions?.find((y) => y?.id == questionDetails?.id)?.response_type != "gradation") ||
                (questions?.find((y) => y?.id == questionDetails?.id)?.response_type == "gradation" &&
                  Array.isArray(questions?.find((y) => y?.id == questionDetails?.id)?.answer) &&
                  checkArray(questions?.find((y) => y?.id == questionDetails?.id)?.answer, questionDetails?.sub_questions?.length)))
                ? "Submit"
                : isLastObjectByProperty(questions, questionDetails, "id")
                ? "Next"
                : "Next"}
            </Button>
          </Col>
        )}
      </Container>
      <SurveyCompleteModal
        show={completeModalShow}
        onHide={() => {
          setCompleteModalShow(false);
          history.push("/sis/redirect");
        }}
      />
    </Fragment>
  );
};

export default Survey;
