// import node module libraries
import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  InputGroup,
  Modal,
} from 'react-bootstrap';

// import media files
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSignupUserMutation } from 'pages/redux/features/auth/authApi';
import { setPageState, setRole } from 'pages/redux/features/auth/authSlice';
import * as Yup from 'yup';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomButton } from 'pages/components/CustomButton';
import { logosblack } from 'pages/assets/images/imageUrl';

const Register = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [showTerms, setShowTerms] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [icon, setIcon] = useState(eyeOff);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [icon1, setIcon1] = useState(eyeOff);

  const handleTermsClose = () => {
    setShowTerms(false);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const togglePassword1 = () => {
    setPasswordShown1(!passwordShown1);
  };

  const [signupUser, { isLoading: isSigningUp }] = useSignupUserMutation();
  const handleSignup = async (values) => {
    const res = await signupUser(values);
    if (res?.data?.success) {
      dispatch(setRole('student'));
      dispatch(setPageState('verify-account'));
      history.push({
        pathname: '/verify-account',
        state: {
          detail: res?.data?.message,
          type: 'register',
          email: values?.email,
        },
      });
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    phone: Yup.string()
      // .matches(
      //   phoneRegExp,
      //   'Phonenumber is not valid, correct format -> 08012345678'
      // )
      .required('Phonenumber is required')
      // .min(8, 'Enter a valid phone number')
      .label('Phone number'),
    password: Yup.string().required('Password is required').min(8),
    confirmpassword: Yup.string('Confirm password is required')
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      password: '',
      confirmpassword: '',
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        phone: values?.phone?.replace('+', ''),
      };
      handleSignup(resolveValues);
    },
  });
  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        {/* <Col xl={7} lg={6} md={0} className='text-lg-end text-center'>
          <Image src={BackgroundImage} alt='' className='img-fluid' />
        </Col> */}
        <Col lg={9} md={9} className="py-8 py-xl-0">
          <Card>
            <Card.Header className="mb-1">
              <Image src={logosblack} alt="" className="img-fluid w-50" />
            </Card.Header>
            <Card.Body className="p-6">
              <div className="mb-4">
                <h1 className="mb-1 fw-bold">Register</h1>
                <span>
                  Already have an account?{' '}
                  <Link to="/login" className="ms-1">
                    Login
                  </Link>
                </span>
              </div>
              {/* Form */}
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="firstname"
                      placeholder="First Name"
                      required
                      onChange={formik.handleChange}
                    />
                    {formik.errors.firstname && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.firstname}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      id="lastname"
                      placeholder="Last Name"
                      required
                      onChange={formik.handleChange}
                    />
                    {formik.errors.lastname && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.lastname}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      id="phone"
                      placeholder="Phone Number"
                      required
                      onChange={(e) => {
                        formik.setFieldValue('phone', e?.target?.value);
                      }}
                    />
                    {/* <PhoneInput
                      country={'ng'}
                      value={formik.values?.phone}
                      onChange={formik.handleChange}
                      inputStyle={{
                        width: '100%',
                        height: '47px',
                      }}
                    /> */}
                    {formik.errors.phone && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.phone}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* email */}
                    <Form.Label>Email </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder="Email address here"
                      required
                      onChange={formik.handleChange}
                    />
                    {formik.errors.email && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.email}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>Password </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        id="password"
                        placeholder="**************"
                        required
                        onChange={formik.handleChange}
                      />

                      <Button
                        variant="outline-secondary"
                        onClick={togglePassword}
                      >
                        <Icon icon={passwordShown ? eye : eyeOff} size={18} />
                      </Button>
                    </InputGroup>
                    {formik.errors.password && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.password}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>Confirm Password </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={passwordShown1 ? 'text' : 'password'}
                        id="confirmpassword"
                        placeholder="**************"
                        required
                        onChange={formik.handleChange}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={togglePassword1}
                      >
                        <Icon icon={passwordShown1 ? eye : eyeOff} size={18} />
                      </Button>
                    </InputGroup>
                    {formik.errors.confirmpassword && (
                      <p className="fs-8 text-danger mb-0">
                        {formik.errors.confirmpassword}
                      </p>
                    )}
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <Form.Check
                      type="checkbox"
                      id="check-api-checkbox"
                      required
                    >
                      <Form.Check.Input type="checkbox" required />
                      <Form.Check.Label>
                        I agree to the
                        <Link to="#" onClick={() => setShowTerms(true)}>
                          {' '}
                          Terms and Conditions.{' '}
                        </Link>
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>

                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    <CustomButton
                      variant="primary"
                      type="submit"
                      text="Register"
                      loading={isSigningUp}
                    />
                  </Col>
                </Row>
              </Form>
              <hr className="my-4" />
              {/* <div className="mt-4 text-center">
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-facebook"
                >
                  <i className="fab fa-facebook"></i>
                </Link>{' '}
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-twitter"
                >
                  <i className="fab fa-twitter"></i>
                </Link>{' '}
                <Link
                  to="#"
                  className="btn-social btn-social-outline btn-google"
                >
                  <i className="fab fa-google"></i>
                </Link>
              </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
      <Modal show={showTerms} onHide={handleTermsClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ justifyContent: 'center' }}>
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Terms />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

var Terms = () => {
  return (
    <div style={{ maxHeight: '700px', overflow: 'auto' }}>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Last updated: 2022-06-22
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          1.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Introduction
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Welcome to&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          E-Learning Application
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          ! By using this application, you agree to these terms and conditions
          related to the use of personal information, safeguarding and
          guidelines for using the e-learning application. Please read these
          terms carefully before using our application.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These Terms of Service (“Terms”, “Terms of Service”) govern your use
          of our mobile and web application&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film and Television E-Learning App&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (together or individually “Service”) operated by&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          (“The Academy”, “we”, “our”, “us”).
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our Privacy Policy also governs your use of our Service and explains
          how we collect, safeguard and disclose information that results from
          your use of our application.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your agreement with us includes these Terms and our Privacy Policy
          (“Agreements”). You acknowledge that you have read and understood
          Agreements, and agree to be bound of them.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you do not agree with (or cannot comply with) Agreements, then you
          may not use the Service, but please let us know by emailing at&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: '#ffffff',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          so we can try to find a solution. These Terms apply to all visitors,
          users and others who wish to access or use Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          2. Consent to Use Personal Information
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By using this e-learning application, you agree to provide us with
          your personal information, such as your name, email address and other
          details (pictures, videos, testimonials, etc). This information is
          necessary for us to provide you with our e-learning services,
          including updates, notifications and other relevant information. We
          will use this information only for teh purpose of providing our
          services to you and wall not disclose it to a third party without your
          consent.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          3.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Safeguarding
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We take your privacy and security seriously and we have put in place
          appropriate measures to safeguard your personal information. We use
          secure servers and encryption technologies to protect your information
          from unauthorized access or disclosure. However, we cannot guarantee
          that our security measures will prevent all unauthorized access to
          your information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          4.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Guidelines for Using the KAP E-Learning Application
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We expect all users of our e-learning application to comply with the
          following guidelines:
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '14pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use the KAP e-learning application only for educational purposes
              and not for any illegal or unauthorized activities.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use the KAP educational tablet only for educational purposes and
              not for any illegal or unauthorized activities.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Do not share your login credentials with anyone else, and do not
              allow anyone else to use your account.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Respect the intellectual property rights of others and do not use
              any copyrighted material without permission.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Do not engage in any harassing, abusive, or discriminatory
              behavior towards other users.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '14pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Report any inappropriate behavior or content to the application
              administrators.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          5.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Communications
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By using our Service, you agree to subscribe to newsletters, marketing
          or promotional materials and other information we may send. However,
          you may opt out of receiving any, or all, of these communications from
          us by following the unsubscribe link or by emailing at&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          6.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Purchases
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you wish to purchase any product or service made available through
          Service (“Purchase”), you may be asked to supply certain information
          relevant to your Purchase including but not limited to, your credit or
          debit card number, the expiration date of your card, your billing
          address, and your shipping information.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You represent and warrant that: (i) you have the legal right to use
          any card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may employ the use of third party services for the purpose of
          facilitating payment and the completion of Purchases. By submitting
          your information, you grant us the right to provide the information to
          these third parties subject to our Privacy Policy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to: product or service availability,
          errors in the description or price of the product or service, error in
          your order or other reasons.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          7.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Contests, Sweepstakes and Promotions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Any contests, sweepstakes or other promotions (collectively,
          “Promotions”) made available through Service may be governed by rules
          that are separate from these Terms of Service. If you participate in
          any Promotions, please review the applicable rules as well as our
          Privacy Policy. If the rules for a Promotion conflict with these Terms
          of Service, Promotion rules will apply.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          8.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Subscriptions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Some parts of Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles will be set depending
          on the type of subscription plan you select when purchasing a
          Subscription.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or KAP
          Film/Television Academy cancels it. You may cancel your Subscription
          renewal either through your online account management page or by
          contacting support@kapfilmtelevisionacademy.ng customer support team.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          A valid payment method is required to process the payment for your
          subscription. You shall provide KAP Film/Television Academy with
          accurate and complete billing information that may include but not
          limited to full name, address, state, postal or zip code, telephone
          number, and a valid payment method information. By submitting such
          payment information, you automatically authorize KAP Film/Television
          Academy to charge all Subscription fees incurred through your account
          to any such payment instruments.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Should automatic billing fail to occur for any reason, KAP
          Film/Television Academy reserves the right to terminate your access to
          the Service with immediate effect.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          9.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          No
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Free Trial
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy does not offer a Subscription with a free
          trial.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          10.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Fee Changes
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy, in its sole discretion and at any time,
          may modify Subscription fees for the Subscriptions. Any Subscription
          fee change will become effective at the end of the then-current
          Billing Cycle.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy will provide you with a reasonable prior
          notice of any change in Subscription fees to give you an opportunity
          to terminate your Subscription before such change becomes effective.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your continued use of Service after Subscription fee change comes into
          effect constitutes your agreement to pay the modified Subscription fee
          amount.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          11.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Refunds
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We do not issue refunds for paid subscriptions.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          12.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Content
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material (“Content”). You are responsible for Content that you post on
          or through Service, including its legality, reliability, and
          appropriateness.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By posting Content on or through Service, You represent and warrant
          that: (i) Content is yours (you own it) and/or you have the right to
          use it and the right to grant us the rights and license as provided in
          these Terms, and (ii) that the posting of your Content on or through
          Service does not violate the privacy rights, publicity rights,
          copyrights, contract rights or any other rights of any person or
          entity. We reserve the right to terminate the account of anyone found
          to be infringing on a copyright.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You retain any and all of your rights to any Content you submit, post
          or display on or through Service and you are responsible for
          protecting those rights. We take no responsibility and assume no
          liability for Content you or any third party posts on or through
          Service. However, by posting Content using Service you grant us the
          right and license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such Content on and through Service. You
          agree that this license includes the right for us to make your Content
          available to other users of Service, who may also use your Content
          subject to these Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy has the right but not the obligation to
          monitor and edit all Content provided by users.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          In addition, Content found on or through this Service are the property
          of KAP Film/Television Academy or used with permission. You may not
          distribute, modify, transmit, reuse, download, repost, copy, or use
          said Content, whether in whole or in part, for commercial purposes or
          for personal gain, without express advance written permission from us.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          13&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Prohibited Uses
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may use Service only for lawful purposes and in accordance with
          Terms. You agree not to use Service:
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '14pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              In any way that violates any applicable national or international
              law or regulation.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              &nbsp;For the purpose of exploiting, harming, or attempting to
              exploit or harm minors in any way by exposing them to
              inappropriate content or otherwise.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail”, “chain letter,”
              “spam,” or any other similar solicitation.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              To impersonate or attempt to impersonate The Academy, a The
              Academy employee, another user, or any other person or entity.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              In any way that infringes upon the rights of others, or in any way
              is illegal, threatening, fraudulent, or harmful, or in connection
              with any unlawful, illegal, fraudulent, or harmful purpose or
              activity.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '14pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of Service, or which, as determined by us, may
              harm or offend The Academy or users of Service or expose them to
              liability.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Additionally, you agree not to:
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '14pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use Service in any manner that could disable, overburden, damage,
              or impair Service or interfere with any other party’s use of
              Service, including their ability to engage in real time activities
              through Service.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use any robot, spider, or other automatic device, process, or
              means to access Service for any purpose, including monitoring or
              copying any of the material on Service.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use any manual process to monitor or copy any of the material on
              Service or for any other unauthorized purpose without our prior
              written consent.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Use any device, software, or routine that interferes with the
              proper working of Service.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Introduce any viruses, trojan horses, worms, logic bombs, or other
              material which is malicious or technologically harmful.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of Service, the server on which Service is
              stored, or any server, computer, or database connected to Service.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Attack Service via a denial-of-service attack or a distributed
              denial-of-service attack.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Take any action that may damage or falsify The Academy rating.
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '14pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              Otherwise attempt to interfere with the proper working of Service.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          14.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Analytics
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may use third-party Service Providers to monitor and analyze the
          use of our Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          15.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          No Use By Children
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Service is intended only for access and use by individuals at least
          thirtteen (13) years old. By accessing or using Service, you warrant
          and represent that you are at least thirteen (13) years of age and
          with the full authority, right, and capacity to enter into this
          agreement and abide by all of the terms and conditions of Terms. If
          you are not at least thirteen (13) years old, you are prohibited from
          both the access and usage of Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          16.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Accounts
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trademark that is
          subject to any rights of another person or entity other than you,
          without appropriate authorization. You may not use as a username any
          name that is offensive, vulgar or obscene.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to refuse service, terminate accounts, remove or
          edit content, or cancel orders in our sole discretion.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          17.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Intellectual Property
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of KAP Film/Television Academy and its licensors. Service is
          protected by copyright, trademark, and other laws of and foreign
          countries. Our trademarks may not be used in connection with any
          product or service without the prior written consent of KAP
          Film/Television Academy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          18.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Copyright Policy
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We respect the intellectual property rights of others. It is our
          policy to respond to any claim that Content posted on Service
          infringes on the copyright or other intellectual property rights
          (“Infringement”) of any person or entity.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you are a copyright owner, or authorized on behalf of one, and you
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement, please submit your claim via email
          to&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          , with the subject line: “Copyright Infringement” and include in your
          claim a detailed description of the alleged Infringement as detailed
          below.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may be held accountable for damages (including costs and
          attorneys’ fees) for misrepresentation or bad-faith claims on the
          infringement of any Content found on and/or through Service on your
          copyright.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          19.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Notice and Procedure for Copyright Infringement Claims
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may submit a notification by providing our Copyright Agent with
          the following information in writing:
        </span>
      </p>
      <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: '48px' }}>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '14pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright’s interest;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              a description of the copyrighted work that you claim has been
              infringed, including the URL (i.e., web page address) of the
              location where the copyrighted work exists or a copy of the
              copyrighted work;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              identification of the URL or other specific location on Service
              where the material that you claim is infringing is located;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              your address, telephone number, and email address;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '0pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </span>
          </p>
        </li>
        <li
          aria-level={1}
          dir="ltr"
          style={{
            listStyleType: 'lower-roman',
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre',
            marginLeft: '-18pt',
            paddingLeft: '18pt',
          }}
        >
          <p
            dir="ltr"
            style={{
              lineHeight: '1.2',
              textAlign: 'justify',
              marginTop: '0pt',
              marginBottom: '14pt',
            }}
          >
            <span
              style={{
                fontSize: '11pt',
                color: '#000000',
                backgroundColor: 'transparent',
                fontWeight: 400,
                fontStyle: 'normal',
                fontVariant: 'normal',
                textDecoration: 'none',
                verticalAlign: 'baseline',
                whiteSpace: 'pre-wrap',
              }}
            >
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </span>
          </p>
        </li>
      </ol>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You can contact our Copyright Agent via email at&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          20.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Error Reporting and Feedback
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          You may provide us either directly at&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: '#ffffff',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          &nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          or via third party sites and tools with information and feedback
          concerning errors, suggestions for improvements, ideas, problems,
          complaints, and other matters related to our Service (“Feedback”). You
          acknowledge and agree that: (i) you shall not retain, acquire or
          assert any intellectual property right or other right, title or
          interest in or to the Feedback; (ii) The Academy may have development
          ideas similar to the Feedback; (iii) Feedback does not contain
          confidential information or proprietary information from you or any
          third party; and (iv) The Academy is not under any obligation of
          confidentiality with respect to the Feedback. In the event the
          transfer of the ownership to the Feedback is not possible due to
          applicable mandatory laws, you grant The Academy and its affiliates an
          exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
          unlimited and perpetual right to use (including copy, modify, create
          derivative works, publish, distribute and commercialize) Feedback in
          any manner and for any purpose.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          21.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Links To Other Web Sites
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by KAP Film/Television Academy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          KAP Film/Television Academy has no control over, and assumes no
          responsibility for the content, privacy policies, or practices of any
          third party web sites or services. We do not warrant the offerings of
          any of these entities/individuals or their websites.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          YOU ACKNOWLEDGE AND AGREE THAT THE ACADEMY SHALL NOT BE RESPONSIBLE OR
          LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
          ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
          ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
          THIRD PARTY WEB SITES OR SERVICES.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
          POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          22.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Disclaimer Of Warranty
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          THESE SERVICES ARE PROVIDED BY THE ACADEMY ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. THE ACADEMY MAKES NO REPRESENTATIONS OR WARRANTIES
          OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR
          SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN.
          YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT,
          AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          NEITHER THE ACADEMY NOR ANY PERSON ASSOCIATED WITH THE ACADEMY MAKES
          ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
          SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
          SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE ACADEMY NOR
          ANYONE ASSOCIATED WITH THE ACADEMY REPRESENTS OR WARRANTS THAT THE
          SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
          THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
          MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
          THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          THE ACADEMY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS
          FOR PARTICULAR PURPOSE.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          23.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Limitation Of Liability
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
          (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF
          LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER
          OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION
          OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF
          OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
          CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS
          AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
          LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF THE ACADEMY HAS BEEN
          PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
          PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF THE
          ACADEMY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
          SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
          PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION
          OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          24.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Termination
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We may terminate or suspend your account and bar access to Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of Terms.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If you wish to terminate your account, you may simply discontinue
          using Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          All provisions of Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          25.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Governing Law
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          These Terms shall be governed and construed in accordance with the
          laws of Nigeria, which governing law applies to agreement without
          regard to its conflict of law provisions.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service and
          supersede and replace any prior agreements we might have had between
          us regarding Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          26.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Changes To Service
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to withdraw or amend our Service, and any service
          or material we provide via Service, in our sole discretion without
          notice. We will not be liable if for any reason all or any part of
          Service is unavailable at any time or for any period. From time to
          time, we may restrict access to some parts of Service, or the entire
          Service, to users, including registered users.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          27.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Changes To Terms and Conditions
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          We reserve the right to modify or update these terms and conditions at
          any time without prior notice. Any changes will be effective
          immediately upon posting on our application.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Your continued use of the Platform following the posting of modified
          or updated Terms means that you accept and agree to the changes. You
          are expected to check this page frequently so you are aware of any
          changes, as they are binding on you.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use Service.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          28.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Waiver And Severability
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          No waiver by The Academy of any term or condition set forth in Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of The Academy to assert a right or provision under Terms shall not
          constitute a waiver of such right or provision.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          If any provision of Terms is held by a court or other tribunal of
          competent jurisdiction to be invalid, illegal or unenforceable for any
          reason, such provision shall be eliminated or limited to the minimum
          extent such that the remaining provisions of Terms will continue in
          full force and effect.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          29.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Acknowledgement
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          By using our e-learning application, you agree to these terms and
          conditions. If you do not agree with any part of these terms and
          conditions, please do not use our e-learning application. If you have
          any questions or concerns about these terms and conditions, please
          contact us at info@kap.academy.
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          30.&nbsp;
        </span>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 700,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Contact Us
        </span>
      </p>
      <p
        dir="ltr"
        style={{
          lineHeight: '1.2',
          textAlign: 'justify',
          marginTop: '14pt',
          marginBottom: '14pt',
        }}
      >
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          Please send your feedback, comments, requests for technical support by
          email:&nbsp;
        </span>
        <a href="mailto:info@kap.academy" style={{ textDecoration: 'none' }}>
          <span
            style={{
              fontSize: '11pt',
              color: '#0000ff',
              backgroundColor: '#ffffff',
              fontWeight: 700,
              fontStyle: 'normal',
              fontVariant: 'normal',
              textDecoration: 'underline',
              WebkitTextDecorationSkip: 'none',
              textDecorationSkipInk: 'none',
              verticalAlign: 'baseline',
              whiteSpace: 'pre-wrap',
            }}
          >
            info@kap.academy
          </span>
        </a>
        <span
          style={{
            fontSize: '11pt',
            color: '#000000',
            backgroundColor: 'transparent',
            fontWeight: 400,
            fontStyle: 'normal',
            fontVariant: 'normal',
            textDecoration: 'none',
            verticalAlign: 'baseline',
            whiteSpace: 'pre-wrap',
          }}
        >
          .
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default Register;
