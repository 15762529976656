// import node module libraries
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import {
  Col,
  Row,
  Dropdown,
  Card,
  Breadcrumb,
  Button,
  Modal,
  Table,
  ListGroup,
  Toast,
  Spinner,
} from 'react-bootstrap';
import { Trash, Edit, Inbox, MoreVertical, Send } from 'react-feather';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';
// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import Checkbox from 'components/elements/advance-table/Checkbox';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
// import data files
import AddNewForum from './AddNewForum';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useDeletePostMutation,
  useGetPostsMutation,
  useGetProgramsLiteMutation,
  useGetVacanciesMutation,
  useRestorePostMutation,
} from 'pages/redux/features/user/userApi';
import { sortByProperty } from 'pages/helper/formatArray';
import { capitalize, truncateText } from 'pages/helper/formatText';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { saveProgrammes } from 'pages/redux/features/auth/authSlice';

const ForumList = () => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [getPosts, { isLoading }] = useGetPostsMutation();
  const [deletePost, { isLoading: isDeleting }] = useDeletePostMutation();
  const [restorePost, { isLoading: isRestoring }] = useRestorePostMutation();
  const [refresh, setRefresh] = useState(false);
  const [initial, setInitial] = useState(null);
  const [deleteShow, setDeleteShow] = useState(false);
  const [restoreShow, setRestoreShow] = useState(false);
  const [type, setType] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { programmes } = useSelector((state) => state.userAuth);

  const handleRestoreClose = (refetch) => {
    setRestoreShow(false);
    refetch && setRefresh(!refresh);
  };
  const handleDeleteClose = (refetch) => {
    setDeleteShow(false);
    refetch && setRefresh(!refresh);
  };
  const handleClose = () => {
    setShow(false);
    setRefresh(!refresh);
  };
  const handleShow = () => {
    setType('add');
    setInitial(null);
    setShow(true);
  };

  const handlePublish = (value) => {
    console.log(value);
  };
  const handleRestoreOpen = (value) => {
    setType('restore');
    setInitial(value);
    setRestoreShow(true);
  };
  const handleDeleteOpen = (value) => {
    setType('delete');
    setInitial(value);
    setDeleteShow(true);
  };
  const handleRestore = async () => {
    const res = await restorePost({ id: initial?.id });
    console.log('res', res);
    if (res?.data?.success) {
      handleRestoreClose(true);
      // setList(res?.data?.data?.posts?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleDelete = async () => {
    const res = await deletePost({ id: initial?.id });
    console.log('res', res);
    if (res?.data?.success) {
      handleDeleteClose(true);
      // setList(res?.data?.data?.posts?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchForums = async () => {
    const res = await getPosts();
    console.log('res', res);
    if (res?.data?.success) {
      setList(res?.data?.data?.posts?.data);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchForums();
  }, [refresh]);

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </Link>
  ));

  const ActionMenu = ({ value }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-secondary mx-2" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          <Dropdown.Header>ACTION</Dropdown.Header>
          <Dropdown.Item
            eventKey="1"
            onClick={() => {
              setType('edit');
              setInitial(value);
              setShow(true);
            }}
          >
            {' '}
            <Edit size="18px" className="dropdown-item-icon" /> Edit
          </Dropdown.Item>
          {/* <Dropdown.Item
            eventKey="2"
            onClick={() => {
              handlePublish(value);
            }}
          >
            {' '}
            <Send size="18px" className="dropdown-item-icon" /> Publish
          </Dropdown.Item> */}
          {value?.deleted_at ? (
            <Dropdown.Item
              eventKey="3"
              onClick={() => {
                handleRestoreOpen(value);
              }}
            >
              {' '}
              <Send size="18px" className="dropdown-item-icon" /> Restore
            </Dropdown.Item>
          ) : (
            <Dropdown.Item
              eventKey="3"
              onClick={() => {
                handleDeleteOpen(value);
              }}
            >
              {' '}
              <Trash size="18px" className="dropdown-item-icon" /> Delete
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const programOptions = useMemo(() => {
    let filtered = programmes || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?.id, id: x?.id };
    });
    filtered?.push({ label: 'All', value: null, id: null });
    return filtered;
  }, [programmes]);
  const [getProgramsLite, { isLoading: isLoadingProgrammeLite }] =
    useGetProgramsLiteMutation();
  const fetchPrograms = async () => {
    const res = await getProgramsLite();
    if (res?.data?.success) {
      dispatch(saveProgrammes(res?.data?.data?.programmes));
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  const columns = useMemo(
    () => [
      { accessor: 'sn', Header: 'S/N' },
      // {accessor: 'id', Header: 'ID'},
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({ value, row }) => {
          return (
            <h5
              onClick={() => {
                history.push({
                  pathname: '/admin/forums/' + row?.original?.id,
                  state: row?.original,
                });
              }}
            >
              {truncateText(value, 40)}
            </h5>
          );
        },
      },
      {
        accessor: 'body',
        Header: 'Body',
        Cell: ({ value }) => {
          return truncateText(value, 40);
        },
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: ({ value }) => {
          return capitalize(value);
        },
      },
      {
        accessor: 'user',
        Header: 'Publisher',
        Cell: ({ value }) => {
          return `${value?.firstname} ${value?.lastname}(${value?.email})`;
        },
      },

      {
        accessor: 'programme_id',
        Header: 'Program',
        Cell: ({ value }) => {
          return programOptions?.find((x) => x?.id == value)?.label;
        },
      },
      {
        accessor: 'attachments',
        Header: 'No. of attachments',
        Cell: ({ value }) => {
          return value?.length;
        },
      },

      {
        accessor: 'created_at',
        Header: 'DATE CREATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'updated_at',
        Header: 'LAST UPDATED',
        Cell: ({ value }) => {
          return new Date(value).toDateString();
        },
      },
      {
        accessor: 'action',
        Header: '',
        Cell: ({ value, row }) => {
          return <ActionMenu value={row?.original} />;
        },
      },
    ],
    [programOptions]
  );

  const data = useMemo(() => {
    let filtered = list || [];
    if (filtered.length > 0) {
      filtered = sortByProperty(filtered, 'created_at', 'desc');
      // filtered = filtered.filter((x, index) => !x?.deleted_at);
      filtered = filtered.map((x, index) => {
        return {
          ...x,
          sn: index + 1,
        };
      });
    }

    return filtered;
  }, [list]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, globalFilter } = state;

  const getRowProps = useCallback((row) => {
    return {
      style: {
        backgroundColor: row.original.deleted_at ? '#DC00001A' : 'white',
      },
    };
  }, []);

  const onRowClick = (val) => {
    // setType('edit');
    // setInitial(val);
    // setShow(true);
  };

  if (isLoading) {
    return (
      <Row className="d-flex justify-content-center align-items-center min-vh-100">
        <Spinner style={{ color: '#e0bf00' }} />
      </Row>
    );
  }

  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
            <div className="mb-3 mb-md-0">
              <h1 className="mb-1 h2 fw-bold">Forums</h1>
            </div>
            <div>
              <Button variant="primary" onClick={handleShow}>
                Create Post
              </Button>
              <AddNewForum
                show={show}
                handleClose={handleClose}
                refresh={refresh}
                initialState={initial}
                type={type}
                setRefresh={setRefresh}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <Card>
            <Card.Body className="p-0">
              <div
                className="table-responsive"
                // style={{position: 'relative', zIndex: 9999}}
              >
                <div className="overflow-hidden">
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className="mb-lg-0 mb-2 px-5 py-4"
                    >
                      <GlobalFilter
                        filter={globalFilter}
                        setFilter={setGlobalFilter}
                        placeholder="Search List"
                      />
                    </Col>
                  </Row>
                </div>
                <Table
                  {...getTableProps()}
                  className="text-nowrap striped bordered hover"
                  style={{ position: 'relative', zIndex: 1000 }}
                >
                  <thead className="table-light">
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          {...row.getRowProps(getRowProps(row))}
                          onClick={() => onRowClick(row.original)}
                          className="clickable-row"
                        >
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                {/* Pagination @ Footer */}
                <Pagination
                  previousPage={previousPage}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  gotoPage={gotoPage}
                  nextPage={nextPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={type === 'restore' ? restoreShow : deleteShow}
        onHide={type === 'restore' ? handleRestoreClose : handleDeleteClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {(type === 'restore' ? 'Restore' : 'Delete') + ' Post'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you want to
            {type === 'restore' ? ' restore ' : ' delete '}this Post
          </h5>
          <p className="mb-0">"{initial?.title}"</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
          {/*  Action Buttons  */}
          <Button
            variant="outline-white"
            onClick={
              type === 'restore' ? handleRestoreClose : handleDeleteClose
            }
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={type === 'restore' ? handleRestore : handleDelete}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Fragment>
  );
};

export default ForumList;
