import React, { useState, useEffect} from 'react'
import { Col, Row, Card} from 'react-bootstrap';
import { ResponsiveContainer,CartesianGrid, XAxis, YAxis, Tooltip, Legend, BarChart, Bar  } from 'recharts';
import { useGetAnalyticsMutation } from 'pages/redux/features/user/userApi';


const Analytics = () => {
  const [getAnalytics, { isLoading }] = useGetAnalyticsMutation();
  const [ageData, setAgeData] = useState([]);
  const [communityData, setCommunityData] = useState()
  const [genderData, setGenderData] = useState()
  const [educationData, setEducationData] = useState()
  const [disabilityStatusData, setDisabilityStatusData] = useState()
  const [statesData, setStatesData] = useState()
  const [incomeData, setIncomeData] = useState()

  const fetchAnalytics = async () => {
    const stats = await getAnalytics();
          setAgeData(stats?.data?.data?.ages)
          setCommunityData(stats?.data?.data?.communities)
          setGenderData(stats?.data?.data?.gender)
          setEducationData(stats?.data?.data?.education)
          setDisabilityStatusData(stats?.data?.data?.disability_status)
          setStatesData(stats?.data?.data?.states)
          setIncomeData(stats?.data?.data?.income)
    
  }

  useEffect(() => {
    fetchAnalytics()
  },[]);

  
  const firstData = ageData.map( (age) => { 
    return {
       "name" : Object.keys(age).toString(), 
       "ages": parseInt(Object.values(age))
      }
    }
    )

  return (
    <div className='w-100'>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Analytics</h1>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Card>
            <Card.Body style={{ width: "18rem" }}>
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Ages</Card.Title>
              <BarChart width={450} height={250} data={firstData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="education" fill="#8884d8" /> */}
                <Bar dataKey="ages" fill="#d0444a" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} className='mt-3 mt-lg-0'>
          <Card >
            <Card.Body style={{ width: "18rem" }} >
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Gender</Card.Title>
              <BarChart width={450} height={250} data={genderData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="gender" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="gender" fill="#8884d8" /> */}
                <Bar dataKey="total" fill="#82ca9d" />
              </BarChart>
              
            </Card.Body>
          </Card>
        </Col>

      </Row>

     <Row style={{ marginTop: "20px" }} >
        <Col xl={6} lg={6} md={12} sm={12}>
          <Card>
            <Card.Body style={{ width: "18rem" }}>
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Education</Card.Title>
              <BarChart width={450} height={250} data={educationData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="education" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="education" fill="#8884d8" /> */}
                <Bar dataKey="total" fill="#A020F0" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} className='mt-3 mt-lg-0'>
          <Card>
            <Card.Body style={{ width: "18rem" }} >
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Disability Status</Card.Title>
              <BarChart width={450} height={250} data={disabilityStatusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="disability" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="education" fill="#8884d8" /> */}
                <Bar dataKey="total" fill="#da5d85" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>
     </Row>

     <Row style={{ marginTop: "20px" }}>
        <Col xl={6} lg={6} md={12} sm={12}>
          <Card>
            <Card.Body style={{ width: "18rem" }}>
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>States</Card.Title>
              <BarChart width={450} height={250} data={statesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="state" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="education" fill="#8884d8" /> */}
                <Bar dataKey="total" fill="#d0444a" />
              </BarChart>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={6} lg={6} md={12} sm={12} className='mt-3 mt-lg-0'>
          <Card>
            <Card.Body style={{ width: "18rem" }}>
              <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Income</Card.Title>
              <BarChart width={450} height={250} data={incomeData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="monthly_income" />
                <YAxis />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey="education" fill="#8884d8" /> */}
                <Bar dataKey="total" fill="#6289b3" />
              </BarChart>
           
            </Card.Body>
          </Card>
        </Col>
     </Row>

      <Row style={{ marginTop: "20px"}}>
      
      <Col xl={6} lg={6} md={12} sm={12}>
        <Card >
          <Card.Body style={{ width: "18rem" }}>
            <Card.Title style={{ fontWeight: "bold", fontSize: "14px", marginBottom: "5px"}}>Communities</Card.Title>
            <ResponsiveContainer  width={450} height={250}>
            <BarChart data={communityData} >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="community" />
              <YAxis />
              <Tooltip />
              <Legend />
              {/* <Bar  dataKey="community" fill="#8884d8" /> */}
              <Bar  dataKey="total" fill="#1aa7ec" /> 
            </BarChart>
            </ResponsiveContainer>
          </Card.Body>
        </Card>
      </Col>

      </Row>
     
    </div>
  )
}

export default Analytics

