// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
  DropdownButton,
  Dropdown,
  Row,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAddTopicToClassMutation,
  useGetClassesMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AssignClass = ({ show, handleClose, id }) => {
  const [classOptions, setClassOptions] = useState([]);

  const [getClasses, { isLoading: fetchingClasses }] = useGetClassesMutation();
  const [addTopicToClass, { isLoading }] = useAddTopicToClassMutation();

  const handleAssigning = async (class_id) => {
    const res = await addTopicToClass({ topic_id: id, class_id: class_id });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchClasses = async () => {
    const res = await getClasses();
    if (res?.data?.success) {
      const options = res?.data?.data?.classes?.map((x) => {
        return { id: x?.id, label: x?.title, value: x?.id };
      });
      setClassOptions(options);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const validationSchema = Yup.object().shape({
    class: Yup.object().required('Required').typeError('Required'),
  });

  const formik = useFormik({
    initialValues: {
      class: null,
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      handleAssigning(values?.class?.id);
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{'Add Topic to Class'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading || fetchingClasses ? (
          <Row className="d-flex justify-content-center align-items-center ">
            <Spinner style={{ color: '#e0bf00' }} />
          </Row>
        ) : (
          <Fragment>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Class</Form.Label>
                <MultiSelectDropdown
                  options={classOptions}
                  selected={formik.values?.class}
                  setSelected={(e) => {
                    formik.setFieldValue('class', e);
                  }}
                  placeholder={'Select class to assign to'}
                />
                {formik.errors.class && (
                  <p className="fs-8 text-danger mb-0">{formik.errors.class}</p>
                )}
              </Form.Group>
            </Form>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignClass;
