// import node module libraries
import { Fragment, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Modal,
  Toast,
} from 'react-bootstrap';

// import custom components
import ReactQuillEditor from 'components/elements/editor/ReactQuillEditor';
import { FormSelect } from 'components/elements/form-select/FormSelect';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import { useCreateAdminMutation } from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddNewAdmin = ({ show, handleClose, size = 'lg' }) => {
  const adminTypeOptions = [
    { value: 'academic', label: 'School' },
    { value: 'operations', label: 'Linkage' },
    { value: 'payment', label: 'Finance' },
  ];

  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('Last Name is required'),
    role: Yup.object().required('Role is required').typeError('Required'),
    email: Yup.string()
      .email('Enter valid email')
      .required('Email is required'),
    phone: Yup.string()
      .matches(
        phoneRegExp,
        'Phonenumber is not valid, correct format -> 08012345678'
      )
      .required('Phonenumber is required')
      .label('Phone number'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      role: null,
    },
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      handleAdminCreation(values);
    },
  });

  const [createAdmin, { isLoading }] = useCreateAdminMutation();

  const handleAdminCreation = async (values) => {
    const { email, firstname, lastname, phone, role } = values;
    const res = await createAdmin({
      firstname,
      lastname,
      email,
      phone,
      type: role?.value,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Admin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                id="firstname"
                placeholder="First Name"
                required
                onChange={formik.handleChange}
              />
              {formik.errors.firstname && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.firstname}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                id="lastname"
                placeholder="Last Name"
                required
                onChange={formik.handleChange}
              />
              {formik.errors.lastname && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.lastname}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                id="phone"
                placeholder="Phone Number"
                required
                onChange={(e) => {
                  formik.setFieldValue('phone', e?.target?.value);
                }}
              />
              {formik.errors.phone && (
                <p className="fs-8 text-danger mb-0">{formik.errors.phone}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email </Form.Label>
              <Form.Control
                type="email"
                id="email"
                placeholder="Email address here"
                required
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <p className="fs-8 text-danger mb-0">{formik.errors.email}</p>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Role</Form.Label>
              <MultiSelectDropdown
                required
                options={adminTypeOptions}
                selected={formik.values?.role}
                setSelected={(e) => {
                  formik.setFieldValue('role', e);
                }}
                placeholder={'Select options'}
              />
              {formik.errors.role && (
                <p className="fs-8 text-danger mb-0">{formik.errors.role}</p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading}
        >
          Add New Admin
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewAdmin;
