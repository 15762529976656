// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useCreateAssignmentMutation,
  useUpdateAssignmentMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { timeFormatCheck } from 'pages/helper/formatText';

const AddAssignment = ({ show, handleClose, type, id, initialState }) => {
  const [unit, setUnit] = useState('minutes');


  const [createAssignment, { isLoading: isCreatingAssignment }] =
    useCreateAssignmentMutation();

  const [updateAssignment, { isLoading: isUpdatingAssignment }] =
    useUpdateAssignmentMutation();

  const handleAssignmentCreation = async (data) => {
    const res =
      type == 'edit'
        ? await updateAssignment({ id: initialState?.id, data })
        : await createAssignment(data);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    instructions: Yup.string().required('Required').typeError('Text expected'),
    // deadline: Yup.string().required().typeError('Required').label('Deadline'),
    date: Yup.string().required('Required').typeError('Text expected'),
    time: Yup.string().required('Required').typeError('Text expected'),
    total: Yup.number().required('Required').typeError('Number expected'),
    pass_mark: Yup.number()
      .required('Required')
      .lessThan(Yup.ref('total'), 'Pass mark must be less than total mark')
      .typeError('Number expected'),
    topic_id: Yup.number().required('Required').typeError('Number expected'),
    has_attachments: Yup.boolean().required().typeError('Yes/No expected'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      instructions: initialState?.instructions || '',
      time: initialState?.deadline?.split(' ')[1] || '',
      date: initialState?.deadline?.split(' ')[0] || '',
      deadline: initialState?.deadline || null,
      total: initialState?.total || null,
      pass_mark: initialState?.pass_mark || null,
      topic_id: id || null,
      has_attachments: initialState?.has_attachments || false,
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveTime = timeFormatCheck(values.time)
        ? values.time
        : values.time + ':00';
      const resolveValues = {
        ...values,
        deadline: values.date + ' ' + resolveTime,
        has_attachments: JSON.parse(values?.has_attachments),
      };
      delete resolveValues.date;
      delete resolveValues.time;
      handleAssignmentCreation(resolveValues);
    },
  });

  function handleUnitSelect(e) {
    setUnit(e.target?.value);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Assignment' : 'Add Assignment'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Assignment Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Instructions</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="instructions"
                placeholder="Instructions"
                required
                value={formik.values?.instructions}
                onChange={formik.handleChange}
              />
              {formik.errors.instructions && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.instructions}
                </p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Deadline</Form.Label>
              <InputGroup style={{ width: '100%' }}>
                <Form.Group style={{ width: '50%' }}>
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    id="date"
                    min={new Date().toISOString().split('T')[0]}
                    placeholder="Date"
                    required
                    value={formik.values?.date}
                    onChange={(e) => {
                      formik.setFieldValue('date', e?.target?.value);
                    }}
                  />
                  {formik.errors?.date && (
                    <p className="fs-8 text-danger mb-0">
                      {formik.errors?.date}
                    </p>
                  )}
                </Form.Group>
                <Form.Group style={{ width: '50%' }}>
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    type="time"
                    id="time"
                    placeholder="Time"
                    required
                    value={formik.values?.time}
                    onChange={(e) => {
                      formik.setFieldValue('time', e?.target?.value);
                    }}
                  />
                  {formik.errors?.time && (
                    <p className="fs-8 text-danger mb-0">
                      {formik.errors?.time}
                    </p>
                  )}
                </Form.Group>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Has Attachment</Form.Label>
              <Form.Control
                as="select"
                id="has_attachments"
                placeholder="Yes/No"
                required
                value={formik.values?.has_attachments}
                onChange={formik.handleChange}
              >
                <option value={null}>Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
              {formik.errors?.has_attachments && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.has_attachments}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Total mark</Form.Label>
              <FormControl
                type="number"
                placeholder="Enter a number"
                id="total"
                value={formik.values?.total}
                onChange={formik.handleChange}
              />
              {formik.errors?.total && (
                <p className="fs-8 text-danger mb-0">{formik.errors?.total}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Pass mark</Form.Label>
              <FormControl
                type="number"
                placeholder="Enter a number"
                id="pass_mark"
                value={formik.values?.pass_mark}
                onChange={formik.handleChange}
              />
              {formik.errors?.pass_mark && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.pass_mark}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={
            isCreatingAssignment || isUpdatingAssignment || !formik.isValid
          }
        >
          {isCreatingAssignment || isUpdatingAssignment ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAssignment;
