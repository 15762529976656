import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const MultiSelectDropdown = ({
  controlStyles = {},
  options = [],
  isMulti = false,
  placeholder = '',
  selected = null,
  setSelected = () => {},
  isLoading,
}) => {
  return (
    <Select
      styles={{
        control: (styles) => ({
          ...styles,
          height: isMulti ? '100%' : 50,
          minHeight: 50,
          backgroundColor: 'transparent',
          borderColor: '#d9d9d9',
          ...controlStyles,
        }),
      }}
      options={options}
      components={animatedComponents}
      isMulti={isMulti}
      isLoading={isLoading}
      placeholder={placeholder}
      value={selected}
      onChange={(data) => {
        setSelected(data);
      }}
    />
  );
};

export default MultiSelectDropdown;
