// import node module libraries
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal, ListGroup, Image, Col } from 'react-bootstrap';

// import custom components
import { Avatar } from 'components/elements/bootstrap/Avatar';

// import context file
import { ChatContext } from 'context/Context';
import { truncateText } from 'pages/helper/formatText';
import TruncateTextWithToggle from 'pages/components/text/TruncateTextWithToggle';
import { arrowDownRight } from 'react-icons-kit/feather';
import Icon from 'react-icons-kit';
import { chevronsRight } from 'react-icons-kit/feather';
import WarnSign from 'pages/assets/images/warning-sign.png';
import { checkCircle } from 'react-icons-kit/feather/checkCircle';

const SurveyCompleteModal = (props) => {
  const history = useHistory();
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      {/* <Modal.Header closeButton={!props?.data || props?.data?.length < 1}> */}
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Survey Submitted Successfully
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        className="text-center text-primary-hover"
      >
        <Icon icon={checkCircle} size={150} className="text-primary" />
        <h4 className="mt-4">Thank you</h4>
        <h5 className="mt-2">
          To download your certificate, you need to have finished all the class
          videos, weekly quizzes, and the program's survey
        </h5>
      </Modal.Body>
    </Modal>
  );
};

export default SurveyCompleteModal;
