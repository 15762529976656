// Centered aligned section heading
// title, subtitle with description in centered aligned

// import node module libraries
import { Col, Row } from 'react-bootstrap';

const SectionHeadingCenter = ({
  title,
  subtitle,
  description,
  textColor,
  textStyle,
}) => {
  return (
    <Row className="mb-5 justify-content-center">
      <Col lg={8} md={12} sm={12} className="text-center" style={textStyle}>
        {subtitle && (
          <span
            className={`mb-3 d-block text-uppercase fw-semi-bold ls-xl ${
              textColor || 'text-primary'
            }`}
          >
            {subtitle}
          </span>
        )}
        {title && (
          <h2 className={`mb-2 display-4 fw-bold ${textColor || 'text-black'}`}>
            {title}
          </h2>
        )}
        {description && (
          <p className={`lead ${textColor || 'text-black'}`}>{description}</p>
        )}
      </Col>
    </Row>
  );
};

export default SectionHeadingCenter;
