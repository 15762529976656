import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import StatRightIcon from 'components/dashboard/common/stats/StatRightIcon';
import { useGetDashboardStatsMutation } from 'pages/redux/features/user/userApi';

const InstructorDashboard = () => {
  const [getDashboardStats, { isLoading }] = useGetDashboardStatsMutation();
  const [stats, setStats] = useState(null);

  const fetchDashboardStats = async () => {
    const stats = await getDashboardStats();
    if (stats?.data?.success) {
      setStats(stats?.data?.data);
    }
  };

  useEffect(() => {
    fetchDashboardStats();
  }, []);

  return (
    <div>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
            <div className="mb-3 mb-lg-0">
              <h1 className="mb-0 h2 fw-bold">Dashboard</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        {(stats?.courses?.total || stats?.courses?.toString()) && (
          <Col xl={4} lg={6} md={12} sm={12}>
            <StatRightIcon
              title="COURSES"
              value={(stats?.courses?.total || stats?.courses)?.toString()}
              iconName="book-open"
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        )}
        {(stats?.students?.total || stats?.students?.toString()) && (
          <Col xl={4} lg={6} md={12} sm={12}>
            <StatRightIcon
              title="students"
              value={(stats?.students?.total || stats?.students)?.toString()}
              iconName="book-open"
              iconColorVariant="primary"
              classValue="mb-4"
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default InstructorDashboard;
