import { v4 as uuid } from 'uuid';

const NavbarDefault = ({ programs }) => {
  return [
    {
      id: uuid(),
      menuitem: 'Programmes',
      link: '#',
      children: programs,
    },
    {
      id: uuid(),
      menuitem: 'Jobs',
      link: '/jobs',
    },
    {
      id: uuid(),
      menuitem: 'Finance',
      link: '/finance',
    },
    {
      id: uuid(),
      menuitem: 'Resources',
      link: '/resources',
    },
    {
      id: uuid(),
      menuitem: 'Forum',
      link: '/forum',
    },
    {
      id: uuid(),
      menuitem: 'About us',
      link: '/aboutus',
    },
  ];
};

export default NavbarDefault;
