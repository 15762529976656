// import node module libraries
import { Fragment, useState } from 'react';
import { Form, FormControl, InputGroup, Button, Modal } from 'react-bootstrap';

// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  useCreateTeacherMutation,
  useCreateVacancyMutation,
  useUpdateVacancyMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const AddNewJob = ({ show, handleClose, size = 'lg', type, initialState }) => {
  const phoneRegExp = /^[-\s\.]?[0-9]{9,11}$/im;

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required'),
    company_name: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    requirements: Yup.string().required('Required'),
    deadline: Yup.string().required('Required'),
    link: Yup.string().required('Required'),
    // comment: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      company_name: initialState?.company_name || '',

      requirements: initialState?.requirements || '',
      deadline:
        (initialState?.deadline &&
          moment(initialState?.deadline).format('YYYY-MM-DD')) ||
        '',

      title: initialState?.title || '',
      description: initialState?.description || '',
      link: initialState?.link || '',
      comment: initialState?.comment || '',
    },
    validateOnMount: false,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        deadline: values.deadline + ' 23:59:59',
      };
      handleJobCreation(resolveValues);
    },
  });

  const [createVacancy, { isLoading }] = useCreateVacancyMutation();
  const [updateVacancy, { isLoading: isUpdating }] = useUpdateVacancyMutation();

  const handleJobCreation = async (values) => {
    const res =
      type == 'edit'
        ? await updateVacancy({ id: initialState?.id, data: values })
        : await createVacancy(values);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Job Linkage' : 'Add Job Linkage'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Company name</Form.Label>
              <Form.Control
                type="text"
                id="company_name"
                placeholder="Company name"
                required
                value={formik.values?.company_name}
                onChange={formik.handleChange}
              />
              {formik.errors.company_name && formik.touched.company_name && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.company_name}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                id="title"
                placeholder="Title"
                required
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && formik.touched.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && formik.touched.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Requirements</Form.Label>
              <Form.Control
                type="text"
                id="requirements"
                placeholder="Requirements"
                required
                value={formik.values?.requirements}
                onChange={formik.handleChange}
              />
              {formik.errors.requirements && formik.touched.requirements && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.requirements}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Deadline</Form.Label>
              <Form.Control
                type="date"
                id="deadline"
                placeholder="Deadline"
                required
                min={new Date().toISOString().split('T')[0]}
                value={formik.values?.deadline}
                onChange={(e) => {
                  formik.setFieldValue('deadline', e?.target?.value);
                }}
              />
              {formik.errors?.deadline && formik.touched.deadline && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.deadline}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                id="link"
                placeholder="Job link"
                required
                value={formik.values?.link}
                onChange={formik.handleChange}
              />
              {formik.errors.link && formik.touched.link && (
                <p className="fs-8 text-danger mb-0">{formik.errors.link}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="comment"
                placeholder="Comment"
                value={formik.values?.comments}
                onChange={formik.handleChange}
              />
              {formik.errors.comment && formik.touched.comment && (
                <p className="fs-8 text-danger mb-0">{formik.errors.comment}</p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || isLoading}
        >
          {type == 'edit' ? 'Edit Job' : 'Add Job'}
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewJob;
