// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useCreateTopicMutation,
  useGetCoursesLiteMutation,
  useUpdateTopicMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';
import { removeUndefinedKeys } from 'pages/helper/formatObject';

const AddTopic = ({ show, handleClose, id, type, initialState, courseId }) => {
  const [courses, setCourses] = useState([]);
  const [initialCourse, setInitialCourse] = useState(null);

  const [getCourses, { isLoading: fetchingInstructorCourses }] =
    useGetCoursesLiteMutation();

  const [createTopic, { isLoading: isCreatingTopic }] =
    useCreateTopicMutation();

  const [updateTopic, { isLoading: isUpdatingTopic }] =
    useUpdateTopicMutation();

  useEffect(() => {
    const fetchCourse = async () => {
      const res = await getCourses();
      if (res?.data?.success) {
        setCourses(
          res?.data?.data?.courses
            ?.filter((val) => !val.deleted_at)
            .map((course) => {
              return { label: course.title, value: course.id, id: course.id };
            })
        );
      }
    };
    fetchCourse();
  }, []);

  const handleTopicCreation = async (formData, values) => {
    const res =
      type == 'edit'
        ? await updateTopic({ id: initialState?.id, formData: formData })
        : await createTopic(formData);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Required').typeError('Text expected'),
    description: Yup.string().required('Required').typeError('Text expected'),
    note: Yup.string().typeError('Text expected'),
    course_id: Yup.object()
      .required('Required')
      .typeError('Course is expected'),
    // course_class_id: Yup.string()
    //   .required('Required')
    //   .typeError('Class Id expected'),
  });

  const formik = useFormik({
    initialValues: {
      title: initialState?.title || '',
      description: initialState?.description || '',
      course_id: initialCourse || '',
      note: initialState?.note || '',
      videos: initialState?.videos || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        course_id: values.course_id?.id,
      };
      !(values?.videos instanceof File) &&
        !(values?.videos instanceof FileList) &&
        delete resolveValues?.videos;

      const formData = objectToFormData(removeUndefinedKeys(resolveValues));
      logFormData(formData);
      handleTopicCreation(formData, resolveValues);
    },
  });

  useEffect(() => {
    setInitialCourse(
      courses?.find((course) => course.id == initialState?.course_id)
    );
  }, [courses, initialState]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{type == 'edit' ? 'Edit Topic' : 'Add Topic'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Topic Title</Form.Label>
              <Form.Control
                required
                type="text"
                id="title"
                placeholder="Title"
                value={formik.values?.title}
                onChange={formik.handleChange}
              />
              {formik.errors.title && (
                <p className="fs-8 text-danger mb-0">{formik.errors.title}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Course</Form.Label>
              <MultiSelectDropdown
                options={courses}
                selected={formik.values?.course_id}
                setSelected={(e) => {
                  formik.setFieldValue('course_id', e);
                }}
                placeholder={'Select Course'}
                loading={fetchingInstructorCourses}
              />
              {formik.errors.course_id && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.course_id}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{type == 'edit' ? 'Update Note' : 'Note'}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="note"
                placeholder="note"
                required
                value={formik.values?.note}
                onChange={formik.handleChange}
              />
              {formik.errors.note && (
                <p className="fs-8 text-danger mb-0">{formik.errors.note}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {type == 'edit' ? 'Add New Video' : 'Videos'}
              </Form.Label>
              <Form.Control
                type="file"
                accept="video/*"
                id="videos"
                multiple
                required
                onChange={(e) => {
                  formik.setFieldValue('videos', e.target.files);
                }}
              />
              {formik.errors?.videos && (
                <p className="fs-8 text-danger mb-0">{formik.errors?.videos}</p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isCreatingTopic || isUpdatingTopic}
        >
          {isCreatingTopic || isUpdatingTopic ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTopic;
