// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
  DropdownButton,
  Dropdown,
  Row,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useAssignInstructorToCourseMutation,
  useGetTeachersMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const AssignInstructor = ({ show, handleClose, id }) => {
  const [instructorOptions, setInstructorOptions] = useState([]);

  const [getTeachers, { isLoading: fetchingTeachers }] =
    useGetTeachersMutation();
  const [assignInstructorToCourse, { isLoading }] =
    useAssignInstructorToCourseMutation();

  const handleAssigning = async (instructor_id) => {
    const res = await assignInstructorToCourse({
      user_id: instructor_id,
      course_id: id,
    });
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchInstructors = async () => {
    const res = await getTeachers();
    if (res?.data?.success) {
      const options = res?.data?.data?.map((x) => {
        return {
          id: x?.id,
          label: x?.firstname + ' ' + x?.lastname,
          value: x?.id,
        };
      });
      setInstructorOptions(options);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    fetchInstructors();
  }, []);

  const validationSchema = Yup.object().shape({
    instructor: Yup.object().required('Required').typeError('Required'),
  });

  const formik = useFormik({
    initialValues: {
      instructor: null,
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      handleAssigning(values?.instructor?.id);
    },
  });

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{'Assign Instructor to Class'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading || fetchingTeachers ? (
          <Row className="d-flex justify-content-center align-items-center ">
            <Spinner style={{ color: '#e0bf00' }} />
          </Row>
        ) : (
          <Fragment>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Instructor</Form.Label>
                <MultiSelectDropdown
                  options={instructorOptions}
                  selected={formik.values?.instructor}
                  setSelected={(e) => {
                    formik.setFieldValue('instructor', e);
                  }}
                  placeholder={'Select instructor to assign to'}
                />
                {formik.errors.instructor && (
                  <p className="fs-8 text-danger mb-0">
                    {formik.errors.instructor}
                  </p>
                )}
              </Form.Group>
            </Form>
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignInstructor;
