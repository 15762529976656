// import node module libraries
import { Link } from 'react-router-dom';
import { Clock, Video, Users } from 'react-feather';
import {
  Container,
  Image,
  Button,
  Row,
  Col,
  Card,
  Form,
  ListGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiFacebook, mdiTwitter, mdiEmail } from '@mdi/js';
import { Loader } from 'pages/components/Loader';

const MainSection = ({
  item,
  bgColor,
  mainTextColor,
  mainBtnPressed,
  setMainBtnPressed,
}) => {
  return (
    <div className={`py-md-10 py-6 ${bgColor || 'bg-black'}`}>
      <Container>
        <Row style={{ alignItems: 'center' }}>
          <Col xl={6} lg={6} md={12}>
            {!(item?.title || item?.name) || !item?.description ? (
              <div className="d-flex justify-content-center">
                <Loader isFullScreen={false} />
              </div>
            ) : (
              <div className="mb-4 mb-xl-0 text-center text-md-start">
                {/*  Caption  */}
                <h1
                  className={`display-3 fw-bold mb-3 ls-sm ${
                    mainTextColor || 'text-primary'
                  }`}
                >
                  {item?.title || item?.name}
                </h1>
                {item?.description && (
                  <p className="mb-4 lead text-white">{item?.description}</p>
                )}
                {/*  List  */}
                {item?.mainRedirect &&
                  (!item?.popOverOnMain ? (
                    item?.mainRedirectLink ? (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setMainBtnPressed(!mainBtnPressed);
                        }}
                        to={item?.mainRedirectLink || '#'}
                        className="popup-youtube btn btn-white btn-lg fs-4"
                      >
                        {item?.mainRedirect}
                      </Link>
                    ) : (
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setMainBtnPressed(!mainBtnPressed);
                        }}
                        className="popup-youtube btn btn-white btn-lg fs-4"
                      >
                        {item?.mainRedirect}
                      </Button>
                    )
                  ) : (
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Header as="h3">Requirements</Popover.Header>
                          <Popover.Body>
                            You need to complete at least level 1 certification
                            to access these opportunities
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button className="popup-youtube btn btn-white btn-lg fs-4">
                        {item?.mainRedirect}
                      </Button>
                    </OverlayTrigger>
                  ))}

                {item?.secondaryRedirect && (
                  <Link
                    to={item?.secondaryRedirectLink || '#'}
                    className="popup-youtube btn btn-primary btn-lg fs-4 mx-3"
                  >
                    {item?.secondaryRedirect}
                  </Link>
                )}
              </div>
            )}
          </Col>
          {item?.image && (
            <Col xl={6} lg={6} md={12} className="m-auto">
              <Card style={{ zIndex: 1 }}>
                <Image
                  src={item?.image}
                  // className='d-block mx-auto img-fluid '
                />
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default MainSection;
