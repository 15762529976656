import { Loader } from 'pages/components/Loader';
import {
  areFileListsEqual,
  extractPropsByInputArray,
  generateNaturalNumbers,
  getAdjacentObject,
  getFilenames,
  isSubstringInArray,
  sortByProperty,
  updateObjects,
} from 'pages/helper/formatArray';
import {
  useEnrollStudentForCourseMutation,
  useGetInstructorStudentQuizMutation,
  useGetQuizByIdMutation,
  useScoreAnswerMutation,
  useStartQuizMutation,
  useSubmitAnswerMutation,
  useSubmitQuizMutation,
} from 'pages/redux/features/user/userApi';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Radio, RadioGroup } from 'react-radio-group';
import CheckboxGroup from 'react-checkbox-group';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';

const QuizReview = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state?.userAuth);
  const id = location?.state?.quiz_id;
  const student_id = location?.state?.student_id;
  const title = location?.state?.quiz?.title;
  const [quizDetails, setQuizDetails] = useState(null);
  const [problems, setProblems] = useState([]);
  const [problemDetails, setProblemDetails] = useState(null);
  const [questionKey, setQuestionKey] = useState(0);
  const [noLoading, setNoLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [scoreAnswer, { isLoading: isScoringAnswer }] =
    useScoreAnswerMutation();
  const [getInstructorStudentQuiz, { isLoading: isLoadingQuizAnswer }] =
    useGetInstructorStudentQuizMutation();

  const handleScoreAnswer = async ({ answer_id, score }) => {
    const res = await scoreAnswer({ answer_id, score });
    if (res?.data?.success) {
      setNoLoading(true);
      fetchQuizAnswers();
      toast.success(res?.data?.message || 'Score updated successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
  };

  const fetchQuizAnswers = async ({ student_id, quiz_id }) => {
    const res = await getInstructorStudentQuiz({ student_id, quiz_id });
    if (res?.data?.success) {
      setQuizDetails(res?.data?.data?.quiz);
      if (res?.data?.data?.quiz?.problems?.length > 0) {
        let extProblems = sortByProperty(
          [...res?.data?.data?.quiz?.problems],
          'number',
          'asc'
        );
        for (let i = 0; i < extProblems.length; i++) {
          const ans = res?.data?.data?.quiz?.answers?.find(
            (x) => x?.problem_id == extProblems[i].id
          );
          let newObj = {
            ...extProblems[i],
            answer:
              extProblems[i]?.type == 'essay'
                ? ans?.essay_response
                : extProblems[i]?.type == 'multichoice'
                ? ans?.option
                : extProblems[i]?.type == 'binary'
                ? ans?.binary_answer
                : extProblems[i]?.type == 'multiselect'
                ? [ans?.option]
                : null,
            answerScore: ans?.score,
            answer_id: ans?.id,
            files: '',
          };
          extProblems[i] = newObj;
        }
        setProblems(extProblems);
        setQuestionKey(questionKey || 1);
        setProblemDetails(
          problemDetails
            ? extProblems?.find((x) => problemDetails?.id == x?.id)
            : extProblems[0]
        );
      }
    } else {
      toast.error(res?.error?.data?.message || 'Error!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      // history.goBack();
    }
    setNoLoading(false);
  };
  useEffect(() => {
    location?.state?.student_id &&
      location?.state?.quiz_id &&
      fetchQuizAnswers({
        student_id: location?.state?.student_id,
        quiz_id: location?.state?.quiz_id,
      });
  }, [location?.state?.student_id, location?.state?.quiz_id]);

  if ((isLoadingQuizAnswer || !quizDetails || !problems) && !noLoading) {
    return <Loader />;
  }

  return (
    <Fragment>
      <Container className="py-lg-5 py-5 overflow-auto min-vh-100">
        <Card className="mb-5">
          <Card.Body>
            <h3 className="fw-semi-bold">{quizDetails?.title}</h3>
          </Card.Body>
        </Card>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <h4>
            Questions {questionKey}/{problems?.length || '0'}
          </h4>
        </div>
        <Row>
          {problems?.map((x, index) => (
            <Button
              className="justify-content-center"
              style={{
                width: 40,
                height: 40,
                marginRight: 15,
                marginBottom: 15,
                padding: 0,
                backgroundColor:
                  questionKey == x?.number
                    ? '#e0bf00'
                    : (x?.answer && x?.type != 'multiselect') ||
                      (x?.type == 'multiselect' &&
                        Array.isArray(x?.answer) &&
                        x?.answer?.length > 0)
                    ? '#219653'
                    : '#EDF2F7',
                color:
                  (x?.answer && x?.type != 'multiselect') ||
                  (x?.type == 'multiselect' &&
                    Array.isArray(x?.answer) &&
                    x?.answer?.length > 0)
                    ? 'white'
                    : 'black',
              }}
              variant="transparent"
              onClick={() => {
                setQuestionKey(x?.number);
                setProblemDetails(
                  problems?.find((y) => y?.number == x?.number)
                );
              }}
            >
              {x?.number}
            </Button>
          ))}
        </Row>
        <Row className="mt-3 mb-xl-0">
          <Card className="mb-2">
            <Card.Title className="mt-4 mx-3 mb-0">
              <h4 className="fw-semi-bold">{problemDetails?.statement}</h4>
            </Card.Title>
            <Card.Body className="mt-0">
              {problemDetails?.type == 'multichoice' && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={problemDetails?.answer?.text}
                >
                  <Col className="d-flex align-item-center">
                    <Radio
                      key={uuid()}
                      className="my-radio"
                      value={problemDetails?.answer?.text}
                      style={{
                        width: '18px',
                        height: '18px',
                        marginTop: '3px',
                        '--active-color': 'green',
                      }}
                    />
                    <p className=" fs-4 fw-semi-bold mx-3">
                      {problemDetails?.answer?.text}
                    </p>
                    <p className=" fs-4 fw-semi-bold mx-3">
                      {problemDetails?.answer?.correct === 1
                        ? `(Correct)`
                        : `(Wrong)`}
                    </p>
                  </Col>
                </RadioGroup>
              )}
              {problemDetails?.type == 'binary' && (
                <RadioGroup
                  name="radioGroup"
                  selectedValue={problemDetails?.answer}
                >
                  <Col className="d-flex align-item-center">
                    <Radio
                      key={uuid()}
                      className="my-radio"
                      value={problemDetails?.answer}
                      style={{
                        width: '18px',
                        height: '18px',
                        marginTop: '3px',
                        '--active-color': 'green',
                      }}
                    />
                    <p className=" fs-4 fw-semi-bold mx-3">
                      {problemDetails?.answer}
                    </p>
                    <p className=" fs-4 fw-semi-bold mx-3">
                      {problemDetails?.correct === 1 ? `(Correct)` : `(Wrong)`}
                    </p>
                  </Col>
                </RadioGroup>
              )}
              {problemDetails?.type == 'essay' && (
                <Form.Control
                  as="textarea"
                  disabled={true}
                  rows={10}
                  id="answer"
                  placeholder="Answer"
                  required
                  value={problemDetails?.answer}
                />
              )}
              {problemDetails?.type == 'multiselect' && (
                <CheckboxGroup
                  key={uuid()}
                  name="checkboxGroup"
                  value={problemDetails?.answer[0]?.text}
                >
                  {(Checkbox) => (
                    <Col className="d-flex align-item-center">
                      <Checkbox
                        key={uuid()}
                        className="my-checkbox"
                        value={problemDetails?.answer[0]?.text}
                        style={{
                          width: '18px',
                          height: '18px',
                          marginTop: '3px',
                          '--active-color': 'green',
                        }}
                      />
                      <p className=" fs-4 fw-semi-bold mx-3">
                        {problemDetails?.answer[0]?.text}
                      </p>
                      <p className=" fs-4 fw-semi-bold mx-3">
                        {problemDetails?.answer[0]?.correct === 1
                          ? `(Correct)`
                          : `(Wrong)`}
                      </p>
                    </Col>
                  )}
                </CheckboxGroup>
              )}

              {problemDetails?.has_attachment &&
              problemDetails?.has_attachment != 0 ? (
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>Attachments</Form.Label>
                  {Array.from(problemDetails?.has_attachment).map((file) => (
                    <div className="me-3 mb-3 d-flex align-items-center justify-content-between">
                      <p className="mb-0">{file.name}</p>
                    </div>
                  ))}
                </Form.Group>
              ) : (
                <></>
              )}
            </Card.Body>
            <Card.Footer className="d-flex justify-content-end">
              <InputGroup style={{ maxWidth: 340 }}>
                <InputGroup.Text>Score</InputGroup.Text>
                <Form.Control
                  disabled={problemDetails?.type !== 'essay'}
                  id="score"
                  placeholder="Score"
                  required
                  value={
                    problemDetails?.type == 'multiselect'
                      ? problemDetails?.answer[0]?.correct === 1
                        ? problemDetails?.score
                        : 0
                      : problemDetails?.type == 'multichoice'
                      ? problemDetails?.answer?.correct === 1
                        ? problemDetails?.score
                        : 0
                      : problemDetails?.type == 'binary'
                      ? problemDetails?.answer?.binary_answer === 'true'
                        ? problemDetails?.score
                        : 0
                      : problemDetails?.answerScore
                  }
                  onChange={(e) =>
                    setProblemDetails({
                      ...problemDetails,
                      answerScore:
                        parseInt(e?.target?.value) > problemDetails?.score
                          ? problemDetails?.score
                          : e?.target?.value,
                    })
                  }
                  style={{ textAlign: 'right' }}
                />
                <InputGroup.Text>{`/${problemDetails?.score}`}</InputGroup.Text>
                {problemDetails?.type == 'essay' && (
                  <Button
                    onClick={() =>
                      handleScoreAnswer({
                        answer_id: problemDetails?.answer_id,
                        score: parseInt(problemDetails?.answerScore),
                      })
                    }
                  >
                    {isScoringAnswer ? <Spinner size="sm" /> : 'Update'}
                  </Button>
                )}
              </InputGroup>
            </Card.Footer>
          </Card>
        </Row>
        {problemDetails && (
          <Col className="d-flex justify-content-between mt-4">
            <Button
              onClick={() => {
                const prevProb = getAdjacentObject(
                  problems,
                  problemDetails?.id,
                  'id',
                  'prev'
                );
                if (prevProb) {
                  setQuestionKey(prevProb?.number);
                  setProblemDetails(
                    problems?.find((y) => y?.number == prevProb?.number)
                  );
                }
              }}
              variant="secondary"
              disabled={isScoringAnswer}
            >
              Previous
            </Button>
            <Button
              disabled={isScoringAnswer}
              onClick={() => {
                const nextProb = getAdjacentObject(
                  problems,
                  problemDetails?.id,
                  'id',
                  'next'
                );
                if (nextProb) {
                  setQuestionKey(nextProb?.number);
                  setProblemDetails(
                    problems?.find((y) => y?.number == nextProb?.number)
                  );
                }
              }}
            >
              Next
            </Button>
          </Col>
        )}
      </Container>
    </Fragment>
  );
};

export default QuizReview;
