export function removeUndefinedKeys(obj) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'undefined') {
      delete obj[key];
    }
  });
  return obj;
}
export function getValue(input, key = 'value') {
  if (
    typeof input === 'string' ||
    typeof input === 'boolean' ||
    typeof input === 'number'
  ) {
    return input;
  } else if (typeof input === 'object' && input !== null && key in input) {
    return input[key];
  } else {
    return undefined;
  }
}
