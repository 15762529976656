// import node module libraries
import { Fragment, useState } from 'react';
import { Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { CustomButton as Button } from 'pages/components/CustomButton';
// import custom components
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  useCreateLiveClassMutation,
  useGetCoursesLiteMutation,
  useGetProgramsLiteMutation,
  useUpdateLiveClassMutation,
} from 'pages/redux/features/user/userApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgrammes } from 'pages/redux/features/auth/authSlice';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { getValue } from 'pages/helper/formatObject';

const AddNewLiveClass = ({
  show,
  handleClose,
  size = 'md',
  type,
  initialState,
}) => {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const { programmes } = useSelector((state) => state.userAuth);
  const validationSchema = Yup.object().shape({
    topic: Yup.string().required('Required'),
    description: Yup.string().required('Required'),
    course_id: Yup.mixed().required('Required'),
    time: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
  });

  const programOptions = useMemo(() => {
    let filtered = programmes || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name, value: x?.id, id: x?.id };
    });
    filtered?.push({ label: 'All', value: null, id: null });
    return filtered;
  }, [programmes]);

  const courseOptions = useMemo(() => {
    let filtered = courses || [];
    filtered = filtered?.map((x) => {
      return { label: x?.name || x?.title, value: x?.id, id: x?.id };
    });
    return filtered;
  }, [courses]);

  console.log(
    '(initialState?.start_time &&',
    initialState?.start_time && moment(initialState?.start_time).format('hh:mm')
  );

  const formik = useFormik({
    initialValues: {
      topic: initialState?.topic || '',
      description: initialState?.description || '',
      time:
        (initialState?.start_time &&
          moment(initialState?.start_time).format('hh:mm')) ||
        '',
      date:
        (initialState?.start_time &&
          moment(initialState?.start_time).format('YYYY-MM-DD')) ||
        '',
      course_id:
        courseOptions?.find((x) => x?.id == initialState?.course_id) || '',
    },
    validateOnMount: false,
    validateOnChange: validateOnChange,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      const resolveValues = {
        ...values,
        time: `${values.date}T${values.time}:00Z`,
        course_id: getValue(values?.course_id),
      };
      delete resolveValues.date;
      handleLiveClass(resolveValues);
      // console.log(resolveValues);
    },
  });

  const [getProgramsLite, { isLoading: isLoadingProgrammeLite }] =
    useGetProgramsLiteMutation();
  const [getCoursesLite, { isLoading: isLoadingCoursesLite }] =
    useGetCoursesLiteMutation();
  const [createLiveClass, { isLoading }] = useCreateLiveClassMutation();
  const [updateLiveClass, { isLoading: isUpdating }] =
    useUpdateLiveClassMutation();

  const fetchPrograms = async () => {
    const res = await getProgramsLite();
    if (res?.data?.success) {
      dispatch(saveProgrammes(res?.data?.data?.programmes));
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const fetchCourses = async () => {
    const res = await getCoursesLite();
    if (res?.data?.success) {
      setCourses(res?.data?.data?.courses);
    } else {
      toast.error(res?.error?.data?.message || 'Something went wrong!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    show && fetchCourses();
  }, [show]);

  const handleLiveClass = async (values) => {
    const res =
      type == 'edit'
        ? await updateLiveClass({ id: initialState?.id, data: values })
        : await createLiveClass(values);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size={size}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Live Class' : 'Create Live Class'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Topic</Form.Label>
              <Form.Control
                type="text"
                id="topic"
                placeholder="Topic"
                required
                value={formik.values?.topic}
                onChange={formik.handleChange}
              />
              {formik.errors.topic && formik.touched.topic && (
                <p className="fs-8 text-danger mb-0">{formik.errors.topic}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                id="description"
                placeholder="Description"
                required
                value={formik.values?.description}
                onChange={formik.handleChange}
              />
              {formik.errors.description && formik.touched.description && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.description}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Time</Form.Label>
              <Form.Control
                type="time"
                id="startDate"
                min={new Date().toISOString().split('T')[1]}
                placeholder="Start time"
                required
                value={formik?.values?.time}
                onChange={(e) => {
                  formik.setFieldValue('time', e?.target?.value);
                }}
              />
              {formik.errors.time && (
                <p className="fs-8 text-danger mb-0">{formik.errors.time}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                id="startDate"
                min={new Date().toISOString().split('T')[0]}
                placeholder="Start date"
                required
                value={formik?.values?.date}
                onChange={(e) => {
                  formik.setFieldValue('date', e?.target?.value);
                }}
              />
              {formik.errors.date && (
                <p className="fs-8 text-danger mb-0">{formik.errors.date}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Course</Form.Label>
              <MultiSelectDropdown
                options={courseOptions}
                selected={formik.values?.course_id}
                setSelected={(e) => formik.setFieldValue('course_id', e)}
                placeholder={'Select course'}
                isLoading={isLoadingCoursesLite}
              />
              {formik.errors.course_id && formik.touched.course_id && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.course_id}
                </p>
              )}
            </Form.Group>
          </Form>
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        {/*  Action Buttons  */}
        <Button
          variant="outline-white"
          onClick={handleClose}
          disabled={isLoading || isUpdating}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setValidateOnChange(true);
            formik.handleSubmit();
          }}
          disabled={!formik.isValid || isLoading || isUpdating}
          loading={isLoading || isUpdating}
        >
          {type == 'edit' ? 'Edit Live Class' : 'Add Live Class'}
        </Button>
      </Modal.Footer>
      <ToastContainer />
    </Modal>
  );
};

export default AddNewLiveClass;
