import React from 'react';
import { Row, Spinner } from 'react-bootstrap';

export const Loader = ({ isFullScreen = true, height }) => {
  return (
    <Row
      className={`d-flex justify-content-center align-items-center ${
        isFullScreen ? 'min-vh-100' : ''
      }`}
      style={{ height: height }}
    >
      <Spinner style={{ color: '#e0bf00' }} />
    </Row>
  );
};
