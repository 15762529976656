// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
  Form,
  FormControl,
  InputGroup,
  Button,
  Col,
  Modal,
  Spinner,
  DropdownButton,
  Dropdown,
  Row,
} from 'react-bootstrap';

// import custom components
import MultiSelectDropdown from 'pages/components/MultiSelectDropdown';
import {
  useCreateProblemMutation,
  useUpdateProblemMutation,
} from 'pages/redux/features/user/userApi';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { convertToYesNo, timeFormatCheck } from 'pages/helper/formatText';
import { extractObjectValues } from 'pages/helper/formatObjectToArray';
import { getPropertyArray } from 'pages/helper/formatArray';
import { logFormData, objectToFormData } from 'pages/helper/objectToFormData';

const AddProblem = ({
  show,
  handleClose,
  type,
  id,
  parent_type,
  initialState,
}) => {
  const [createProblem, { isLoading: isCreatingProblem }] =
    useCreateProblemMutation();

  const [updateProblem, { isLoading: isUpdatingProblem }] =
    useUpdateProblemMutation();

  const handleProblemCreation = async (data) => {
    const res =
      type == 'edit'
        ? await updateProblem({ id: initialState?.id, data })
        : await createProblem(data);
    if (res?.data?.success) {
      toast.success(res?.data?.message || 'Successful!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    } else {
      toast.error(res?.error?.data?.message || 'Failed!', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const validationSchema = Yup.object().shape({
    statement: Yup.string().required('Required').typeError('Text expected'),
    type: Yup.object().required('Required').typeError('Text expected'),
    correct_options: Yup.mixed()
      .when('type_value', {
        is: 'multiselect',
        then: Yup.array().required().typeError('Required'),
      })
      .when('type_value', {
        is: 'multichoice',
        then: Yup.object().required().typeError('Required'),
      })
      .when('type_value', {
        is: 'binary',
        then: Yup.object().required().typeError('Required'),
      })
      .typeError('Text expected'),
    parent_id: Yup.string().required('Required').typeError('Text expected'),
    parent_type: Yup.string().required('Required').typeError('Text expected'),
    number: Yup.number().required('Required').typeError('Number expected'),
    has_attachment: Yup.boolean()
      .required('Required')
      .typeError('Yes/No expected'),
    score: Yup.number()
      .required('Required')
      .min(1)
      .typeError('Number expected')
      .label('Score'),
    optionA: Yup.string()
      .when('type_value', {
        is: 'multiselect',
        then: Yup.string().required().typeError('Text expected'),
      })
      .when('type_value', {
        is: 'multichoice',
        then: Yup.string().required().typeError('Text expected'),
      })
      // .when('type_value', {
      //   is: 'binary',
      //   then: Yup.string().required().typeError('Text expected'),
      // })
      .label('Option A'),
    optionB: Yup.string()
      .when('type_value', {
        is: 'multiselect',
        then: Yup.string().required().typeError('Text expected'),
      })
      .when('type_value', {
        is: 'multichoice',
        then: Yup.string().required().typeError('Text expected'),
      })
      // .when('type_value', {
      //   is: 'binary',
      //   then: Yup.string().required().typeError('Text expected'),
      // })
      .label('Option B'),
    optionC: Yup.string()
      .when('type_value', {
        is: 'multiselect',
        then: Yup.string().required().typeError('Text expected'),
      })
      .when('type_value', {
        is: 'multichoice',
        then: Yup.string().required().typeError('Text expected'),
      })
      .label('Option C'),
    optionD: Yup.string()
      .when('type_value', {
        is: 'multiselect',
        then: Yup.string().required().typeError('Text expected'),
      })
      .when('type_value', {
        is: 'multichoice',
        then: Yup.string().required().typeError('Text expected'),
      })
      .label('Option D'),
  });

  const typeOptions = [
    { label: 'Multichoice', value: 'multichoice' },
    { label: 'Multiselect', value: 'multiselect' },
    { label: 'Binary', value: 'binary' },
    { label: 'Essay', value: 'essay' },
  ];
  const questionOptions = [
    { label: 'A', value: 'a', placeholder: 'Option A' },
    { label: 'B', value: 'b', placeholder: 'Option B' },
    { label: 'C', value: 'c', placeholder: 'Option C' },
    { label: 'D', value: 'd', placeholder: 'Option D' },
  ];
  const questionOptions1 = [
    { label: 'Yes', value: 'yes', placeholder: 'Option A' },
    { label: 'No', value: 'no', placeholder: 'Option B' },
  ];

  const formik = useFormik({
    initialValues: {
      statement: initialState?.statement || '',
      score: initialState?.score || 0,
      type:
        typeOptions?.find((type) => initialState?.type == type?.value) || '',
      type_value: initialState?.type || '',
      correct_options:
        initialState?.type == 'binary'
          ? questionOptions1?.find(
              (option) =>
                initialState?.correct_options[0]?.label == option?.value
            )
          : initialState?.type == 'multichoice'
          ? questionOptions?.filter(
              (option) =>
                initialState?.correct_options[0]?.label == option?.value
            )
          : initialState?.type == 'multiselect'
          ? questionOptions?.filter((option) =>
              initialState?.correct_options?.find(
                (x) => x?.label == option?.value
              )
            )
          : null,
      parent_id: id || null,
      parent_type: parent_type || null,
      number: initialState?.number || '',
      has_attachment:
        questionOptions1?.find(
          (option) =>
            convertToYesNo(initialState?.has_attachment) == option?.value
        ) || false,
      score: initialState?.score || 0,
      optionA: initialState?.options?.find((x) => x?.label == 'a')?.text || '',
      optionB: initialState?.options?.find((x) => x?.label == 'b')?.text || '',
      optionC: initialState?.options?.find((x) => x?.label == 'c')?.text || '',
      optionD: initialState?.options?.find((x) => x?.label == 'd')?.text || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      const resolveType =
        typeof values?.type == 'object' ? values?.type?.value : values?.type;
      const resolveValues = {
        ...values,
        type: resolveType,
        correct_options: Array.isArray(values?.correct_options)
          ? getPropertyArray(values?.correct_options, 'value')
          : resolveType == 'essay'
          ? null
          : [values?.correct_options?.value],
        has_attachment:
          typeof values?.has_attachment == 'string'
            ? JSON.parse(values?.has_attachment)
              ? 1
              : 0
            : values?.has_attachment
            ? 1
            : 0,
        option_keys:
          resolveType == 'multichoice' || resolveType == 'multiselect'
            ? ['a', 'b', 'c', 'd']
            : // : resolveType == 'binary'
              // ? ['a', 'b']
              null,
        option_values:
          resolveType == 'multichoice' || resolveType == 'multiselect'
            ? [
                values?.optionA,
                values?.optionB,
                values?.optionC,
                values?.optionD,
              ]
            : // : resolveType == 'binary'
              // ? [values?.optionA, values?.optionB]
              null,
      };
      delete resolveValues?.optionA;
      delete resolveValues?.optionB;
      delete resolveValues?.optionC;
      delete resolveValues?.optionD;
      delete resolveValues?.type_value;
      const formData = objectToFormData(resolveValues);
      logFormData(formData);
      handleProblemCreation(formData);
    },
  });

  useEffect(() => {
    formik?.values?.type &&
      formik.setFieldValue('type_value', formik?.values?.type?.value);
  }, [formik?.values?.type]);
  useEffect(() => {
    if (
      formik?.values?.type_value == 'multiselect' &&
      !Array.isArray(formik?.values?.correct_options)
    ) {
      formik.setFieldValue('correct_options', [
        formik?.values?.correct_options?.value !== 'yes' &&
        formik?.values?.correct_options?.value !== 'no'
          ? formik?.values?.correct_options
          : questionOptions[0],
      ]);
    }
    if (
      formik?.values?.type_value == 'multichoice' &&
      Array.isArray(formik?.values?.correct_options)
    ) {
      formik.setFieldValue(
        'correct_options',
        formik?.values?.correct_options[0]
      );
    }
    if (
      formik?.values?.type_value == 'multichoice' &&
      !Array.isArray(formik?.values?.correct_options)
    ) {
      formik.setFieldValue(
        'correct_options',
        formik?.values?.correct_options?.value !== 'yes' &&
          formik?.values?.correct_options?.value !== 'no'
          ? formik?.values?.correct_options
          : questionOptions[0]
      );
    }
    if (formik?.values?.type_value == 'binary') {
      formik.setFieldValue('correct_options', questionOptions1[0]);
    }
  }, [formik?.values?.type_value]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {type == 'edit' ? 'Edit Problem' : 'Add Problem'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Fragment>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Number</Form.Label>
              <Form.Control
                required
                type="number"
                id="number"
                placeholder="Number"
                value={formik.values?.number}
                onChange={formik.handleChange}
              />
              {formik.errors.number && (
                <p className="fs-8 text-danger mb-0">{formik.errors.number}</p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Statement</Form.Label>
              <Form.Control
                as="textarea"
                rows={2}
                id="statement"
                placeholder="Statement"
                required
                value={formik.values?.statement}
                onChange={formik.handleChange}
              />
              {formik.errors.statement && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors.statement}
                </p>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type</Form.Label>
              <MultiSelectDropdown
                options={typeOptions}
                selected={formik.values?.type}
                setSelected={(e) => {
                  formik.setFieldValue('type', e);
                }}
                placeholder={'Select type'}
              />
              {formik.errors.type && (
                <p className="fs-8 text-danger mb-0">{formik.errors.type}</p>
              )}
            </Form.Group>

            {formik.values?.type?.value == 'multichoice' ||
            formik.values?.type?.value == 'multiselect' ? (
              <>
                <Form.Group className="mb-3">
                  <Form.Label sm={2}>Options</Form.Label>
                  {(formik.values?.type?.value == 'binary'
                    ? questionOptions1
                    : questionOptions
                  )?.map((option) => (
                    <>
                      <InputGroup>
                        <InputGroup.Text>{option?.label}</InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder={option?.placeholder}
                          id={'option' + option?.label}
                          value={formik.values['option' + option?.label]}
                          onChange={formik.handleChange}
                        />
                      </InputGroup>
                      {formik.errors['option' + option?.label] && (
                        <p className="fs-8 text-danger mb-0">
                          {formik.errors['option' + option?.label]}
                        </p>
                      )}
                    </>
                  ))}
                </Form.Group>
              </>
            ) : null}

            {(formik.values?.type?.value == 'multichoice' ||
              formik.values?.type?.value == 'multiselect' ||
              formik.values?.type?.value == 'binary') && (
              <Form.Group className="mb-3">
                <Form.Label>Correct option(s)</Form.Label>
                <MultiSelectDropdown
                  options={
                    formik.values?.type?.value == 'binary'
                      ? questionOptions1
                      : questionOptions
                  }
                  selected={formik.values?.correct_options}
                  setSelected={(e) => {
                    formik.setFieldValue('correct_options', e);
                  }}
                  placeholder={'Select option(s)'}
                  isMulti={formik.values?.type?.value == 'multiselect'}
                />
                {formik.errors?.correct_options && (
                  <p className="fs-8 text-danger mb-0">
                    {formik.errors?.correct_options}
                  </p>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Score</Form.Label>
              <Form.Control
                required
                type="number"
                id="score"
                placeholder="Number"
                value={formik.values?.score}
                onChange={formik.handleChange}
              />
              {formik.errors.score && (
                <p className="fs-8 text-danger mb-0">{formik.errors.score}</p>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Has Attachment</Form.Label>
              <Form.Control
                as="select"
                id="has_attachment"
                placeholder="Yes/No"
                required
                value={formik.values?.has_attachment}
                onChange={formik.handleChange}
              >
                <option value={null}>Select</option>
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
              {formik.errors?.has_attachment && (
                <p className="fs-8 text-danger mb-0">
                  {formik.errors?.has_attachment}
                </p>
              )}
            </Form.Group>
          </Form>
          {/* {formik.errors && (
            <p className="fs-8 text-danger mb-0">
              {JSON.stringify(formik.errors)}
            </p>
          )} */}
        </Fragment>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between border-0 pt-0">
        <Button variant="outline-white" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          style={{ width: 120 }}
          onClick={formik.handleSubmit}
          disabled={isCreatingProblem || isUpdatingProblem || !formik.isValid}
        >
          {isCreatingProblem || isUpdatingProblem ? (
            <Spinner size="sm" />
          ) : (
            'Confirm'
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProblem;
